// AttendanceTable.js - holds validation details on attendance
import React from 'react'
import PropTypes from 'utils/propTypes'
import { Table } from 'reactstrap'
import { localDisplayTime, displayDurationMins } from 'utils/dates.js'
import { Query } from 'react-apollo'
import { DEFAULT_ERROR_MSG } from 'constants/text.js'
import { queries, utils } from '../../graphql'
import LoadingPlaceHolder from '../LoadingPlaceHolder'
import Roles from 'utils/roles.js'
import { BIO_VERIFY_TYPE, AttendanceReasonMsg, AttendanceStatuses, AttendanceStatusMsg } from 'constants/attendance.js'
import Avatar from '../Avatar.js'
import UpdateStatusForm from './UpdateStatusForm.js'
import { sortByPendingStatus, genAttendanceDates } from 'utils/attendance.js'
import AttendanceNoteModal from './AttendanceNoteModal.js'
import * as moment from 'moment-timezone'

const defaultHeaders = ['Status', 'Invalid', 'Meeting', 'Location', 'Start time', 'Duration', 'Check in', 'Check out', 'Time left geo', 'Battery %', 'Check in biometric', 'Check out biometric', 'Notes']
const noPrintHeaders = ['Location', 'Notes']

const VerificationRow = ({verification}) => {
  if (verification.biometricVerificationType) {
    return (<span>{BIO_VERIFY_TYPE(verification.biometricVerificationType)}</span>)
  } else if (verification.photoUrl) {
    return (<Avatar size={50} src={verification.photoUrl} />)
  } else {
    return (<span>{'None'}</span>)
  }
}

const InvalidReasonsRow = ({invalidReasons}) => {
  const meetingItems = invalidReasons.map(reason =>
    <li key={reason}>{AttendanceReasonMsg(reason)}</li>
  )

  return (
    <ul>
      { meetingItems.length ? meetingItems : <li>N/A</li> }
    </ul>
  )
}

const getBatteryPercentage = (battery) => {
  if (parseFloat(battery)) {
    return battery > 0 ? `${battery * 100}%` : 'N/A'
  }
  return 'N/A'
}

class AttendanceTable extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      activeDropdown: null
    }
  }

  reportDuration = client => {
    if (this.props.start && this.props.end) {
      return localDisplayTime(this.props.start, 'll') + '-' + localDisplayTime(this.props.end, 'll')
    } else {
      return localDisplayTime(client.createdAt, 'll') + '-' + localDisplayTime(new Date(), 'll')
    }
  }

  toggle = () => this.setState(prevState => ({dropdownOpen: !prevState.dropdownOpen}))

  render () {
    const { headers, client, authUser, roleState, allowEditStatus, validationStatuses, ...restProps } = this.props
    const isClient = roleState === Roles.CLIENT
    let start, end
    if (this.props.start && this.props.end) {
      start = moment(this.props.start).format('MM/DD/YYYY')
      end = moment(this.props.end).format('MM/DD/YYYY')
    } else {
      const [startDate, endDate] = genAttendanceDates(client)
      start = startDate
      end = endDate
    }

    const authHeader = utils.generateAuthHeader(authUser.token)
    const query = isClient ? queries.selfActivityQuery : queries.clientsActivityQuery
    const params = isClient ? {startDate: start, endDate: end} : {clientId: client.id, startDate: start, endDate: end}
    return (
      <Table responsive hover {...restProps}>
        <thead>
          <tr className='text-capitalize text-center'>
            <th colSpan={headers.length}><h5>{client.lastName}, {client.firstName} ({this.reportDuration(client)})</h5></th>
          </tr>
          <tr className='text-capitalize text-center'>
            {headers.map((item, index) => <th className={noPrintHeaders.includes(item) ? 'd-print-none' : null} key={index}>{item}</th>)}
          </tr>
        </thead>
        <tbody>
          <Query query={query} variables={params} context={authHeader}>
            {({ loading, error, data }) => {
              if (error) return <tr><td colSpan={headers.length}><div>{DEFAULT_ERROR_MSG}</div></td></tr>
              if (loading) return <tr><td colSpan={headers.length}><LoadingPlaceHolder /></td></tr>

              const results = isClient ? data.attendanceByDate : data.clientsAttendanceByDate

              return results.filter(attendance => validationStatuses.includes(attendance.validation.status)).sort(sortByPendingStatus)
                .map(({ id, validation, meetingStartTime, meetingDurationMins, checkIn, checkOut, checkInBatteryLevel, meeting, isVirtual, checkInVerification, checkOutVerification, notes }, index) => (
                  <tr key={index}>
                    { allowEditStatus && !isClient
                      ? (<React.Fragment><td className='text-center d-none d-print-block'>{AttendanceStatusMsg(validation.status)}</td>
                        <td className='text-center d-print-none'><UpdateStatusForm clientId={client.id} attendanceId={id} validationStatus={validation.status} /></td></React.Fragment>)
                      : (<React.Fragment><td className='text-center'>{AttendanceStatusMsg(validation.status)}</td></React.Fragment>)
                    }
                    <td className='text-left'><InvalidReasonsRow {...validation} /></td>
                    <td className='text-center'>{meeting.name}</td>
                    <td className='text-center d-print-none'>{isVirtual ? 'Virtual meeting' : `${meeting.address.city}, ${meeting.address.state}`}</td>
                    <td className='text-center'>{localDisplayTime(meetingStartTime, 'ddd, MM/DD/YYYY h:mmA')}</td>
                    <td className='text-center'>{displayDurationMins(meetingDurationMins)}</td>
                    <td className='text-center'>{localDisplayTime(checkIn, 'h:mmA')}</td>
                    <td className='text-center'>
                      {!checkOutVerification.biometricVerificationType && validation.invalidReasons.indexOf('LEFT_EARLY') > -1 ? 'AUTO @ ' + localDisplayTime(checkOut, 'h:mmA') : localDisplayTime(checkOut, 'h:mmA')}
                    </td>
                    <td className='text-center'>
                      {
                        isVirtual ? 'Virtual Meeting'
                          : !checkOutVerification.biometricVerificationType && validation.invalidReasons.indexOf('LEFT_EARLY') > -1
                            ? localDisplayTime(checkOut, 'h:mmA')
                            : 'N/A'
                      }
                    </td>
                    <td className='text-center'>{getBatteryPercentage(checkInBatteryLevel)}</td>
                    <td className='text-center'><VerificationRow verification={checkInVerification} /></td>
                    <td className='text-center'><VerificationRow verification={checkOutVerification} /></td>
                    <td className='text-center text-nowrap d-print-none'>{notes ? (<AttendanceNoteModal notes={notes} meeting={meeting} meetingStartTime={meetingStartTime} />) : (<span>{'-'}</span>)}</td>
                    <td className='text-center d-print-none'>{notes ? (<span>{notes}</span>) : (<span>{'-'}</span>)}</td>
                  </tr>
                ))
            }}
          </Query>
        </tbody>
      </Table>
    )
  }
}

AttendanceTable.propTypes = {
  headers: PropTypes.node,
  client: PropTypes.object.isRequired,
  roleState: PropTypes.oneOf([Roles.CLIENT, Roles.PROBATION_OFFICER, Roles.CASE_MANAGER]).isRequired,
  validationStatuses: PropTypes.array.isRequired,
  allowEditStatus: PropTypes.bool.isRequired
}

AttendanceTable.defaultProps = {
  headers: defaultHeaders,
  validationStatuses: Object.values(AttendanceStatuses),
  allowEditStatus: false

}

export default AttendanceTable
