import React from 'react'
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng
} from 'react-places-autocomplete'
import { Input } from 'reactstrap'
import { TBVError } from 'components/TBVForm'

export class LocationSearchInput extends React.Component {
  state = { address: this.props.form.values[this.props.field.name] || '' }

  handleChange = address => {
    this.setState({ address })
  }

  reset = () => {
    this.props.form.setFieldValue('streetAddress', '')
    this.props.form.setFieldValue('city', '')
    this.props.form.setFieldValue('state', '')
    this.props.form.setFieldValue('postalCode', '')
    this.props.form.setFieldValue('latitude', '')
    this.props.form.setFieldValue('longitude', '')
  }

  handleSelect = async (address) => {
    const result = await geocodeByAddress(address)
    if (result) {
      this.reset()
    }

    const geocodedAddress = result[0]
    this.setState({ address: geocodedAddress.formatted_address })
    const addressComp = geocodedAddress.address_components
    let streetNumber = addressComp.find(c => c.types.indexOf('street_number') !== -1)
    streetNumber = streetNumber && streetNumber.long_name
    let street = addressComp.find(c => c.types.indexOf('route') !== -1)
    street = street && street.long_name
    let city = addressComp.find(c => c.types.indexOf('locality') !== -1)
    city = city && city.long_name
    let state = addressComp.find(c => c.types.indexOf('administrative_area_level_1') !== -1)
    state = state && state.long_name
    let postalCode = addressComp.find(c => c.types.indexOf('postal_code') !== -1)
    postalCode = postalCode && postalCode.long_name
    const streetAddress = `${streetNumber} ${street}`.trim()
    this.props.form.setFieldValue('streetAddress', streetAddress)
    this.props.form.setFieldValue('city', city)
    this.props.form.setFieldValue('state', state)
    this.props.form.setFieldValue('postalCode', postalCode)
    const { lat, lng } = await getLatLng(geocodedAddress)
    this.props.form.setFieldValue('latitude', lat)
    this.props.form.setFieldValue('longitude', lng)
  }

  hasErrors = () => {
    const errors = this.props.form.errors
    return this.props.form.touched[this.props.field.name] &&
        (!!errors['streetAddress'] ||
        !!errors['city'] ||
        !!errors['state'] ||
        !!errors['postalCode'] ||
        !!errors['latitude'] ||
        !!errors['longitude'])
  }

  handleBlur = () => {
    this.props.form.setFieldTouched(this.props.field.name, true)
  }

  render () {
    const { disabled } = this.props
    return (
      <PlacesAutocomplete
        value={this.state.address}
        onChange={this.handleChange}
        onSelect={this.handleSelect}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <React.Fragment>
            <Input
              {...getInputProps({
                disabled,
                placeholder: 'Search by place name or address...',
                className: 'location-search-input'
              })}
              onBlur={this.handleBlur}
            />
            { this.hasErrors() && (
              <TBVError message={'Valid address is required'} className='text-danger' />
            )}
            <div className='autocomplete-dropdown-container'>
              {loading && <div>Loading...</div>}
              {suggestions.map(suggestion => {
                const className = suggestion.active
                  ? 'suggestion-item--active'
                  : 'suggestion-item'
                const style = suggestion.active
                  ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                  : { backgroundColor: '#ffffff', cursor: 'pointer' }
                return (
                  <div
                    {...getSuggestionItemProps(suggestion, {
                      className,
                      style
                    })}
                  >
                    <span>{suggestion.description}</span>
                  </div>
                )
              })}
            </div>
          </React.Fragment>
        )}
      </PlacesAutocomplete>
    )
  }
}
