// AttendanceTable.js - holds meeting attendance for clients

import React from 'react'
import PropTypes from 'utils/propTypes'
import {SortDropdown, dropdownItems} from '../Dashboard/SortDropdown'
import AttendanceRow from './AttendanceRow'

class AttendanceTable extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      dropdownOpen: false,
      sort: dropdownItems[0]
    }
  }

  toggle = () => this.setState(prevState => ({dropdownOpen: !prevState.dropdownOpen}))

  render () {
    const { clientsData } = this.props
    const { sort, dropdownOpen } = this.state
    const sortedClientsData = [...clientsData].sort(sort.sort)

    return (
      <div className='attendance-table'>
        <div className='p-4 float-left'>
          <span className='font-weight-bold' style={{fontSize: 22}}>Number of meetings attended</span>
        </div>
        <div className='d-flex float-right mt-4 mr-2'>
          <SortDropdown title={sort.title} dropdownOpen={dropdownOpen} toggle={this.toggle} onItemClick={item => this.setState({sort: item})} />
        </div>
        <div className='attendance-table__body'>
          {sortedClientsData.map((client, i) => (
            <AttendanceRow key={i} client={client} />
          ))}
        </div>
      </div>
    )
  }
}

AttendanceTable.propTypes = {
  clientsData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      photoUrl: PropTypes.string,
      firstName: PropTypes.string,
      meetingsAttended: PropTypes.number,
      meetingsRequiredPerMonth: PropTypes.number
    })
  )
}

AttendanceTable.defaultProps = {
  clientsData: []
}

export default AttendanceTable
