// ClientRepots.js - client reports

import React from 'react'
import { compose } from 'recompose'
import { NavLink as ReactNavLink } from 'react-router-dom'
import PropTypes from 'prop-types'
import { graphql, Query } from 'react-apollo'
import { queries, mutations, utils } from '../../graphql'
import { Row, Col, Card, ListGroup, ListGroupItem } from 'reactstrap'
import {
  ActivityFeed,
  ManagerNotesFeed,
  WeeklyAttendanceGraph,
  WeeklyAttendanceTable,
  MonthlyAttendanceGraph
} from 'components/Client'
import AddNoteForm from 'components/AddNoteForm'
import LoadingPlaceHolder from 'components/LoadingPlaceHolder'
import bn from 'utils/bemnames'
import ContactCard from '../Card/ContactCard'
import { ChatWithClient, EditClientInfo } from '../Card'
// import EmailReportModal from '../EmailReportModal.js'
import PrintAttendanceModal from '../PrintAttendanceModal.js'
import { genFullName } from 'utils/user.js'
import Roles from 'utils/roles.js'
import TBVButton from '../TBVButton.js'
import { MANAGER_CLIENT_ATTENDANCE } from 'constants/routes.js'
import { ATTENDANCE_LIST_TITLE, VIEW_MEETINGS, MEETINGS_REQUIRED_LABEL, MEETINGS_VERIFIED_LABEL, DEFAULT_ERROR_MSG } from 'constants/text.js'
import { MeetingsAttended, RequiredMeetings } from '../Icons'
import { getColor } from 'utils/colors.js'
import { AttendanceStatuses } from 'constants/attendance.js'
import { genAttendanceDates, getAttendancesStatusCount } from 'utils/attendance.js'
import Hidable from '../Hidable'
import MessageForm from './MessageForm'

const bem = bn.create('client-report')

const ClientReports = (props) => {
  const { authUser, clientId, roleState, manager } = props
  const data = {id: clientId}
  const EditCurrClientInfo = () => (<EditClientInfo id={clientId} />)
  const ChatCurrClient = () => (<ChatWithClient id={clientId} />)
  const sendMessage = async (variables) => {
    return props.sendClientSms({variables: variables, context: utils.generateAuthHeader(authUser.token)})
  }

  return (
    <Query query={queries.getClientQuery} variables={data} context={authUser.authHeader}>
      {({ loading, error, data }) => {
        if (error) return <div>{DEFAULT_ERROR_MSG}</div>
        if (loading) return <LoadingPlaceHolder />
        const {
          meetingsRequiredPerMonth,
          meetingsAttended,
          photoUrl,
          latestAttendanceActivity,
          weeklyAttendance,
          firstName,
          lastName,
          meetingsRequiredPerWeek,
          managerId
          /* id */
        } = data.user

        const fullName = genFullName(firstName, lastName)
        const PrintCurrClient = () => (<PrintAttendanceModal roleState={roleState} client={data.user} authUser={authUser} />)
        // const EmailReportModalCurrClient = () => (<EmailReportModal authUser={authUser} clientId={id} firstName={firstName} lastName={lastName} />)
        const readonly = managerId !== manager.id
        // for attendance activity query
        const [startDate, endDate] = genAttendanceDates(data.user)

        return (
          <Row className={bem.b()}>
            <Col md={{size: 10, offset: 1}}>
              <Row>
                <Col md={6}>
                  <ContactCard user={data.user} >
                    <div className='d-flex'>
                      <div className='d-flex flex-column text-center flex-grow-1'>
                        <div className='d-flex justify-content-center'>
                          <RequiredMeetings size={65} fillObj={getColor('aquamarine')} />
                        </div>
                        <h1><b>{meetingsRequiredPerMonth}</b></h1>
                        <small><b>{MEETINGS_REQUIRED_LABEL}</b></small>
                      </div>
                      <div className='d-flex flex-column text-center flex-grow-1'>
                        <div className='d-flex justify-content-center'>
                          <MeetingsAttended size={65} fillObj={getColor('aquamarine')} />
                        </div>
                        <h1><b>{meetingsAttended}</b></h1>
                        <small><b>{MEETINGS_VERIFIED_LABEL}</b></small>
                      </div>
                    </div>
                    <Hidable show={!readonly}>
                      <div>
                        <hr className='w-75 border-lightBlueGrey mt-4 border-bottom' />
                      </div>
                      <div className='d-flex justify-content-center'>
                        <ListGroup className='d-flex flex-column pl-4'>
                          <div className='flex-grow-1 p-2'>
                            <ListGroupItem tag={PrintCurrClient} />
                          </div>
                          <div className='flex-grow-1 p-2'>
                            <ListGroupItem tag={ChatCurrClient} />
                          </div>
                        </ListGroup>
                        <ListGroup className='d-flex flex-column pr-4'>
                          {/* <div className='flex-grow-1 p-2'>
                            <ListGroupItem tag={EmailReportModalCurrClient} />
                          </div> */}
                          <div className='flex-grow-1 p-2'>
                            <ListGroupItem tag={EditCurrClientInfo} />
                          </div>
                        </ListGroup>
                      </div>
                    </Hidable>
                  </ContactCard>

                  <Hidable show={!readonly}>
                    <Card className='border-0 tbv-box-shadow mb-3'>
                      <div className='d-flex justify-content-between w-100 pt-3 pl-4 pr-4'>
                        <h4 className='font-weight-bold'>{ATTENDANCE_LIST_TITLE}</h4>
                      </div>
                      <div>
                        <hr className='w-100 border-lightBlueGrey border-bottom' />
                      </div>
                      <div className='d-flex flex-column text-center flex-grow-1'>
                        <Query query={queries.clientsActivityQuery} variables={{clientId: clientId, startDate: startDate, endDate: endDate}} context={authUser.authHeader}>
                          {({ loading: activityLoading, error: activityError, data: activityData }) => {
                            if (activityError) return <div>{DEFAULT_ERROR_MSG}</div>
                            if (activityLoading) return <LoadingPlaceHolder />
                            const pendingCount = getAttendancesStatusCount(activityData.clientsAttendanceByDate, AttendanceStatuses.PENDING)
                            return (
                              <p className='font-size-lg'>You have <span className='text-bluish'><b>{pendingCount}</b></span> meetings to verify.</p>
                            )
                          }}
                        </Query>
                      </div>
                      <div className='d-flex flex-column text-center flex-grow-1 m-3 pl-5 pr-5'>
                        <ReactNavLink to={MANAGER_CLIENT_ATTENDANCE + clientId}>
                          <TBVButton block title={VIEW_MEETINGS} />
                        </ReactNavLink>
                      </div>
                    </Card>
                  </Hidable>

                  <Card className='border-0 tbv-box-shadow mb-3'>
                    <div className='d-flex justify-content-between w-100 p-4'>
                      <h4 className='font-weight-bold'>Notes</h4>
                    </div>
                    <Query query={queries.managerNotesQuery} variables={{clientId: clientId}} context={authUser.authHeader}>
                      {({ loading: notesLoading, error: notesError, data: notesData }) => {
                        if (notesError) return <div>{DEFAULT_ERROR_MSG}</div>
                        if (notesLoading) return <LoadingPlaceHolder />
                        return (
                          <ManagerNotesFeed notes={notesData} />
                        )
                      }}
                    </Query>
                    <Hidable show={!readonly}>
                      <AddNoteForm clientId={clientId} />
                    </Hidable>
                  </Card>
                </Col>

                <Col className='float-right' md={6} xs={12}>
                  <Card className='border-0 tbv-box-shadow pb-3 mb-3'>
                    <WeeklyAttendanceGraph authUser={authUser} reqMeetings={meetingsRequiredPerWeek} clientId={clientId} />
                  </Card>

                  <Card className='border-0 tbv-box-shadow pb-3 mb-3'>
                    <div className='d-flex justify-content-between w-100 p-4'>
                      <h4 className='font-weight-bold'>Attendance This Week</h4>
                    </div>
                    <WeeklyAttendanceTable weeklyAttendance={weeklyAttendance} />
                  </Card>

                  <Card className='border-0 tbv-box-shadow pb-3 mb-3'>
                    <div className='d-flex justify-content-between w-100 p-4'>
                      <h4 className='font-weight-bold'>Recent Activity</h4>
                    </div>
                    <ActivityFeed latestAttendance={latestAttendanceActivity} name={fullName} photoUrl={photoUrl} />
                  </Card>
                  <Hidable show={!readonly}>
                    <MessageForm
                      className='border-0 tbv-box-shadow pb-3 mb-3'
                      title={`Send a message to ${firstName}`}
                      clients={[data.user]}
                      submit={sendMessage}
                    />
                  </Hidable>
                </Col>
              </Row>

              <Row>
                <Col md={12}>
                  <Card className='border-0 tbv-box-shadow pb-3 mb-3'>
                    <MonthlyAttendanceGraph authUser={authUser} reqMeetings={meetingsRequiredPerMonth} clientId={clientId} />
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
        )
      }}
    </Query>
  )
}

ClientReports.propTypes = {
  authUser: PropTypes.object.isRequired,
  clientId: PropTypes.string.isRequired,
  roleState: PropTypes.oneOf([Roles.PROBATION_OFFICER, Roles.CASE_MANAGER]).isRequired
}

export default compose(graphql(mutations.SEND_CLIENT_SMS, {name: 'sendClientSms'}))(ClientReports)
