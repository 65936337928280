// InviteSignUp.js - for client signups via manager invites

import React from 'react'
import Log from 'utils/logging.js'

import ClientSignUpForm from './SignUp.js'

import { withInvite } from 'components/Session'

import { DURATION_DAYS } from 'constants/forms.js'

const InviteSignUp = (props) => {
  const {
    invite
  } = props

  const initialData = {
    firstName: invite.firstName || '',
    lastName: invite.lastName || '',
    email: invite.email || '',
    phoneNumber: invite.phone || '',
    duration: invite.durationDays || '',
    durationUnit: DURATION_DAYS,
    reqMeetings: invite.reqMeetings || '',
    reqMeetingsUnit: invite.reqMeetingsFreq || '',
    probationId: invite.probationId || ''
  }
  Log.info(initialData)
  Log.info(invite.token)
  Log.info('invite signup')
  return (
    <ClientSignUpForm inviteToken={invite.token} initialData={initialData} {...props} />
  )
}

export default withInvite(InviteSignUp)
