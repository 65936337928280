// AdminLayout.js - the dashoard layout

import React from 'react'
import { Content, Footer, AdminHeader } from 'components/Layout'
import { withAdmin } from 'components/Session'
import { Col, Row } from 'reactstrap'
import AdminMenu from './AdminMenu'

class AdminLayout extends React.Component {
  render () {
    const { children, authUser, admin, title } = this.props
    const whiteHeaderColor = true
    return (
      <main className='tbv-app bg-light'>
        <Content fluid className={whiteHeaderColor ? 'tbv-dash tbv-dash-white pl-2 pr-2 pr-sm-4 pr-md-5' : 'tbv-dash'}>
          <AdminHeader white={whiteHeaderColor} admin={admin} authUser={authUser} />
          <Row className='tbv-dash-title tbv-dash-admin-title d-flex flex-column justify-content-center'>
            <Col md={{size: 10, offset: 1}}>
              <h4 className='font-weight-bold pl-15px'>{title}</h4>
            </Col>
          </Row>
          <Row className='ml-0'>
            <Col className='pl-0' md={{size: 10, offset: 1}}>
              <AdminMenu />
            </Col>
          </Row>
          <Row className='ml-0'>
            <Col className='pl-0' md={{size: 10, offset: 1}}>
              {children}
            </Col>
          </Row>
          <Footer />
        </Content>
      </main>
    )
  }
}

export default withAdmin(AdminLayout)
