import React from 'react'
import { graphql } from 'react-apollo'
import { mutations, utils } from '../../graphql'
import { compose, pure } from 'recompose'
import PropTypes from 'prop-types'
import { Col, Row, Card, Table, Button } from 'reactstrap'
import bn from 'utils/bemnames'
import Hidable from '../Hidable'
import { withRouter } from 'react-router'
import InviteManager from './InviteManager'
import ManagersListItem from './ManagersListItem'

const bem = bn.create('managers')
class ManagersComponent extends React.Component {
  state = {
    managerModalOpen: false
  }
  toggleInvite = () => {
    this.setState({
      managerModalOpen: !this.state.managerModalOpen
    })
  }
  assignManager = async (managerId, clientId) => {
    await this.props.UpdateClientManager({ variables: { managerId, clientId },
      context: utils.generateAuthHeader(this.props.authUser.token)
    })
    window.location.reload()
  }
  render () {
    const { managers } = this.props
    const managerOptions = managers.map(m => ({ value: m.id, text: `${m.firstName} ${m.lastName}` }))
    return (
      <Row className={bem.b()}>
        <Col>
          <Card>
            <div>
              <div className='pt-3 pr-3 float-right' onClick={this.toggleInvite}>
                <Button outline color='primary'>Invite manager</Button>
              </div>
            </div>
            <Hidable show={managers.length}>
              <div className='d-flex justify-content-between w-100 p-4'>
                <Table responsive>
                  <thead>
                    <tr>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Role</th>
                      <th>&nbsp;</th>
                    </tr>
                  </thead>
                  <tbody>
                    {managers.map((m, i) => {
                      return (
                        <ManagersListItem key={i} manager={m} options={managerOptions} assignManager={this.assignManager} />
                      )
                    })}
                  </tbody>
                </Table>
              </div>
            </Hidable>
            <Hidable show={!managers.length}>
              <div className='w-100 p-4 text-center'>
                <p className='font-weight-bold empty-card-text'>No managers</p>
              </div>
            </Hidable>
          </Card>
        </Col>
        <InviteManager open={this.state.managerModalOpen} toggle={this.toggleInvite} />
      </Row>
    )
  }
}

ManagersComponent.propTypes = {
  authUser: PropTypes.object.isRequired,
  managers: PropTypes.array.isRequired
}

const Managers = compose(
  withRouter,
  graphql(mutations.UPDATE_CLIENT_MANAGER, {name: 'UpdateClientManager'}),
  pure
)(ManagersComponent)

export default Managers
