import React from 'react'
import { Modal, Button, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label, Input } from 'reactstrap'
import { compose, pure } from 'recompose'
import Hidable from '../Hidable'

class OrgLogoComponent extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      activeFile: null
    }
  }

    onfileChanged = (e) => {
      const file = e.target.files[0]
      this.setState({
        activeFile: file
      })
    }

    onSubmit = () => {
      if (!this.state.activeFile) return
      this.props.submit(this.state.activeFile)
    }

    render () {
      return (
        <Modal
          isOpen={this.props.open}
          toggle={this.props.toggle}
        >
          <ModalHeader toggle={this.props.toggle}>Upload Org Logo</ModalHeader>
          <ModalBody>
            <Form>
              <FormGroup>
                <Hidable show={this.props.currentLogo}>
                  <Label for='logo'>Current Logo</Label>
                  <img alt='logo' className='admin-org-logo' src={this.props.currentLogo} />
                </Hidable>
                <Label for='logo'>Upload Logo</Label>
                <Input type='file' onChange={this.onfileChanged} name='logo' />
              </FormGroup>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button onClick={this.onSubmit} color='primary'>
                  Upload
            </Button>
            <Button color='secondary' onClick={this.props.toggle}>
                  Cancel
            </Button>
          </ModalFooter>
        </Modal>
      )
    }
}

export const OrgLogo = compose(
  pure
)(OrgLogoComponent)
