import React from 'react'
import PropTypes from 'prop-types'
import { Col, Row } from 'reactstrap'
import { Client as ChatClient } from 'twilio-chat'
import { graphql } from 'react-apollo'
import { mutations, utils, queries } from '../../graphql'
import { compose, pure } from 'recompose'
import { withRouter } from 'react-router'
import { withLoading } from '../../hocs/withLoading'
import LoadingPlaceHolder from '../LoadingPlaceHolder'
import ChatChannel from './ChatChannel'

class ClientConversationComponent extends React.Component {
  state = {
    chatReady: false,
    messages: [],
    newMessage: '',
    channels: [],
    status: 'default',
    conversationObj: this.props.data.getManagerChat
  }

  componentDidMount () {
    if (!this.state.conversationObj) {
      this.createChat()
    } else {
      this.initChat()
    }
  }

  createChat = async () => {
    const { data: { createManagerChat: conversation } } = await this.props.createConversation({
      context: utils.generateAuthHeader(this.props.authUser.token),
      variables: {
        input: {
          clientId: this.props.clientObj.id
        }
      }
    })
    this.setState({
      conversationObj: conversation
    }, this.initChat)
  }

  initChat = async () => {
    window.chatClient = ChatClient
    this.chatClient = await ChatClient.create(this.state.conversationObj.token)
    this.setState({ statusString: 'Connecting……' })

    this.chatClient.on('connectionStateChanged', state => {
      if (state === 'connecting') {
        this.setState({
          statusString: 'Connecting…',
          status: 'default'
        })
      }
      if (state === 'connected') {
        this.setState({
          statusString: 'Connected',
          status: 'success'
        })
      }
      if (state === 'disconnecting') {
        this.setState({
          statusString: 'Disconnecting…',
          chatReady: false,
          status: 'default'
        })
      }
      if (state === 'disconnected') {
        this.setState({
          statusString: 'Disconnected',
          chatReady: false,
          status: 'warning'
        })
      }
      if (state === 'denied') {
        this.setState({
          statusString: 'Failed to connect',
          chatReady: false,
          status: 'error'
        })
      }
    })
    this.chatClient.on('channelJoined', channel => {
      this.setState({ channels: [...this.state.channels, channel] })
    })
    this.chatClient.on('channelLeft', thisChannel => {
      this.setState({
        channels: [...this.state.channels.filter(it => it !== thisChannel)]
      })
    })
  };

  render () {
    const { manager, clientObj } = this.props
    const { conversationObj } = this.state
    const { channels } = this.state
    const selectedChannel = channels.find(it => it.sid === conversationObj.conversationSid)

    if (!conversationObj) {
      return <LoadingPlaceHolder />
    }

    return (
      <Row>
        <Col md={{ size: 10, offset: 1 }}>
          <ChatChannel
            channelProxy={selectedChannel}
            myIdentity={conversationObj.managerIdentity}
            clientObj={clientObj}
            managerObj={manager}
          />
        </Col>
      </Row>
    )
  }
}

ClientConversationComponent.propTypes = {
  clientObj: PropTypes.object.isRequired,
  manager: PropTypes.object.isRequired,
  authUser: PropTypes.object.isRequired
}

const ClientConversation = compose(
  withRouter,
  graphql(queries.managerChat, {
    options: props => ({
      context: utils.generateAuthHeader(props.authUser.token),
      variables: {
        input: {
          clientId: props.clientObj.id
        }
      }
    })
  }),
  withLoading,
  graphql(mutations.CREATE_NEW_CONVERSATION, {name: 'createConversation'}),
  pure
)(ClientConversationComponent)

export default ClientConversation
