// text.js - various text in the app
export const COMPANY_NAME = 'TBV'
export const COMPANY_NAME_COPYRIGHT = 'TBV, Inc.'
export const HEADER_DOWNLOAD = 'Download the app'
export const IOS_APP_LINK = 'https://itunes.apple.com/us/app/id1425986032'
export const ANDROID_APP_LINK = 'https://play.google.com/store/apps/details?id=com.tbv.totalrecovery'
export const APP_DOWNLOAD_LINK = 'http://onelink.to/76g7de'
export const SUPPORT_EMAIL = 'support@tbvme.com'

export const SIGN_UP = 'Sign up'
export const LOG_IN = 'Log in'
export const LOG_OUT = 'Log out'
export const PROFILE = 'Your Profile'
export const UPDATE_PASSWORD = 'Update Password'
export const FORGOT_PASSWORD = 'Request a Reset Link'
export const EDIT_PROFILE = 'Edit profile'
export const EDIT_CLIENT_INFO = 'Edit client info'
export const CHAT_CLIENT = 'Chat with client'
export const FORGOT_PASSWORD_LINK = 'Forgot Password?'
export const LOGIN_TITLE_CLIENT = 'Difficult roads lead to beautiful destinations'
export const LOGIN_TITLE_SUPERVISOR = 'Simplify the process.'
export const LOGIN_SUBTITLE_CLIENT = 'TBV helps you stay on track of your meeting attendance.'
export const LOGIN_SUBTITLE_SUPERVISOR = "TBV helps keep track of your client's meeting attendance."
export const DONT_HAVE_ACCOUNT = "Don't have an account?"

export const WELCOME_HEADER = 'Welcome to TBV'
export const WELCOME_SIGNUP_SUCCESS = 'Congratulations on setting up your account.'
export const WELCOME_PROMPT = 'How would you like to start using TBV?'
export const WELCOME_CARD_DASH_BTN_TITLE = 'Dashboard'
export const WELCOME_CARD_DASH_TITLE = 'Take me to my dashboard'
export const WELCOME_CARD_DASH_TEXT = 'Start exploring TBV from your dashboard. You can always add your clients in later.'
export const WELCOME_CARD_ADDCLIENT_BTN_TITLE = 'Add a Client'
export const WELCOME_CARD_ADDCLIENT_TITLE = 'Start by adding one client.'
export const WELCOME_CARD_ADDCLIENT_TEXT = 'Simply enter details about your first client to familiarize yourself with TBV.'
export const WELCOME_CARD_UPLOADCLIENTS_TITLE = 'Upload your list of clients'
export const WELCOME_CARD_UPLOADCLIENTS_TEXT = 'Have a long list of clients? Quickly upload all of your clients at once with our CSV uploader.'
export const WELCOME_CARD_UPLOADCLIENTS_BTN_TITLE = 'Upload Clients'

export const ADD_CLIENT = 'Add a client'
export const ADD_CLIENT_SIGNUP = 'Invite client'
export const ADD_CLIENT_DASH = 'Add Client +'

export const MEETINGS_BEHIND = 'Behind this month'
export const MEETINGS_EXCEEDING = 'Exceeding requirements'
export const MEETINGS_ON_TARGET = 'On target'

export const CLIENTS_DETAIL = 'Client Info'
export const VIEW_REPORT = 'View Report'
export const DISCHARGE_CLIENT = 'Discharge client'
export const MESSAGE_CLIENT = 'Message client'
export const EMAIL_CLIENT = 'Contact Client'
export const EMAIL_CLIENT_SUBJECT = 'TBV: Message from your supervisor'

export const STAT_NUM_CLIENTS = 'All clients'
export const STAT_NUM_CLIENTS_TARGET = 'Clients on target'
export const STAT_NUM_CLIENTS_BEHIND = 'Clients behind requirements'
export const STAT_NUM_CLIENTS_EXCEEDING = 'Clients exceeding requirements'
export const MESSAGE_CLIENTS = 'Message clients'

export const CONCLUDE_OVERSITE = 'Conclude Oversite'
export const EMAIL_DISCHARGE = 'Discharge report'
export const EMAIL_NONCOMPLIANCE = 'Discharge report'
export const PRINT_REPORT = 'Generate report'
export const EMAIL_COMPLETION = 'Email completion report'

export const DASH_WELCOME_MESSAGE = 'Welcome to TBV!'
export const DASH_MANAGER_INTRO_MESSAGE = 'TBV allows you to track client attendance at recovery meetings.'

export const DASH_CLIENT_INTRO_MESSAGE = 'TBV allows you to keep track of your recovery meetings.'

export const HERO_DESCRIPTION = 'Introducing TBV. A self-help meeting attendance verification system powered by the TBV app.'
export const HERO_TAGLINE = 'Trust but verify.'
export const HERO_IMG_ALT = 'TBV App Hero'

export const ADD_CLIENT_NOTE = 'Add a new note'

export const ADD_ORGANIZATION = 'Add an organization'

export const INSTRUCTIONS_FILL_OUT_FORM = 'Fill out and submit this form'
export const INSTRUCTIONS_DOWNLOAD = HEADER_DOWNLOAD + ' and sign in'
export const INSTRUCTIONS_ADD_PAYMENT = 'Add your payment information'
export const INSTRUCTIONS_ADD_CLIENT_DETAIL = 'Your client will receive an email with a link to complete the signup'
export const INSTRUCTIONS_ADD_CLIENT_LAST_STEP = 'They will then'

export const INSTRUCTIONS_TITLE_CLIENT = 'How it works'
export const INSTRUCTIONS_HEADER_CLIENT = "Let's start your TBV account"
export const INSTRUCTIONS_SUBHEADER_CLIENT = "It's just a few steps and then you'll be able to quickly search for meetings, check in to ones you attend, and keep track of your progress"

export const INSTRUCTIONS_ADD_PAYMENT_TITLE = 'Last Steps'
export const INSTRUCTIONS_ADD_PAYMENT_HEADER = 'Almost finished'
export const INSTRUCTIONS_ADD_PAYMENT_SUBHEADER = 'Add your payment information to complete the signup, you will be charged $20 per month'

export const ADD_PAYMENT = 'Add Payment'
export const PHONE_NUM_PLACEHOLDER = '(999)999-9999'
export const DOWNLOAD_APP = 'Download the mobile app'

export const CLENT_WELCOME_MESSAGE = 'Welcome to TBV'
export const CLIENT_WELCOME_INTRO = 'Congratulations on setting up your account. How would you like to start using TBV?'
export const CLIENT_WELCOME_DOWNLOAD = 'Download the app'
export const CLIENT_WELCOME_DOWNLOAD_INSTRUCTIONS = 'Click the button below to get the app download link.'
export const CLIENT_WELCOME_SEND_BUTTON = 'Download App'

export const ADD_CLIENT_SUCCESS = 'Success! Client will receive an email to finish sign up.'

export const UPDATE_CLIENT_BTN = 'Save'

export const DURATION_SUPERVISION_LABEL = 'Duration of supervision'
export const REQ_MEETINGS_LABEL = 'Required meetings'
export const PROBATION_ID_LABEL = 'Program ID Number'
export const FIRST_NAME_LABEL = 'First Name'
export const LAST_NAME_LABEL = 'Last Name'
export const EMAIL_LABEL = 'Email'
export const PASSWORD_LABEL = 'Password'
export const PHONE_LABEL = 'Phone Number'
export const HOME_ADDRESS_LABEL = 'Home Address'
export const BILLING_ADDRESS_LABEL = 'Billing Address'
export const STATE_LABEL = 'State'
export const CITY_LABEL = 'City'
export const ZIP_CODE_LABEL = 'Zip code'
export const TERMS_LABEL = 'Terms of Service'

export const DEFAULT_ERROR_MSG = 'We encountered a problem, Please Try Again or Contact support@tbvme.com.'

export const ADD_NOTE_BTN = 'Add Note'
export const ADD_ORG_BTN = 'Add Organization'
export const ADD_ORG_MEETING_BTN = 'Save Meeting'
export const ADD_NOTE_SUCCESS = 'Note created.'
export const UPDATE_CLIENT_SUCCESS = 'Client Updated.'

export const UPDATE_MANAGER_SUCCESS = 'Profile Updated.'
export const UPDATE_MANAGER_BTN = 'Save'
export const EMAIL_HOME_PLACEHOLDER = 'Enter your email'

export const MONTHLY_GRAPH_TITLE = 'Monthly Meeting Attendance For'

export const MANAGERS_PLACEHOLDER = 'e.g. your supervisor'
export const MANAGERS_LABEL = ''

export const UPDATE_CLIENT_MANAGER_TITLE = 'Update Supervisor'
export const UPDATE_CLIENT_MANAGER_BTN = 'Save'
export const UPDATE_CLIENT_MANAGER_SUCCESS = 'Successful Update.'

export const PAYMENT_SUCCESS = 'Payment Added.'
export const UPDATE_PASSWORD_SUCCESS = 'Password Updated.'

export const MANAGER_HOME_TEXT = 'Go to Dashboard'
export const CLIENT_HOME_TEXT = 'Go to Profile'
export const ADMIN_HOME_TEXT = 'Go to Admin'

export const PRIVACY_LABEL = 'Privacy Policy'

export const SUBSCRIPTION_STATUS_LABEL = 'Status'
export const SUBSCRIPTION_ACTIVE_STATUS = 'You are currently paying $20/mo'
export const SUBSCRIPTION_INACTIVE_STATUS = 'Your subscription is inactive. Please Add Payment.'
export const SUBSCRIPTION_PAST_DUE_STATUS = 'Your subscription is past due. Please Add Payment.'
export const CANCEL_SUBSCRIPTION_BTN = 'Cancel Subscription'
export const CANCEL_NOTE_LABEL = 'Why are you cancelling?'
export const CANCEL_SUBSCRIPTION_SUCCESS = 'You have cancelled.'
export const SUBSCRIPTION_ACTION_LABEL = 'Action'

export const INSTRUCTIONS_TITLE_MANAGER = 'How it works'
export const INSTRUCTIONS_HEADER_MANAGER = "Let's start your TBV Supervisor Account"
export const INSTRUCTIONS_SUBHEADER_MANAGER = "It's just a few steps and then you'll be ready to track meeting attendance"

export const INSTRUCTIONS_ADD_CLIENTS = 'Start adding your clients'

export const UPDATE_ATTENDANCE_STATUS_SUCCESS = 'Status saved'
export const VIEW_ATTENDANCE = 'View Attendance'
export const VIEW_MEETINGS = 'View Meetings'
export const ATTENDANCE_LIST_TITLE = 'Meetings List'
export const MEETINGS_REQUIRED_LABEL = 'Meetings required per month'
export const MEETINGS_ATTENDED_LABEL = 'Meetings attended this month'
export const MEETINGS_VERIFIED_LABEL = 'Meetings verified this month'
export const VIEW_NOTES = 'View Notes'

export const EMAIL_DISCHARGE_SEND = 'Send'
export const EMAIL_DISCHARGE_CANCEL = 'Cancel'
export const EMAIL_DISCHARGE_CONFIRM = 'Would you like to send Discharge Report?'
export const EMAIL_DISCHARGE_FAIL = 'We had trouble sending, would you like to try again?'

export const REPORTS_MENU = 'Reports'
export const DASHBOARD_MENU = 'Overview'
export const CLIENTS_MENU = 'Clients'
export const ORG_MENU = 'Organizations'
export const ORG_MANAGERS = 'Managers'

export const LEGEND_ON_TARGET = 'On target'
export const LEGEND_EXCEEDING = 'Exceeding'
export const LEGEND_BEHIND = 'Falling behind'

export const PENDING_INVITES_MENU = 'Pending Invites'

export const INVITE_ERROR_MSG = 'This invite is no longer valid. Please check your email or contact your Supervisor.'

export const MESSAGE_GROUP_TITLE = 'Send a text message to these clients'
export const MESSAGE_GOOD = 'Hey :client_name:, you\'re doing great work this month. Keep it up!'
export const MESSAGE_BEHIND = 'Hey :client_name:, you\'re falling a bit behind this month. Make sure you get to all your meetings.'
