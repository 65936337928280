// FormInput.js - reusable form input

import React from 'react'

import PropTypes from 'prop-types'

import { Input } from 'reactstrap'

import TBVError from './Error.js'

const FormInput = ({field, form: {touched, errors}, showError, ...props}) => {
  const errorMsg = touched[field.name] && errors[field.name]

  return (
    <React.Fragment>
      <Input {...field} {...props} />
      { showError && errorMsg && (
        <TBVError message={errorMsg} className='text-danger position-absolute' />
      )}
    </React.Fragment>
  )
}

FormInput.propTypes = {
  field: PropTypes.object.isRequired,
  form: PropTypes.object,
  showError: PropTypes.bool,
  props: PropTypes.object
}

FormInput.defaultProps = {
  props: {},
  showError: true
}

export default FormInput
