// MyOrganization page

import React from 'react'
import { compose, pure } from 'recompose'
import PropTypes from 'prop-types'
import { Col, Row, Card, Table, NavLink as BSNavLink, Button } from 'reactstrap'
import bn from 'utils/bemnames'
import TBVButton from '../TBVButton'
import Hidable from '../Hidable'
import { NavLink } from 'react-router-dom'
import { withRouter } from 'react-router'

const bem = bn.create('organization')
const weekDaysMapping = { 1: 'Monday', 2: 'Tuesday', 3: 'Wednesday', 4: 'Thursday', 5: 'Friday', 6: 'Saturday', 7: 'Sunday' }
class MyOrganizationComponent extends React.Component {
  state = {
    managerModalOpen: false
  }
  create = () => {
    this.props.history.push('/myorganization/addmeeting')
  }
  toggleInvite = () => {
    this.setState({
      managerModalOpen: !this.state.managerModalOpen
    })
  }
  render () {
    const { meetings } = this.props.manager.organization
    return (
      <Row className={bem.b()}>
        <Col>
          <Card>
            <div>
              <div className='pt-3 pr-3 float-right' style={{display: 'inline-block'}}>
                <Button outline color='primary' onClick={this.create}>+ Add meeting</Button>
              </div>
            </div>
            <Hidable show={meetings.length}>
              <div className='d-flex justify-content-between w-100 p-4'>
                <Table responsive striped borderless>
                  <thead>
                    <tr>
                      <th>Meeting Name</th>
                      <th>Location</th>
                      <th>Day</th>
                      <th>Start time</th>
                      <th>&nbsp;</th>
                    </tr>
                  </thead>
                  <tbody>
                    {meetings.map((m, i) => {
                      const { address: { city, street, state, postalCode } } = m
                      return (
                        <tr key={i}>
                          <td>{m.name}</td>
                          <td>{`${street} ${city}, ${state} ${postalCode}`}</td>
                          <td>{weekDaysMapping[m.weekDay]}</td>
                          <td>{m.startTime}</td>
                          <td><BSNavLink
                            tag={NavLink}
                            to={`/myorganization/meeting/${m.id}/edit`}
                            className='float-right'
                            activeClassName='active'
                            exact>
                            <TBVButton title='Modify' />
                          </BSNavLink></td>
                        </tr>
                      )
                    })}
                  </tbody>
                </Table>
              </div>
            </Hidable>
            <Hidable show={!meetings.length}>
              <div className='w-100 p-4 text-center'>
                <p className='font-weight-bold empty-card-text'>You have not added any meetings yet</p>
              </div>
            </Hidable>
          </Card>
        </Col>
      </Row>
    )
  }
}

MyOrganizationComponent.propTypes = {
  authUser: PropTypes.object.isRequired,
  manager: PropTypes.object.isRequired
}

const MyOrganization = compose(
  withRouter,
  pure
)(MyOrganizationComponent)

export default MyOrganization
