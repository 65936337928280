// ClientHeader.js - the dashboard header

import React from 'react'

import bn from 'utils/bemnames'

import {
  Navbar,
  Nav,
  NavItem,
  NavLink,
  Popover,
  PopoverBody,
  Row,
  Card,
  Col
} from 'reactstrap'

import { NavLink as ReactNavLink } from 'react-router-dom'

import logoSVGImage from 'assets/img/logo/tbv-app-icon.svg'
import DownloadLink from 'components/DownloadLink'

import Avatar from 'components/Avatar'

import { EDIT_PROFILE, LOG_OUT, HEADER_DOWNLOAD, VIEW_ATTENDANCE } from 'constants/text'

import { auth } from '../../firebase'

import classNames from 'classnames'

import { CLIENT_PROFILE, CLIENT_ATTENDANCE } from 'constants/routes'

const bem = bn.create('dash-header')

class ClientHeader extends React.Component {
  state = {
    isOpenUserCardPopover: false
  };

  toggleUserCardPopover = () => {
    this.setState({
      isOpenUserCardPopover: !this.state.isOpenUserCardPopover
    })
  };

  handleSidebarControlButton = event => {
    event.preventDefault()
    event.stopPropagation()

    document.querySelector('.tbv-sidebar').classList.toggle('tbv-sidebar--open')
  };

  render () {
    const { client } = this.props
    return (
      <Navbar light expand className={classNames(bem.b('bg-white'), 'd-flex', 'tbv-dash-navbar')}>
        <Row className='w-100'>
          <Col>
            <DownloadLink className='navbar-brand d-flex'>
              <Row className='d-flex justify-content-center align-items-center pl-2 pl-sm-2 ml-sm-2 ml-md-2 ml-lg-2'>
                <img
                  src={logoSVGImage}
                  className='rounded'
                  style={{ width: 60, height: 60, cursor: 'pointer' }}
                  alt='logo'
                />
                <span className={classNames(bem.e('brand-text'), 'd-none d-sm-block pl-2 text-darkBlueGrey')}>{HEADER_DOWNLOAD}</span>
              </Row>
            </DownloadLink>
          </Col>

          <Nav navbar className={classNames(bem.e('nav-right'), 'ml-2 ml-sm-auto')}>
            <NavItem className='d-none d-md-block'>
              <NavLink className='position-relative pr-0 d-flex flex-column justify-content-center h-100'>
                <span>{client.firstName}</span>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink id='Popover2'>
                <Avatar
                  onClick={this.toggleUserCardPopover}
                  className='can-click'
                  src={client.photoUrl}
                />
              </NavLink>
              <Popover
                placement='bottom-end'
                isOpen={this.state.isOpenUserCardPopover}
                toggle={this.toggleUserCardPopover}
                target='Popover2'
                className='p-0 border-0'
                style={{ minWidth: 250 }}>
                <PopoverBody className='p-0 border-light tbv-popover-menu'>
                  <Card className='border-0 tbv-box-shadow'>
                    <div className='d-flex justify-content-center flex-column'>
                      <ReactNavLink to={CLIENT_PROFILE}><p className='font-weight-bold border-bottom m-0 p-3'>{EDIT_PROFILE}</p></ReactNavLink>
                    </div>
                    <div className='d-flex justify-content-center flex-column'>
                      <ReactNavLink to={CLIENT_ATTENDANCE}><p className='font-weight-bold border-bottom m-0 p-3'>{VIEW_ATTENDANCE}</p></ReactNavLink>
                    </div>
                    <div className='d-flex justify-content-center flex-column'>
                      <NavLink className='tbv-logout font-weight-bold m-0 p-3'
                        onClick={() => { auth.doSignOut() }} tabIndex='-1'>
                        {LOG_OUT}
                      </NavLink>
                    </div>
                  </Card>
                </PopoverBody>
              </Popover>
            </NavItem>
          </Nav>
        </Row>

      </Navbar>
    )
  }
}

export default ClientHeader
