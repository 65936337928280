// graph.js - utils for generating graph labels
import Log from './logging.js'
import moment from 'moment'

export const monthNumToName = (month) => {
  return moment.monthsShort()[month - 1]
}

export const getWeekDate = (week, year) => {
  return moment().year(year).week(week).day('monday')
}

export const getWeekEnd = (week, year) => {
  return moment().year(year).week(week).endOf('week').add(1, 'day')
}

export const weekOfMonth = (m) => {
  const mIsoWeek = m.isoWeek()
  const startIsoWeek = moment(m).startOf('month').isoWeek()
  if (mIsoWeek < startIsoWeek) {
    return 52 + mIsoWeek - startIsoWeek + 1
  } else {
    return mIsoWeek - startIsoWeek + 1
  }
}

export const getMonthWeekStartDates = (momentObj) => {
  let clonedMoment = moment(momentObj)

  // get week number for first day of month
  const monthStart = moment(clonedMoment.startOf('month'))
  Log.info('monthStart:', monthStart.format('YYYY/MM/DD'))

  // get week count of last week in month
  const wksCount = weekOfMonth(clonedMoment.endOf('month'))
  Log.info('weeks count:', wksCount)

  const monthDates = Array(wksCount).fill().map((_, i) => moment(monthStart).add(i, 'weeks'))
  return monthDates
}

export const MONTH_NUMS = Array(11).fill().map((_, i) => i + 1)
