import React from 'react'
import PropTypes from 'prop-types'

const AuthButtonTag = ({ href, onClick, text, linkText }) => {
  return (
    <div className='text-center pt-1'>
      <h6 className='pt-2'>{text}</h6>
      <h6 className='pt-1'>
        <a className='text-darkBlueGrey' href={href} onClick={onClick}>
          {linkText}</a>
      </h6>
    </div>
  )
}

AuthButtonTag.propTypes = {
  href: PropTypes.any,
  onClick: PropTypes.func,
  text: PropTypes.string,
  linkText: PropTypes.string
}

export default AuthButtonTag
