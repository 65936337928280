import React from 'react'
import { Route } from 'react-router-dom'

const LayoutRoute = ({ component: Component, layout: Layout, title = '', withBackButton, ...rest }) => (
  <Route
    {...rest}
    render={props => (
      <Layout title={title} withBackButton={withBackButton}>
        <Component {...props} />
      </Layout>
    )}
  />
)

export default LayoutRoute
