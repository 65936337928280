import React from 'react'

import { Form, Input } from 'reactstrap'
import { MdSearch } from 'react-icons/lib/md'

const SearchInput = ({fill = 'white'}) => {
  return (
    <Form inline className='tbv-search-form' onSubmit={e => e.preventDefault()}>
      <MdSearch
        size='20'
        fill={fill}
        className='tbv-search-form__icon-search text-secondary'
      />
      <Input
        type='search'
        className='tbv-search-form__input'
        placeholder='Search...'
      />
    </Form>
  )
}

export default SearchInput
