// MeetingsAttended.js - icons
import React from 'react'
import Icon from 'react-icon-base'

import PropTypes from 'prop-types'

const MeetingsAttended = (props) => {
  const { fillObj, ...restProps } = props

  return (
    <Icon viewBox='0 0 46 43' {...restProps}>
      <title>{props.title || 'Meetings Verified'}</title>
      <g>
        <g fillRule='evenodd'>
          <path fill={fillObj} d='M12 2h22.155a1 1 0 0 1 .998 1.072L34.089 17.71a12 12 0 0 1-7.778 10.375l-3.196 1.19L13.002 21.7A5 5 0 0 1 11 17.698V3a1 1 0 0 1 1-1z' />
          <path fillRule='nonzero' d='M19 34v-5.144a18.583 18.583 0 0 1-7.696-7.524 1.385 1.385 0 0 1-.418-.072l-.737-.243A15.23 15.23 0 0 1 0 6.655v-2.27C0 3.62.62 3 1.385 3H9V1.385A1.385 1.385 0 0 1 10.385 0h24.923c.764 0 1.384.62 1.384 1.385V3h7.634c.765 0 1.385.62 1.385 1.385v2.27A15.211 15.211 0 0 1 35.59 21l-.764.255c-.14.05-.289.075-.439.077a18.583 18.583 0 0 1-7.619 7.483V34h3.462c.764 0 1.384.62 1.384 1.385v5.538c0 .765-.62 1.385-1.384 1.385H16.385c-.765 0-1.385-.62-1.385-1.385v-5.538c0-.765.62-1.385 1.385-1.385H19zm2.77 0H24v-4.152c-.38.063-.766.094-1.154.093a6.923 6.923 0 0 1-1.077-.08V34zM9 5.77H2.77v.885a12.438 12.438 0 0 0 7.07 11.242A18.573 18.573 0 0 1 9 12.365V5.769zm26.85 12.133a12.43 12.43 0 0 0 7.091-11.248V5.77h-6.249v6.596c0 1.91-.292 3.773-.842 5.538zM11.77 2.769v9.596a15.823 15.823 0 0 0 9.39 14.447 4.154 4.154 0 0 0 3.375 0 15.823 15.823 0 0 0 9.388-14.447V2.769H11.77zm6 36.77h11.076v-2.77H17.77v2.77z' />
        </g>
      </g>
    </Icon>
  )
}

MeetingsAttended.propTypes = {
  fillObj: PropTypes.string.isRequired
}

MeetingsAttended.defaultProps = {
  fillObj: ''
}

export default MeetingsAttended
