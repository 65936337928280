// ClientReportsPage.js - client reports page

import React from 'react'
import Page from '../components/Page'
import Landing from '../components/Dashboard/ClientReports.js'

import { withManager } from '../components/Session'

class ClientReportsPage extends React.Component {
  render () {
    const {authUser, roleState} = this.props

    return (
      <Page className='ClientReportsPage'>
        <Landing
          authUser={authUser}
          clientId={this.props.match.params.id}
          roleState={roleState}
          manager={this.props.manager}
        />
      </Page>
    )
  }
}

export default withManager(ClientReportsPage)
