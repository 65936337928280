// AdminHeader.js - the admin header

import React from 'react'
import bn from 'utils/bemnames'
import {
  Navbar,
  Nav,
  NavItem,
  NavLink,
  Popover,
  PopoverBody,
  Card,
  Col,
  Row
} from 'reactstrap'
import { NavLink as ReactNavLink } from 'react-router-dom'
import classNames from 'classnames'
import Avatar from 'components/Avatar'
import SearchInput from 'components/SearchInput'
import tbvWhiteSvg from 'assets/img/logo/tbv-logo-white-no-tagline.svg'
import tbvSvg from 'assets/img/logo/tbv-logo-full-color-no-tagline.svg'
import { LOG_OUT } from 'constants/text'
import { ADMIN_HOME } from 'constants/routes'
import { auth } from '../../firebase'

const bem = bn.create('dash-header')

class AdminHeader extends React.Component {
  state = {
    isOpenUserCardPopover: false
  };

  toggleUserCardPopover = () => {
    this.setState({
      isOpenUserCardPopover: !this.state.isOpenUserCardPopover
    })
  };

  render () {
    const { admin, white } = this.props
    const fill = white ? 'white' : '#283845'

    return (
      <Navbar className='tbv-dash-navbar' expand>
        <Row className='w-100'>
          <Col xs={12} sm={3} >
            <ReactNavLink to={ADMIN_HOME} className='navbar-brand d-flex pl-3 flex-column justify-content-center h-100'>
              <span className='d-inline-flex'>
                <img
                  src={white ? tbvWhiteSvg : tbvSvg}
                  height='25'
                  alt=''
                />
              </span>
            </ReactNavLink>
          </Col>

          <Nav className='d-none' navbar>
            <SearchInput fill={fill} />
          </Nav>

          <Nav navbar className={classNames(bem.e('nav-right'), 'ml-2 ml-sm-auto')}>
            <NavItem className='d-none d-md-block'>
              <NavLink className='position-relative pr-0 d-flex flex-column justify-content-center h-100'>
                <span>{admin.firstName}</span>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink id='Popover2'>
                <Avatar
                  onClick={this.toggleUserCardPopover}
                  className='can-click'
                  src={admin.photoUrl}
                />
              </NavLink>
              <Popover
                placement='bottom-end'
                isOpen={this.state.isOpenUserCardPopover}
                toggle={this.toggleUserCardPopover}
                target='Popover2'
                className='p-0 border-0 tbv-popover-menu'
                style={{ minWidth: 250 }}>
                <PopoverBody className='p-0 border-light'>
                  <Card className='border-0 tbv-box-shadow'>
                    <div className='d-flex justify-content-center flex-column'>
                      <NavLink className='tbv-logout font-weight-bold m-0 p-3'
                        onClick={() => { auth.doSignOut() }} tabIndex='-1'>
                        {LOG_OUT}
                      </NavLink>
                    </div>
                  </Card>
                </PopoverBody>
              </Popover>
            </NavItem>
          </Nav>
          <Col xs={12} className='d-none'>
            <Nav className='d-md-none' navbar>
              <SearchInput fill={fill} />
            </Nav>
          </Col>
        </Row>
      </Navbar>
    )
  }
}

export default AdminHeader
