// Address section - for home and billing adddress

import React from 'react'

import PropTypes from 'prop-types'

import { FormGroup, Label } from 'reactstrap'

import StateDropDown from './StateDropDown.js'

import { ValidationFields, TBVFormField } from 'components/TBVForm'

import * as Yup from 'yup'

import { HOME_ADDRESS_LABEL, STATE_LABEL, CITY_LABEL, ZIP_CODE_LABEL } from 'constants/text.js'

export const AddressSchema = Yup.object().shape({
  address: ValidationFields.STREET_ADDRESS,
  city: ValidationFields.CITY,
  state: ValidationFields.STATE,
  zipCode: ValidationFields.ZIP_CODE
})

export const AddressPropsMap = {
  address: '',
  city: '',
  state: '',
  stateIsOpen: false,
  zipCode: ''
}

export const AddressSection = (props) => {
  const {
    addressId,
    addressLabel,
    addressInputProps,

    cityId,
    cityLabel,
    cityInputProps,

    stateId,
    stateLabel,
    stateIdIsOpen,

    zipCodeId,
    zipCodeLabel,
    zipCodeInputProps
  } = props

  return (
    <React.Fragment>

      <div className='form-row'>
        <TBVFormField className='col-md-6 required' label={addressLabel} id={addressId} {...addressInputProps} />
        <TBVFormField className='col-md-6 required' label={cityLabel} id={cityId} {...cityInputProps} />
      </div>

      <div className='form-row'>
        <FormGroup className='col-md-6 required'>
          <Label for={stateId}>{stateLabel}</Label>
          <StateDropDown name={stateId} isOpenId={stateIdIsOpen} />
        </FormGroup>
        <TBVFormField className='col-md-6 required' label={zipCodeLabel} id={zipCodeId} {...zipCodeInputProps} />
      </div>

    </React.Fragment>
  )
}

AddressSection.propTypes = {
  addressId: PropTypes.string,
  addressLabel: PropTypes.string,
  addressInputProps: PropTypes.object,
  cityId: PropTypes.string,
  cityLabel: PropTypes.string,
  cityInputProps: PropTypes.object,

  stateId: PropTypes.string,
  stateLabel: PropTypes.string,
  stateIdIsOpen: PropTypes.string,

  zipCodeId: PropTypes.string,
  zipCodeLabel: PropTypes.string,
  zipCodeInputProps: PropTypes.object
}

AddressSection.defaultProps = {
  addressId: 'address',
  addressLabel: HOME_ADDRESS_LABEL,
  addressInputProps: {
    type: 'text',
    placeholder: ''
  },
  cityId: 'city',
  cityLabel: CITY_LABEL,
  cityInputProps: {
    type: 'text',
    placeholder: 'e.g. Cambridge'
  },

  stateId: 'state',
  stateLabel: STATE_LABEL,
  stateIdIsOpen: 'stateIsOpen',

  zipCodeId: 'zipCode',
  zipCodeLabel: ZIP_CODE_LABEL,
  zipCodeInputProps: {
    type: 'text',
    placeholder: 'e.g. 02138'
  }
}

// export default AddressSection
