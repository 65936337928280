import React from 'react'

import { NavLink } from 'react-router-dom'

import { withAppUser } from './Session'

import { CLIENT_HOME, MANAGER_HOME, SUPERVISOR_HOME, ADMIN_HOME } from 'constants/routes.js'

import { CLIENT_HOME_TEXT, MANAGER_HOME_TEXT, ADMIN_HOME_TEXT } from 'constants/text.js'
import { ORG_ROLES } from '../utils/roles'

const LoggedInLink = props => {
  const { client, manager } = props
  const managerHomeLink = manager && (manager.role === ORG_ROLES.MANAGER || manager.role === ORG_ROLES.ORG_MANAGER) ? MANAGER_HOME : SUPERVISOR_HOME
  const userHomeLink = client ? CLIENT_HOME : manager ? managerHomeLink : ADMIN_HOME
  const userHomeText = client ? CLIENT_HOME_TEXT : manager ? MANAGER_HOME_TEXT : ADMIN_HOME_TEXT
  /* eslint-disable jsx-a11y/anchor-has-content */
  return (
    <div>
      <NavLink className='font-weight-bold pl-1 text-darkBlueGrey' to={userHomeLink}>{userHomeText}</NavLink>
    </div>
  )
}

export default withAppUser(LoggedInLink)
