// LogInPage.js - login, register, forgot password

import React from 'react'

import { Card, Col, Row } from 'reactstrap'

import Roles, { getSignUpPath } from 'utils/roles'
import { CLIENT_PROFILE, FORGOT_PASSWORD, DASHBOARD } from 'constants/routes'

import Page from 'components/Page'
import LogInForm from 'components/LogInForm'

import tbvWhiteSvg from '../assets/img/logo/tbv-logo-white.svg'

class LogInPage extends React.Component {
  handleSignUpClick = () => {
    this.props.history.push(getSignUpPath(this.props.roleState))
  }

  handleForgotPasswordClick = () => {
    this.props.history.push(FORGOT_PASSWORD)
  }

  handleLoggedIn = () => {
    if (this.props.roleState === Roles.CLIENT) {
      this.props.history.push(CLIENT_PROFILE)
    } else {
      this.props.history.push(DASHBOARD)
    }
  }

  render () {
    const {
      title,
      subTitle,
      cardSubtitle,
      cardTitle
    } = this.props

    return (
      <Page
        className='LogInPage'>
        <Row className='justify-content-center align-items-center h-100'>
          <Col sm={11} md={10} lg={9} xl={8}>
            <div className='d-flex mobile-column'>
              <div className='flex-grow-1 flex-basis-0 p-3'>
                <h1 className='text-white font-weight-bold'>{title}</h1>
                <p className='text-white'>{subTitle}</p>
              </div>
              <div className='flex-grow-1 flex-basis-0 p-3 justify-content-center d-flex flex-column align-middle'>
                <Card body className='border-0 tbv-box-shadow mx-md-3 px-3 px-md-4 px-lg-5 mb-3'>
                  <h5 className='font-weight-bold pt-1'>{cardTitle}</h5>
                  <LogInForm
                    roleState={this.props.roleState}
                    onSignUpClick={this.handleSignUpClick}
                    onForgotPasswordClick={this.handleForgotPasswordClick}
                    onLoggedIn={this.handleLoggedIn}
                  />
                  {cardSubtitle}
                </Card>
              </div>
            </div>
            <img alt='' className='mt-5' height={69} src={tbvWhiteSvg} />
          </Col>
        </Row>
      </Page>
    )
  }
}

export default LogInPage
