import React from 'react'
import PropTypes from 'prop-types'
import { Row, Col, FormGroup, Label } from 'reactstrap'
import TBVButton from '../TBVButton.js'
import { compose, pure } from 'recompose'
import 'react-datepicker/dist/react-datepicker.css'
import { EditLocationMap } from './EditLocationMap'
import Avatar from '../Avatar'

const days = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday'
]

const AttendanceLocationFormComponent = (props) => {
  const {
    attendance,
    submit
  } = props
  const { meeting } = attendance
  const meetingLocation = { ...meeting.location }
  const attendanceLocation = { lat: attendance.checkInVerification.lat, lng: attendance.checkInVerification.lng }
  const markers = [meetingLocation, attendanceLocation]
  const keep = () => {
    submit({
      id: attendance.id,
      useSuggestion: false
    })
  }
  const change = () => {
    submit({
      id: attendance.id,
      useSuggestion: true
    })
  }
  return (
    <div className='p-3'>
      <Row>
        <Col md={6}>
          <h5 className='meeting-form-title'>Meeting Info</h5>
          <Row>
            <Col md={3}><Label>Name</Label></Col>
            <Col md={9}>{meeting.name}</Col>
          </Row>
          <Row>
            <Col md={3}><Label>Location</Label></Col>
            <Col md={9}>{`${meeting.address.street} ${meeting.address.city}, ${meeting.address.state} ${meeting.address.city}`}</Col>
          </Row>
          <Row>
            <Col md={3}><Label>Day</Label></Col>
            <Col md={9}>{days[meeting.weekDay - 1]}</Col>
          </Row>
        </Col>
        <Col md={6}>
          <h5 className='meeting-form-title'>Submitted by</h5>
          <Row>
            <Col md={3}><Avatar size={30} src={attendance.client.photoUrl} /></Col>
            <Col md={9}>{`${attendance.client.firstName} ${attendance.client.lastName}`}</Col>
          </Row>
          <Row>
            <Col md={3}><Label>Phone</Label></Col>
            <Col md={9}>{attendance.client.phone}</Col>
          </Row>
          <Row>
            <Col md={3}><Label>Email</Label></Col>
            <Col md={9}>{attendance.client.email}</Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col md={12}>
          <FormGroup>
            <h5 className='meeting-form-title'>Meeting Location</h5>
            <div className='location-map-key'>
              <span><img width='30px' alt='' src='http://maps.google.com/mapfiles/kml/paddle/1.png' />Current location</span>
              <span className='pl-15px'><img alt='' width='30px' src='http://maps.google.com/mapfiles/kml/paddle/2.png' />Suggested location</span>
            </div>
            <EditLocationMap markers={markers} latitude={meetingLocation.lat} longitude={meetingLocation.lng} />
          </FormGroup>
        </Col>
      </Row>
      <span className='float-right'>
        <TBVButton outline className='xl-button mr-15px' title='Keep Current' onClick={keep} />
        <TBVButton className='xl-button' title='Use suggested' onClick={change} />
      </span>
    </div>
  )
}

AttendanceLocationFormComponent.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object
}

const AttendanceLocationForm = compose(
  pure
)(AttendanceLocationFormComponent)

export default AttendanceLocationForm
