// TBVButton.js - standard button

import React from 'react'

import { PropTypes } from 'prop-types'

import { Button } from 'reactstrap'

const TBVButton = ({
  title,
  className,
  buttonType,
  buttonSize,
  buttonColor,
  handleClick,
  ...restProps
}) => {
  return (
    <Button
      color={buttonColor}
      type={buttonType}
      size={buttonSize}
      className={className} {...restProps}>
      {title}

    </Button>
  )
}

TBVButton.propTypes = {
  title: PropTypes.string,
  className: PropTypes.string,
  buttonType: PropTypes.string,
  buttonSize: PropTypes.string
}

TBVButton.defaultProps = {
  className: 'text-uppercase',
  buttonType: 'submit',
  buttonSize: 'lg',
  buttonColor: 'primary'
}

export default TBVButton
