// DashboardPage.js - client home page

import React from 'react'

import { Col, Row } from 'reactstrap'

import Page from 'components/Page'
import Clients from 'components/Dashboard/Clients'

import { CLIENT_DETAIL } from 'constants/routes'

import { withManager } from '../components/Session'

class ClientsPage extends React.Component {
  handleClientDetailClick = (e, userId) => {
    this.props.history.push(CLIENT_DETAIL + '/' + userId)
  }

  render () {
    const { manager } = this.props

    return (
      <Page className='ClientsPage'>
        <Row>
          <Col>
            <Clients
              clients={manager.clients}
              onClientDetailClick={this.handleClientDetailClick}
            />
          </Col>
        </Row>
      </Page>
    )
  }
}

export default withManager(ClientsPage)
