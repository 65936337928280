import * as React from 'react'
import { withGoogleMap, GoogleMap, Marker } from 'react-google-maps'
import { compose, pure, withProps } from 'recompose'

const PIN_URL_BASE = 'http://maps.google.com/mapfiles/kml/paddle/'
const MapComponent = (props) => {
  return (
    <div>
      <GoogleMap
        defaultZoom={16}
        defaultCenter={{ lat: props.latitude, lng: props.longitude }}
      >
        {props.markers.map((m, i) => <Marker key={i} onDragEnd={m.dragEnd} icon={{url: `${PIN_URL_BASE}${i + 1}.png`}} draggable={m.draggable} position={{ lat: m.lat, lng: m.lng }} />)}
      </GoogleMap>
    </div>
  )
}

export const EditLocationMap = compose(
  withProps({
    googleMapURL: 'https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places',
    loadingElement: <div style={{ height: '100%' }} />,
    containerElement: <div style={{ height: '550px' }} />,
    mapElement: <div style={{ height: '100%' }} />
  }),
  withGoogleMap,
  pure
)(MapComponent)
