const tooltips = {
  displayColors: false,
  backgroundColor: 'white',
  bodyFontColor: '#7c8f9e',
  titleFontSize: 14,
  titleFontColor: '#283845',
  bodySpacing: 10,
  titleMarginBottom: 20,
  xPadding: 20,
  yPadding: 20,
  xAlign: 'left',
  borderColor: '#e8e8e8',
  borderWidth: 1
}

const barSpacing = {
  stacked: true,
  gridLines: {
    display: false
  },
  barThickness: 24,
  barPercentage: 1,
  categoryPercentage: 0.6
}

export const horizontalBarOptions = {
  legend: {
    display: false
  },
  maintainAspectRatio: false,
  scales: {
    xAxes: [{
      stacked: false,
      ticks: {
        stepSize: 3
      }
    }],
    yAxes: [barSpacing]
  },
  tooltips
}

export const barOptions = {
  legend: {
    display: false
  },
  maintainAspectRatio: false,
  scales: {
    yAxes: [{
      stacked: false,
      ticks: {
        stepSize: 5,
        min: 0
      }
    }],
    xAxes: [barSpacing]
  },
  tooltips
}

export const weeklyBarOptions = {
  legend: {
    display: false
  },
  maintainAspectRatio: false,
  scales: {
    yAxes: [{
      stacked: false,
      ticks: {
        stepSize: 2,
        min: 0
      }
    }],
    xAxes: [barSpacing]
  },
  tooltips
}
