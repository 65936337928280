// UpdateManager.js - form for managers to update

import React from 'react'
import Log from 'utils/logging.js'

import { graphql, compose } from 'react-apollo'

import PropTypes from 'prop-types'

import Roles from 'utils/roles.js'
import { mutations, utils } from '../../../graphql'

import { PersonInfoSchema, PersonInfoPropsMap, PersonInfoSection } from './PersonInfoSection.js'

import { UPDATE_MANAGER_BTN, DEFAULT_ERROR_MSG, UPDATE_MANAGER_SUCCESS } from 'constants/text.js'

import AuthButton from './AuthButton'

import { withFormik } from 'formik'

import { Form } from 'reactstrap'

const _buildValidationSchema = () => {
  return PersonInfoSchema
}

const formikEnhancer = withFormik({
  validationSchema: _buildValidationSchema(),
  mapPropsToValues: props => ({
    ...props.initialValues,
    ...PersonInfoPropsMap
  }),
  handleSubmit: async (values, {setSubmitting, setErrors, setStatus, props}) => {
    const payload = {
      ...values
    }
    Log.info('UpdateManager')
    Log.info(payload)

    const { UpdateManager, authUser } = props
    const variables = {
      phone: payload.phoneNumber,
      firstName: payload.firstName,
      lastName: payload.lastName,
      email: payload.email
    }

    // update graphQL manager
    Log.info('graphQl update manager')
    try {
      const manager = await UpdateManager({ variables: variables,
        context: utils.generateAuthHeader(authUser.token)
      })
      Log.info('TBV manager updated')
      Log.info(manager)
      setStatus({success: true})
      setSubmitting(false)
    } catch (error) {
      Log.info('failed graphQL manager update')
      Log.error(error)
      setErrors({submit: DEFAULT_ERROR_MSG})
      setSubmitting(false)
    }
  },
  displayName: 'UpdateManagerForm'
})

const UpdateManagerForm = props => {
  const {
    touched,
    errors,
    status,
    handleSubmit,
    isSubmitting,
    initialValues,
    initialData,
    children
  } = props
  // update initialValues with initialData
  Object.assign(initialValues, initialData)

  return (
    <Form className='tbv-login-form' onSubmit={handleSubmit}>
      <PersonInfoSection />
      <AuthButton
        title={UPDATE_MANAGER_BTN}
        disabled={isSubmitting}
        errors={errors}
        status={status}
        successMsg={UPDATE_MANAGER_SUCCESS}
        touched={touched}
      />
      {children}
    </Form>
  )
}

UpdateManagerForm.propTypes = {
  roleState: PropTypes.oneOf([Roles.CASE_MANAGER, Roles.PROBATION_OFFICER]).isRequired,
  onFormSubmit: PropTypes.func.isRequired,
  initialData: PropTypes.object,
  authUser: PropTypes.object.isRequired
}

UpdateManagerForm.defaultProps = {
  initialData: {},
  inviteToken: null
}
const UpdateManagerFormGraphQL = compose(
  graphql(mutations.UPDATE_MANAGER, {name: 'UpdateManager'})
)(formikEnhancer(UpdateManagerForm))

export default UpdateManagerFormGraphQL
