// Dropdown with sorting functions

import React from 'react'

import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap'

const numberMeetingsBehind = client => client.meetingsRequiredPerMonth - client.meetingsAttended

const sortByBehind = (a, b) => {
  const numberBehindA = numberMeetingsBehind(a)
  const numberBehindB = numberMeetingsBehind(b)
  return numberBehindB - numberBehindA || b.meetingsAttended - a.meetingsAttended
}

const sortByMeetingsAttended = (a, b) => {
  const numberBehindA = numberMeetingsBehind(a)
  const numberBehindB = numberMeetingsBehind(b)
  return b.meetingsAttended - a.meetingsAttended || numberBehindA - numberBehindB
}

const sortByExceeding = (a, b) => {
  const numberBehindA = numberMeetingsBehind(a)
  const numberBehindB = numberMeetingsBehind(b)
  return numberBehindA - numberBehindB || a.meetingsAttended - b.meetingsAttended
}

const sortByAZ = (a, b) => {
  if (a.lastName.toLowerCase() < b.lastName.toLowerCase()) return -1
  if (a.lastName.toLowerCase() > b.lastName.toLowerCase()) return 1
  return sortByExceeding(a, b)
}

const sortByZA = (a, b) => {
  if (a.lastName.toLowerCase() > b.lastName.toLowerCase()) return -1
  if (a.lastName.toLowerCase() < b.lastName.toLowerCase()) return 1
  return sortByExceeding(a, b)
}

export const dropdownItems = [
  {
    title: 'Most behind', sort: sortByBehind
  },
  {
    title: 'Meetings attended', sort: sortByMeetingsAttended
  },
  {
    title: 'Most exceeding', sort: sortByExceeding
  },
  {
    title: 'A - Z', sort: sortByAZ
  },
  {
    title: 'Z - A', sort: sortByZA
  }
]

export const SortDropdown = ({dropdownOpen, toggle, onItemClick, title}) => (
  <Dropdown isOpen={dropdownOpen} toggle={toggle} className='tbv-sort'>
    <DropdownToggle caret>Sort by: <strong>{title}</strong></DropdownToggle>
    <DropdownMenu>
      {dropdownItems.map(item => (
        <DropdownItem
          key={item.title}
          onClick={() => onItemClick(item)}
          value={item}>{item.title}</DropdownItem>
      ))}
    </DropdownMenu>
  </Dropdown>
)
