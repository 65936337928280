import React from 'react'
import { withManager } from 'components/Session'
import { Modal, ModalHeader, ModalBody } from 'reactstrap'
import ManagerInviteManager from '../SignUp/Form/ManagerInviteManager'

const InviteManagerForm = (props) => {
  return (
    <Modal
      isOpen={props.open}
      toggle={props.toggle}
    >
      <ModalHeader toggle={props.toggle}>Invite Manager</ModalHeader>
      <ModalBody>
        <ManagerInviteManager />
      </ModalBody>
    </Modal>
  )
}

export default withManager(InviteManagerForm)
