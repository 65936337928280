// CreateOrganizationMeetingForm.js - form for manager to add org meeting

import React from 'react'
import { graphql } from 'react-apollo'
import PropTypes from 'prop-types'
import { mutations, utils } from '../../graphql'
import { compose, pure } from 'recompose'
import 'react-datepicker/dist/react-datepicker.css'
import { withRouter } from 'react-router'
import MeetingForm from '../MeetingForm'
import { ADMIN_MEETINGS } from 'constants/routes'

const CreateMeetingFormComponent = (props) => {
  const handleSubmit = async (values) => {
    await props.createMeeting({
      variables: {
        input: {
          ...values,
          organizationId: props.match.params.orgId || null
        }
      },
      context: utils.generateAuthHeader(props.authUser.token)
    })
    props.history.push(ADMIN_MEETINGS)
  }
  return (
    <MeetingForm handleSubmit={handleSubmit} isAdmin />
  )
}

CreateMeetingFormComponent.propTypes = {
  authUser: PropTypes.object.isRequired,
  admin: PropTypes.object.isRequired
}

const CreateMeeting = compose(
  withRouter,
  graphql(mutations.CREATE_MEETING, {name: 'createMeeting'}),
  pure
)(CreateMeetingFormComponent)

export default CreateMeeting
