// utils/forms.js
import { DURATION_MONTHS, DURATION_YEARS } from 'constants/forms.js'
import moment from 'moment'

export const convertToDaysDuration = (count, unit) => {
  switch (unit) {
    case DURATION_YEARS:
      return moment().add(count, 'years').diff(moment(), 'days')
    case DURATION_MONTHS:
      return moment().add(count, 'months').diff(moment(), 'days')
    default:
      return count
  }
}
