// Landing - manager Landing component

import React from 'react'
import { compose } from 'recompose'
import { graphql } from 'react-apollo'
import PropTypes from 'prop-types'
import {withRouter} from 'react-router'
import { Col, Row, Card } from 'reactstrap'
import TBVCard from '../Card/TBVCard'
import AttendanceTable from '../Charts/AttendanceTable'
import * as textConstants from 'constants/text'
import * as meetingUtils from 'utils/meetings.js'
import moment from 'moment'
import bn from 'utils/bemnames'
import { CLIENT_STATUS } from 'constants/status'
import MessageForm from './MessageForm'
import Hidable from '../Hidable'
import { mutations, utils } from '../../graphql'
const bem = bn.create('dash-landing')

class Landing extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      selectedFilter: ''
    }
  }

  getFilteredClients = (clients) => {
    if (this.state.selectedFilter) {
      return clients.filter(c => {
        const pace = meetingUtils.getMeetingPace(c.meetingsAttended, c.meetingsRequiredPerMonth, moment().endOf('month'))
        return c.status === CLIENT_STATUS.ACTIVE && pace === this.state.selectedFilter
      })
    }
    return clients.filter(c => c.status === CLIENT_STATUS.ACTIVE)
  }
  changeFilter = (filter) => () => {
    this.setState({
      selectedFilter: filter
    })
  }
  sendMessage = async (variables) => {
    return this.props.sendClientSms({variables: variables, context: utils.generateAuthHeader(this.props.authUser.token)})
  }
  render () {
    const { manager } = this.props
    const { selectedFilter } = this.state
    const activeClients = manager.clients.filter(c => c.status === CLIENT_STATUS.ACTIVE)
    let clients = this.getFilteredClients(activeClients)
    const clientPaceStats = meetingUtils.getMeetingPaceCounts(activeClients, moment().endOf('month'))
    return (
      <Row className={bem.b()}>
        <Col>
          <Row className={bem.e('stats-row')}>
            <Col xs={6} lg={3}>
              <TBVCard onClick={this.changeFilter('')} className={`border-white ${selectedFilter === '' && 'selected'}`}>
                <h6 className='font-weight-bold p-2'>{textConstants.STAT_NUM_CLIENTS}</h6>
                <h1 className='font-weight-bold'>{activeClients.length}</h1>
              </TBVCard>
            </Col>
            <Col xs={6} lg={3}>
              <TBVCard
                className={`border-behind ${selectedFilter === meetingUtils.REQ_MEETINGS_BEHIND && 'selected'}`}
                onClick={this.changeFilter(meetingUtils.REQ_MEETINGS_BEHIND)}
              >
                <h6 className='font-weight-bold p-2'>{textConstants.STAT_NUM_CLIENTS_BEHIND}</h6>
                <h1 className='font-weight-bold'>{clientPaceStats[meetingUtils.REQ_MEETINGS_BEHIND]}</h1>
              </TBVCard>
            </Col>
            <Col xs={6} lg={3}>
              <TBVCard
                className={`border-on-target ${selectedFilter === meetingUtils.REQ_MEETINGS_ON_TARGET && 'selected'}`}
                onClick={this.changeFilter(meetingUtils.REQ_MEETINGS_ON_TARGET)}
              >
                <h6 className='font-weight-bold p-2'>{textConstants.STAT_NUM_CLIENTS_TARGET}</h6>
                <h1 className='font-weight-bold'>{clientPaceStats[meetingUtils.REQ_MEETINGS_ON_TARGET]}</h1>
              </TBVCard>
            </Col>
            <Col xs={6} lg={3}>
              <TBVCard
                className={`border-exceeding ${selectedFilter === meetingUtils.REQ_MEETINGS_EXCEEDING && 'selected'}`}
                onClick={this.changeFilter(meetingUtils.REQ_MEETINGS_EXCEEDING)}
              >
                <h6 className='font-weight-bold p-2'>{textConstants.STAT_NUM_CLIENTS_EXCEEDING}</h6>
                <h1 className='font-weight-bold'>{clientPaceStats[meetingUtils.REQ_MEETINGS_EXCEEDING]}</h1>
              </TBVCard>
            </Col>
          </Row>
          <Row>
            <Col>
              <Hidable show={!!selectedFilter && !!clients.length}>
                <MessageForm title={textConstants.MESSAGE_GROUP_TITLE} clients={clients} submit={this.sendMessage} />
              </Hidable>
            </Col>
          </Row>
          <Row>
            <Col>
              <Card className='border-0 tbv-box-shadow pb-3'>
                { !!clients.length && <AttendanceTable clientsData={clients} />}
                { !clients.length && !clients.length && (
                  <p className='text-center font-weight-bold empty-attendance-table'>You have no active clients.</p>
                )}
                { !clients.length && !!clients.length && (
                  <p className='text-center font-weight-bold empty-attendance-table'>No active clients match this criteria.</p>
                )}
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    )
  }
}

Landing.propTypes = {
  authUser: PropTypes.object.isRequired,
  manager: PropTypes.object.isRequired
}

export default compose(
  withRouter,
  graphql(mutations.SEND_CLIENT_SMS, {name: 'sendClientSms'})
)(Landing)
