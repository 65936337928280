// Probation Id Section - for client supervision duration

import React from 'react'

import PropTypes from 'prop-types'

import { ValidationFields, TBVFormField } from 'components/TBVForm'

import * as Yup from 'yup'

import { PROBATION_ID_LABEL } from 'constants/text.js'

export const ProbationdIdSchema = Yup.object().shape({
  probationId: ValidationFields.PROBATION_ID
})

export const ProbationIdPropsMap = {
  probationId: ''
}

export const ProbationIdSection = (props) => {
  const {
    probationId,
    probationIdLabel,
    probationIdInputProps
  } = props

  return (
    <React.Fragment>
      <TBVFormField label={probationIdLabel} id={probationId} {...probationIdInputProps} />
    </React.Fragment>
  )
}

ProbationIdSection.propTypes = {
  probationId: PropTypes.string,
  probationIdLabel: PropTypes.string,
  probationIdInputProps: PropTypes.object
}

ProbationIdSection.defaultProps = {
  probationId: 'probationId',
  probationIdLabel: PROBATION_ID_LABEL,
  probationIdInputProps: {
    type: 'string',
    placeholder: '00-00000'
  }
}
