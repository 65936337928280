// forms/constants.js
export const MEETING_FREQ_WEEKLY = 'WEEKLY'
export const MEETING_FREQ_MONTHLY = 'MONTHLY'

export const MEETING_FREQ_ITEMS = {MONTHLY: 'Monthly', WEEKLY: 'Weekly'}

export const DURATION_MONTHS = 'months'
export const DURATION_DAYS = 'days'
export const DURATION_YEARS = 'years'

export const DURATION_ITEMS = {days: 'Days', months: 'Months', years: 'Years'}
