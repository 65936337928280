// CancelSubscriptionForm.js - payment checkout form
import React from 'react'
import Log from 'utils/logging.js'

import { graphql, compose } from 'react-apollo'

import PropTypes from 'prop-types'

import { mutations, utils } from '../../../graphql'

import { CANCEL_SUBSCRIPTION_BTN, CANCEL_SUBSCRIPTION_SUCCESS, DEFAULT_ERROR_MSG } from 'constants/text'

import { CancelNoteSchema, CancelNotePropsMap, CancelNoteSection } from './CancelNoteSection.js'

import AuthButton from './AuthButton'

import { withFormik } from 'formik'

import { Form } from 'reactstrap'

const _buildValidationSchema = () => {
  return CancelNoteSchema
}

const formikEnhancer = withFormik({
  validationSchema: _buildValidationSchema(),
  mapPropsToValues: props => ({
    ...CancelNotePropsMap
  }),
  handleSubmit: async (values, {setSubmitting, setErrors, setStatus, props}) => {
    const payload = {
      ...values
    }
    const {
      authUser,
      CancelSubscription
    } = props

    Log.info('submit cancellation')
    const variables = { note: payload.note }
    try {
      // cancel subscription on paymentProfile
      await CancelSubscription({ variables: variables,
        context: utils.generateAuthHeader(authUser.token)
      })
    } catch (err) {
      Log.info('failed graphQL subscription cancellation')
      Log.error(err)
      setErrors({submit: DEFAULT_ERROR_MSG})
      setSubmitting(false)
    }
  },
  displayName: 'CancelSubscriptionForm'
})

const CancelSubscriptionForm = (props) => {
  const {
    touched,
    errors,
    status,
    handleSubmit,
    isSubmitting,
    values,
    children
  } = props

  return (
    <Form className='tbv-login-form' onSubmit={handleSubmit}>
      <CancelNoteSection values={values} />
      <AuthButton
        title={CANCEL_SUBSCRIPTION_BTN}
        disabled={isSubmitting}
        errors={errors}
        touched={touched}
        status={status}
        successMsg={CANCEL_SUBSCRIPTION_SUCCESS}
      />
      {children}
    </Form>
  )
}

CancelSubscriptionForm.propTypes = {
  onFormSubmit: PropTypes.func.isRequired,
  authUser: PropTypes.object.isRequired
}

const CancelSubscriptionFormGraphQL = compose(
  graphql(mutations.CANCEL_CLIENT_SUBSCRIPTION, {name: 'CancelSubscription'})
)(formikEnhancer(CancelSubscriptionForm))

export default CancelSubscriptionFormGraphQL
