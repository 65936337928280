// ForgotPasswordPage.js- forgot password

import React from 'react'

import { Card, Row, Col } from 'reactstrap'

import Page from 'components/Page'
import ForgotPasswordForm from 'components/ForgotPasswordForm'

class ForgotPasswordPage extends React.Component {
  render () {
    return (
      <Page className='ForgotPasswordPage'>
        <Row className='justify-content-center align-items-center h-100 flex-column'>
          <Col sm={4}>
            <h5 className='text-white mb-4 font-weight-bold text-center'>{'Forgot your password?'}</h5>
            <Card body className='border-0 tbv-box-shadow mb-3'>
              <ForgotPasswordForm />
            </Card>
          </Col>
        </Row>
      </Page>
    )
  }
}

export default ForgotPasswordPage
