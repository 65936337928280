// LandingPage.js - client home page

import React from 'react'
import Log from 'utils/logging.js'
import { Col, Row } from 'reactstrap'
import Page from '../components/Page'
import { Organization } from '../components/Admin'
import { withAdmin } from '../components/Session'

class OrganizationsPage extends React.Component {
  render () {
    Log.info('organizations page')
    Log.info(this.props)
    const { admin, authUser } = this.props
    Log.info(admin)
    return (
      <Page className='DashboardPage'>
        <Row>
          <Col>
            <Organization authUser={authUser} admin={admin} />
          </Col>
        </Row>
      </Page>
    )
  }
}

export default withAdmin(OrganizationsPage)
