import React from 'react'

import { Navbar, Nav, NavItem, NavLink } from 'reactstrap'

import { NavLink as ReactNavLink } from 'react-router-dom'

import { COMPANY_NAME_COPYRIGHT } from 'constants/text.js'

import { PRIVACY, TOS } from 'constants/routes.js'

import bn from 'utils/bemnames'
import classNames from 'classnames'

const bem = bn.create('footer')

const navItems = [
  { to: TOS, name: 'Terms of Service', exact: true },
  { to: PRIVACY, name: 'Privacy Policy', exact: true }
]

const Footer = () => {
  return (
    <Navbar className='tbv-footer'>
      <Nav navbar>
        <NavItem>
          &copy; {COMPANY_NAME_COPYRIGHT}
        </NavItem>
      </Nav>
      <Nav className={bem.e('nav-right')}>
        {navItems.map(({ to, name, exact }, index) => (
          <NavItem key={index} className={classNames(bem.e('nav-item'), 'd-none', 'd-lg-block')}>
            <NavLink
              id={`navItem-${name}-${index}`}
              tag={ReactNavLink}
              to={to}
              activeClassName='active'
              exact={exact}>
              <span>{name}</span>
            </NavLink>
          </NavItem>
        ))}
      </Nav>
      <Nav navbar className={classNames(bem.e('nav-right'), 'd-md-none')}>
        {navItems.map(({ to, name, exact }, index) => (
          <NavItem key={index} className={classNames(bem.e('nav-item'), 'd-md-none')}>
            <NavLink
              id={`navItem-${name}-${index}`}
              tag={ReactNavLink}
              className='position-relative'
              to={to}
              activeClassName='active'
              exact={exact}>
              <span>{name}</span>
            </NavLink>
          </NavItem>
        ))}
      </Nav>
    </Navbar>
  )
}

export default Footer
