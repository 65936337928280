// UpdateClient.js - form for managers to update clients

import React from 'react'
import Log from 'utils/logging.js'

import { graphql, compose } from 'react-apollo'

import PropTypes from 'prop-types'

import Roles from 'utils/roles.js'
import { mutations, queries, utils } from '../../../graphql'

import { DurationSchema, DurationPropsMap, DurationSection } from './DurationSection.js'
import { RequiredMeetingsSchema, RequiredMeetingsPropsMap, RequiredMeetingsSection } from './RequiredMeetingsSection.js'
import { ProbationdIdSchema, ProbationIdPropsMap, ProbationIdSection } from './ProbationIdSection.js'
import { ReminderSection, ReminderSchema, ReminderPropsMap } from './ReminderSection.js'
import { StatusSection, StatusPropsMap, StatusSchema } from './StatusSection'

import { UPDATE_CLIENT_BTN, DEFAULT_ERROR_MSG, UPDATE_CLIENT_SUCCESS } from 'constants/text.js'

import AuthButton from './AuthButton'

import { withFormik } from 'formik'

import { Form } from 'reactstrap'

import { convertToDaysDuration } from 'utils/forms.js'

const _buildValidationSchema = () => {
  return DurationSchema
    .concat(RequiredMeetingsSchema).concat(ProbationdIdSchema).concat(ReminderSchema).concat(StatusSchema)
}

const formikEnhancer = withFormik({
  validationSchema: _buildValidationSchema(),
  mapPropsToValues: props => ({
    ...props.initialValues,
    ...ProbationIdPropsMap,
    ...DurationPropsMap,
    ...RequiredMeetingsPropsMap,
    ...ReminderPropsMap,
    ...StatusPropsMap
  }),
  handleSubmit: async (values, {setSubmitting, setErrors, setStatus, props}) => {
    const payload = {
      ...values
    }
    Log.info('updateClient')
    Log.info(payload)

    const { UpdateClient, authUser, clientId } = props
    const variables = {
      reqMeetingsFreq: payload.reqMeetingsUnit,
      reqMeetings: payload.reqMeetings,
      durationDays: convertToDaysDuration(payload.duration, payload.durationUnit),
      probationId: payload.probationId,
      attendanceReminder: payload.attendanceReminder,
      attendanceReminderDays: payload.attendanceReminder ? payload.attendanceReminderDays || 0 : 0,
      status: payload.status,
      clientId: clientId
    }

    // update graphQL client
    Log.info('graphQl update client')
    try {
      const user = await UpdateClient({ variables: variables,
        context: utils.generateAuthHeader(authUser.token),
        update: (store, {data: { updateClient }}) => {
          const data = {user: updateClient}
          Log.info('updateclient update')
          Log.info(data)
          store.writeQuery({query: queries.getClientQuery, variables: {id: clientId}, data: data})
        }
      })
      Log.info('TBV client updated')
      Log.info(user)
      setStatus({success: true})
      setSubmitting(false)
      // props.onFormSubmit()
    } catch (error) {
      Log.info('failed graphQL client update')
      Log.error(error)
      setErrors({submit: DEFAULT_ERROR_MSG})
      setSubmitting(false)
    }
  },
  displayName: 'UpdateClientForm'
})

const UpdateClientForm = props => {
  const {
    touched,
    errors,
    status,
    handleSubmit,
    isSubmitting,
    values,
    initialValues,
    initialData,
    children
  } = props
  // update initialValues with initialData
  Object.assign(initialValues, initialData)

  return (
    <Form className='tbv-login-form' onSubmit={handleSubmit}>
      <StatusSection values={values} />
      <DurationSection values={values} />
      <RequiredMeetingsSection values={values} />
      <ProbationIdSection values={values} />
      <ReminderSection values={values} />
      <AuthButton
        title={UPDATE_CLIENT_BTN}
        disabled={isSubmitting}
        errors={errors}
        status={status}
        successMsg={UPDATE_CLIENT_SUCCESS}
        touched={touched}
      />
      {children}
    </Form>
  )
}

UpdateClientForm.propTypes = {
  roleState: PropTypes.oneOf([Roles.CASE_MANAGER, Roles.PROBATION_OFFICER]).isRequired,
  onFormSubmit: PropTypes.func.isRequired,
  initialData: PropTypes.object,
  clientId: PropTypes.string.isRequired,
  authUser: PropTypes.object.isRequired
}

UpdateClientForm.defaultProps = {
  initialData: {},
  inviteToken: null
}
const UpdateClientFormGraphQL = compose(
  graphql(mutations.UPDATE_CLIENT, {name: 'UpdateClient'})
)(formikEnhancer(UpdateClientForm))

export default UpdateClientFormGraphQL
