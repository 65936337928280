// withClient.js - HOC for loading app client

import React from 'react'

import withAuthorization from './withAuthorization'
import LoadingPlaceHolder from 'components/LoadingPlaceHolder'

import { Query } from 'react-apollo'
import { queries } from '../../graphql'
import { Redirect } from 'react-router-dom'

import { MANAGER_HOME, ADMIN_HOME } from 'constants/routes.js'

const withClient = (Component) => {
  class WithClient extends React.Component {
    render () {
      const { authUser, children, ...props } = this.props
      return (
        <Query query={queries.authedUserQuery} context={authUser.authHeader}>
          {({ loading, error, data }) => {
            if (error) return (<Redirect to='/' />)
            if (loading) return (<LoadingPlaceHolder />)

            // grab client or manager
            const { client, manager, admin } = data.authedUser

            // ensure client authed
            if (client) {
              return (<Component client={client} authUser={authUser} {...props}>{children}</Component>)
            } else if (manager) {
              return (<Redirect to={MANAGER_HOME} />)
            } else if (admin) {
              return (<Redirect to={ADMIN_HOME} />)
            } else {
              return (<Redirect to='/' />)
            }
          }}
        </Query>
      )
    }
  }

  const authCondition = (authUser) => !!authUser
  return withAuthorization(authCondition)(WithClient)
}

export default withClient
