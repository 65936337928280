// DropDown.js - reusable form dropdown with formik
import React from 'react'
import Log from 'utils/logging.js'

import PropTypes from 'prop-types'

import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle } from 'reactstrap'

import TBVError from './Error.js'

const TBVDropDown = ({isOpenId, select, title, items, field, form: {touched, errors, values, setFieldValue, setFieldTouched}, showError, ...props}) => {
  const errorMsg = touched[field.name] && errors[field.name]

  const toggle = (e) => {
    Log.info('toggle dropdown')
    Log.info(e.target.value)
    setFieldValue(isOpenId, !values[isOpenId])
  }

  const onItemClick = (valueId) => {
    Log.info('itemClick set value')
    Log.info(valueId)
    setFieldValue(field.name, valueId)
  }

  const handleBlur = () => {
    setFieldTouched(field.name, true)
  }

  const ddItems = Object.keys(items).map((valueId, idx) =>
    <DropdownItem key={field.name + '-' + idx} name={field.name} value={valueId} onClick={() => onItemClick(valueId)} >{items[valueId]}</DropdownItem>
  )

  return (
    <React.Fragment>
      <Dropdown name={field.name} onBlur={handleBlur} isOpen={values[isOpenId]} toggle={toggle} {...field} {...props}>
        <DropdownToggle id={field.name} onBlur={handleBlur} {...field} {...props} caret>
          {items[values[field.name]] || title}
        </DropdownToggle>
        <DropdownMenu name={field.name} id={field.name} onBlur={handleBlur}>
          {ddItems}
        </DropdownMenu>
      </Dropdown>
      { showError && errorMsg && (
        <TBVError message={errorMsg} className='text-danger' />
      )}
    </React.Fragment>
  )
}

TBVDropDown.propTypes = {
  isOpenId: PropTypes.string.isRequired,
  values: PropTypes.object.isRequired,
  props: PropTypes.object,
  title: PropTypes.string,
  items: PropTypes.object.isRequired
}

TBVDropDown.defaultProps = {
  props: {},
  title: '',
  showError: true
}

export default TBVDropDown
