// Duration section - for client supervision duration

import React from 'react'

import PropTypes from 'prop-types'

import { FormGroup, Label } from 'reactstrap'

import { ValidationFields, TBVFormField } from 'components/TBVForm'

import DurationUnitDropDown from './DurationUnitDropDown.js'

import * as Yup from 'yup'

import { DURATION_SUPERVISION_LABEL } from 'constants/text.js'

export const DurationSchema = Yup.object().shape({
  duration: ValidationFields.DURATION_SUPERVISION,
  durationUnit: ValidationFields.DURATION_UNIT
})

export const DurationPropsMap = {
  duration: '',
  durationUnit: '',
  durationUnitIsOpen: false
}

export const DurationSection = (props) => {
  const {
    durationId,
    durationLabel,
    durationInputProps,
    durationUnitIsOpen,
    durationUnitId,
    ...restProps
  } = props

  return (
    <React.Fragment>
      <div className='form-row' {...restProps}>
        <TBVFormField className='col-md-6 required' label={durationLabel} id={durationId} {...durationInputProps} />
        <FormGroup className='col-md-6'>
          <Label for={durationUnitId}>&nbsp;</Label>
          <DurationUnitDropDown name={durationUnitId} isOpenId={durationUnitIsOpen} showError />
        </FormGroup>
      </div>
    </React.Fragment>
  )
}

DurationSection.propTypes = {
  durationId: PropTypes.string,
  durationLabel: PropTypes.string,
  durationInputProps: PropTypes.object,

  durationUnitId: PropTypes.string,
  durationUnitIsOpen: PropTypes.string
}

DurationSection.defaultProps = {
  durationId: 'duration',
  durationLabel: DURATION_SUPERVISION_LABEL,
  durationInputProps: {
    type: 'integer',
    placeholder: 'e.g. 6'
  },
  durationUnitId: 'durationUnit',
  durationUnitIsOpen: 'durationUnitIsOpen'
}
