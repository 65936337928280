// Legend.js - graph legend
import React from 'react'

import { LEGEND_ON_TARGET, LEGEND_BEHIND, LEGEND_EXCEEDING } from 'constants/text.js'

const Legend = props => (
  <div {...props}>
    <div className='d-flex tbv-legend'>
      <div className='tbv-legend-on-target pr-3'><small>{LEGEND_ON_TARGET}</small></div>
      <div className='tbv-legend-exceeding pr-3'><small>{LEGEND_EXCEEDING}</small></div>
      <div className='tbv-legend-behind pr-3'><small>{LEGEND_BEHIND}</small></div>
    </div>
  </div>
)

export default Legend
