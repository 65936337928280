import React from 'react'
import { graphql } from 'react-apollo'
import PropTypes from 'prop-types'
import { mutations, utils, queries } from '../../graphql'
import { compose, pure } from 'recompose'
import 'react-datepicker/dist/react-datepicker.css'
import { withRouter } from 'react-router'
import MeetingForm from '../MeetingForm'
import { withLoading } from '../../hocs/withLoading'

const EditMeetingFormComponent = (props) => {
  const handleSubmit = async (values) => {
    await props.updateMeeting({
      variables: {
        input: {
          ...values,
          id: props.match.params.id
        }
      },
      context: utils.generateAuthHeader(props.authUser.token)
    })
    props.history.push('/meetings')
  }
  return (
    <MeetingForm handleSubmit={handleSubmit} initialValues={props.data.meeting} isAdmin />
  )
}

EditMeetingFormComponent.propTypes = {
  authUser: PropTypes.object.isRequired,
  admin: PropTypes.object.isRequired
}

const EditMeetingForm = compose(
  withRouter,
  graphql(queries.getMeeting, {
    options: props => ({
      context: utils.generateAuthHeader(props.authUser.token),
      fetchPolicy: 'network-only',
      variables: {
        input: {
          id: props.match.params.id
        }
      }
    })
  }),
  graphql(mutations.EDIT_MEETING, {name: 'updateMeeting'}),
  withLoading,
  pure
)(EditMeetingFormComponent)

export default EditMeetingForm
