// ClientDetail.js - client detail

import React from 'react'
import Log from 'utils/logging.js'

import PropTypes from 'prop-types'

import { Query } from 'react-apollo'

import { queries, utils } from '../../graphql'

import Roles from 'utils/roles'

import { Col, Row, Card, NavLink as BSNavLink } from 'reactstrap'

import { UpdateClientForm } from 'components/SignUp'

import ContactCard from '../Card/ContactCard'
import { NavLink } from 'react-router-dom'
import TBVButton from '../TBVButton'
import * as routes from 'constants/routes'
import LoadingPlaceHolder from '../LoadingPlaceHolder'

import { DURATION_DAYS } from 'constants/forms.js'

import { DEFAULT_ERROR_MSG } from 'constants/text.js'

const _genInitialData = (client) => {
  const initialData = {
    duration: client.durationDays || '',
    durationUnit: DURATION_DAYS,
    reqMeetings: client.reqMeetings || '',
    reqMeetingsUnit: client.reqMeetingsFreq || '',
    probationId: client.probationId || '',
    attendanceReminder: client.attendanceReminder,
    attendanceReminderDays: client.attendanceReminderDays || '',
    status: client.status || ''
  }
  Log.info(initialData)
  return initialData
}

const ClientContactCardItems = ({userId}) => {
  return (
    <BSNavLink
      tag={NavLink}
      to={routes.CLIENT_REPORTS + userId}
      activeClassName='active'
      exact>
      <TBVButton title='Back to details' />
    </BSNavLink>
  )
}

const ClientDetail = (props) => {
  const { authUser, userId, roleState } = props
  const authHeader = utils.generateAuthHeader(authUser.token)
  const data = {id: userId}
  return (
    <Query query={queries.getClientQuery} variables={data} context={authHeader}>
      {({ loading, error, data }) => {
        if (error) return <div>{DEFAULT_ERROR_MSG}</div>
        if (loading) return <LoadingPlaceHolder />

        return (
          <React.Fragment>
            <Row>
              <Col md={{size: 4, offset: 4}}>
                <ContactCard
                  user={data.user} >
                  <div className='d-flex justify-content-center'>
                    <ClientContactCardItems userId={userId} />
                  </div>
                </ContactCard>
              </Col>
            </Row>
            <Row>
              <Col md={{size: 10, offset: 1}}>
                <Card className='border-0 tbv-box-shadow p-5 mb-3'>
                  <UpdateClientForm
                    className='float-right border-0'
                    roleState={roleState}
                    initialData={_genInitialData(data.user)}
                    clientId={userId}
                    authUser={authUser}
                    onLogInClick={(e) => { e.preventDefault() }}
                    onFormSubmit={(e) => { e.preventDefault() }}
                  />
                </Card>
              </Col>
            </Row>
          </React.Fragment>
        )
      }}
    </Query>
  )
}

ClientDetail.propTypes = {
  authUser: PropTypes.object.isRequired,
  userId: PropTypes.string.isRequired,
  roleState: PropTypes.oneOf([Roles.CASE_MANAGER, Roles.PROBATION_OFFICER]).isRequired
}

export default ClientDetail
