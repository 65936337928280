// PaymentProfile Statuses
export const PaymentStatus = {
  CANCEL_END_OF_PERIOD: 'CANCEL_END_OF_PERIOD',
  PAST_DUE: 'PAST_DUE',
  CANCELED: 'CANCELED',
  ACTIVE: 'ACTIVE'
}

// App Local Payment Statuses
export const ACTIVE_PAYMENT = 'Active'
export const PAST_DUE_PAYMENT = 'Past Due'
export const CANCELED_PAYMENT = 'Canceled'
export const CANCEL_END_OF_PERIOD_PAYMENT = 'Canceled'

// Map grapql status -> app
export const PaymentStatusMsg = (paymentStatus) => {
  switch (paymentStatus) {
    case PaymentStatus.CANCEL_END_OF_PERIOD:
      return CANCEL_END_OF_PERIOD_PAYMENT
    case PaymentStatus.PAST_DUE:
      return PAST_DUE_PAYMENT
    case PaymentStatus.CANCELED:
      return CANCELED_PAYMENT
    case PaymentStatus.ACTIVE:
      return ACTIVE_PAYMENT
    default:
      return ACTIVE_PAYMENT
  }
}
