// Error.js - form error component
import React from 'react'

import PropTypes from 'prop-types'

const Error = ({message, ...props}) => (

  <div {...props}>
    {message}
  </div>

)

Error.propTypes = {
  message: PropTypes.string
}

Error.defaultProps = {
  message: ''
}

export default Error
