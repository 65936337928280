// SignUp.js - form for login, register, forgot password

import React from 'react'
import Log from 'utils/logging.js'

import { graphql, compose } from 'react-apollo'

import PropTypes from 'prop-types'

import Roles from 'utils/roles.js'
import { mutations, utils } from '../../../graphql'

import { PersonInfoSchema, PersonInfoPropsMap, PersonInfoSection } from './PersonInfoSection.js'
import { DurationSchema, DurationPropsMap, DurationSection } from './DurationSection.js'
import { RequiredMeetingsSchema, RequiredMeetingsPropsMap, RequiredMeetingsSection } from './RequiredMeetingsSection.js'
import { ProbationdIdSchema, ProbationIdPropsMap, ProbationIdSection } from './ProbationIdSection.js'
import { TermsSchema, TermsPropsMap, TermsSection } from './TermsSection.js'

import { DEFAULT_ERROR_MSG, ADD_CLIENT_SIGNUP, ADD_CLIENT_SUCCESS } from 'constants/text'

import AuthButton from './AuthButton'

import { withFormik } from 'formik'

import { Form, FormGroup } from 'reactstrap'

import { withManager } from 'components/Session'

const _buildValidationSchema = () => {
  return PersonInfoSchema.concat(DurationSchema)
    .concat(RequiredMeetingsSchema).concat(ProbationdIdSchema).concat(TermsSchema)
}

const formikEnhancer = withFormik({
  validationSchema: _buildValidationSchema(),
  mapPropsToValues: props => ({
    ...PersonInfoPropsMap,
    ...ProbationIdPropsMap,
    ...DurationPropsMap,
    ...RequiredMeetingsPropsMap,
    ...TermsPropsMap
  }),
  handleSubmit: async (values, {setSubmitting, setErrors, setStatus, resetForm, props}) => {
    let invite
    const payload = {
      ...values
    }

    const { CreateClientInvite, authUser } = props
    const variables = {
      phone: payload.phoneNumber,
      firstName: payload.firstName,
      lastName: payload.lastName,
      email: payload.email,
      reqMeetingsFreq: payload.reqMeetingsUnit,
      reqMeetings: payload.reqMeetings,
      durationDays: payload.duration,
      probationId: payload.probationId
    }
    Log.info('addClient graphql')
    Log.info(payload)

    // create invite to signup
    try {
      invite = await CreateClientInvite({variables: variables, context: utils.generateAuthHeader(authUser.token)})
      Log.info('TBV invite created')
      Log.info(invite)
      resetForm()
      setStatus({success: true})
      setSubmitting(false)
    } catch (error) {
      Log.info('failed graphQL client create')
      Log.error(error)
      setErrors({submit: DEFAULT_ERROR_MSG})
      setSubmitting(false)
    }
  },
  displayName: 'AddClientForm'
})

const AddClientForm = props => {
  const {
    touched,
    errors,
    status,
    handleSubmit,
    isSubmitting,
    values,
    onLogInClick,
    children
  } = props

  return (
    <Form className='tbv-login-form' onSubmit={handleSubmit}>

      <PersonInfoSection />
      <DurationSection values={values} />
      <RequiredMeetingsSection values={values} />
      <ProbationIdSection values={values} />
      <FormGroup className='required'>
        <TermsSection values={values} />
      </FormGroup>
      <AuthButton
        title={ADD_CLIENT_SIGNUP}
        disabled={isSubmitting}
        errors={errors}
        status={status}
        successMsg={ADD_CLIENT_SUCCESS}
        touched={touched}
        onClick={() => (onLogInClick())}
      />
      {children}
    </Form>
  )
}

AddClientForm.propTypes = {
  roleState: PropTypes.oneOf([Roles.PROBATION_OFFICER, Roles.CASE_MANAGER]).isRequired,
  onLogInClick: PropTypes.func,
  onFormSubmit: PropTypes.func
}

const AddClientFormGraphQL = compose(
  graphql(mutations.CREATE_CLIENT_INVITE, {name: 'CreateClientInvite'})
)(formikEnhancer(AddClientForm))

export default withManager(AddClientFormGraphQL)
