import React from 'react'
import { withAdmin } from 'components/Session'
import { Modal, ModalHeader, ModalBody } from 'reactstrap'
import AdminInviteManager from '../SignUp/Form/AdminInviteManager'

const InviteManagerForm = (props) => {
  return (
    <Modal
      isOpen={props.open}
      toggle={props.toggle}
    >
      <ModalHeader toggle={props.toggle}>Invite Manager</ModalHeader>
      <ModalBody>
        <AdminInviteManager />
      </ModalBody>
    </Modal>
  )
}

export default withAdmin(InviteManagerForm)
