// MonthsDropwDown.js - dropdown of years

import React from 'react'

import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap'

import moment from 'moment'

const NUM_YEARS = 2

const genYearItems = (numYears) => {
  return Array(numYears).fill().map((_, idx) => {
    const yearStart = moment().subtract(idx, 'years')
    return { title: yearStart.format('YYYY'),
      name: yearStart.format('MM/DD/YYYY'),
      startObj: yearStart.startOf('year'),
      start: yearStart.startOf('year').format('MM/DD/YYYY'),
      endObj: yearStart.endOf('year'),
      end: yearStart.endOf('year').format('MM/DD/YYYY')
    }
  })
}

export const yearItems = genYearItems(NUM_YEARS)

export const YearsDropDown = ({dropdownOpen, toggle, onItemClick, title}) => (
  <Dropdown isOpen={dropdownOpen} toggle={toggle} className='tbv-filter'>
    <DropdownToggle caret><small>{title}</small></DropdownToggle>
    <DropdownMenu>
      {yearItems.map(item => (
        <DropdownItem
          key={item.title}
          onClick={() => onItemClick(item)}
          value={item}>{item.title}</DropdownItem>
      ))}
    </DropdownMenu>
  </Dropdown>
)
