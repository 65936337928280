// SignUp.js - form for login, register, forgot password

import React from 'react'
import Log from 'utils/logging.js'

import { graphql, compose } from 'react-apollo'

import PropTypes from 'prop-types'

import { getLogInPath } from 'utils/roles.js'

import { auth } from '../../../firebase'
import { mutations } from '../../../graphql'

import { PersonInfoSchema, PersonInfoPropsMap, PersonInfoSection } from './PersonInfoSection.js'
import { TermsSchema, TermsPropsMap, TermsSection } from './TermsSection.js'
import { PasswordSchema, PasswordPropsMap, PasswordSection } from './PasswordSection.js'

import { DEFAULT_ERROR_MSG, LOG_IN } from 'constants/text'

import AuthButton from './AuthButton'

import { withFormik } from 'formik'

import { Form, FormGroup } from 'reactstrap'
import AuthButtonTag from './AuthButtonTag'

const _buildValidationSchema = () => {
  return PersonInfoSchema.concat(TermsSchema).concat(PasswordSchema)
}

const formikEnhancer = withFormik({
  validationSchema: _buildValidationSchema(),
  mapPropsToValues: props => ({
    ...props.initialValues,
    ...PersonInfoPropsMap,
    ...TermsPropsMap,
    ...PasswordPropsMap
  }),
  handleSubmit: async (values, {setSubmitting, setErrors, setStatus, props}) => {
    Log.info('handleSubmit')
    let authUser
    let user
    const payload = {
      ...values
    }

    const createUserFunc = props.CreateManager
    const variables = {
      phone: payload.phoneNumber,
      firstName: payload.firstName,
      lastName: payload.lastName,
      inviteToken: props.inviteToken
    }

    Log.info('createManager')
    Log.info(payload)
    try {
      // create firebase user
      authUser = await auth.doCreateUserWithEmailAndPassword(payload.email, payload.password)
      Log.info('FireBase user created')
      Log.info(authUser)
      variables.authProviderId = authUser.user.uid
      variables.email = authUser.user.email
      const input = {variables: variables}

      // create graphQL user
      Log.info('manager graphQl create input')
      Log.info(input)
      try {
        user = await createUserFunc(input)
        Log.info('TBV mamanger created')
        Log.info(user)
        // setStatus({success: true})
        // setSubmitting(false)
        props.onFormSubmit()
      } catch (error) {
        Log.info('failed graphQL user create')
        Log.error(error)
        setErrors({submit: DEFAULT_ERROR_MSG})
        setSubmitting(false)
      }
    } catch (error) {
      Log.info('failed firebase user create')
      Log.error(error)
      setErrors({submit: error.message})
      setSubmitting(false)
    }
  },
  displayName: 'ManagerSignUpForm'
})

const ManagerSignUpForm = props => {
  const {
    touched,
    errors,
    handleSubmit,
    isSubmitting,
    values,
    initialValues,
    initialData,
    onLogInClick,
    roleState,
    children
  } = props

  Object.assign(initialValues, initialData)

  const renderLogInLink = (roleState) => {
    return getLogInPath(roleState)
  }

  return (
    <Form className='tbv-login-form' onSubmit={handleSubmit}>

      <PersonInfoSection />
      <PasswordSection />
      <FormGroup className='required'>
        <TermsSection values={values} />
      </FormGroup>
      <AuthButton
        disabled={isSubmitting}
        errors={errors}
        touched={touched}
        href={renderLogInLink(roleState)}
        onClick={(e) => (onLogInClick())}
        subTitle={<AuthButtonTag text='Already have an account?' linkText={LOG_IN} href={renderLogInLink(roleState)} />}
      />

      {children}
    </Form>
  )
}

ManagerSignUpForm.propTypes = {
  onLogInClick: PropTypes.func.isRequired,
  onFormSubmit: PropTypes.func.isRequired,
  initialData: PropTypes.object
}

ManagerSignUpForm.defaultProps = {
  initialData: {},
  inviteToken: null
}

const ManagerSignUpFormGraphQL = compose(
  graphql(mutations.CREATE_MANAGER, {name: 'CreateManager'})
)(formikEnhancer(ManagerSignUpForm))

export default ManagerSignUpFormGraphQL
