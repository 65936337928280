// App.js

import React from 'react'

import Roles, { getLogInPath } from 'utils/roles'
import * as Routes from 'constants/routes'

import GAListener from 'components/GAListener'
import {
  LayoutRoute,
  HomeLayout,
  DashboardLayout,
  ClientLayout,
  EmptyLayout,
  FormsLayout,
  DashboardNoMenuLayout,
  AdminNoMenuLayout,
  AdminLayout
} from 'components/Layout'

// pages
import LogInPage from 'pages/LogInPage.js'
import SignUpPage from 'pages/SignUpPage.js'
import ClientProfilePage from 'pages/ClientProfilePage.js'
import ForgotPasswordPage from 'pages/ForgotPasswordPage.js'
import ManagerWelcomePage from 'pages/ManagerWelcomePage.js'
import AddClientPage from 'pages/AddClientPage.js'
import ClientsPage from 'pages/ClientsPage.js'
import ClientDetailPage from 'pages/ClientDetailPage.js'
import DashboardLandingPage from 'pages/DashboardLandingPage.js'
import ClientReportsPage from 'pages/ClientReportsPage.js'
import ClientWelcomePage from 'pages/ClientWelcomePage.js'
import InviteSignUpPage from 'pages/InviteSignUpPage.js'
import InviteManagerSignUpPage from 'pages/InviteManagerSignUpPage.js'
import ManagerProfilePage from 'pages/ManagerProfilePage.js'
import AttendanceReportPage from 'pages/AttendanceReportPage.js'
import ClientAttendancePage from 'pages/ClientAttendancePage.js'
import PrivacyPage from 'pages/PrivacyPage.js'
import TermsOfServicePage from 'pages/TermsOfServicePage.js'
import AttendanceVerifyPage from 'pages/AttendanceVerifyPage.js'
import AdminLandingPage from 'pages/AdminLandingPage.js'
import MyOrganizationPage from 'pages/MyOrganizationPage.js'
import CreateOrganizationMeetingPage from 'pages/CreateOrgMeetingPage.js'
import EditOrgMeetingPage from 'pages/EditOrgMeetingPage.js'
import AdminMeetingsPage from 'pages/AdminMeetingsPage.js'
import AdminEditMeetingPage from 'pages/AdminEditMeetingPage.js'
import PendingInvitesPage from 'pages/PendingInvitesPage.js'
import ManagersPage from 'pages/ManagersPage.js'
import AdminReviewAttendancePage from 'pages/AdminReviewAttendancePage.js'
import AdminCreateMeetingPage from 'pages/AdminCreateMeetingPage.js'
import AdminAttendanceLocationPage from 'pages/AdminAttendanceLocationPage.js'
import ApproveNewMeetingPage from 'pages/ApproveNewMeetingPage.js'
import ClientConversationPage from 'pages/ClientConversationPage.js'

// withAuthentication HOC
import { withAuthentication } from 'components/Session'

// graphQL
import { graphQLClient } from './graphql'
import { ApolloProvider } from 'react-apollo'

import componentQueries from 'react-component-queries'
import { BrowserRouter, Redirect, Switch } from 'react-router-dom'
import './styles/reduction.css'
import {
  LOGIN_TITLE_CLIENT,
  LOGIN_SUBTITLE_CLIENT,
  LOGIN_TITLE_SUPERVISOR,
  LOGIN_SUBTITLE_SUPERVISOR
} from './constants/text'
import OrganizationsPage from './pages/OrganizationsPage'

const getBasename = () => {
  return `/${process.env.PUBLIC_URL.split('/').pop()}`
}

const supervisorLoginLink = (
  <div>
    <div className='supervisor-divider'>
      <p className='supervisor-divider__text'>or</p>
    </div>
    <div>
      <a
        className='link text-center supervisor-login'
        href={getLogInPath(Roles.PROBATION_OFFICER)}
      >
        Supervisor login
      </a>
    </div>
  </div>
)

class App extends React.Component {
  render () {
    return (
      <ApolloProvider client={graphQLClient}>
        <BrowserRouter basename={getBasename()}>
          <GAListener>
            <Switch>
              <LayoutRoute
                exact
                path={Routes.LOG_IN_CLIENT}
                layout={HomeLayout}
                component={props => (
                  <LogInPage
                    title={LOGIN_TITLE_CLIENT}
                    subTitle={LOGIN_SUBTITLE_CLIENT}
                    cardSubtitle={supervisorLoginLink}
                    {...props}
                    roleState={Roles.CLIENT}
                  />
                )}
              />
              <LayoutRoute
                exact
                path={Routes.LOG_IN_OFFICER}
                layout={HomeLayout}
                component={props => (
                  <LogInPage
                    title={LOGIN_TITLE_SUPERVISOR}
                    subTitle={LOGIN_SUBTITLE_SUPERVISOR}
                    {...props}
                    cardTitle='Supervisor Login'
                    roleState={Roles.PROBATION_OFFICER}
                  />
                )}
              />
              <LayoutRoute
                exact
                path={Routes.SIGN_UP_CLIENT}
                layout={FormsLayout}
                component={props => (
                  <SignUpPage {...props} roleState={Roles.CLIENT} />
                )}
              />
              <LayoutRoute
                exact
                path={Routes.SIGN_UP_OFFICER}
                layout={FormsLayout}
                component={props => (
                  <SignUpPage {...props} roleState={Roles.PROBATION_OFFICER} />
                )}
              />
              <LayoutRoute
                exact
                path={Routes.FORGOT_PASSWORD}
                layout={HomeLayout}
                component={ForgotPasswordPage}
              />
              <LayoutRoute
                exact
                path={Routes.CLIENT_PROFILE}
                layout={ClientLayout}
                component={props => (
                  <ClientProfilePage {...props} roleState={Roles.CLIENT} />
                )}
              />
              {/** ----------- ADMIN ----------- */}
              <LayoutRoute
                exact
                path={Routes.ADMIN_HOME}
                layout={AdminLayout}
                title='Admin Panel'
                component={props => <AdminLandingPage {...props} />}
              />
              <LayoutRoute
                exact
                path={Routes.ADMIN_ATTENDANCE}
                layout={AdminLayout}
                title='Meeting Location Requests'
                component={props => <AdminReviewAttendancePage {...props} />}
              />
              <LayoutRoute
                exact
                path={Routes.ADMIN_ATTENDANCE_REVIEW}
                layout={AdminNoMenuLayout}
                withBackButton
                title='Review Meeting Location'
                component={props => <AdminAttendanceLocationPage {...props} />}
              />
              <LayoutRoute
                exact
                path={Routes.ADMIN_MEETINGS}
                layout={AdminLayout}
                title='Meetings'
                component={props => <AdminMeetingsPage {...props} />}
              />
              <LayoutRoute
                exact
                path={Routes.ADMIN_MEETINGS_EDIT}
                withBackButton
                layout={AdminNoMenuLayout}
                title='Edit Meeting'
                component={props => <AdminEditMeetingPage {...props} />}
              />
              <LayoutRoute
                exact
                path={Routes.ADMIN_REVIEW_MEETING}
                withBackButton
                layout={AdminNoMenuLayout}
                title='Review Meeting'
                component={props => <ApproveNewMeetingPage {...props} />}
              />
              <LayoutRoute
                exact
                path={Routes.ADMIN_CREATE_MEETING}
                withBackButton
                layout={AdminNoMenuLayout}
                title='Create Meeting'
                component={props => <AdminCreateMeetingPage {...props} />}
              />
              <LayoutRoute
                exact
                path={Routes.ORGANIZATION_PAGE}
                layout={AdminLayout}
                title='Organizations'
                component={props => <OrganizationsPage {...props} />}

              />
              {/** ----------- /ADMIN ----------- */}
              <LayoutRoute
                exact
                path={Routes.DASHBOARD}
                layout={DashboardLayout}
                title='Overview'
                component={props => <DashboardLandingPage {...props} />}
              />
              <LayoutRoute
                exact
                path={Routes.MANAGER_WELCOME}
                layout={FormsLayout}
                component={props => <ManagerWelcomePage {...props} />}
              />
              <LayoutRoute
                exact
                path={Routes.ADD_CLIENT}
                layout={FormsLayout}
                component={props => (
                  <AddClientPage
                    {...props}
                    roleState={Roles.PROBATION_OFFICER}
                  />
                )}
              />
              <LayoutRoute
                exact
                path={Routes.MANAGER_CLIENT_ATTENDANCE + ':id'}
                withBackButton
                layout={DashboardNoMenuLayout}
                component={props => (
                  <AttendanceVerifyPage
                    {...props}
                    roleState={Roles.PROBATION_OFFICER}
                  />
                )}
              />
              <LayoutRoute
                exact
                path={Routes.MANAGER_ATTENDANCE_REPORT + ':id'}
                withBackButton
                layout={EmptyLayout}
                component={props => (
                  <AttendanceReportPage
                    {...props}
                    roleState={Roles.PROBATION_OFFICER}
                  />
                )}
              />
              <LayoutRoute
                exact
                path={Routes.CLIENT_ATTENDANCE}
                withBackButton
                layout={EmptyLayout}
                component={props => (
                  <ClientAttendancePage {...props} roleState={Roles.CLIENT} />
                )}
              />
              <LayoutRoute
                exact
                path={Routes.CLIENTS}
                withBackButton
                layout={DashboardLayout}
                title='Clients'
                component={props => <ClientsPage {...props} />}
              />
              <LayoutRoute
                exact
                path={Routes.PENDING_INVITES}
                layout={DashboardLayout}
                title='Pending Invites'
                component={props => <PendingInvitesPage {...props} />}
              />
              <LayoutRoute
                exact
                path={Routes.CLIENT_DETAIL + ':id'}
                layout={DashboardNoMenuLayout}
                withBackButton
                component={props => (
                  <ClientDetailPage
                    roleState={Roles.PROBATION_OFFICER}
                    {...props}
                  />
                )}
              />
              <LayoutRoute
                exact
                path={Routes.CLIENT_CONVERSATION}
                layout={DashboardNoMenuLayout}
                withBackButton
                component={props => (
                  <ClientConversationPage {...props} />
                )}
              />
              <LayoutRoute
                exact
                path={Routes.CLIENT_REPORTS + ':id'}
                withBackButton
                layout={DashboardNoMenuLayout}
                component={props => (
                  <ClientReportsPage
                    {...props}
                    roleState={Roles.PROBATION_OFFICER}
                  />
                )}
              />
              <LayoutRoute
                exact
                path={Routes.MANAGER_ORG}
                layout={DashboardLayout}
                title='Meetings'
                component={props => <MyOrganizationPage {...props} />}
              />
              <LayoutRoute
                exact
                path={Routes.MANAGER_ORG_MANAGERS}
                layout={DashboardLayout}
                title='Managers'
                component={props => <ManagersPage {...props} />}
              />
              <LayoutRoute
                exact
                path={Routes.MANAGER_CREATE_ORG_MEETING}
                withBackButton
                layout={DashboardNoMenuLayout}
                title='Create Meeting'
                component={props => <CreateOrganizationMeetingPage {...props} />}
              />
              <LayoutRoute
                exact
                path={Routes.MANAGER_EDIT_ORG_MEETING}
                withBackButton
                layout={DashboardNoMenuLayout}
                title='Edit Meeting'
                component={props => <EditOrgMeetingPage {...props} />}
              />
              <LayoutRoute
                exact
                path={Routes.SIGN_UP_INVITE + ':token'}
                layout={FormsLayout}
                component={props => (
                  <InviteSignUpPage {...props} roleState={Roles.CLIENT} />
                )}
              />
              <LayoutRoute
                exact
                path={Routes.SIGN_UP_MANAGER_INVITE + ':token'}
                layout={FormsLayout}
                component={props => (
                  <InviteManagerSignUpPage {...props} roleState={Roles.PROBATION_OFFICER} />
                )}
              />
              <LayoutRoute
                exact
                path={Routes.CLIENT_WELCOME}
                layout={FormsLayout}
                component={props => (
                  <ClientWelcomePage {...props} roleState={Roles.CLIENT} />
                )}
              />
              <LayoutRoute
                exact
                path={Routes.MANAGER_PROFILE}
                layout={DashboardNoMenuLayout}
                component={props => (
                  <ManagerProfilePage
                    {...props}
                    roleState={Roles.PROBATION_OFFICER}
                  />
                )}
              />
              <LayoutRoute
                exact
                path={Routes.PRIVACY}
                layout={FormsLayout}
                component={props => <PrivacyPage />}
              />
              <LayoutRoute
                exact
                path={Routes.TOS}
                layout={FormsLayout}
                component={props => <TermsOfServicePage />}
              />
              <LayoutRoute
                exact
                path={Routes.HOME}
                layout={HomeLayout}
                component={props => (
                  <LogInPage
                    title={LOGIN_TITLE_CLIENT}
                    subTitle={LOGIN_SUBTITLE_CLIENT}
                    cardSubtitle={supervisorLoginLink}
                    {...props}
                    roleState={Roles.CLIENT}
                  />
                )}
              />

              <Redirect to='/' />
            </Switch>
          </GAListener>
        </BrowserRouter>
      </ApolloProvider>
    )
  }
}

const query = ({ width }) => {
  if (width < 575) {
    return { breakpoint: 'xs' }
  }

  if (width > 576 && width < 767) {
    return { breakpoint: 'sm' }
  }

  if (width > 768 && width < 991) {
    return { breakpoint: 'md' }
  }

  if (width > 992 && width < 1199) {
    return { breakpoint: 'lg' }
  }

  if (width > 1200) {
    return { breakpoint: 'xl' }
  }

  return { breakpoint: 'xs' }
}

// export default componentQueries(query)(App)
export default componentQueries(query)(withAuthentication(App))
