// Landing - manager Landing component

import React from 'react'
import { compose, pure } from 'recompose'
import PropTypes from 'prop-types'
import { queries, utils } from '../../graphql'
import { withRouter } from 'react-router'
import { Table, Col, Row, Card, NavLink as BSNavLink, InputGroup, Input } from 'reactstrap'
import bn from 'utils/bemnames'
import { withApollo } from 'react-apollo'
import TBVButton from '../TBVButton'
import Hidable from '../Hidable'
import { NavLink } from 'react-router-dom'
const bem = bn.create('meetings')
const weekDaysMapping = { 1: 'Monday', 2: 'Tuesday', 3: 'Wednesday', 4: 'Thursday', 5: 'Friday', 6: 'Saturday', 7: 'Sunday' }
const meetingTypes = [
  { label: 'Choose meeting type', value: '' },
  { label: 'Alcoholics Anonymous', value: 'AA' },
  { label: 'Narcotics Anonymous', value: 'NA' },
  { label: 'Smart Recovery', value: 'SR' },
  { label: 'Life Ring', value: 'LR' },
  { label: 'Y12SR', value: 'Y12' },
  { label: 'Refuge Recovery', value: 'RR' },
  { label: 'Celebrate Recovery', value: 'CR' },
  { label: 'Other', value: 'OTHER' }
]
const days = [
  { label: 'Choose day of week', value: '' },
  { label: 'Monday', value: 1 },
  { label: 'Tuesday', value: 2 },
  { label: 'Wednesday', value: 3 },
  { label: 'Thursday', value: 4 },
  { label: 'Friday', value: 5 },
  { label: 'Saturday', value: 6 },
  { label: 'Sunday', value: 7 }
]

const states = [
  {
    'value': '',
    'label': ''
  },
  {
    'value': 'Alabama',
    'label': 'AL'
  },
  {
    'value': 'Alaska',
    'label': 'AK'
  },
  {
    'value': 'Arizona',
    'label': 'AZ'
  },
  {
    'value': 'Arkansas',
    'label': 'AR'
  },
  {
    'value': 'California',
    'label': 'CA'
  },
  {
    'value': 'Colorado',
    'label': 'CO'
  },
  {
    'value': 'Connecticut',
    'label': 'CT'
  },
  {
    'value': 'Delaware',
    'label': 'DE'
  },
  {
    'value': 'Florida',
    'label': 'FL'
  },
  {
    'value': 'Georgia',
    'label': 'GA'
  },
  {
    'value': 'Guam',
    'label': 'GU'
  },
  {
    'value': 'Hawaii',
    'label': 'HI'
  },
  {
    'value': 'Idaho',
    'label': 'ID'
  },
  {
    'value': 'Illinois',
    'label': 'IL'
  },
  {
    'value': 'Indiana',
    'label': 'IN'
  },
  {
    'value': 'Iowa',
    'label': 'IA'
  },
  {
    'value': 'Kansas',
    'label': 'KS'
  },
  {
    'value': 'Kentucky',
    'label': 'KY'
  },
  {
    'value': 'Louisiana',
    'label': 'LA'
  },
  {
    'value': 'Maine',
    'label': 'ME'
  },
  {
    'value': 'Maryland',
    'label': 'MD'
  },
  {
    'value': 'Massachusetts',
    'label': 'MA'
  },
  {
    'value': 'Michigan',
    'label': 'MI'
  },
  {
    'value': 'Minnesota',
    'label': 'MN'
  },
  {
    'value': 'Mississippi',
    'label': 'MS'
  },
  {
    'value': 'Missouri',
    'label': 'MO'
  },
  {
    'value': 'Montana',
    'label': 'MT'
  },
  {
    'value': 'Nebraska',
    'label': 'NE'
  },
  {
    'value': 'Nevada',
    'label': 'NV'
  },
  {
    'value': 'New Hampshire',
    'label': 'NH'
  },
  {
    'value': 'New Jersey',
    'label': 'NJ'
  },
  {
    'value': 'New Mexico',
    'label': 'NM'
  },
  {
    'value': 'New York',
    'label': 'NY'
  },
  {
    'value': 'North Carolina',
    'label': 'NC'
  },
  {
    'value': 'North Dakota',
    'label': 'ND'
  },
  {
    'value': 'Ohio',
    'label': 'OH'
  },
  {
    'value': 'Oklahoma',
    'label': 'OK'
  },
  {
    'value': 'Oregon',
    'label': 'OR'
  },
  {
    'value': 'Pennsylvania',
    'label': 'PA'
  },
  {
    'value': 'Rhode Island',
    'label': 'RI'
  },
  {
    'value': 'South Carolina',
    'label': 'SC'
  },
  {
    'value': 'South Dakota',
    'label': 'SD'
  },
  {
    'value': 'Tennessee',
    'label': 'TN'
  },
  {
    'value': 'Texas',
    'label': 'TX'
  },
  {
    'value': 'Utah',
    'label': 'UT'
  },
  {
    'value': 'Vermont',
    'label': 'VT'
  },
  {
    'value': 'Virgin Islands',
    'label': 'VI'
  },
  {
    'value': 'Virginia',
    'label': 'VA'
  },
  {
    'value': 'Washington',
    'label': 'WA'
  },
  {
    'value': 'West Virginia',
    'label': 'WV'
  },
  {
    'value': 'Wisconsin',
    'label': 'WI'
  },
  {
    'value': 'Wyoming',
    'label': 'WY'
  }
]

class MeetingsComponent extends React.Component {
  state = {
    searchTerm: '',
    postalCode: '',
    city: '',
    state: '',
    meetingType: '',
    dayOfWeek: '',
    meetings: [],
    mode: 'EDIT'
  }

  onChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  search = async () => {
    if (!this.state.searchTerm && !this.state.postalCode && !this.state.meetingType && !this.state.dayOfWeek && !this.state.city && !this.state.state) {
      return
    }
    const { data: { meetingsForAdmin: meetings } } = await this.props.client.query({
      query: queries.adminMeetingSearch,
      context: utils.generateAuthHeader(this.props.authUser.token),
      variables: {
        input: {
          term: this.state.searchTerm,
          postalCode: this.state.postalCode,
          city: this.state.city,
          state: this.state.state,
          dayOfWeek: this.state.dayOfWeek,
          meetingType: this.state.meetingType
        }
      }
    })
    this.setState({
      mode: 'EDIT',
      meetings
    })
  }

  review = () => {
    this.setState({
      mode: 'REVIEW',
      meetings: this.props.pending
    })
  }

  render () {
    return (
      <Row className={bem.b()}>
        <Col>
          <Row>
            <Col>
              <Card className='border-0 tbv-box-shadow'>
                <div className='admin-meeting-search-form'>
                  <Row>
                    <Col sm={4} className='admin-meeting-search-form__form'>
                      <div className='admin-meeting-search-form__bg'>
                        <section>
                          <h4>Find a meeting</h4>
                          <InputGroup style={{marginTop: 10}}>
                            <Input placeholder='Meeting name' name='searchTerm' onChange={this.onChange} value={this.state.searchTerm} />
                          </InputGroup>
                          <InputGroup style={{marginTop: 10}}>
                            <Input placeholder='City' name='city' onChange={this.onChange} value={this.state.city} />
                          </InputGroup>
                          <InputGroup style={{marginTop: 10}}>
                            <Input type='select' placeholder='State' name='state' onChange={this.onChange}>
                              {states.map((mt, idx) => <option key={idx} value={mt.value}>{mt.label}</option>)}
                            </Input>
                          </InputGroup>
                          <InputGroup style={{marginTop: 10}}>
                            <Input placeholder='Postal code' name='postalCode' onChange={this.onChange} value={this.state.postalCode} />
                          </InputGroup>
                          <InputGroup style={{marginTop: 10}}>
                            <Input type='select' placeholder='Meeting type' name='meetingType' onChange={this.onChange}>
                              {meetingTypes.map((mt, idx) => <option key={idx} value={mt.value}>{mt.label}</option>)}
                            </Input>
                          </InputGroup>
                          <InputGroup style={{marginTop: 10}}>
                            <Input type='select' placeholder='Day of week' name='dayOfWeek' onChange={this.onChange}>
                              {days.map((day, idx) => <option key={idx} value={day.value}>{day.label}</option>)}
                            </Input>
                          </InputGroup>
                          <TBVButton style={{marginTop: 15}} className='float-right' onClick={this.search} title='Search' />
                          <BSNavLink
                            tag={NavLink}
                            to={`/meetings/new`}
                            className='float-right'
                            style={{marginTop: 6}}
                            exact>
                            <TBVButton outline title='Create new' />
                          </BSNavLink>
                        </section>
                        <section>
                          <h4>Review submissions</h4>
                          <p>You have <strong>{this.props.pending.length}</strong> submissions to review</p>
                          <TBVButton className='float-right' disabled={!this.props.pending.length} onClick={this.review} title='Review now' />
                        </section>
                      </div>
                    </Col>
                    <Col sm={8}>
                      <Hidable show={this.state.meetings.length}>
                        <div className='justify-content-between w-100 p-4'>
                          <Table responsive striped borderless>
                            <thead>
                              <tr>
                                <th>Meeting Name</th>
                                <th>Location</th>
                                <th>Day</th>
                                <th>Start</th>
                                <th>&nbsp;</th>
                              </tr>
                            </thead>
                            <tbody>
                              {this.state.meetings.map((m, i) => {
                                const { address: { city, street, state, postalCode } } = m
                                return (
                                  <tr key={i}>
                                    <td>{m.name}</td>
                                    <td>{`${street} ${city}, ${state} ${postalCode}`}</td>
                                    <td>{weekDaysMapping[m.weekDay]}</td>
                                    <td>{m.startTime}</td>
                                    <td><BSNavLink
                                      tag={NavLink}
                                      to={this.state.mode === 'EDIT' ? `/meetings/${m.id}/edit` : `/meetings/${m.id}/review`}
                                      className='float-right'
                                      activeClassName='active'
                                      exact>
                                      <TBVButton title='View' />
                                    </BSNavLink></td>
                                  </tr>
                                )
                              })}
                            </tbody>
                          </Table>
                        </div>
                      </Hidable>
                      <Hidable show={!this.state.meetings.length}>
                        <div className='w-100 p-4 text-center'>
                          <p className='font-weight-bold empty-card-text'>No meetings {this.state.mode === 'REVIEW' && 'to review'}</p>
                        </div>
                      </Hidable>
                    </Col>
                  </Row>
                </div>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    )
  }
}

MeetingsComponent.propTypes = {
  authUser: PropTypes.object.isRequired,
  admin: PropTypes.object.isRequired
}

export const Meetings = compose(
  withRouter,
  withApollo,
  pure
)(MeetingsComponent)
