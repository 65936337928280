// Address section - for home and billing adddress

import React from 'react'

import PropTypes from 'prop-types'

import { ValidationFields, TBVFormField } from 'components/TBVForm'

import * as Yup from 'yup'

import { PHONE_LABEL, FIRST_NAME_LABEL, LAST_NAME_LABEL, EMAIL_LABEL } from 'constants/text.js'

export const PersonInfoSchema = Yup.object().shape({
  firstName: ValidationFields.FIRST_NAME,
  lastName: ValidationFields.LAST_NAME,
  phoneNumber: ValidationFields.PHONE,
  email: ValidationFields.EMAIL
})

export const PersonInfoPropsMap = {
  firstName: '',
  lastName: '',
  phoneNumber: '',
  email: ''
}

export const PersonInfoSection = (props) => {
  const {
    emailId,
    emailLabel,
    emailInputProps,

    firstNameId,
    firstNameLabel,
    firstNameInputProps,

    lastNameId,
    lastNameLabel,
    lastNameInputProps,

    phoneNumberId,
    phoneNumberLabel,
    phoneNumberInputProps

  } = props

  return (
    <React.Fragment>

      <div className='form-row'>
        <TBVFormField className='col-md-6 required' label={firstNameLabel} id={firstNameId} {...firstNameInputProps} />
        <TBVFormField className='col-md-6 required' label={lastNameLabel} id={lastNameId} {...lastNameInputProps} />
      </div>

      <TBVFormField className='required' label={phoneNumberLabel} id={phoneNumberId} {...phoneNumberInputProps} />
      <TBVFormField className='required' label={emailLabel} id={emailId} {...emailInputProps} />

    </React.Fragment>
  )
}

PersonInfoSection.propTypes = {
  firstNameId: PropTypes.string,
  firstNameLabel: PropTypes.string,
  firstNameInputProps: PropTypes.object,

  lastNameId: PropTypes.string,
  lastNameLabel: PropTypes.string,
  lastNameInputProps: PropTypes.object,

  phoneNumberId: PropTypes.string,
  phoneNumberLabel: PropTypes.string,
  phoneNumerInputProps: PropTypes.object,

  emailId: PropTypes.string,
  emailLabel: PropTypes.string,
  emailInputProps: PropTypes.object
}

PersonInfoSection.defaultProps = {
  firstNameId: 'firstName',
  firstNameLabel: FIRST_NAME_LABEL,
  firstNameInputProps: {
    type: 'string',
    placeholder: ''
  },

  lastNameId: 'lastName',
  lastNameLabel: LAST_NAME_LABEL,
  lastNameInputProps: {
    type: 'string',
    placeholder: ''
  },
  phoneNumberId: 'phoneNumber',
  phoneNumberLabel: PHONE_LABEL,
  phoneNumerInputProps: {
    type: 'string',
    placeholder: ''
  },

  emailId: 'email',
  emailLabel: EMAIL_LABEL,
  emailInputProps: {
    type: 'email',
    placeholder: ''
  }
}

// export default PersonInfoSection
