// SignUp.js - form for login, register, forgot password

import React from 'react'
import { withFormik } from 'formik'
import { Form, FormGroup } from 'reactstrap'
import Log from 'utils/logging.js'
import { graphql, compose } from 'react-apollo'
import PropTypes from 'prop-types'

import Roles, { getLogInPath } from 'utils/roles.js'
import { auth } from '../../../firebase'
import { mutations } from '../../../graphql'

import {
  AddressSchema,
  AddressPropsMap,
  AddressSection
} from './AddressSection.js'
import {
  PersonInfoSchema,
  PersonInfoPropsMap,
  PersonInfoSection
} from './PersonInfoSection.js'
import { TermsSchema, TermsPropsMap, TermsSection } from './TermsSection.js'
import {
  PasswordSchema,
  PasswordPropsMap,
  PasswordSection
} from './PasswordSection.js'

import { DEFAULT_ERROR_MSG, ADD_CLIENT_SIGNUP, LOG_IN } from 'constants/text'

import AuthButton from './AuthButton'
import AuthButtonTag from './AuthButtonTag'

const _buildValidationSchema = () => {
  return PersonInfoSchema.concat(AddressSchema)
    .concat(TermsSchema)
    .concat(PasswordSchema)
}

const formikEnhancer = withFormik({
  validationSchema: _buildValidationSchema(),
  mapPropsToValues: (props) => ({
    ...props.initialValues,
    ...PersonInfoPropsMap,
    ...AddressPropsMap,
    ...TermsPropsMap,
    ...PasswordPropsMap
  }),
  handleSubmit: async (
    values,
    { setSubmitting, setErrors, setStatus, props }
  ) => {
    let authUser, user
    const payload = {
      ...values
    }

    const createUserFunc = props.CreateUser
    const variables = {
      phone: payload.phoneNumber,
      firstName: payload.firstName,
      lastName: payload.lastName,
      address: {
        street: payload.address,
        city: payload.city,
        state: payload.state,
        postalCode: payload.zipCode
      },
      inviteToken: props.inviteToken
    }

    Log.info('createUser')
    Log.info(payload)
    try {
      // create firebase user
      authUser = await auth.doCreateUserWithEmailAndPassword(
        payload.email,
        payload.password
      )
      Log.info('FireBase user created')
      Log.info(authUser)
      variables.authProviderId = authUser.user.uid
      variables.email = authUser.user.email
      const input = { variables: variables }

      // create graphQL user
      Log.info('user graphQl create input')
      Log.info(input)
      try {
        user = await createUserFunc(input)
        Log.info('TBV client created')
        Log.info(user)
        // setStatus({success: true})
        // setSubmitting(false)
        props.onFormSubmit()
      } catch (error) {
        Log.info('failed graphQL client create')
        Log.error(error)
        setErrors({ submit: DEFAULT_ERROR_MSG })
        setSubmitting(false)
      }
    } catch (error) {
      Log.info('failed firebase user create')
      Log.error(error)
      setErrors({ submit: error.message })
      setSubmitting(false)
    }
  },
  displayName: 'SignUpForm'
})

const SignUpForm = (props) => {
  const {
    touched,
    errors,
    handleSubmit,
    isSubmitting,
    values,
    title,
    onLogInClick,
    roleState,
    initialValues,
    initialData,
    inviteToken,
    children
  } = props
  Log.info('invite token in singup', inviteToken)
  // update initialValues with initialData
  Object.assign(initialValues, initialData)

  const renderLogInLink = (roleState) => {
    return getLogInPath(roleState)
  }

  const isClient = roleState === Roles.CLIENT
  return (
    <Form className='tbv-login-form' onSubmit={handleSubmit}>
      <PersonInfoSection />
      <PasswordSection />
      <AddressSection values={values} />
      <FormGroup className='required'>
        <TermsSection values={values} />
      </FormGroup>
      <AuthButton
        title={isClient ? title : ADD_CLIENT_SIGNUP}
        disabled={isSubmitting}
        errors={errors}
        touched={touched}
        onClick={() => onLogInClick()}
        under={
          isClient ? (
            <AuthButtonTag
              text='Already have an account?'
              linkText={LOG_IN}
              href={renderLogInLink(roleState)}
            />
          ) : null
        }
      />
      {children}
    </Form>
  )
}

SignUpForm.propTypes = {
  roleState: PropTypes.oneOf([Roles.CLIENT, Roles.PROBATION_OFFICER])
    .isRequired,
  onLogInClick: PropTypes.func.isRequired,
  onFormSubmit: PropTypes.func.isRequired,
  initialData: PropTypes.object,
  inviteToken: PropTypes.string
}

SignUpForm.defaultProps = {
  initialData: {},
  inviteToken: null
}
const SignUpFormGraphQL = compose(
  graphql(mutations.CREATE_CLIENT, { name: 'CreateUser' })
)(formikEnhancer(SignUpForm))

export default SignUpFormGraphQL
