// ManagerNotesFeed.js - manager notes on client

import React from 'react'
import moment from 'moment'

import FeedItem from './FeedItem.js'

export const ManagerNotesFeed = ({ notes }) => {
  const noteItems = notes.managerNotes.map((note, i) =>
    <FeedItem key={i} text={note.note} time={moment(note.createdAt).calendar()} />
  )

  return notes.managerNotes.length ? (
    <div>
      {noteItems}
    </div>
  ) : (
    <div>
      <FeedItem text='No notes for this client yet, add your first note' />
    </div>
  )
}

export default ManagerNotesFeed
