// AttendanceNoteModal.js - attendance note modal

import React from 'react'

import PropTypes from 'prop-types'

import { NavLink,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter } from 'reactstrap'

import { localDisplayTime } from 'utils/dates.js'

import { VIEW_NOTES } from 'constants/text.js'

class AttendanceNoteModal extends React.Component {
  state = {
    showModal: false
  }

  toggle = () => {
    return this.setState({
      showModal: !this.state.showModal
    })
  }

  render () {
    const { notes, meeting, meetingStartTime } = this.props
    return (
      <div>
        <NavLink style={{'cursor': 'pointer', 'textDecoration': 'underline'}} className='p-0' onClick={() => this.setState({showModal: true})}>{VIEW_NOTES}</NavLink>
        <Modal
          isOpen={this.state.showModal}
          toggle={this.toggle}
          className={this.props.className}>
          <ModalHeader className='text-white' toggle={this.toggle}>
            <b>{meeting.name}</b><br />
            <small>{localDisplayTime(meetingStartTime, 'dddd | MM/DD/YYYY | hh:mma')}</small>
          </ModalHeader>
          <ModalBody>
            {notes}
          </ModalBody>
          <ModalFooter>
            &nbsp;
          </ModalFooter>
        </Modal>
      </div>
    )
  }
}

AttendanceNoteModal.propTypes = {
  className: PropTypes.string
}

AttendanceNoteModal.defaultProps = {
  className: 'tbv-attendance-note'
}

export default AttendanceNoteModal
