import React from 'react'
import Log from 'utils/logging.js'

import { graphql, compose } from 'react-apollo'

import PropTypes from 'prop-types'
import { mutations, utils } from '../../../graphql'
import { PersonInfoSchema, PersonInfoPropsMap, PersonInfoSection } from './PersonInfoSection.js'
import { DEFAULT_ERROR_MSG } from 'constants/text'
import AuthButton from './AuthButton'
import { withFormik } from 'formik'
import { Form } from 'reactstrap'
import { withManager } from 'components/Session'
import {ManagerRoleDropdown, ManagerRoleSchema, ManagerRoleMap} from './ManagerRoleDropdown'
import { managerCan } from '../../Session/permission'
import { MANAGER_ACL } from '../../../constants/acl'
import { Redirect } from 'react-router-dom'
import { MANAGER_HOME } from 'constants/routes.js'

const _buildValidationSchema = () => {
  return PersonInfoSchema.concat(ManagerRoleSchema)
}

const formikEnhancer = withFormik({
  validationSchema: _buildValidationSchema(),
  mapPropsToValues: props => ({
    ...PersonInfoPropsMap,
    ...ManagerRoleMap
  }),
  handleSubmit: async (values, {setSubmitting, setErrors, setStatus, resetForm, props}) => {
    let invite
    const payload = {
      ...values
    }

    const { CreateManagerInvite, authUser } = props
    const variables = {
      phone: payload.phoneNumber,
      firstName: payload.firstName,
      lastName: payload.lastName,
      email: payload.email,
      roleName: payload.roleName,
      organizationId: props.manager.organization.id
    }
    Log.info('addManager graphql')
    Log.info(payload)

    // create invite to signup
    try {
      invite = await CreateManagerInvite({variables: variables, context: utils.generateAuthHeader(authUser.token)})
      Log.info('TBV invite created')
      Log.info(invite)
      resetForm()
      setStatus({success: true})
      setSubmitting(false)
    } catch (error) {
      Log.info('failed graphQL client create')
      Log.error(error)
      setErrors({submit: DEFAULT_ERROR_MSG})
      setSubmitting(false)
    }
  },
  displayName: 'ManagerInviteManagerForm'
})

const ManagerInviteManagerForm = props => {
  const {
    touched,
    errors,
    status,
    handleSubmit,
    isSubmitting,
    onLogInClick,
    children
  } = props
  if (!managerCan(MANAGER_ACL.orgSupervisor, props.manager.role)) {
    return (<Redirect to={MANAGER_HOME} />)
  }

  return (
    <Form className='tbv-login-form' onSubmit={handleSubmit}>
      <PersonInfoSection />
      <ManagerRoleDropdown roleName={props.manager.role} />
      <AuthButton
        title={'Send Invite'}
        disabled={isSubmitting}
        errors={errors}
        status={status}
        successMsg={'Invite sent'}
        touched={touched}
        onClick={() => (onLogInClick())}
      />
      {children}
    </Form>
  )
}

ManagerInviteManagerForm.propTypes = {
  onLogInClick: PropTypes.func,
  onFormSubmit: PropTypes.func
}

const ManagerInviteManagerFormGraphQL = compose(
  graphql(mutations.CREATE_MANAGER_INVITE, {name: 'CreateManagerInvite'})
)(formikEnhancer(ManagerInviteManagerForm))

export default withManager(ManagerInviteManagerFormGraphQL)
