// LogInForm.js - form for login

import React from 'react'
import Log from 'utils/logging.js'

import PropTypes from 'prop-types'
import Roles, { getSignUpPath } from 'utils/roles'
import { FORGOT_PASSWORD } from 'constants/routes'
import { auth } from '../firebase'

import * as textConstants from 'constants/text.js'

import { ValidationFields, TBVBaseForm } from 'components/TBVForm'

import { withFormik } from 'formik'
import * as Yup from 'yup'

import AuthButton from './SignUp/Form/AuthButton'
import AuthButtonTag from './SignUp/Form/AuthButtonTag'

const ForgotPasswordLink = ({forgotPasswordClick}) => {
  return (
    <a href={FORGOT_PASSWORD}
      onClick={forgotPasswordClick}>
      {textConstants.FORGOT_PASSWORD_LINK}
    </a>
  )
}

const formikEnhancer = withFormik({
  validationSchema: Yup.object().shape({
    email: ValidationFields.EMAIL,
    password: ValidationFields.LOGIN_PASSWORD
  }),
  mapPropsToValues: props => ({
    email: '',
    password: ''
  }),
  handleSubmit: (values, {setSubmitting, setErrors, setStatus, props}) => {
    const payload = {
      ...values
    }
    auth.doSignInWithEmailAndPassword(payload.email, payload.password)
      .then(() => {
        Log.info('logged in')
        setStatus({success: true})
        setSubmitting(false)
        props.onLoggedIn()
      })
      .catch(error => {
        Log.info('failed logged in')
        Log.info(error)
        setErrors({submit: error.message})
        setSubmitting(false)
      })
  },
  displayName: 'LogInForm'
})

const LogInForm = props => {
  const {
    errors,
    isSubmitting,
    handleSubmit,
    onSignUpClick,
    onForgotPasswordClick,
    emailLabel,
    emailInputProps,
    passwordLabel,
    passwordInputProps,
    children
  } = props

  const forgotPasswordLink = () => (
    <span className='float-right'>
      <ForgotPasswordLink forgotPasswordClick={(e) => (onForgotPasswordClick())} />
    </span>
  )

  const renderSignUpLink = () => {
    return getSignUpPath(props.roleState)
  }

  const fields = [
    {id: 'email', label: emailLabel, ...emailInputProps},
    {id: 'password', label: passwordLabel, children: forgotPasswordLink(), ...passwordInputProps}
  ]

  return (
    <TBVBaseForm className='tbv-login-form p-2' fields={fields} onSubmit={handleSubmit}>

      <AuthButton
        title={textConstants.LOG_IN}
        disabled={isSubmitting}
        errors={errors}
        onClick={(e) => onSignUpClick()}
        subTitle={<AuthButtonTag text={textConstants.DONT_HAVE_ACCOUNT} linkText={textConstants.SIGN_UP} href={renderSignUpLink()} />}
      />

      {children}
    </TBVBaseForm>
  )
}

LogInForm.propTypes = {
  roleState: PropTypes.oneOf([Roles.PROBATION_OFFICER, Roles.CLIENT, Roles.CASE_MANAGER]).isRequired,
  emailLabel: PropTypes.string,
  emailInputProps: PropTypes.object,
  passwordLabel: PropTypes.string,
  passwordInputProps: PropTypes.object,
  onLoggedIn: PropTypes.func.isRequired,
  onSignUpClick: PropTypes.func.isRequired,
  onForgotPasswordClick: PropTypes.func.isRequired
}

LogInForm.defaultProps = {
  emailLabel: 'Email address',
  emailInputProps: {
    type: 'email',
    placeholder: ''
  },
  passwordLabel: 'Password',
  passwordInputProps: {
    type: 'password',
    placeholder: ''
  }
}

export default formikEnhancer(LogInForm)
