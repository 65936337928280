// MonthlyAttendanceGraph.js - shows meetings attended by month
// MonthlyAttendancGraphj.js
import React from 'react'

import PropTypes from 'prop-types'

import moment from 'moment'

import { Bar } from 'react-chartjs-2'

import { barOptions } from 'configs/charts'

import { getColor } from 'utils/colors'

import { monthNumToName, MONTH_NUMS } from 'utils/graph.js'

import * as meetingUtils from 'utils/meetings.js'

import { Query } from 'react-apollo'

import { queries } from '../../graphql'

import LoadingPlaceHolder from '../LoadingPlaceHolder.js'
import Legend from '../Charts/Legend.js'

import { yearItems, YearsDropDown } from './YearsDropDown.js'

import { MONTHLY_GRAPH_TITLE, DEFAULT_ERROR_MSG } from 'constants/text.js'

const generateLabels = (monthlyAtttendance) => {
  // [January-2017,]
  return monthlyAtttendance.map(item => (monthNumToName(item.meetingMonth)))
}

const generateDataPoints = (monthlyAtttendance) => {
  return monthlyAtttendance.map(item => (item.meetingCount))
}

const generateBarColors = (monthlyAtttendance, reqMeetings) => {
  return monthlyAtttendance.map(item => {
    let monthEndDate = moment([item.meetingYear, item.meetingMonth - 1]).endOf('month')
    let pace = meetingUtils.getMeetingPace(item.meetingCount, reqMeetings, monthEndDate)
    switch (pace) {
      case meetingUtils.REQ_MEETINGS_BEHIND:
        return getColor('behind')
      case meetingUtils.REQ_MEETINGS_EXCEEDING:
        return getColor('exceeding')
      default:
        return getColor('on-target')
    }
  })
}

const _genGraphItem = (mon, year) => ({ 'meetingYear': year,
  'meetingMonth': mon,
  'meetingCount': 0,
  '__typename': 'MonthlyAttendanceCount'})

const fillMonthlyGaps = (year, maxMonth, monthlyAtttendance) => {
  // {"meetingYear":2018,"meetingMonth":7,"meetingCount":3,"__typename":"MonthlyAttendanceCount"}
  let cloneData = monthlyAtttendance.slice()
  const allMons = MONTH_NUMS
  const currMons = monthlyAtttendance.map(item => item.meetingMonth)

  // fill the gaps
  allMons.forEach(mon => {
    if (!currMons.includes(mon) && mon <= maxMonth) {
      cloneData.push(_genGraphItem(mon, year))
    }
  })

  // sort data by year and wk
  cloneData.sort((m1, m2) => {
    if (m1.meetingMonth < m2.meetingMonth) {
      return -1
    } else if (m1.meetingMonth > m2.meetingMonth) {
      return 1
    } else {
      return 0
    }
  })

  return cloneData
}

const genLineData = (monthlyAtttendance, reqMeetings) => {
  const barColors = generateBarColors(monthlyAtttendance, reqMeetings)

  return {
    labels: generateLabels(monthlyAtttendance),
    datasets: [
      {
        label: 'Meeting Count',
        backgroundColor: barColors,
        borderColor: barColors,
        borderWidth: 1,
        data: generateDataPoints(monthlyAtttendance)
      }
    ]
  }
}

export const genBarOptions = (monthlyAtttendance) => {
  let cloneOptions = {...barOptions}
  const counts = monthlyAtttendance.map(item => item.meetingCount)
  const maxCount = Math.max(...counts)

  if (maxCount && maxCount > 10) {
    cloneOptions.scales.yAxes[0].ticks.stepSize = parseInt(maxCount / 5, 10)
    return cloneOptions
  } else {
    return cloneOptions
  }
}

class MonthlyAttendanceGraph extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      dropdownOpen: false,
      year: yearItems[0]
    }
  }

  toggle = () => this.setState(prevState => ({dropdownOpen: !prevState.dropdownOpen}))

  render () {
    const { authUser, reqMeetings, clientId } = this.props
    const { dropdownOpen, year } = this.state
    const monthlyGraphVars = {clientId: clientId, startDate: year.start, endDate: year.end}

    return (
      <Query query={queries.clientMonthlyCountQuery} variables={monthlyGraphVars} context={authUser.authHeader}>
        {({ loading, error, data }) => {
          if (error) return <div>{DEFAULT_ERROR_MSG}</div>
          if (loading) return <LoadingPlaceHolder />

          // fill the gaps in client monthyl date
          const monthlyResults = fillMonthlyGaps(moment().year(), moment().month() + 1, data.clientMonthlyAttendanceCount)

          return (
            <div>
              <div className='d-flex justify-content-between w-100 p-4 tbv-graph'>
                <h4 className='font-weight-bold'>{MONTHLY_GRAPH_TITLE + ' ' + year.title}</h4>
                <YearsDropDown title={year.title} dropdownOpen={dropdownOpen} toggle={this.toggle} onItemClick={item => this.setState({year: item})} />
              </div>
              <div className='ml-4 mr-4'>
                <Bar data={genLineData(monthlyResults, reqMeetings)} options={genBarOptions(monthlyResults)} />
              </div>
              <div className='d-flex justify-content-end pt-3 pr-4'>
                <Legend className='pl-3' />
              </div>
            </div>
          )
        }}
      </Query>
    )
  }
}

MonthlyAttendanceGraph.propTypes = {
  reqMeetings: PropTypes.number.isRequired
}

export default MonthlyAttendanceGraph
