import { gql } from 'apollo-boost'

import { UserDetailFragment,
  ManagerDetailFragment,
  ClientMonthlyCountFragment,
  ClientWeeklyCountFragment,
  AttendanceFragment,
  ManagerNoteFragment,
  AdminDetailFragment,
  AvailableManagerFragment } from './fragments.js'

export const meUserQuery = gql`
  query MeUser {
      me {
        ...UserDetail
      }
  }
  ${UserDetailFragment}
`

export const meManagerQuery = gql`
  query MeManager($type: String) {
      meManager(input:{type: $type}) {
        ...ManagerDetail
      }
  }
  ${ManagerDetailFragment}
`

export const clientInvitesQuery = gql`
  query MeManager($type: String) {
      meManager(input:{type: $type}) {
        clientInvites {
          id
          firstName
          lastName
          phone
          email
          token
          createdAt
          updatedAt
        }
      }
  }
`

export const getMeetingsQuery = gql`
  query {
    meetings(input: {}) {
      id
      name
      program
      durationMinutes
    }
  }
`
export const getOrganizationsQuery = gql`
  query {
    organizations {
      id
      name
      logoUrl
    }
  }
`

export const attendanceNeedsReview = gql`
  query { 
    attendanceNeedsReview {
      id
      checkIn
      checkOut
      status
      notes
      rating
      meetingStartTime
      meetingDurationMins
      meeting {
        id
        name
        address {
          street
          city
          state
          postalCode
        }
        weekDay,
        location {
          lat
          lng
        }
        startTime
        endTime
        type
      }
      client {
        id
        email
        firstName
        lastName
        phone
        photoUrl
        address {
          city
          state
          street
          postalCode
        }
      }
    }
  }
`

export const adminGetAttendance = gql`
  query get($input: AttendanceGetInput! ) { 
    adminGetAttendance(input: $input) {
      id
      checkIn
      checkOut
      status
      notes
      rating
      meetingStartTime
      meetingDurationMins
      checkInVerification {
        lat
        lng
      }
      meeting {
        id
        name
        address {
          street
          city
          state
          postalCode
        }
        weekDay,
        location {
          lat
          lng
        }
        startTime
        endTime
        type
      }
      client {
        id
        email
        firstName
        lastName
        phone
        photoUrl
        address {
          city
          state
          street
          postalCode
        }
      }
    }
  }
`

export const getOrganizationQuery = gql`
  query find($input: OrganizationIdInput!) {
    organization(input: $input) {
      id
      logoUrl
      managers {
        id
        firstName
        lastName
        photoUrl
        email
        role
        clients {
          id
          firstName
          lastName
          email
          managerId
        }
      }
    }
  }
`

export const getFireBaseUserQuery = gql`
    query getFireBaseUser {
        firUser @client {
            uid email
        }
    }
`

export const userQuery = gql`
  query UserQuery {
    user @client {
      id
      role
      email
    }
  }
`
export const getClientQuery = gql`
  query Client($id: String!) {
      user(input:{id: $id}) {
        ...UserDetail
      }
}
  ${UserDetailFragment}
`

export const clientMonthlyCountQuery = gql`
  query ClientMonthlyCount($clientId: String!, $startDate: ISODateTime!, $endDate: ISODateTime!) {
      clientMonthlyAttendanceCount(input:{clientId: $clientId, startDate: $startDate endDate: $endDate}) {
        ...MonthlyCountDetail
      }
  }
  ${ClientMonthlyCountFragment}
`

export const clientWeeklyCountQuery = gql`
  query ClientWeeklyCount($clientId: String!, $startDate: ISODateTime!, $endDate: ISODateTime!) {
      clientWeeklyAttendanceCount(input:{clientId: $clientId, startDate: $startDate endDate: $endDate}) {
        ...WeeklyCountDetail
      }
  }
  ${ClientWeeklyCountFragment}
`

export const clientsActivityQuery = gql`
  query ClientsActivity($clientId: String!, $startDate: ISODateTime!, $endDate: ISODateTime!, $validationStatuses: [AttendanceValidationStatus]) {
      clientsAttendanceByDate(input:{clientId: $clientId, startDate: $startDate endDate: $endDate, validationStatuses: $validationStatuses}) {
        ...AttendanceDetail
      }
  }
  ${AttendanceFragment}
`

export const managerNotesQuery = gql`
  query ManagerNotes($clientId: String!) {
      managerNotes(input:{clientId: $clientId, limit: 10, offset: 0}) {
        ...ManagerNoteDetail
      }
  }
  ${ManagerNoteFragment}
`

export const getClientInviteQuery = gql`
  query getInvite($token: String!) {
      getInvite(input:{token: $token}) {
       id token firstName lastName phone email probationId reqMeetings reqMeetingsFreq durationDays createdAt
      }
  }
`

export const getManagerInviteQuery = gql`
  query getManagerInvite($token: String!) {
      getManagerInvite(input:{token: $token}) {
        id token firstName lastName phone email roleName organizationId createdAt
      }
  }
`

export const authedUserQuery = gql`
  query AuthedUser {
      authedUser {
        client {...UserDetail}
        manager {
          ...ManagerDetail
        }
        admin {...AdminDetail}
      }
  }
  ${UserDetailFragment}
  ${ManagerDetailFragment}
  ${AdminDetailFragment}
`

export const selfActivityQuery = gql`
  query ClientActivity($startDate: ISODateTime!, $endDate: ISODateTime!, $validationStatuses: [AttendanceValidationStatus]) {
      attendanceByDate(input:{startDate: $startDate endDate: $endDate, validationStatuses: $validationStatuses}) {
        ...AttendanceDetail
      }
  }
  ${AttendanceFragment}
`

export const availableManagersQuery = gql`
  query AvailableManagers($limit: Int, $offset: Int) {
      managers(input:{limit: $limit, offset: $offset}) {
        ...AvailableManagerDetail
      }
  }
  ${AvailableManagerFragment}
`

export const getMeeting = gql`
  query meeting($input: MeetingIdInput) {
    meeting(input: $input) {
      id
      name
      address {
        street
        city
        state
        postalCode
      }
      weekDay,
      location {
        lat
        lng
      }
      createdBy {
        id
        client {
          id
          firstName
          lastName
          photoUrl
          phone
          email
        }
      }
      startTime
      endTime
      type
      allowsVirtual
      virtualUrl
      isDemoMeeting
      temporarilySuspended
      meetingPassword
    }
  }
`
export const adminMeetingSearch = gql`
query meetingsForAdmin($input: MeetingsForAdminWhereInput! ) {
  meetingsForAdmin(input: $input) {
     id
      name
      address {
        street
        city
        state
        postalCode
      }
      weekDay,
      nextMeeting,
      location {
        lat
        lng
      }
      startTime
      endTime
      type
      allowsVirtual
      virtualUrl
      isDemoMeeting
      temporarilySuspended
      meetingPassword
  }
}
`

export const pendingMeetings = gql`
query pendingMeetings {
  pendingMeetings {
     id
      name
      address {
        street
        city
        state
        postalCode
      }
      weekDay,
      nextMeeting,
      location {
        lat
        lng
      }
      startTime
      endTime
      type
      allowsVirtual
      virtualUrl
      isDemoMeeting
      temporarilySuspended
      meetingPassword
  }
}
`
export const getManagerChatToken = gql`
query getManagerChatToken {
  getManagerChatToken {
    token
  }
}
`
export const managerChat = gql`
  query getManagerChat($input: ManagerChatWhereInput!){
    getManagerChat(input: $input) {
      id
      clientId
      managerId
      conversationSid
      clientParticipantSid
      managerIdentity
      token
    }
  }
`
