// DurationUnitDropDown.js  - for client signup on supervision duration
import React from 'react'

import { TBVDropDown } from '../../TBVForm'

import { Field } from 'formik'

import { DURATION_ITEMS } from 'constants/forms.js'

const DurationUnitDropDown = props => (
  <Field
    component={TBVDropDown}
    {...props}
  />
)

DurationUnitDropDown.defaultProps = {
  props: {},
  title: 'e.g Months',
  items: DURATION_ITEMS
}

export default DurationUnitDropDown
