// AddClientPage.js - sign up form

import React from 'react'

import { Card, Col, Row } from 'reactstrap'

import { getRoleTitle, getLogInPath } from 'utils/roles'
import { DASHBOARD } from 'constants/routes'

import Page from 'components/Page'
import { AddClientForm } from 'components/SignUp'
import Instructions from 'components/SignUp/Instructions'
import * as textConstants from 'constants/text.js'
import DownloadLink from 'components/DownloadLink'

class AddClientPage extends React.Component {
  renderRoleTitle () {
    return getRoleTitle(this.props.roleState)
  }

  handleLogoClick = () => {
    this.props.history.push('/')
  }

  handleLogInClick = () => {
    this.props.history.push(getLogInPath(this.props.roleState))
  }

  handleFormSubmit = () => {
    this.props.history.push(DASHBOARD)
  }

  render () {
    return (
      <Page className='AddClientPage pt-5'>
        <Row>
          <Col sm={4}>
            <Instructions
              header={textConstants.ADD_CLIENT}
              subHeader={''}
            >
              <li>{textConstants.INSTRUCTIONS_FILL_OUT_FORM}</li>
              <li>{textConstants.INSTRUCTIONS_ADD_CLIENT_DETAIL}</li>
              <li>{textConstants.INSTRUCTIONS_ADD_CLIENT_LAST_STEP} <DownloadLink title={textConstants.COMPANY_NAME + ' mobile app'}>{textConstants.DOWNLOAD_APP.toLowerCase()}</DownloadLink></li>
            </Instructions>
          </Col>
          <Col>
            <Row className='tbv-add-client-right'>
              <Col>
                <Card size='md' body>
                  <AddClientForm
                    roleState={this.props.roleState}
                    onLogInClick={this.handleLogInClick}
                    onFormSubmit={this.handleFormSubmit}
                  />
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </Page>
    )
  }
}

export default AddClientPage
