import React from 'react'
import PropTypes from 'utils/propTypes'

import { Media } from 'reactstrap'
import * as notificationUtils from 'utils/notifications.js'

import Avatar from 'components/Avatar'

import moment from 'moment'

import {
  MdSettings,
  MdClose
} from 'react-icons/lib/md'

const Notifications = ({ clients, onClick }) => {
  const notifications = notificationUtils.createClientsNotificationList(clients)

  notifications.sort((n1, n2) => {
    const n1Date = n1.checkOut || n1.checkIn
    const n2Date = n2.checkOut || n2.checkIn
    if (moment(n1Date).isAfter(n2Date)) {
      return -1
    } else if (moment(n1Date).isBefore(n2Date)) {
      return 1
    } else {
      return 0
    }
  })
  const hasNotifications = notifications && notifications.length

  return (
    <div className='tbv-notifications-wrapper d-flex flex-column'>
      <div className='d-flex justify-content-between align-middle'>
        <div className='d-flex flex-column justify-content-center m-2 p-1'>
          <MdSettings className='d-none' size={25} />
        </div>
        <div className='d-flex flex-column justify-content-center'>
          <p className='m-2 p-1 font-weight-bold'>Client Activity</p>
        </div>
        <div onClick={onClick} className='d-flex flex-column justify-content-center m-2 p-1 link'>
          <MdClose size={18} />
        </div>
      </div>
      {hasNotifications ? renderNotifications(notifications) : renderNoActivity()}
    </div>
  )
}

const renderNotifications = notificationsData => notificationsData.map(({ id, avatar, message, date, name }) => (
  <div key={id} className='tbv-notifications d-flex border-top w-100 position-relative align-middle'>
    <Media left className='pr-1 ml-2 d-flex flex-column justify-content-center mb-3 mt-3'>
      <Avatar size={25} tag={Media} object src={avatar} alt='Avatar' />
    </Media>
    <div className='d-flex flex-column justify-content-center'>
      <p className='mb-0 mr-3 pr-2'><strong>{name}</strong>&nbsp;{message}</p>
    </div>
    <small className='text-bluishGrey position-absolute bottom-right m-1'>{date}</small>
  </div>
))

const renderNoActivity = () => (
  <div className='flex-column d-flex justify-content-center text-center m-4'>No recent activity.</div>
)

Notifications.propTypes = {
  notificationsData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.ID,
      avatar: PropTypes.string,
      message: PropTypes.node,
      date: PropTypes.date
    })
  )
}

Notifications.defaultProps = {
  notificationsData: []
}

export default Notifications
