// withAdmin.js - HOC for loading app admin

import React from 'react'

import withAuthorization from './withAuthorization'
import LoadingPlaceHolder from 'components/LoadingPlaceHolder'

import { Query } from 'react-apollo'
import { queries } from '../../graphql'
import { Redirect } from 'react-router-dom'
import { MANAGER_HOME, CLIENT_HOME } from 'constants/routes'

const withAdmin = (Component) => {
  class WithAdmin extends React.Component {
    render () {
      const { authUser, children, ...props } = this.props
      return (
        <Query query={queries.authedUserQuery} context={authUser.authHeader}>
          {({ loading, error, data }) => {
            if (error) return (<Redirect to='/' />)
            if (loading) return (<LoadingPlaceHolder />)

            // grab admin, manager or client
            const { client, manager, admin } = data.authedUser

            // ensure admin authed
            if (admin) {
              return (<Component admin={admin} authUser={authUser} {...props}>{children}</Component>)
            } else if (manager) {
              return (<Redirect to={MANAGER_HOME} />)
            } else if (client) {
              return (<Redirect to={CLIENT_HOME} />)
            } else {
              return (<Redirect to='/' />)
            }
          }}
        </Query>
      )
    }
  }

  const authCondition = (authUser) => !!authUser
  return withAuthorization(authCondition)(WithAdmin)
}

export default withAdmin
