// AuthButtons - signup button with errors and link to login

import React, { Fragment } from 'react'

import PropTypes from 'prop-types'

import * as textConstants from 'constants/text.js'

import { TBVError } from 'components/TBVForm'
import TBVButton from 'components/TBVButton.js'

const AuthButton = ({ errors, touched, disabled, subTitle, title, status, successMsg }) => {
  return (
    <Fragment>
      <div className='text-center pt-3'>
        <TBVButton block title={title} disabled={disabled} />
        {errors.submit && <p className='text-danger pt-2'>{errors.submit}</p>}
        {errors.terms && touched.terms && (
          <TBVError message={errors.terms} className='text-danger mt-2' />
        )}
        {status && status.success && successMsg && (<p className='text-success pt-2'>{successMsg}</p>)}
      </div>
      {subTitle}
    </Fragment>
  )
}

AuthButton.propTypes = {
  disabled: PropTypes.bool,
  errors: PropTypes.object.isRequired,
  touched: PropTypes.any,
  title: PropTypes.string,
  subTitle: PropTypes.element,
  status: PropTypes.object,
  successMsg: PropTypes.string
}

AuthButton.defaultProps = {
  disabled: false,
  touched: false,
  subTitle: null,
  title: textConstants.SIGN_UP
}

export default AuthButton
