// withManager.js - HOC for loading app manager

import React from 'react'

import withAuthorization from './withAuthorization'
import LoadingPlaceHolder from 'components/LoadingPlaceHolder'
import { Redirect } from 'react-router-dom'
import { Query } from 'react-apollo'

import { queries } from '../../graphql'
import { CLIENT_HOME, ADMIN_HOME } from 'constants/routes.js'

const withManager = (Component, allowedRoles) => {
  class WithManager extends React.Component {
    render () {
      const { authUser, children, ...props } = this.props
      return (
        <Query query={queries.authedUserQuery} context={authUser.authHeader}>
          {({ loading, error, data }) => {
            if (error) return (<Redirect to='/' />)
            if (loading) return (<LoadingPlaceHolder />)

            // grab manager or client
            const { client, manager, admin } = data.authedUser

            // ensure manager authed
            if (manager) {
              return (<Component manager={manager} authUser={authUser} {...props}>{children}</Component>)
            } else if (client) {
              return (<Redirect to={CLIENT_HOME} />)
            } else if (admin) {
              return (<Redirect to={ADMIN_HOME} />)
            } else {
              return (<Redirect to='/' />)
            }
          }}
        </Query>
      )
    }
  }

  const authCondition = (authUser) => !!authUser
  return withAuthorization(authCondition)(WithManager)
}

export default withManager
