import React from 'react'
import Log from 'utils/logging.js'
import { Col, Row, Card } from 'reactstrap'
import Page from '../components/Page'
import { withManager } from '../components/Session'
import CreateOrganizationMeetingForm from '../components/Dashboard/CreateOrganizationMeetingForm'
import bn from 'utils/bemnames'
const bem = bn.create('organization-meeting')

class CreateOrganizationMeetingPage extends React.Component {
  componentWillMount () {
    if (!this.props.manager.organization) {
      Log.info('Not allowed')
      this.props.history.push('/')
    }
  }

  render () {
    Log.info(this.props)
    const { manager, authUser } = this.props
    Log.info(manager)
    return (
      <Page className='DashboardPage'>
        <Row>
          <Col>
            <Row className={bem.b()}>
              <Col md={{size: 10, offset: 1}}>
                <Card>
                  <CreateOrganizationMeetingForm authUser={authUser} manager={manager} />
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </Page>
    )
  }
}

export default withManager(CreateOrganizationMeetingPage)
