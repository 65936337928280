// fragements.js - graphQL fragments for mutations and queries.

import { gql } from 'apollo-boost'

export const AttendanceValidationFragment = gql`
  fragment AttendanceValidationDetail on AttendanceValidation {
    status
    invalidReasons
  }`

export const AttendanceFragment = gql`
  fragment AttendanceDetail on Attendance {
    id
    checkIn
    checkOut
    status
    meeting { name address { city state } }
    notes
    rating
    meetingStartTime
    meetingDurationMins
    checkInBatteryLevel
    isVirtual
    checkInVerification { lat lng biometricVerificationType photoUrl }
    checkOutVerification { lat lng biometricVerificationType photoUrl }
    validation { ...AttendanceValidationDetail }
  }
  ${AttendanceValidationFragment}
  `

export const AttendanceNoMeetingFragment = gql`
  fragment AttendanceNoMeetingDetail on Attendance {
    id
    checkIn
    checkOut
    status
    notes
    rating
    isVirtual
    meetingStartTime
    meetingDurationMins
    checkInVerification { lat lng biometricVerificationType photoUrl }
    checkOutVerification { lat lng biometricVerificationType photoUrl }
    validation { ...AttendanceValidationDetail }
  }
  ${AttendanceValidationFragment}
  `

export const PaymentFragment = gql`
  fragment PaymentDetail on PaymentProfile {
    providerId
    active
    status
  }`

export const UserDetailFragment = gql`
  fragment UserDetail on User {
    id
    email
    firstName
    lastName
    phone
    photoUrl
    address {
      city
      state
      street
      postalCode
    }
    sobrietyDate
    daysSober
    meetingsAttended
    meetingsRequiredPerMonth
    status
    weeklyAttendance {
        mon {
            attended
            count
        }
        tue {
            attended
            count
        }
        wed {
            attended
            count
        }
        thu {
            attended
            count
        }
        fri {
            attended
            count
        }
        sat {
            attended
            count
        }
        sun {
            attended
            count
        }
    }
    currentMonthAttendance {
      id
      checkIn
      checkOut
      validation {
        status
      }
      isVirtual
      meeting {
        name
        address {
          city
          state
        }
      }
    }
    reqMeetings
    reqMeetingsFreq
    durationDays
    probationId
    attendanceReminder
    attendanceReminderDays
    latestAttendanceActivity {
      ...AttendanceNoMeetingDetail
    }
    meetingsRequiredPerWeek
    createdAt
    managerId
  }
  ${AttendanceNoMeetingFragment}
`

export const ManagerDetailFragment = gql`
  fragment ManagerDetail on Manager {
    id
    email
    firstName
    lastName
    role
    phone
    photoUrl
    role
    clientInvites {
      id
    }
    organization {
      id
      name
      logoUrl
      meetings {
        id
        name
        address {
          street
          city
          state
          postalCode
        }
        weekDay,
        location {
          lat
          lng
        }
        startTime
        endTime
        type
      }
    }
    clients {
      ...UserDetail
    }
    createdAt
  }
  ${UserDetailFragment}
`

export const AdminDetailFragment = gql`
  fragment AdminDetail on Admin {
    id
    email
    firstName
    lastName
    role
    phone
    photoUrl
    createdAt
  }
`

export const ClientMonthlyCountFragment = gql`
  fragment MonthlyCountDetail on  MonthlyAttendanceCount {
    meetingYear
    meetingMonth
    meetingCount
  }`

export const ClientWeeklyCountFragment = gql`
  fragment WeeklyCountDetail on  WeeklyAttendanceCount {
    meetingYear
    meetingMonth
    meetingWeek
    meetingCount
  }`

export const ManagerNoteFragment = gql`
  fragment ManagerNoteDetail on ManagerNote {
    note
    createdAt
  }`

export const AvailableManagerFragment = gql`
  fragment AvailableManagerDetail on AvailableManager {
    id
    firstName
    lastName
  }`
