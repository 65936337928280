// DashboardLayout.js - the dashoard layout

import React from 'react'

import { Content, Footer, DashboardHeader } from 'components/Layout'

import { withAdmin } from 'components/Session'
import { Col, Row } from 'reactstrap'

import { BackButton } from '../BackButton'

class AdminLayoutNoMenu extends React.Component {
  render () {
    const { children, authUser, admin, title, withBackButton } = this.props
    const whiteHeaderColor = true

    return (
      <main className='tbv-app bg-light'>
        <Content fluid className={whiteHeaderColor ? 'tbv-dash tbv-dash-white' : 'tbv-dash'}>
          <DashboardHeader white={whiteHeaderColor} manager={admin} authUser={authUser} />
          <Row className='tbv-dash-title d-flex flex-column justify-content-center pt-3'>
            <Col md={{size: 10, offset: 1}} >
              <h1 className='font-weight-bold pl-15px float-left'>{title}</h1>
              { withBackButton && (
                <div className='float-right'>
                  <BackButton />
                </div>
              )}
            </Col>
          </Row>
          {children}
          <Footer />
        </Content>
      </main>
    )
  }
}

export default withAdmin(AdminLayoutNoMenu)
