// withPrint.js - for printing components
import React from 'react'

import ReactToPrint from 'react-to-print'

const withPrint = (Component) => {
  class WithPrint extends React.Component {
    render () {
      const { children, ...restProps } = this.props
      return (
        <React.Fragment>
          <ReactToPrint
            // eslint-disable-next-line
            trigger={() => <a role='button' href='#'>{children}</a>}
            content={() => this.componentRef}
          />
          <div className='d-none'>
            <Component {...restProps} ref={el => (this.componentRef = el)} />
          </div>
        </React.Fragment>
      )
    }
  }

  return WithPrint
}

export default withPrint
