// AttendanceRow.js - holds meeting attendance for clients

import React from 'react'
import PropTypes from 'utils/propTypes'
import Avatar from '../Avatar'
import { getMeetingPace, REQ_MEETINGS_EXCEEDING, REQ_MEETINGS_BEHIND } from 'utils/meetings.js'
import moment from 'moment'
import { NOT_ATTENDED } from '../../utils/attendance'
import AttendanceTableDay from './AttendanceTableDay'
import { Popover, PopoverBody, NavLink as BSNavLink } from 'reactstrap'
import { getStatusCounts, getAttendanceIcon } from 'utils/attendance'
import { AttendanceStatuses } from 'constants/attendance'
import { CLIENT_REPORTS } from 'constants/routes'
import { NavLink } from 'react-router-dom'

class AttendanceRow extends React.Component {
  state = {
    isPopoverOpen: false
  }

  getBorderClass = (client) => {
    const pace = getMeetingPace(client.meetingsAttended, client.meetingsRequiredPerMonth, moment().endOf('month'))
    switch (pace) {
      case REQ_MEETINGS_BEHIND:
        return `behind`
      case REQ_MEETINGS_EXCEEDING:
        return `exceeding`
      default:
        return `ontarget`
    }
  }

  toggle = () => {
    this.setState({
      isPopoverOpen: !this.state.isPopoverOpen
    })
  }

  render () {
    const { client } = this.props
    const attendance = client.currentMonthAttendance
    const diff = client.meetingsRequiredPerMonth - client.meetingsAttended
    let attendanceWithBlanks
    if (diff < 1) {
      attendanceWithBlanks = attendance
    } else {
      attendanceWithBlanks = new Array(diff).fill(NOT_ATTENDED)
      attendanceWithBlanks.unshift(...attendance)
    }
    const statusCounts = getStatusCounts(attendance)

    return (
      <div className={`attendance-row attendance-row--${this.getBorderClass(client)}`}>
        <div className='attendance-row__info' title={`${client.firstName} ${client.lastName}`} id={`Client-${client.id}`}>
          <Avatar size={30} src={client.photoUrl} />
          <BSNavLink to={`${CLIENT_REPORTS}${client.id}`} tag={NavLink} className='attendance-row-link'>
            <span className='name'>{`${client.firstName} ${client.lastName}`}</span>
          </BSNavLink>
        </div>
        <Popover trigger='hover' isOpen={this.state.isPopoverOpen} toggle={this.toggle} placement='bottom' target={`Client-${client.id}`}>
          <PopoverBody>
            <div className='m1 attendance-row-popover'>
              <div>
                <p className='text-center font-weight-bold mb-0'>
                  {`${client.firstName} ${client.lastName}`}
                </p>
              </div>
              <hr style={{borderTop: '1px solid #dedede', margin: '10px -10px 10px -10px'}} />
              <div className='mb-2'>
                <i>
                  <img alt={AttendanceStatuses.UNVERIFIED} src={getAttendanceIcon(AttendanceStatuses.UNVERIFIED)} />
                </i>
                <span className='ml-2'>
                  Meetings unverified: <span className='float-right'>{statusCounts[AttendanceStatuses.UNVERIFIED]}</span>
                </span>
              </div>
              <div className='mb-2'>
                <i>
                  <img alt={AttendanceStatuses.PENDING} src={getAttendanceIcon(AttendanceStatuses.PENDING)} />
                </i>
                <span className='ml-2'>
                  Meetings pending: <span className='float-right'>{statusCounts[AttendanceStatuses.PENDING]}</span>
                </span>
              </div>
              <div className='mb-2'>
                <i>
                  <img alt={AttendanceStatuses.VERIFIED} src={getAttendanceIcon(AttendanceStatuses.VERIFIED)} />
                </i>
                <span className='ml-2'>
                  Meetings verified: <span className='float-right'>{statusCounts[AttendanceStatuses.VERIFIED]}</span>
                </span>
              </div>
              <hr style={{borderTop: '1px solid #dedede', margin: '10px -10px 10px -10px'}} />
              <div>
                <p className='font-weight-bold mb-0'>
                  Meetings required: <span className='float-right'>{client.meetingsRequiredPerMonth}</span>
                </p>
              </div>
            </div>
          </PopoverBody>
        </Popover>
        <div className='attendance-row__attendance'>
          {attendanceWithBlanks.map((item, i) => (
            <AttendanceTableDay key={i} clientId={client.id} attendance={item} />
          ))}
        </div>
      </div>
    )
  }
}

AttendanceRow.propTypes = {
  client: PropTypes.shape({
    id: PropTypes.string,
    photoUrl: PropTypes.string,
    firstName: PropTypes.string,
    meetingsAttended: PropTypes.number,
    meetingsRequiredPerMonth: PropTypes.number
  })
}

export default AttendanceRow
