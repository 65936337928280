import React from 'react'
import Log from 'utils/logging.js'
import { Col, Row } from 'reactstrap'
import Page from '../components/Page'
import { withManager } from '../components/Session'
import MyOrganization from '../components/Dashboard/MyOrganization'

class MyOrganizationPage extends React.Component {
  componentWillMount () {
    if (!this.props.manager.organization) {
      this.props.history.push('/')
    }
  }

  render () {
    Log.info('managerorganizations page')
    Log.info(this.props)
    const { manager, authUser } = this.props
    Log.info(manager)
    return (
      <Page className='DashboardPage'>
        <Row>
          <Col>
            <MyOrganization authUser={authUser} manager={manager} />
          </Col>
        </Row>
      </Page>
    )
  }
}

export default withManager(MyOrganizationPage)
