import 'babel-polyfill'
import React from 'react'
import ReactDOM from 'react-dom'

import App from './App'

// if (process.env.NODE_ENV !== 'production') {
// eslint-disable-next-line
localStorage.setItem('debug', 'tbv-app:*')
// }

ReactDOM.render(<App />, document.getElementById('root'))
