// DownloadLink.js - app download link

import React from 'react'
import { PropTypes } from 'prop-types'

import { IOS_APP_LINK } from 'constants/text.js'

const DownloadLink = ({children, ...restProps}) => {
  /* eslint-disable jsx-a11y/anchor-has-content */
  return (
    <a href={IOS_APP_LINK} target='_blank' rel='noopener noreferrer' {...restProps}>{children}</a>
  )
}

DownloadLink.propTypes = {
  title: PropTypes.string.isRequired
}

DownloadLink.defaultProps = {
  title: 'TBV Mobile App'
}

export default DownloadLink
