// StripeCCSection.js
import React from 'react'

import PropTypes from 'prop-types'

import { FormGroup, Label } from 'reactstrap'

import { CardNumberElement, CardExpiryElement, CardCVCElement } from 'react-stripe-elements'

const createOptions = (fontSize: string, padding: ?string) => {
  return {
    style: {
      base: {
        fontSize,
        color: '#424242',
        letterSpacing: '0.025em',
        fontFamily: 'Poppins,Roboto,Helvetica Neue,Arial,sans-serif',
        '::placeholder': {
          color: '#bdbdbd'
        },
        ...(padding ? {padding} : {})
      },
      invalid: {
        color: '#f85032'
      }
    }
  }
}

const StripeCCSection = (props) => {
  const {
    cardNumberId,
    cardNumberLabel,
    expireId,
    expireLabel,
    cvcId,
    cvcLabel
  } = props
  const fontSize = '16px'

  return (
    <React.Fragment>
      <FormGroup className='required'>
        <Label for={cardNumberLabel}>{cardNumberLabel}</Label>
        <CardNumberElement className='form-control' id={cardNumberId} {...createOptions(fontSize)} />
      </FormGroup>

      <div className='form-row'>
        <FormGroup className='col-md-6 required'>
          <Label for={expireLabel}>{expireLabel}</Label>
          <CardExpiryElement className='form-control' id={expireId} {...createOptions(fontSize)} />
        </FormGroup>

        <FormGroup className='col-md-6 required'>
          <Label for={cvcLabel}>{cvcLabel}</Label>
          <CardCVCElement className='form-control' id={cvcId} {...createOptions(fontSize)} />
        </FormGroup>
      </div>

    </React.Fragment>
  )
}

StripeCCSection.propTypes = {
  cardNumberId: PropTypes.string,
  cardNumberLabel: PropTypes.string,
  expireId: PropTypes.string,
  expireLabel: PropTypes.string,
  cvcId: PropTypes.string,
  cvcLabel: PropTypes.string
}

StripeCCSection.defaultProps = {
  cardNumberId: 'cardNumber',
  cardNumberLabel: 'Card Number',

  expireId: 'expire',
  expireLabel: 'Expiration date',

  cvcId: 'cvc',
  cvcLabel: 'CVC'
}

export default StripeCCSection
