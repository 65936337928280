// StateDropDown.js - duration unit dropdown
import React from 'react'

import { TBVDropDown } from '../../TBVForm'

import { Field } from 'formik'

import STATES from 'constants/states.js'

const StateDropDown = props => (
  <Field
    component={TBVDropDown}
    {...props}
  />
)

StateDropDown.defaultProps = {
  items: STATES,
  title: 'e.g. MA'
}

export default StateDropDown
