// ManagerProfilePage.js - client detail page for manager

import React from 'react'

import Page from 'components/Page'
import { ManagerProfile } from 'components/Manager'

import { withManager } from 'components/Session'

class ManagerProfilePage extends React.Component {
  render () {
    const { authUser, manager, roleState } = this.props

    return (
      <Page className='ClientDetailPage'>
        <ManagerProfile
          authUser={authUser}
          roleState={roleState}
          manager={manager}
        />
      </Page>
    )
  }
}

export default withManager(ManagerProfilePage)
