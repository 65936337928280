import React from 'react'
import { graphql } from 'react-apollo'
import PropTypes from 'prop-types'
import { mutations, utils, queries } from '../../graphql'
import { Col, Row, Card } from 'reactstrap'
import { compose, pure } from 'recompose'
import 'react-datepicker/dist/react-datepicker.css'
import { withRouter } from 'react-router'
import { withLoading } from '../../hocs/withLoading'
import bn from 'utils/bemnames'
import AttendanceLocationForm from './AttendanceLocationForm'

const bem = bn.create('attendance-location')

const AttendanceLocationComponent = (props) => {
  const handleSubmit = async (input) => {
    await props.adminUpdateMeetingLocation({
      variables: {
        input: {
          ...input
        }
      },
      context: utils.generateAuthHeader(props.authUser.token),
      update: (store) => {
        const data = store.readQuery({query: queries.attendanceNeedsReview})
        const index = data.attendanceNeedsReview.findIndex(a => a.id === input.id)
        data.attendanceNeedsReview[index].needsLocationReview = false
        store.writeQuery({query: queries.attendanceNeedsReview, data: data})
      }
    })
    props.history.push('/reviewattendance')
  }
  return (
    <Row className={bem.b()}>
      <Col>
        <Row>
          <Col md={{size: 10, offset: 1}}>
            <Card className='border-0 tbv-box-shadow pb-3'>
              <AttendanceLocationForm submit={handleSubmit} attendance={props.data.adminGetAttendance} handleSubmit={handleSubmit} />
            </Card>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

AttendanceLocationComponent.propTypes = {
  authUser: PropTypes.object.isRequired,
  admin: PropTypes.object.isRequired
}

const AttendanceLocation = compose(
  withRouter,
  graphql(queries.adminGetAttendance, {
    options: props => ({
      context: utils.generateAuthHeader(props.authUser.token),
      variables: {
        input: {
          id: props.match.params.id
        }
      }
    })
  }),
  graphql(mutations.ADMIN_UPDATE_MEETING_LOCATION, {name: 'adminUpdateMeetingLocation'}),
  withLoading,
  pure
)(AttendanceLocationComponent)

export { AttendanceLocation }
