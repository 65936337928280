// DashboardLayout.js - the dashoard layout

import React from 'react'
import { Content, Footer, DashboardHeader } from 'components/Layout'
import {
  MdImportantDevices,
  MdLoyalty
} from 'react-icons/lib/md'
import NotificationSystem from 'react-notification-system'
import { NOTIFICATION_SYSTEM_STYLE } from 'constants/notifications'
import * as textConstants from 'constants/text'
import { withManager } from 'components/Session'
import { Col, Row } from 'reactstrap'
import Menu from './Menu'
import moment from 'moment'
import { BackButton } from '../BackButton'

class DashboardLayout extends React.Component {
  componentDidMount () {
    setTimeout(() => {
      if (!this.notificationSystem) {
        return
      }

      this.notificationSystem.addNotification({
        title: <MdImportantDevices />,
        message: textConstants.DASH_WELCOME_MESSAGE,
        level: 'info'
      })
    }, 1500)

    setTimeout(() => {
      if (!this.notificationSystem) {
        return
      }

      this.notificationSystem.addNotification({
        title: <MdLoyalty />,
        message:
          textConstants.DASH_MANAGER_INTRO_MESSAGE,
        level: 'info'
      })
    }, 2500)
  }

  render () {
    const { children, authUser, manager, title, withBackButton } = this.props
    const whiteHeaderColor = true
    const isNew = (moment(manager.createdAt).isAfter(moment().subtract(15, 'minutes')))

    return (
      <main className='tbv-app bg-light'>
        <Content fluid className={whiteHeaderColor ? 'tbv-dash tbv-dash-white pl-2 pr-2 pr-sm-4 pr-md-5' : 'tbv-dash'}>
          <DashboardHeader white={whiteHeaderColor} manager={manager} authUser={authUser} />
          <Row className='tbv-dash-title d-flex flex-column justify-content-center pt-3'>
            <Col md={{size: 10, offset: 1}} >
              <h1 className='font-weight-bold pl-15px float-left'>{title}</h1>
              { withBackButton && (
                <div className='float-right'>
                  <BackButton />
                </div>
              )}
            </Col>
          </Row>
          <Row className='ml-0'>
            <Col md={{size: 10, offset: 1}}>
              <Menu manager={manager} org={manager.organization} clients={manager.clients} />
            </Col>
          </Row>
          <Row className='ml-0'>
            <Col md={{size: 10, offset: 1}} className='pl-0'>
              {children}
            </Col>
          </Row>
          <Footer />
        </Content>
        {isNew && (
          <NotificationSystem
            dismissible={false}
            ref={notificationSystem =>
              (this.notificationSystem = notificationSystem)
            }
            style={NOTIFICATION_SYSTEM_STYLE}
          />)}
      </main>
    )
  }
}

export default withManager(DashboardLayout)
