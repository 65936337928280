// SubscriptionInfo.js - Subscription Info
import React from 'react'

import PropTypes from 'prop-types'

import Log from 'utils/logging.js'

import Roles from 'utils/roles'

import { CancelSubscriptionForm } from '../SignUp'

import { SUBSCRIPTION_ACTIVE_STATUS,
  SUBSCRIPTION_INACTIVE_STATUS,
  SUBSCRIPTION_STATUS_LABEL,
  SUBSCRIPTION_PAST_DUE_STATUS,
  SUBSCRIPTION_ACTION_LABEL } from 'constants/text.js'

import { PaymentStatusMsg } from 'constants/payments.js'

import { isCanceled, isPastDue } from 'utils/payments.js'

const SubscriptionInfo = ({client, authUser, roleState}) => {
  const { paymentProfile } = client
  const hasSubscription = paymentProfile ? paymentProfile.active : false
  const subscriptionStatus = hasSubscription ? PaymentStatusMsg(paymentProfile.status) : SUBSCRIPTION_INACTIVE_STATUS
  return (<React.Fragment>
    {(hasSubscription && !isCanceled(client.paymentProfile.status) && <p>{SUBSCRIPTION_ACTIVE_STATUS}</p>)}
    <p><span><b>{SUBSCRIPTION_STATUS_LABEL}:</b></span>&nbsp;<span>{subscriptionStatus}</span></p>
    {(hasSubscription && isPastDue(client.paymentProfile.status) &&
    <p><span><b>{SUBSCRIPTION_ACTION_LABEL}:</b></span>&nbsp;<span>{SUBSCRIPTION_PAST_DUE_STATUS}</span></p>
    )}
    {(hasSubscription && !isCanceled(client.paymentProfile.status) &&
    <CancelSubscriptionForm client={client} onFormSubmit={() => { Log.info('cancel subscription success') }} authUser={authUser} roleState={roleState} />
    )}
  </React.Fragment>)
}

SubscriptionInfo.propTypes = {
  authUser: PropTypes.object.isRequired,
  client: PropTypes.object.isRequired,
  roleState: PropTypes.oneOf([Roles.CLIENT]).isRequired
}

export default SubscriptionInfo
