// WeeklyAttendanceTable.js - table to show meeting attendance

import React from 'react'

import {SILVER, SUCCEEDED} from 'constants/colors'

import { MdCheckCircle, MdRemoveCircle } from 'react-icons/lib/md'

const AttendanceVal = ({ attended, fill }) => {
  return attended ? <MdCheckCircle size='25' fill={fill} /> : <MdRemoveCircle size='25' fill={fill} />
}

const Week = ({weeklyAttendance}) => Object
  .keys(weeklyAttendance)
  .map(day => {
    const attendance = weeklyAttendance[day]

    return typeof attendance === 'object' && (
      <Day key={day} day={day} attended={attendance.attended} />
    )
  })

const Day = ({day, attended}) => (
  <div className='d-flex flex-column text-center'>
    <p className='mb-0'><small className='text-capitalize text-bluishGrey'>{day}</small></p>
    <AttendanceVal day={day} attended={attended} fill={attended ? SUCCEEDED : SILVER} />
  </div>
)

const WeeklyAttendanceTable = ({weeklyAttendance}) => {
  return (
    <div className='border-top d-flex justify-content-center'>
      <div className='d-flex justify-content-between w-100 pl-3 pr-3 pt-3'>
        <Week weeklyAttendance={weeklyAttendance} />
      </div>
    </div>
  )
}

export default WeeklyAttendanceTable
