// CreateOrganizationMeetingForm.js - form for manager to add org meeting

import React from 'react'
import { graphql } from 'react-apollo'
import PropTypes from 'prop-types'
import { mutations, utils, queries } from '../../graphql'
import { compose, pure } from 'recompose'
import 'react-datepicker/dist/react-datepicker.css'
import { withRouter } from 'react-router'
import MeetingForm from '../MeetingForm'
import { withLoading } from '../../hocs/withLoading'

const EditOrganizationMeetingFormComponent = (props) => {
  const handleSubmit = async (values) => {
    await props.updateMeeting({
      variables: {
        input: {
          ...values,
          id: props.match.params.id
        }
      },
      context: utils.generateAuthHeader(props.authUser.token),
      update: (store, {data: {updateMeeting}}) => {
        const data = store.readQuery({query: queries.authedUserQuery})
        const meetings = data.authedUser.manager.organization.meetings
        const idx = meetings.findIndex(m => {
          return m.id === updateMeeting.id
        })
        meetings[idx] = updateMeeting
        store.writeQuery({query: queries.authedUserQuery, data: meetings})
      }
    })
    props.history.push('/myorganization')
  }
  return (
    <MeetingForm handleSubmit={handleSubmit} initialValues={props.data.meeting} />
  )
}

EditOrganizationMeetingFormComponent.propTypes = {
  authUser: PropTypes.object.isRequired,
  manager: PropTypes.object.isRequired
}

const EditOrganizationMeetingForm = compose(
  withRouter,
  graphql(queries.getMeeting, {
    options: props => ({
      context: utils.generateAuthHeader(props.authUser.token),
      variables: {
        input: {
          id: props.match.params.id
        }
      }
    })
  }),
  graphql(mutations.EDIT_MEETING, {name: 'updateMeeting'}),
  withLoading,
  pure
)(EditOrganizationMeetingFormComponent)

export default EditOrganizationMeetingForm
