// withAppUser.js - HOC for loading app user

import React from 'react'

import withAuthentication from './withAuthentication'
import LoadingPlaceHolder from 'components/LoadingPlaceHolder'

import { Query } from 'react-apollo'
import { queries, utils } from '../../graphql'

const withAppUser = (Component) => {
  class WithAppUser extends React.Component {
    render () {
      const { authUser, children, ...props } = this.props

      if (authUser && authUser.token) {
        return (
          <Query query={queries.authedUserQuery} context={utils.generateAuthHeader(authUser.token)}>
            {({ loading, error, data }) => {
              if (error) return null
              if (loading) return (<LoadingPlaceHolder />)

              // grab manager or client
              const { client, manager, admin } = data.authedUser

              // add client, manager attributes
              return (<Component admin={admin} manager={manager} client={client} authUser={authUser} {...props}>{children}</Component>)
            }}
          </Query>
        )
      } else {
        return null
      }
    }
  }

  return withAuthentication(WithAppUser)
}

export default withAppUser
