// utils/attendance.js

import { AttendanceStatuses, PendingStatuses } from 'constants/attendance.js'
import verified from '../assets/img/icons/verified.svg'
import unverified from '../assets/img/icons/unverified.svg'
import pending from '../assets/img/icons/pending.svg'
import notattended from '../assets/img/icons/notattended.svg'

import moment from 'moment-timezone'

export const sortByIdDesc = (a, b) => {
  const idA = parseInt(a.id, 10)
  const idB = parseInt(b.id, 10)
  return idB - idA
}

export const NOT_ATTENDED = {
  validation: {
    status: 'NOT_ATTENDED'
  }
}

export const getAttendanceIcon = (status) => {
  switch (status) {
    case AttendanceStatuses.VERIFIED:
      return verified
    case AttendanceStatuses.UNVERIFIED:
      return unverified
    case AttendanceStatuses.PENDING:
      return pending
    default:
      return notattended
  }
}

export const getStatusCounts = (attendance) => {
  return attendance.reduce((p, c) => {
    const status = c.validation.status
    p[status] = p[status] + 1
    return p
  }, {
    [AttendanceStatuses.VERIFIED]: 0,
    [AttendanceStatuses.UNVERIFIED]: 0,
    [AttendanceStatuses.PENDING]: 0
  })
}

export const sortByPendingStatus = (a, b) => {
  const statusA = a.validation.status
  const statusB = b.validation.status
  if (PendingStatuses.includes(statusA) && !PendingStatuses.includes(statusB)) { return -1 } else if (!PendingStatuses.includes(statusA) && PendingStatuses.includes(statusB)) { return 1 }
  return sortByIdDesc(a, b)
}

export const genAttendanceDates = (client) => {
  // generates start and end dates for a clients total-time on app
  // used in client activity query to get all attendance for client
  const startDate = moment(client.createdAt).format('MM/DD/YYYY')
  let tmwUTC = moment()
  tmwUTC.add(1, 'day')
  const endDate = tmwUTC.format('MM/DD/YYYY')
  return [startDate, endDate]
}

export const getAttendancesStatusCount = (attendances, status) => {
  // used to get number of attendance for a particular validation status
  // see @constants/attendance.js#AttendanceStatuses
  return attendances.filter(attendance => attendance.validation.status === status).length
}
