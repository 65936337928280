// LoadingPlaceHolder

import React from 'react'

const LoadingPlaceHolder = () => (
  <div className='d-flex h-100 w-100 position-relative justify-content-center align-items-center my-5'>
    <div className='loader' />
  </div>
)

export default LoadingPlaceHolder
