import React from 'react'
import { FormGroup, Label } from 'reactstrap'
import { Field } from 'formik'
import { ValidationFields, TBVDropDown } from 'components/TBVForm'
import * as Yup from 'yup'

export const StatusSchema = Yup.object().shape({
  status: ValidationFields.STATUS
})

export const StatusPropsMap = {
  status: ''
}

export const StatusSection = (props) => {
  return (
    <React.Fragment>
      <div className='form-row'>
        <FormGroup>
          <Label>Status</Label>
          <Field
            component={TBVDropDown}
            items={{
              'ACTIVE': 'Active',
              'INACTIVE': 'Inactive'
            }}
            name='status'
          />
        </FormGroup>
      </div>
    </React.Fragment>
  )
}
