// ActivityFeed.js - manager activity on client

import React from 'react'

import * as status from 'constants/status.js'
import moment from 'moment'
import { FeedItem } from './FeedItem'

const generateActivityItem = (attendance) => {
  // generate activity feed item from attendance object
  let eventOccurred
  let statement
  if (attendance.status === status.CHECKED_OUT) {
    statement = `successfully checked out of a meeting`
    eventOccurred = moment(attendance.checkOut).calendar()
  } else if (attendance.status === status.CHECKED_IN) {
    statement = `successfully checked into a meeting`
    eventOccurred = moment(attendance.checkIn).calendar()
  }
  return {id: attendance.id, text: statement, occurred: eventOccurred}
}

export const ActivityFeed = (props) => {
  const {latestAttendance, name, photoUrl} = props

  const activityItems = latestAttendance.map((item, i) => {
    const activity = generateActivityItem(item)
    return (
      <FeedItem
        key={i}
        photoUrl={photoUrl}
        text={`${name}  ${activity.text}`}
        time={activity.occurred}
      />
    )
  })

  return latestAttendance.length ? (
    <div>
      {activityItems}
    </div>
  ) : (
    <div>
      <small className='pl-4'>No recent activity to show.</small>
    </div>
  )
}

export default ActivityFeed
