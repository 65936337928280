// CreateOrganizationMeetingForm.js - form for manager to add org meeting

import React from 'react'
import PropTypes from 'prop-types'
import { Form, Row, Col, FormGroup, Input, Label } from 'reactstrap'
import { ADD_ORG_MEETING_BTN } from 'constants/text.js'
import TBVButton from './TBVButton.js'
import {
  ValidationFields,
  TBVFormField,
  TBVFormInput,
  TBVError
} from 'components/TBVForm'
import { withFormik, Field } from 'formik'
import * as Yup from 'yup'
import { compose, pure } from 'recompose'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import moment from 'moment-timezone'
import { LocationSearchInput } from './PlacesComplete'
import { Map } from './Map'
import Hidable from './Hidable.js'
import Avatar from './Avatar'

const DEFAULT_VIRTUAL = {
  streetAddress: '1 City Hall Square #500',
  city: 'Boston',
  state: 'Massachusetts',
  postalCode: '02210',
  latitude: 42.338947,
  longitude: -70.919635
}

const mapToDTO = (values) => {
  return {
    name: values.name,
    weekDay: values.day,
    address: {
      street: values.streetAddress,
      city: values.city,
      state: values.state,
      postalCode: values.postalCode
    },
    location: {
      lat: values.latitude,
      lng: values.longitude
    },
    startTime: moment(values.startTime).format('HH:mm'),
    endTime: moment(values.endTime).format('HH:mm'),
    type: values.type,
    allowsVirtual: values.allowsVirtual,
    virtualUrl: values.virtualUrl,
    meetingPassword: values.meetingPassword,
    isDemoMeeting: values.isDemoMeeting,
    temporarilySuspended: values.temporarilySuspended
  }
}
const formikEnhancer = withFormik({
  validationSchema: Yup.object().shape(ValidationFields.MEETING_FORM),
  mapPropsToValues: ({ initialValues = { address: {}, location: {} } }) => {
    const {
      address: { street, city, state, postalCode }
    } = initialValues
    const formatAddress = `${street} ${city}, ${state} ${postalCode}`
    let startTime = ''
    let endTime = ''

    if (initialValues.startTime) {
      const start = new Date()
      const [hours, minutes] = initialValues.startTime.split(':')
      start.setHours(hours)
      start.setMinutes(minutes)
      startTime = start
    }

    if (initialValues.endTime) {
      const end = new Date()
      const [hours, minutes] = initialValues.endTime.split(':')
      end.setHours(hours)
      end.setMinutes(minutes)
      endTime = end
    }

    return {
      address: initialValues.address.street ? formatAddress : '',
      name: initialValues.name || '',
      type: initialValues.type || '',
      day: initialValues.weekDay || '',
      startTime,
      endTime,
      streetAddress: initialValues.address.street || '',
      city: initialValues.address.city || '',
      state: initialValues.address.state || '',
      postalCode: initialValues.address.postalCode || '',
      latitude: initialValues.location.lat || '',
      longitude: initialValues.location.lng || '',
      createdBy: initialValues.createdBy || {},
      allowsVirtual: initialValues.allowsVirtual || false,
      temporarilySuspended: initialValues.temporarilySuspended || false,
      virtualUrl: initialValues.virtualUrl || '',
      meetingPassword: initialValues.meetingPassword || '',
      isDemoMeeting: initialValues.isDemoMeeting || false
    }
  },
  handleSubmit: async (values, { setSubmitting, props }) => {
    let toSend = values
    if (values.allowsVirtual && !values.streetAddress) {
      toSend = {
        ...values,
        ...DEFAULT_VIRTUAL
      }
    }
    setSubmitting(true)
    props.handleSubmit(mapToDTO(toSend))
    setSubmitting(false)
  },
  displayName: 'MeetingForm'
})

const days = [
  { label: '', value: undefined },
  { label: 'Monday', value: 1 },
  { label: 'Tuesday', value: 2 },
  { label: 'Wednesday', value: 3 },
  { label: 'Thursday', value: 4 },
  { label: 'Friday', value: 5 },
  { label: 'Saturday', value: 6 },
  { label: 'Sunday', value: 7 }
]

const meetingTypes = [
  { label: '', value: undefined },
  { label: 'Alcoholics Anonymous', value: 'AA' },
  { label: 'Narcotics Anonymous', value: 'NA' },
  { label: 'Smart Recovery', value: 'SR' },
  { label: 'Life Ring', value: 'LR' },
  { label: 'Y12SR', value: 'Y12' },
  { label: 'Refuge Recovery', value: 'RR' },
  { label: 'Celebrate Recovery', value: 'CR' },
  { label: 'Other', value: 'OTHER' }
]

const select = ({
  field,
  form: { touched, errors, values, setFieldValue, setFieldTouched },
  showError,
  ...props
}) => {
  const errorMsg = touched[field.name] && errors[field.name]
  const handleBlur = () => {
    setFieldTouched(field.name, true)
  }
  return (
    <React.Fragment>
      <Input
        type='select'
        name={field.name}
        onBlur={handleBlur}
        {...field}
        {...props}
      >
        {props.children}
      </Input>
      {errorMsg && <TBVError message={errorMsg} className='text-danger' />}
    </React.Fragment>
  )
}

const datepicker = ({
  field,
  form: { touched, errors, values, setFieldValue, setFieldTouched },
  showError,
  ...props
}) => {
  const errorMsg = touched[field.name] && errors[field.name]
  const handleBlur = () => {
    setFieldTouched(field.name, true)
  }
  const handleChange = (date) => {
    setFieldValue(field.name, date || '')
  }

  const input = <Input name={field.name} value={values[field.name]} />
  return (
    <React.Fragment>
      <DatePicker
        {...field}
        {...props}
        selected={field.value}
        onBlur={handleBlur}
        customInput={input}
        onChange={handleChange}
        timeIntervals={10}
        showTimeSelect
        showTimeSelectOnly
        timeCaption='Time'
        dateFormat='h:mm aa'
      />

      {errorMsg && <TBVError message={errorMsg} className='text-danger' />}
    </React.Fragment>
  )
}

const MeetingFormComponent = (props) => {
  const {
    errors,
    isSubmitting,
    isReview = false,
    isAdmin = false,
    handleSubmit,
    values,
    deny
  } = props

  return (
    <Form className='p-3' onSubmit={handleSubmit}>
      {isReview && (
        <Row>
          <Col md={6}>
            <h5 className='meeting-form-title'>Submitted by</h5>
            <Row>
              <Col md={3}>
                <Avatar size={30} src={values.createdBy.client.photoUrl} />
              </Col>
              <Col
                md={9}
              >{`${values.createdBy.client.firstName} ${values.createdBy.client.lastName}`}</Col>
            </Row>
            <Row>
              <Col md={3}>
                <Label>Phone</Label>
              </Col>
              <Col md={9}>{values.createdBy.client.phone}</Col>
            </Row>
            <Row>
              <Col md={3}>
                <Label>Email</Label>
              </Col>
              <Col md={9}>{values.createdBy.client.email}</Col>
            </Row>
          </Col>
        </Row>
      )}
      <Row>
        <Col md={6}>
          <h5 className='meeting-form-title'>Meeting Info</h5>
          <TBVFormField disabled={isReview} label='Meeting Name' name='name' />
          <FormGroup>
            <Label>Meeting Type</Label>
            <Field disabled={isReview} component={select} name='type'>
              {meetingTypes.map((mt, idx) => (
                <option key={idx} value={mt.value}>
                  {mt.label}
                </option>
              ))}
            </Field>
          </FormGroup>
          <FormGroup>
            <Label>Day</Label>
            <Field
              disabled={isReview && values['day']}
              component={select}
              label='Name'
              name='day'
            >
              {days.map((d, idx) => (
                <option key={idx} value={d.value}>
                  {d.label}
                </option>
              ))}
            </Field>
          </FormGroup>
          <FormGroup>
            <Label>Time</Label>
            <Field
              disabled={isReview}
              component={datepicker}
              name='startTime'
            />
          </FormGroup>
          <FormGroup>
            <Label>End Time</Label>
            <Field disabled={isReview} component={datepicker} name='endTime' />
          </FormGroup>
          {isAdmin && (
            <FormGroup>
              <Label for='isDemoMeeting' check>
                <Field
                  component={TBVFormInput}
                  type='checkbox'
                  name='isDemoMeeting'
                  id='isDemoMeeting'
                  checked={values.isDemoMeeting}
                />
                Is meeting for demo only?
              </Label>
            </FormGroup>
          )}
          {isAdmin && (
            <FormGroup>
              <Label for='temporarilySuspended' check>
                <Field
                  component={TBVFormInput}
                  type='checkbox'
                  name='temporarilySuspended'
                  id='temporarilySuspended'
                  checked={values.temporarilySuspended}
                />
                Is meeting temporarily suspended (Covid-19)?
              </Label>
            </FormGroup>
          )}
          <FormGroup>
            <Label for='allowsVirtual' check>
              <Field
                component={TBVFormInput}
                type='checkbox'
                name='allowsVirtual'
                id='allowsVirtual'
                checked={values.allowsVirtual}
              />
              Allows Virtual Meeting
            </Label>
          </FormGroup>
          {values.allowsVirtual && (
            <TBVFormField
              disabled={isReview}
              label='Virtual Meeting Url'
              name='virtualUrl'
            />
          )}
          {values.allowsVirtual && (
            <TBVFormField
              disabled={isReview}
              label='Password'
              name='meetingPassword'
            />
          )}
        </Col>
        <Col md={6}>
          <h5 className='meeting-form-title'>Meeting Location</h5>
          <FormGroup>
            <Label>Location</Label>
            <Field
              name='address'
              disabled={isReview}
              component={LocationSearchInput}
            />
          </FormGroup>
          <FormGroup>
            {values.latitude && values.longitude && (
              <Field
                component={Map}
                draggable={!isReview}
                latitude={values.latitude}
                longitude={values.longitude}
              />
            )}
          </FormGroup>
        </Col>
      </Row>
      <Hidable show={!isReview}>
        <span className='float-right'>
          {errors.submit && <p className='text-danger pt-2'>{errors.submit}</p>}
          <TBVButton
            className='xl-button'
            title={ADD_ORG_MEETING_BTN}
            disabled={isSubmitting}
          />
        </span>
      </Hidable>
      <Hidable show={isReview}>
        <span className='float-right'>
          {errors.submit && <p className='text-danger pt-2'>{errors.submit}</p>}
          <TBVButton
            className='xl-button'
            style={{ marginRight: 15 }}
            outline
            onClick={deny}
            title={'Deny'}
            disabled={isSubmitting}
          />
          <TBVButton
            className='xl-button'
            title={'Approve'}
            disabled={isSubmitting}
          />
        </span>
      </Hidable>
    </Form>
  )
}

MeetingFormComponent.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object
}

const MeetingForm = compose(pure)(formikEnhancer(MeetingFormComponent))

export default MeetingForm
