// roles.js - user roles

import * as Routes from 'constants/routes.js'

export const roles = {
  PROBATION_OFFICER: 'PROBATION_OFFICER',
  CASE_MANAGER: 'CASE_MANAGER',
  CLIENT: 'CLIENT'
}

export const ORG_ROLES = {
  MANAGER: 'manager',
  ORG_MANAGER: 'orgManager',
  ORG_SUPERVISOR: 'orgSupervisor',
  ORG_REGIONAL_SUPERVISOR: 'orgRegionalSupervisor'
}

const MANAGER_TITLES = {
  manager: 'Manager',
  orgManager: 'Manager',
  orgSupervisor: 'Supervisor',
  orgRegionalSupervisor: 'Regional Supervisor'
}

export const getOrgRoleTitle = (role) => {
  return MANAGER_TITLES[role]
}

export const getRoleTitle = (role) => {
  switch (role) {
    case roles.PROBATION_OFFICER:
      return 'Supervisor'
    case roles.CASE_MANAGER:
      return 'Supervisor'
    case roles.CLIENT:
      return 'Client'
    default:
      return 'Client'
  }
}

export const getLogInPath = (role) => {
  switch (role) {
    case roles.PROBATION_OFFICER:
      return Routes.LOG_IN_OFFICER
    case roles.CASE_MANAGER:
      return Routes.LOG_IN_MANAGER
    case roles.CLIENT:
      return Routes.LOG_IN_CLIENT
    default:
      return Routes.LOG_IN_CLIENT
  }
}

export const getSignUpPath = (role) => {
  switch (role) {
    case roles.PROBATION_OFFICER:
      return Routes.SIGN_UP_OFFICER
    case roles.CASE_MANAGER:
      return Routes.SIGN_UP_MANAGER
    case roles.CLIENT:
      return Routes.SIGN_UP_CLIENT
    default:
      return Routes.SIGN_UP_CLIENT
  }
}

export default roles
