// Address section - for home and billing adddress

import React from 'react'

import PropTypes from 'prop-types'

import { ValidationFields, TBVFormField } from 'components/TBVForm'

import * as Yup from 'yup'

import { CANCEL_NOTE_LABEL } from 'constants/text.js'

export const CancelNoteSchema = Yup.object().shape({
  note: ValidationFields.CANCEL_NOTE
})

export const CancelNotePropsMap = {
  note: ''
}

export const CancelNoteSection = (props) => {
  const {
    noteId,
    noteLabel,
    noteInputProps
  } = props

  return (
    <React.Fragment>
      <TBVFormField className='required' label={noteLabel} id={noteId} {...noteInputProps} />
    </React.Fragment>
  )
}

CancelNoteSection.propTypes = {
  noteId: PropTypes.string,
  noteLabel: PropTypes.string,
  noteInputProps: PropTypes.object
}

CancelNoteSection.defaultProps = {
  noteId: 'note',
  noteLabel: CANCEL_NOTE_LABEL,
  noteInputProps: {
    type: 'string',
    placeholder: ''
  }
}
