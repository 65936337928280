import React from 'react'

import { PropTypes } from 'prop-types'

import { CardImg, Card, CardBody, CardTitle, CardText, CardSubtitle } from 'reactstrap'
import TBVButton from '../TBVButton'

export const WelcomeCard = ({
  className,
  title,
  subtitle,
  text,
  imgSrc,
  imgSize,
  imgClassName,
  buttonTitle,
  onButtonClick,
  children,
  ...restProps
}) => {
  return (
    <Card className={className} {...restProps}>
      <CardBody className='d-flex text-center justify-content-center align-items-center flex-column'>
        <CardImg
          src={imgSrc}
          style={{ width: imgSize, height: imgSize }}
          className={imgClassName}
          alt={title}
        />
        <CardTitle>{title}</CardTitle>
        <CardSubtitle>{subtitle}</CardSubtitle>
        <CardText>
          <small>{text}</small>
        </CardText>
        <TBVButton className='mt-4' block title={buttonTitle} buttonType={'button'} onClick={onButtonClick} />
      </CardBody>
      {children}
    </Card>
  )
}

WelcomeCard.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  text: PropTypes.string,
  imgSize: PropTypes.number,
  buttonTitle: PropTypes.string,
  onButtonClick: PropTypes.func
}

export default WelcomeCard
