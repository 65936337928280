import React, {Component} from 'react'

class MessageBubble extends Component {
  constructor (props) {
    super(props)
    this.state = {}
  }

    componentDidMount = async () => {
      this.setState({
        ...this.state,
        type: (await this.props.message.getMember()).type
      })
      document.getElementById(this.props.message.sid).scrollIntoView({behavior: 'smooth'})
    };

    componentDidUpdate (prevProps, prevState, snapshot) {
      document.getElementById(this.props.message.sid).scrollIntoView({behavior: 'smooth'})
    }

    render = () => {
      const m = this.props.message
      const isIncoming = this.props.direction === 'incoming'
      const name = this.props.displayNames[this.props.direction]

      return <li id={m.sid} className='clearfix'>
        <div className={isIncoming ? 'message-data align-right' : 'message-data'}>
          <span className='message-data-time' >{m.timestamp.toLocaleString()}</span> &nbsp; &nbsp;
          <span className='message-data-name' >{name}</span> <i className='fa fa-circle me' />

        </div>
        <div className={isIncoming ? 'message other-message float-right' : 'message my-message'}>
          {m.body}
        </div>
      </li>
    }
}

export default MessageBubble
