import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ChatMessages from './ChatMessages'
import { Button } from 'reactstrap'
import Avatar from '../Avatar'
import LoadingPlaceHolder from '../LoadingPlaceHolder'

class ChatChannel extends Component {
  constructor (props) {
    super(props)
    this.state = {
      newMessage: '',
      channelProxy: props.channelProxy,
      messages: [],
      loadingState: 'initializing',
      boundChannels: new Set()
    }
  }

  loadMessagesFor = (thisChannel) => {
    if (this.state.channelProxy === thisChannel) {
      thisChannel.getMessages()
        .then(messagePaginator => {
          if (this.state.channelProxy === thisChannel) {
            this.setState({ messages: messagePaginator.items, loadingState: 'ready' })
          }
        })
        .catch(err => {
          console.error("Couldn't fetch messages", err)
          this.setState({ loadingState: 'failed' })
        })
    }
  };

  componentDidMount = () => {
    if (this.state.channelProxy) {
      this.loadMessagesFor(this.state.channelProxy)

      if (!this.state.boundChannels.has(this.state.channelProxy)) {
        let newChannel = this.state.channelProxy
        newChannel.on('messageAdded', m => this.messageAdded(m, newChannel))
        this.setState({boundChannels: new Set([...this.state.boundChannels, newChannel])})
      }
    }
  }

  componentDidUpdate = (oldProps, oldState) => {
    if (this.state.channelProxy !== oldState.channelProxy) {
      this.loadMessagesFor(this.state.channelProxy)

      if (!this.state.boundChannels.has(this.state.channelProxy)) {
        let newChannel = this.state.channelProxy
        newChannel.on('messageAdded', m => this.messageAdded(m, newChannel))
        this.setState({boundChannels: new Set([...this.state.boundChannels, newChannel])})
      }
    }
  };

  static getDerivedStateFromProps (newProps, oldState) {
    let logic = (oldState.loadingState === 'initializing') || oldState.channelProxy !== newProps.channelProxy
    if (logic) {
      return { loadingState: 'loading messages', channelProxy: newProps.channelProxy }
    } else {
      return null
    }
  }

  messageAdded = (message, targetChannel) => {
    if (targetChannel === this.state.channelProxy) {
      this.setState((prevState, props) => ({
        messages: [...prevState.messages, message]
      }))
    }
  };

  onMessageChanged = event => {
    this.setState({ newMessage: event.target.value })
  };

  sendMessage = event => {
    event.preventDefault()
    if (!this.state.newMessage) return
    const message = this.state.newMessage
    this.setState({ newMessage: '' })
    this.state.channelProxy.sendMessage(message)
  };

  render = () => {
    const loading = ['ready', 'failed'].indexOf(this.state.loadingState) === -1
    return (
      <div className='chat-channel-container'>
        <div className='chat'>
          <div className='chat-header clearfix'>
            <Avatar size={50} src={this.props.clientObj.photoUrl} />
            <div className='chat-about'>
              <div className='chat-with'>{`Chat with ${this.props.clientObj.firstName} ${this.props.clientObj.lastName}`}</div>
              <div className='chat-num-messages'>{ loading ? 'loading messages...' : `${this.state.messages.length} messages`}</div>
            </div>
          </div>
          <div className='chat-history'>
            { loading && <LoadingPlaceHolder /> }
            { this.state.loadingState === 'failed' && (
              <div className='empty-history'>
                <p>Failed to load messages, please try again</p>
              </div>
            )}
            { !loading && this.state.loadingState !== 'failed' && <ChatMessages
              identity={this.props.myIdentity}
              messages={this.state.messages}
              displayNames={{
                incoming: `${this.props.clientObj.firstName} ${this.props.clientObj.lastName}`,
                outgoing: `${this.props.managerObj.firstName} ${this.props.managerObj.lastName}`
              }} />
            }
          </div>
          <div className='chat-message clearfix'>
            <textarea
              placeholder={'Type your message here...'}
              type={'text'}
              name={'message'}
              autoComplete={'off'}
              disabled={this.state.loadingState !== 'ready'}
              onChange={this.onMessageChanged}
              value={this.state.newMessage}
            />
            <Button onClick={this.sendMessage}>Send</Button>
          </div>
        </div>
      </div>
    )
  }
}

ChatChannel.propTypes = {
  clientObj: PropTypes.object.isRequired,
  managerObj: PropTypes.object.isRequired,
  myIdentity: PropTypes.string.isRequired
}

export default ChatChannel
