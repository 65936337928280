import React from 'react'
import PropTypes from 'prop-types'
import { pure, compose } from 'recompose'
import {
  NavLink,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Input,
  Button,
  Label,
  ModalFooter } from 'reactstrap'
import { MdPrint } from 'react-icons/lib/md'
import { DARK_BLUE_GREY } from 'constants/colors'
import * as textConstants from 'constants/text'
import { PrintReport } from './Card'
import DatePicker from 'react-datepicker'
import * as moment from 'moment-timezone'

class PrintAttendanceModalComponent extends React.Component {
  state = {
    showModal: false,
    startDate: moment(this.props.client.createdAt).toDate(),
    endDate: (() => {
      let tmwUTC = new Date()
      tmwUTC.setDate(tmwUTC.getUTCDate() + 1)
      return moment(tmwUTC).toDate()
    })()
  }

  toggle = () => {
    return this.setState({
      showModal: !this.state.showModal
    })
  }

  changeStart = date => {
    this.setState({
      startDate: date
    })
  }

  changeEnd = date => {
    this.setState({
      endDate: date
    })
  }

  render () {
    const { client } = this.props
    const start = moment(client.createdAt).toDate()
    let tmwUTC = new Date()
    tmwUTC.setDate(tmwUTC.getUTCDate() + 1)
    const end = moment(tmwUTC).toDate()
    return (
      <div>
        <NavLink style={{'cursor': 'pointer'}} className='p-0' onClick={() => this.setState({showModal: true})}>
          <MdPrint
            size='30'
            fill={DARK_BLUE_GREY}
          /><small className='pl-1'>{textConstants.PRINT_REPORT}</small>
        </NavLink>
        <Modal
          isOpen={this.state.showModal}
          toggle={this.toggle}
          className={this.props.className}>
          <ModalHeader className='text-center text-white' toggle={this.toggle}>
            Print discharge report
          </ModalHeader>
          <ModalBody>
            <FormGroup>
              <Label>Start report</Label>
              <DatePicker
                onChange={this.changeStart}
                customInput={(<Input />)}
                selected={this.state.startDate}
                minDate={start}
                maxDate={end}
              />
            </FormGroup>
            <FormGroup>
              <Label>End report</Label>
              <DatePicker
                onChange={this.changeEnd}
                customInput={(<Input />)}
                selected={this.state.endDate}
                minDate={start}
                maxDate={end}
              />
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button color='secondary' onClick={this.toggle}>cancel</Button>
            <PrintReport
              authUser={this.props.authUser}
              client={this.props.client}
              roleState={this.props.roleState}
              start={this.state.startDate}
              end={this.state.endDate}
            />
          </ModalFooter>
        </Modal>
      </div>
    )
  }
}

PrintAttendanceModalComponent.propTypes = {
  authUser: PropTypes.object.isRequired,
  className: PropTypes.string
}

PrintAttendanceModalComponent.defaultProps = {
  className: 'tbv-printreport-modal'
}

const PrintAttendanceModal = compose(pure)(PrintAttendanceModalComponent)

export default PrintAttendanceModal
