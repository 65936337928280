// AttendanceStatusDropDown.js  - for updating client attendance
import React from 'react'

import { TBVDropDown } from '../TBVForm'

import { Field } from 'formik'

import { AttendanceStatuses, AttendanceStatusMsg } from 'constants/attendance.js'

const genStatusItems = () => {
  let items = {}
  Object.values(AttendanceStatuses).forEach(statusVal => {
    items[statusVal] = AttendanceStatusMsg(statusVal)
  })
  return items
}

const AttendanceStatusDropDown = props => (
  <Field
    component={TBVDropDown}
    {...props}
  />
)

AttendanceStatusDropDown.defaultProps = {
  props: {},
  title: 'e.g Status',
  items: genStatusItems()
}

export default AttendanceStatusDropDown
