// ClientWelcomePage.js - client welcome page

import React from 'react'

import { Col, Row } from 'reactstrap'

import Page from 'components/Page'
import { ClientWelcome } from 'components/Welcome'

import { CLIENT_PROFILE } from 'constants/routes.js'
import { IOS_APP_LINK } from 'constants/text.js'

import withAuthorization from 'components/Session/withAuthorization'

const ProfileLink = () => (
  <a
    className='link text-darkBlueGrey text-center'
    href={CLIENT_PROFILE}>
    <u>Go to Profile</u>
  </a>
)

class ClientWelcomePage extends React.Component {
  handleProfileClick = (e) => {
    e.preventDefault()
    window.open(IOS_APP_LINK, '_blank')
  }

  render () {
    return (
      <Page
        className='WelcomePage'>
        <Row className='tbv-signup'>
          <Col>
            <ClientWelcome onProfileClick={this.handleProfileClick} />
          </Col>
        </Row>
        <Row>
          <Col className='text-center'>
            <ProfileLink />
          </Col>
        </Row>
      </Page>
    )
  }
}

const authCondition = (authUser) => !!authUser

export default withAuthorization(authCondition)(ClientWelcomePage)
