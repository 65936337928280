// MonthsDropwDown.js - dropdown of months

import React from 'react'

import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap'

import moment from 'moment'

const NUM_MONTHS = 6

const genMonthItems = (numMonths) => {
  return Array(numMonths).fill().map((_, idx) => {
    const monthStart = moment().subtract(idx, 'months')
    return { title: monthStart.startOf('month').format('MMM YYYY'),
      name: monthStart.format('MMMM'),
      startObj: monthStart.startOf('month'),
      start: monthStart.startOf('month').format('MM/DD/YYYY'),
      endObj: monthStart.endOf('month'),
      end: monthStart.endOf('month').format('MM/DD/YYYY')
    }
  })
}

export const monthItems = genMonthItems(NUM_MONTHS)

export const MonthsDropDown = ({dropdownOpen, toggle, onItemClick, title}) => (
  <Dropdown isOpen={dropdownOpen} toggle={toggle} className='tbv-filter'>
    <DropdownToggle caret><span>{title}</span></DropdownToggle>
    <DropdownMenu>
      {monthItems.map(item => (
        <DropdownItem
          key={item.title}
          onClick={() => onItemClick(item)}
          value={item}>{item.title}</DropdownItem>
      ))}
    </DropdownMenu>
  </Dropdown>
)
