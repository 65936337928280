// StateDropDown.js - duration unit dropdown
import React from 'react'

import { TBVDropDown } from '../../TBVForm'

import { Field } from 'formik'

import { MANAGERS_PLACEHOLDER } from 'constants/text.js'

import { withAvailableManagers } from '../../Session'

import { genFullName } from 'utils/user.js'

const generateManagerItems = (availableManagers) => {
  let items = {}
  availableManagers.forEach(manager => {
    items[manager.id] = genFullName(manager.firstName, manager.lastName)
  })
  return items
}

const ManagersDropDown = (props) => {
  const { availableManagers, name, title, isOpenId } = props
  return (
    <Field
      component={TBVDropDown}
      items={generateManagerItems(availableManagers)}
      name={name}
      title={title}
      isOpenId={isOpenId}
    />
  )
}

ManagersDropDown.defaultProps = {
  title: MANAGERS_PLACEHOLDER
}

export default withAvailableManagers(ManagersDropDown)
