import React from 'react'

import { Card, CardBody } from 'reactstrap'

import classNames from 'classnames'

class TBVCard extends React.Component {
  render () {
    const { children, className, ...restProps } = this.props
    const classes = classNames('tbv-card tbv-card-short text-center', className)

    return (
      <Card className={classes} {...restProps}>
        <CardBody className='d-flex flex-column justify-content-center align-items-center'>
          {children}
        </CardBody>
      </Card>
    )
  }
}

TBVCard.defaultProps = {
  className: 'border-white'
}

export default TBVCard
