// Password section

import React from 'react'

import PropTypes from 'prop-types'

import { ValidationFields, TBVFormField } from 'components/TBVForm'

import * as Yup from 'yup'

export const PasswordSchema = Yup.object().shape({
  password: ValidationFields.PASSWORD
})

export const PasswordPropsMap = {
  password: ''
}

export const PasswordSection = (props) => {
  const {
    passwordId,
    passwordLabel,
    passwordInputProps
  } = props

  return (
    <React.Fragment>
      <TBVFormField className='required' label={passwordLabel} id={passwordId} {...passwordInputProps} />
    </React.Fragment>
  )
}

PasswordSection.propTypes = {
  passwordId: PropTypes.string,
  passwordLabel: PropTypes.string,
  passwordInputProps: PropTypes.object
}

PasswordSection.defaultProps = {
  passwordId: 'password',
  passwordLabel: 'Enter a password',
  passwordInputProps: {
    type: 'password',
    placeholder: ''
  }
}
