// utils/meetings.js - handles required meetings calcs

import moment from 'moment'
import Log from './logging.js'
export const MEETING_FREQ_WEEKLY = 'WEEKLY'
export const MEETING_FREQ_MONTHLY = 'MONTHLY'

export const REQ_MEETINGS_ON_TARGET = 'ON_TARGET'
export const REQ_MEETINGS_BEHIND = 'BEHIND'
export const REQ_MEETINGS_EXCEEDING = 'EXCEEDING'

export const getMeetingPace = (attended, required, endDate = null) => {
  const meetingsLeft = required - attended
  let daysLeft = 0

  // get daysLeft from endDate - today
  if (endDate) {
    daysLeft = moment(endDate).diff(moment(), 'days')
  }
  Log.info(endDate)
  Log.info('days left:' + daysLeft)
  if (meetingsLeft < 0) {
    return REQ_MEETINGS_EXCEEDING
  } else if (meetingsLeft === required || meetingsLeft > daysLeft) {
    return REQ_MEETINGS_BEHIND
  } else {
    return REQ_MEETINGS_ON_TARGET
  }
}

export const getMeetingPaceCounts = (clients, endDate = null) => {
  let paceStats = {}
  paceStats[REQ_MEETINGS_BEHIND] = 0
  paceStats[REQ_MEETINGS_EXCEEDING] = 0
  paceStats[REQ_MEETINGS_ON_TARGET] = 0

  // sum up meeting pace stats
  clients.forEach((client) => {
    let pace = getMeetingPace(client.meetingsAttended, client.meetingsRequiredPerMonth, endDate)
    paceStats[pace] += 1
  })
  return paceStats
}
