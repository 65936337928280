// CreateOrganizationMeetingForm.js - form for manager to add org meeting

import React from 'react'
import { graphql } from 'react-apollo'
import PropTypes from 'prop-types'
import { mutations, utils, queries } from '../../graphql'
import { compose, pure } from 'recompose'
import 'react-datepicker/dist/react-datepicker.css'
import { withRouter } from 'react-router'
import MeetingForm from '../MeetingForm'

const CreateOrganizationMeetingFormComponent = (props) => {
  const handleSubmit = async (values) => {
    await props.createOrgMeeting({
      variables: {
        input: values
      },
      context: utils.generateAuthHeader(props.authUser.token),
      update: (store, {data: {createOrgMeeting}}) => {
        const data = store.readQuery({query: queries.authedUserQuery})
        data.authedUser.manager.organization.meetings.push(createOrgMeeting)
        store.writeQuery({query: queries.authedUserQuery, data: data})
      }
    })
    props.history.push('/myorganization')
  }
  return (
    <MeetingForm handleSubmit={handleSubmit} />
  )
}

CreateOrganizationMeetingFormComponent.propTypes = {
  authUser: PropTypes.object.isRequired,
  manager: PropTypes.object.isRequired
}

const CreateOrganizationMeetingForm = compose(
  withRouter,
  graphql(mutations.CREATE_ORG_MEETING, {name: 'createOrgMeeting'}),
  pure
)(CreateOrganizationMeetingFormComponent)

export default CreateOrganizationMeetingForm
