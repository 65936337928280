// Requreid Meetings section - for client signup

import React from 'react'

import PropTypes from 'prop-types'

import { FormGroup, Label } from 'reactstrap'

import { ValidationFields, TBVFormField } from 'components/TBVForm'

import MeetingsUnitDropDown from './MeetingsUnitDropDown.js'

import * as Yup from 'yup'

import { REQ_MEETINGS_LABEL } from 'constants/text.js'

export const RequiredMeetingsSchema = Yup.object().shape({
  reqMeetings: ValidationFields.REQUIRED_MEETINGS,
  reqMeetingsUnit: ValidationFields.REQUIRED_MEETINGS_FREQ
})

export const RequiredMeetingsPropsMap = {
  reqMeetings: '',
  reqMeetingsUnit: '',
  reqMeetingsUnitIsOpen: false
}

export const RequiredMeetingsSection = (props) => {
  const {
    reqMeetingsId,
    reqMeetingsLabel,
    reqMeetingsInputProps,
    reqMeetingsUnitId,
    reqMeetingsUnitIsOpen,
    ...restProps
  } = props

  return (
    <React.Fragment>

      <div className='form-row' {...restProps}>
        <TBVFormField className='col-md-6 required' label={reqMeetingsLabel} id={reqMeetingsId} {...reqMeetingsInputProps} />
        <FormGroup className='col-md-6'>
          <Label for='reqMeetingsUnitLabel'>&nbsp;</Label>
          <MeetingsUnitDropDown name={reqMeetingsUnitId} isOpenId={reqMeetingsUnitIsOpen} />
        </FormGroup>
      </div>

    </React.Fragment>
  )
}

RequiredMeetingsSection.propTypes = {

  reqMeetingsId: PropTypes.string,
  reqMeetingsLabel: PropTypes.string,
  reqMeetingsInputProps: PropTypes.object,

  reqMeetingsUnitId: PropTypes.string,
  reqMeetingsUnitIsOpen: PropTypes.string
}

RequiredMeetingsSection.defaultProps = {
  reqMeetingsId: 'reqMeetings',
  reqMeetingsLabel: REQ_MEETINGS_LABEL,
  reqMeetingsInputProps: {
    type: 'integer',
    placeholder: 'e.g. 15'
  },

  reqMeetingsUnitId: 'reqMeetingsUnit',
  reqMeetingsUnitIsOpen: 'reqMeetingsUnitIsOpen'
}
