// AdminMeetingsPage.js

import React from 'react'
import { compose, pure } from 'recompose'
import { graphql } from 'react-apollo'
import Log from 'utils/logging.js'
import { Col, Row } from 'reactstrap'
import Page from '../components/Page'
import { Meetings } from '../components/Admin/Meetings'
import { withAdmin } from '../components/Session'
import { queries, utils } from '../graphql'
import { withLoading } from '../hocs/withLoading'

class AdminMeetingsPage extends React.Component {
  render () {
    Log.info('admin meetings')
    Log.info(this.props)
    const { admin, authUser, data: { pendingMeetings } } = this.props
    Log.info(admin)
    return (
      <Page className='DashboardPage'>
        <Row>
          <Col>
            <Meetings authUser={authUser} admin={admin} pending={pendingMeetings} />
          </Col>
        </Row>
      </Page>
    )
  }
}

export default compose(
  withAdmin,
  graphql(queries.pendingMeetings, {
    options: props => {
      return {
        context: utils.generateAuthHeader(props.authUser.token)
      }
    }
  }),
  withLoading,
  pure
)(AdminMeetingsPage)
