// Menu.js - admin menu

import React from 'react'

import {
  MdDomain,
  MdPinDrop,
  MdPeople
} from 'react-icons/lib/md'

import { NavLink } from 'react-router-dom'

import {
  Nav,
  NavItem,
  NavLink as BSNavLink
} from 'reactstrap'

import bn from 'utils/bemnames'
import classNames from 'classnames'

import TBVCard from '../Card/TBVCard'

import { ADMIN_HOME, ADMIN_MEETINGS, ADMIN_ATTENDANCE } from 'constants/routes.js'
import { ORG_MENU } from 'constants/text.js'

const navItems = [
  { to: ADMIN_HOME, name: ORG_MENU, exact: false, Icon: MdDomain },
  { to: ADMIN_MEETINGS, name: 'Meetings', exact: false, Icon: MdPeople },
  { to: ADMIN_ATTENDANCE, name: 'Location Review', exact: false, Icon: MdPinDrop }
]

const bem = bn.create('menu')

class AdminMenu extends React.Component {
  render () {
    return (
      <TBVCard className='border-white tbv-admin-menu text-left align-items-left mb-3 mx-3'>
        <div className={classNames(bem.e('content'), 'text-left align-items-left w-100')}>
          <Nav>
            {navItems.map(({ to, name, exact, Icon }, index) => (
              <NavItem key={index} className={bem.e('nav-item')}>
                <BSNavLink
                  id={`navItem-${name}-${index}`}
                  className='text-capitalize pl-0'
                  tag={NavLink}
                  to={to}
                  activeClassName='active'
                  exact={exact}>
                  <Icon className={bem.e('nav-item-icon')} size='24px' />
                  <span className='font-weight-bold pl-3'>{name}</span>
                </BSNavLink>
              </NavItem>
            ))}
          </Nav>
        </div>
      </TBVCard>
    )
  }
}

export default AdminMenu
