// LogInForm.js - form for login

import React from 'react'
import Log from 'utils/logging.js'

import PropTypes from 'prop-types'
import { auth } from '../firebase'

import * as textConstants from 'constants/text.js'

import { ValidationFields, TBVBaseForm } from 'components/TBVForm'

import TBVButton from './TBVButton.js'

import { withFormik } from 'formik'
import * as Yup from 'yup'

const formikEnhancer = withFormik({
  validationSchema: Yup.object().shape({
    password: ValidationFields.PASSWORD,
    confirmPassword: ValidationFields.PASSWORD
      .oneOf([Yup.ref('password'), null], 'Passwords don\'t match.')
      .required('Confirm Password is required.')
  }),
  mapPropsToValues: props => ({
    password: '',
    confirmPassword: ''
  }),
  handleSubmit: (values, {setSubmitting, setErrors, setStatus, resetForm, props}) => {
    const payload = {
      ...values
    }

    auth.doPasswordUpdate(payload.password)
      .then(() => {
        Log.info('password updated')
        resetForm()
        setStatus({success: true})
        setSubmitting(false)
      })
      .catch(error => {
        Log.info('failed password update')
        Log.info(error)
        setErrors({submit: error.message})
      })
  },
  displayName: 'UpdatePasswordForm'
})

const UpdatePasswordForm = props => {
  const {
    errors,
    status,
    isSubmitting,
    handleSubmit,
    passwordLabel,
    passwordInputProps,
    confirmPasswordLabel,
    confirmPasswordInputProps,
    children
  } = props

  const fields = [
    {id: 'password', label: passwordLabel, className: 'required', ...passwordInputProps},
    {id: 'confirmPassword', label: confirmPasswordLabel, className: 'required', ...confirmPasswordInputProps}
  ]

  return (
    <TBVBaseForm className='tbv-update-password-form' fields={fields} onSubmit={handleSubmit}>
      <div className='text-center pt-2'>
        <TBVButton block title={textConstants.UPDATE_PASSWORD} disabled={isSubmitting} />
        {errors.submit && <p className='text-danger pt-2'>{errors.submit}</p>}
        {status && status.success && <p className='text-success pt-2'>{'Password Updated'}</p> }
      </div>
      {children}
    </TBVBaseForm>
  )
}

UpdatePasswordForm.propTypes = {
  passwordLabel: PropTypes.string,
  passwordInputProps: PropTypes.object,
  confirmPasswordLabel: PropTypes.string,
  confirmPasswordInputProps: PropTypes.object
}

UpdatePasswordForm.defaultProps = {
  passwordLabel: 'Password',
  passwordInputProps: {
    type: 'password',
    placeholder: 'Enter password'
  },
  confirmPasswordLabel: 'Confirm Password',
  confirmPasswordInputProps: {
    type: 'password',
    placeholder: 'Confirm your password'
  }
}

export default formikEnhancer(UpdatePasswordForm)
