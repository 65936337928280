import React from 'react'
import { Modal, Button, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Label, Input } from 'reactstrap'
import { withAvailableManagers } from '../Session'
import { compose, pure } from 'recompose'

class AddManagerToOrganizationComponent extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      selectedManagerId: ''
    }
  }

    getAvailableManagerItems = () => {
      return this.props.availableManagers.map(m => {
        return {
          value: m.id,
          text: `${m.firstName} ${m.lastName}`
        }
      })
    }

    onManagerChange = (e) => {
      this.setState({
        selectedManagerId: e.target.value
      })
    }

    onSubmit = () => {
      if (!this.state.selectedManagerId) {
        return
      }
      this.props.assignManager(this.state.selectedManagerId)
    }

    render () {
      return (
        <Modal
          isOpen={this.props.open}
          toggle={this.props.toggle}
        >
          <ModalHeader toggle={this.props.toggle}>Assign manager to Organization</ModalHeader>
          <ModalBody>
            <Form>
              <FormGroup>
                <Label for='managerSelect'>Select</Label>
                <Input type='select' placeholder='Select a manager' onChange={this.onManagerChange} name='managerSelect' id='managerSelect'>
                  <option value={''}>Select a manager</option>
                  {this.getAvailableManagerItems().map((o, i) => (
                    <option key={i} value={o.value}>{o.text}</option>
                  ))}
                </Input>
              </FormGroup>
            </Form>
          </ModalBody>
          <ModalFooter>
            <Button onClick={this.onSubmit} color='primary'>
                  Add manager
            </Button>
            <Button color='secondary' onClick={this.props.toggle}>
                  Cancel
            </Button>
          </ModalFooter>
        </Modal>
      )
    }
}

export const AddManagerToOrganization = compose(
  withAvailableManagers,
  pure
)(AddManagerToOrganizationComponent)
