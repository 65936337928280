// HomeLayout.js - home screen

import React from 'react'

import { Content, Header } from 'components/Layout'

class HomeLayout extends React.Component {
  render () {
    const { children } = this.props
    return (
      <main className='tbv-app'>
        <Content fluid>
          <Header />
          {children}
        </Content>
      </main>
    )
  }
}

export default HomeLayout
