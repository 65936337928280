// SignUpPage.js - sign up form

import React from 'react'

import { Card, Col, Row } from 'reactstrap'

import { getRoleTitle, getLogInPath } from 'utils/roles'
import { CLIENT_WELCOME, PAYMENT_CLIENT } from 'constants/routes'

import Page from 'components/Page'
import { InviteSignUpForm } from 'components/SignUp'
import Instructions from '../components/SignUp/Instructions'
import * as textConstants from '../constants/text'

const paymentOn = !!process.env.REACT_APP_PAYMENT_ACTIVE

class InviteSignUpPage extends React.Component {
  renderRoleTitle () {
    return getRoleTitle(this.props.roleState)
  }

  handleLogInClick = () => {
    this.props.history.push(getLogInPath(this.props.roleState))
  }

  handleSignedUp = () => {
    this.props.history.push(paymentOn ? PAYMENT_CLIENT : CLIENT_WELCOME)
  }
  render () {
    const clientInstructions = (
      <Col sm={4}>
        <Instructions
          title={textConstants.INSTRUCTIONS_TITLE_CLIENT}
          header={textConstants.INSTRUCTIONS_HEADER_CLIENT}
          subHeader={textConstants.INSTRUCTIONS_SUBHEADER_CLIENT}
        >
          <li>{textConstants.INSTRUCTIONS_FILL_OUT_FORM}</li>
          <li>{textConstants.INSTRUCTIONS_ADD_PAYMENT}</li>
          <li><a
            href='#mobile'
            target='_blank'
            title='TBV mobile app'
          >{textConstants.INSTRUCTIONS_DOWNLOAD}</a></li>
        </Instructions>
      </Col>
    )

    return (
      <Page className='SignUpPage pt-5'>
        <Row>
          {clientInstructions}
          <Col>
            <Row className='tbv-signup'>
              <Col>
                <Card>
                  <InviteSignUpForm
                    roleState={this.props.roleState}
                    onLogInClick={this.handleLogInClick}
                    onFormSubmit={this.handleSignedUp}
                    token={this.props.match.params.token}
                  />
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </Page>
    )
  }
}

export default InviteSignUpPage
