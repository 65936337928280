// mobile app verification values
export const FINGERPRINT = 'FINGERPRINT'
export const FACE = 'FACE'
export const IRIS = 'IRIS'
export const HAND = 'HAND'
export const TOUCH = 'TOUCH'

// react app localized biometric value types
export const BIO_VERIFY_TOUCH = 'Touch'
export const BIO_VERIFY_FACE = 'Face'
export const BIO_VERIFY_EYE = 'Eye'

// map mobile -> local biometric value types
// using all upper for consistency
export const BIO_VERIFY_TYPE = (bioMetricType) => {
  switch (bioMetricType.toUpperCase()) {
    case FINGERPRINT:
      return BIO_VERIFY_TOUCH
    case HAND:
      return BIO_VERIFY_TOUCH
    case FACE:
      return BIO_VERIFY_FACE
    case IRIS:
      return BIO_VERIFY_EYE
    case TOUCH:
      return BIO_VERIFY_TOUCH
    default:
      return bioMetricType.toUpperCase()
  }
}

// Attendance Invalid Reasons from graphql
const AttendanceInvalidReasons = {
  LEFT_EARLY: 'LEFT_EARLY',
  ARRIVED_LATE: 'ARRIVED_LATE',
  NO_BIOMETRICS_CHECKIN: 'NO_BIOMETRICS_CHECKIN',
  NO_BIOMETRICS_CHECKOUT: 'NO_BIOMETRICS_CHECKOUT',
  NO_LOCATION_CHECKIN: 'NO_LOCATION_CHECKIN',
  NO_LOCATION_CHECKOUT: 'NO_LOCATION_CHECKOUT',
  DURATION_TOO_SHORT: 'DURATION_TOO_SHORT',
  INVALID_LOCATION_CHECKIN: 'INVALID_LOCATION_CHECKIN',
  INVALID_LOCATION_CHECKOUT: 'INVALID_LOCATION_CHECKOUT',
  NO_SUCH_MEETING: 'NO_SUCH_MEETING',
  MEETING_PENDING: 'MEETING_PENDING'
}

// App Local Display Attendance Invalid Reasons
export const LEFT_EARLY = 'Left Early'
export const ARRIVED_LATE = 'Arrived Late'
export const NO_BIOMETRICS_CHECKOUT = 'No Biometrics check-out'
export const NO_BIOMETRICS_CHECKIN = 'No Biometrics check-in'
export const NO_LOCATION_CHECKIN = 'No location check-in'
export const NO_LOCATION_CHECKOUT = 'No location check-out'
export const DURATION_TOO_SHORT = 'Stay too short'
export const INVALID_LOCATION_CHECKIN = 'Too far check-in'
export const INVALID_LOCATION_CHECKOUT = 'Too far check-out'
export const NO_SUCH_MEETING = 'This meeting does not exist'
export const MEETING_PENDING = 'This meeting is still pending verification'

// Map grapql status -> app
export const AttendanceReasonMsg = (reason) => {
  switch (reason) {
    case AttendanceInvalidReasons.LEFT_EARLY:
      return LEFT_EARLY
    case AttendanceInvalidReasons.ARRIVED_LATE:
      return ARRIVED_LATE
    case AttendanceInvalidReasons.NO_BIOMETRICS_CHECKIN:
      return NO_BIOMETRICS_CHECKIN
    case AttendanceInvalidReasons.NO_BIOMETRICS_CHECKOUT:
      return NO_BIOMETRICS_CHECKOUT
    case AttendanceInvalidReasons.NO_LOCATION_CHECKIN:
      return NO_LOCATION_CHECKIN
    case AttendanceInvalidReasons.NO_LOCATION_CHECKOUT:
      return NO_LOCATION_CHECKOUT
    case AttendanceInvalidReasons.DURATION_TOO_SHORT:
      return DURATION_TOO_SHORT
    case AttendanceInvalidReasons.INVALID_LOCATION_CHECKIN:
      return INVALID_LOCATION_CHECKIN
    case AttendanceInvalidReasons.INVALID_LOCATION_CHECKOUT:
      return INVALID_LOCATION_CHECKOUT
    default:
      return reason
  }
}

// graphql attendance statuses
export const AttendanceStatuses = {
  VERIFIED: 'VERIFIED',
  UNVERIFIED: 'UNVERIFIED',
  PENDING: 'PENDING'
}

export const ATTENDANCE_VERIFIED = 'Verified'
export const ATTENDANCE_UNVERIFIED = 'Unverified'
export const ATTENDANCE_PENDING = 'Pending'
export const ATTENDANCE_UNKNOWN = 'Unknown'

// Map grapql status -> app
export const AttendanceStatusMsg = (status) => {
  switch (status) {
    case AttendanceStatuses.VERIFIED:
      return ATTENDANCE_VERIFIED
    case AttendanceStatuses.UNVERIFIED:
      return ATTENDANCE_UNVERIFIED
    case AttendanceStatuses.PENDING:
      return ATTENDANCE_PENDING
    default:
      return ATTENDANCE_UNKNOWN
  }
}

export const PendingStatuses = [AttendanceStatuses.PENDING]
