import React from 'react'
import { graphql } from 'react-apollo'
import PropTypes from 'prop-types'
import { mutations, utils, queries } from '../../graphql'
import { compose, pure } from 'recompose'
import 'react-datepicker/dist/react-datepicker.css'
import { withRouter } from 'react-router'
import MeetingForm from '../MeetingForm'
import { withLoading } from '../../hocs/withLoading'

const ApproveNewMeetingComponent = (props) => {
  const handleSubmit = async (values) => {
    await props.approveMeeting({
      variables: {
        input: {
          id: props.match.params.id
        }
      },
      context: utils.generateAuthHeader(props.authUser.token)
    })
    props.history.push('/meetings')
  }
  const deny = async () => {
    await props.denyMeeting({
      variables: {
        input: {
          id: props.match.params.id
        }
      },
      context: utils.generateAuthHeader(props.authUser.token)
    })
    props.history.push('/meetings')
  }
  return (
    <MeetingForm isReview handleSubmit={handleSubmit} initialValues={props.data.meeting} deny={deny} />
  )
}

ApproveNewMeetingComponent.propTypes = {
  authUser: PropTypes.object.isRequired,
  admin: PropTypes.object.isRequired
}

const ApproveNewMeeting = compose(
  withRouter,
  graphql(queries.getMeeting, {
    options: props => ({
      context: utils.generateAuthHeader(props.authUser.token),
      variables: {
        input: {
          id: props.match.params.id
        }
      }
    })
  }),
  graphql(mutations.APPROVE_MEETING, {name: 'approveMeeting'}),
  graphql(mutations.DENY_MEETING, {name: 'denyMeeting'}),
  withLoading,
  pure
)(ApproveNewMeetingComponent)

export default ApproveNewMeeting
