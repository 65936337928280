// ClientDetailPage.js - client detail page for manager
import React from 'react'
import Page from 'components/Page'
import { withManager } from '../components/Session'
import ClientConversation from '../components/Dashboard/ClientConversation'

class ClientDetailPage extends React.Component {
  render () {
    const { authUser, manager, match } = this.props

    return (
      <Page className='ClientConversation'>
        <ClientConversation
          authUser={authUser}
          manager={manager}
          clientObj={manager.clients.find(c => c.id === match.params.id)}
        />
      </Page>
    )
  }
}

export default withManager(ClientDetailPage)
