// SignUpPage.js - sign up form

import React from 'react'

import { Card, Col, Row } from 'reactstrap'

import Roles, { getRoleTitle, getLogInPath } from 'utils/roles'
import { MANAGER_WELCOME, CLIENT_WELCOME, PAYMENT_CLIENT } from 'constants/routes'
import Page from 'components/Page'
import { ClientSignUpForm, ManagerSignUpForm } from 'components/SignUp'
import Instructions from 'components/SignUp/Instructions'
import DownloadLink from 'components/DownloadLink.js'
import * as textConstants from '../constants/text'

const paymentOn = !!process.env.REACT_APP_PAYMENT_ACTIVE

class SignUpPage extends React.Component {
  renderRoleTitle () {
    return getRoleTitle(this.props.roleState)
  }

  handleLogInClick = () => {
    this.props.history.push(getLogInPath(this.props.roleState))
  }

  handleSignedUp = () => {
    if (this.props.roleState === Roles.CLIENT) {
      this.props.history.push(paymentOn ? PAYMENT_CLIENT : CLIENT_WELCOME)
    } else {
      this.props.history.push(MANAGER_WELCOME)
    }
  }

  render () {
    const isClient = (this.props.roleState === Roles.CLIENT)
    const Form = isClient ? ClientSignUpForm : ManagerSignUpForm

    return (
      <Page className='SignUpPage pt-5'>
        <Row>
          {isClient ? (
            <Col sm={4}>
              <Instructions
                title={textConstants.INSTRUCTIONS_TITLE_CLIENT}
                header={textConstants.INSTRUCTIONS_HEADER_CLIENT}
                subHeader={textConstants.INSTRUCTIONS_SUBHEADER_CLIENT}
              >
                <li>{textConstants.INSTRUCTIONS_FILL_OUT_FORM}</li>
                {/* <li>{textConstants.INSTRUCTIONS_ADD_PAYMENT}</li> */}
                <li><DownloadLink>{textConstants.INSTRUCTIONS_DOWNLOAD}</DownloadLink></li>
              </Instructions>
            </Col>
          ) : (
            <Col sm={4}>
              <Instructions
                title={textConstants.INSTRUCTIONS_TITLE_MANAGER}
                header={textConstants.INSTRUCTIONS_HEADER_MANAGER}
                subHeader={textConstants.INSTRUCTIONS_SUBHEADER_MANAGER}
              >
                <li>{textConstants.INSTRUCTIONS_FILL_OUT_FORM}</li>
                <li>{textConstants.INSTRUCTIONS_ADD_CLIENTS}</li>
              </Instructions>
            </Col>
          )}
          <Col>
            <Row className='tbv-signup'>
              <Col>
                <Card>
                  <Form
                    roleState={this.props.roleState}
                    onLogInClick={this.handleLogInClick}
                    onFormSubmit={this.handleSignedUp}
                  />
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </Page>
    )
  }
}

export default SignUpPage
