// AddNoteForm.js - form for manager to add note

import React from 'react'
import Log from 'utils/logging.js'

import { graphql, compose } from 'react-apollo'

import PropTypes from 'prop-types'

import { withAuthentication } from 'components/Session'

import { Form } from 'reactstrap'

import { mutations, queries, utils } from '../graphql'

import { ADD_NOTE_SUCCESS, ADD_CLIENT_NOTE, DEFAULT_ERROR_MSG, ADD_NOTE_BTN } from 'constants/text.js'

import TBVButton from './TBVButton.js'

import { ValidationFields, TBVFormField } from 'components/TBVForm'

import { withFormik } from 'formik'
import * as Yup from 'yup'

const formikEnhancer = withFormik({
  validationSchema: Yup.object().shape({
    note: ValidationFields.MANAGER_NOTE
  }),
  mapPropsToValues: props => ({
    note: ''
  }),
  handleSubmit: async (values, {setSubmitting, setErrors, setStatus, resetForm, props}) => {
    const payload = {
      ...values
    }
    // graphql createManageNote call
    const variables = {
      clientId: props.clientId,
      note: payload.note
    }

    Log.info('createManageNote')
    Log.info(variables)

    try {
      const managerNote = await props.CreateManagerNote(
        { variables: variables,
          context: utils.generateAuthHeader(props.authUser.token),
          update: (store, {data: {createManagerNote}}) => {
            const data = store.readQuery({query: queries.managerNotesQuery, variables: {clientId: props.clientId}})
            data.managerNotes.unshift(createManagerNote)
            store.writeQuery({query: queries.managerNotesQuery, variables: {clientId: props.clientId}, data: data})
          }
        }
      )
      resetForm({note: ''})
      Log.info('TBV manager note created')
      Log.info(managerNote)
      setStatus({success: true})
      setSubmitting(false)
    } catch (error) {
      Log.info('failed graphQL create manager note')
      Log.error(error)
      setErrors({submit: DEFAULT_ERROR_MSG})
      setSubmitting(false)
    }
  },
  displayName: 'AddNoteForm'
})

const AddNoteForm = (props) => {
  const {
    errors,
    status,
    isSubmitting,
    handleSubmit,
    noteId,
    noteInputProps,
    children
  } = props

  return (
    <Form className='border-top tbv-add-note p-1 pl-5 pr-5' onSubmit={handleSubmit}>
      <TBVFormField id={noteId} {...noteInputProps} />
      <div className='text-center pt-1 pb-3'>
        <TBVButton block title={ADD_NOTE_BTN} disabled={isSubmitting} />
        {errors.submit && <p className='text-danger pt-2'>{errors.submit}</p>}
        {status && status.success && <p className='text-success pt-2'>{ADD_NOTE_SUCCESS}</p>}
      </div>
      {children}
    </Form>
  )
}

AddNoteForm.propTypes = {
  clientId: PropTypes.string.isRequired,
  noteId: PropTypes.string,
  noteLabel: PropTypes.string,
  noteInputProps: PropTypes.object
}

AddNoteForm.defaultProps = {
  noteId: 'note',
  noteLabel: 'Note',
  noteInputProps: {
    type: 'textarea',
    placeholder: ADD_CLIENT_NOTE
  }
}

const AddNoteFormGraphQL = compose(
  graphql(mutations.CREATE_MANAGER_NOTE, {name: 'CreateManagerNote'})
)(formikEnhancer(AddNoteForm))

export default withAuthentication(AddNoteFormGraphQL)
