// utils/dates.js
import moment from 'moment'

export const localDisplayTime = (utcDateTime, format = 'llll') => {
  if (utcDateTime) {
    return moment(utcDateTime).local().format(format)
  } else {
    return ''
  }
}

export const displayDurationMins = (durationMins) => {
  const duration = moment.duration({'minutes': durationMins})
  return `${duration.hours()}h ${duration.minutes()}m`
}
