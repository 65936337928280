import React from 'react'
import PropTypes from 'utils/propTypes'

import { Card } from 'reactstrap'

import Avatar from '../Avatar'

const ContactCard = ({
  children,
  avatarSize,
  user
}) => {
  const {
    photoUrl,
    lastName,
    firstName
  } = user

  return (
    <Card className='tbv-contact-card border-0 tbv-box-shadow pb-3 mb-3'>
      <div className='d-flex flex-column'>
        <Avatar className='position-absolute top-50 tbv-contact-card-image' src={photoUrl} size={avatarSize} />
        <h4 className='text-center font-weight-bold mb-3 mt-2'>{firstName + ' ' + lastName}</h4>
        {children}
      </div>
    </Card>
  )
}

ContactCard.propTypes = {
  avatar: PropTypes.string,
  avatarSize: PropTypes.number
}

ContactCard.defaultProps = {
  avatarSize: 105
}

export default ContactCard
