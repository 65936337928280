// withAvailableManagers.js - HOC for loading invite for client

import React from 'react'

import LoadingPlaceHolder from 'components/LoadingPlaceHolder'

import { Query } from 'react-apollo'
import { queries, utils } from '../../graphql'

import withAuthorization from './withAuthorization.js'

import { DEFAULT_ERROR_MSG } from 'constants/text.js'

const withAvailableManagers = (Component) => {
  class WithAvailableManagers extends React.Component {
    render () {
      const { authUser, children, ...props } = this.props
      const variables = {limit: 50, offset: 0}
      return (
        <Query query={queries.availableManagersQuery} variables={variables} context={utils.generateAuthHeader(authUser.token)}>
          {({ loading, error, data }) => {
            if (error) return <div>{DEFAULT_ERROR_MSG}</div>
            if (loading) return (<LoadingPlaceHolder />)
            return (<Component availableManagers={data.managers} {...props}>{children}</Component>)
          }}
        </Query>
      )
    }
  }

  const authCondition = (authUser) => !!authUser
  return withAuthorization(authCondition)(WithAvailableManagers)
}
export default withAvailableManagers
