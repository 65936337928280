// withInvite.js - HOC for loading invite for client

import React from 'react'
import Log from 'utils/logging.js'

import LoadingPlaceHolder from 'components/LoadingPlaceHolder'

import { Query } from 'react-apollo'
import { queries } from '../../graphql'

import { INVITE_ERROR_MSG } from 'constants/text.js'

const withManagerInvite = (Component) => {
  class WithManagerInvite extends React.Component {
    render () {
      const { token, children, ...props } = this.props
      Log.info(token)

      return (
        <Query query={queries.getManagerInviteQuery} variables={{token: token}}>
          {({ loading, error, data }) => {
            if (error) return <div>{INVITE_ERROR_MSG}</div>
            if (loading) return (<LoadingPlaceHolder />)
            return (<Component invite={data.getManagerInvite} {...props}>{children}</Component>)
          }}
        </Query>
      )
    }
  }

  return WithManagerInvite
}

export default withManagerInvite
