// PrivacyPage.js - home page

import React from 'react'

import { Col, Row, Card } from 'reactstrap'

import Page from 'components/Page'
import PrivacyPolicy from 'components/PrivacyPolicy.js'

class PrivacyPage extends React.Component {
  render () {
    return (
      <Page
        className='PrivacyPage'>
        <Row className='tbv-privacy'>
          <Col>
            <Card className='border-0 p-1 mb-3'>
              <PrivacyPolicy />
            </Card>
          </Col>
        </Row>
      </Page>
    )
  }
}
export default PrivacyPage
