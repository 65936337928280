// dashboard - manager dashboard component

import React from 'react'
import { Row, Col } from 'reactstrap'
import * as textConstants from 'constants/text'
import { ClientCard } from '../Card'
import { getStatusCounts } from 'utils/attendance'
import { getMeetingPace, REQ_MEETINGS_BEHIND, REQ_MEETINGS_EXCEEDING } from 'utils/meetings.js'
import { AttendanceStatuses } from 'constants/attendance.js'
import { CLIENT_STATUS } from 'constants/status'
import classNames from 'classnames'
import {SortDropdown, dropdownItems} from './SortDropdown'
import moment from 'moment'
import bn from 'utils/bemnames'
import verified from '../../assets/img/icons/verified.svg'

const bem = bn.create('clients')

const getCardPaceClass = (pace) => {
  switch (pace) {
    case REQ_MEETINGS_EXCEEDING:
      return 'border-exceeding'
    case REQ_MEETINGS_BEHIND:
      return 'border-behind'
    default:
      return 'border-on-target'
  }
}

const getCardPaceText = (pace) => {
  switch (pace) {
    case REQ_MEETINGS_EXCEEDING:
      return textConstants.MEETINGS_EXCEEDING
    case REQ_MEETINGS_BEHIND:
      return textConstants.MEETINGS_BEHIND
    default:
      return textConstants.MEETINGS_ON_TARGET
  }
}

const ClientCol = ({item, ...props}) => {
  // create child card
  const meetingsAttended = item.meetingsAttended
  const meetingsRequired = item.meetingsRequiredPerMonth
  const attendance = item.currentMonthAttendance
  const meetingPace = getMeetingPace(meetingsAttended, meetingsRequired, moment().endOf('month'))
  const paceText = getCardPaceText(meetingPace)
  const meetingsBehindText = ((meetingPace === REQ_MEETINGS_BEHIND) ? meetingsRequired - meetingsAttended : '')
  const statusCounts = getStatusCounts(attendance)

  return (
    <Col {...props}>
      <ClientCard
        avatarSize={50}
        key={item.id}
        title={item.firstName + ' ' + item.lastName}
        subtitle={''}
        text={paceText}
        subText={meetingsBehindText}
        className={classNames(getCardPaceClass(meetingPace), 'tbv-card-tall')}
        avatar={item.photoUrl}
        userId={item.id}
        statusCounts={statusCounts}
      />
    </Col>
  )
}

const createGrid = (items) => {
  return items.map((item, i) => <ClientCol key={i} xs={6} lg={3} item={item} />)
}

class Clients extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      dropdownOpen: false,
      sort: dropdownItems[0],
      filter: ''
    }
  }

  toggle = () => this.setState(prevState => ({ dropdownOpen: !prevState.dropdownOpen }))

  setFilter = (filter) => () => {
    this.setState({
      filter
    })
  }

  render () {
    const { onClientDetailClick, clients } = this.props
    const { sort, dropdownOpen, filter } = this.state
    const sortedClientsData = [...clients].sort(sort.sort)
    let clientsData
    if (filter) {
      if (filter === CLIENT_STATUS.INACTIVE) {
        clientsData = sortedClientsData.filter(c => c.status === CLIENT_STATUS.INACTIVE)
      } else {
        clientsData = sortedClientsData.filter(c => {
          return c.statue === CLIENT_STATUS.ACTIVE && c.currentMonthAttendance.some(a => a.validation.status === filter)
        })
      }
    } else {
      clientsData = sortedClientsData.filter(c => c.status === CLIENT_STATUS.ACTIVE)
    }
    return (
      <div className={bem.b()}>
        <Row className='tbv-sort pr-3 pb-3'>
          <Col className='w-100'>
            <div className='clients-filters float-left'>
              <div
                onClick={this.setFilter('')}
                className={`clients-filter ${!filter && 'active'}`}
              >
                All
              </div>
              <div
                onClick={this.setFilter(AttendanceStatuses.PENDING)}
                className={`clients-filter ml-5 ${filter === AttendanceStatuses.PENDING && 'active'}`}
              >
                Pending Meetings
              </div>
              <div
                onClick={this.setFilter(CLIENT_STATUS.INACTIVE)}
                className={`clients-filter ml-5 ${filter === CLIENT_STATUS.ACTIVE && 'active'}`}
              >
                Inactive
              </div>
            </div>
            <div className='float-right'>
              <SortDropdown
                title={sort.title}
                dropdownOpen={dropdownOpen}
                toggle={this.toggle}
                onItemClick={item => this.setState({sort: item})} />
            </div>
          </Col>
        </Row>
        <Row>
          {!!clientsData.length && createGrid(clientsData, onClientDetailClick)}
          {!clients.length && (
            <Col className='empty-clients-grid'>
              <h1 className='text-center font-weight-bold'>You have no clients yet</h1>
            </Col>
          )}
          {!!clients.length && !clientsData.length && (
            <Col className='empty-clients-grid'>
              <p className='text-center'>
                <img style={{width: 40}} alt='Check icon' src={verified} />
              </p>
              <h1 className='text-center font-weight-bold'>Great work, you're all caught up!</h1>
            </Col>
          )}
        </Row>
      </div>
    )
  }
}

export default Clients
