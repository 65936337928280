import React from 'react'
import Log from 'utils/logging.js'

import ManagerSignUp from './ManagerSignUp.js'
import { withManagerInvite } from 'components/Session'

const InviteManagerSignUp = (props) => {
  const {
    invite
  } = props

  const initialData = {
    firstName: invite.firstName || '',
    lastName: invite.lastName || '',
    email: invite.email || '',
    phoneNumber: invite.phone || ''
  }
  Log.info(initialData)
  Log.info(invite.token)
  Log.info('invite manager signup')
  return (
    <ManagerSignUp inviteToken={invite.token} initialData={initialData} {...props} />
  )
}

export default withManagerInvite(InviteManagerSignUp)
