// withAuth.js - HOC for auth

import React from 'react'
import Log from 'utils/logging.js'

import AuthUserContext from './AuthUserContext'
import { firebase } from '../../firebase'

import { utils } from '../../graphql'

const withAuthentication = (Component) =>
  class WithAuthentication extends React.Component {
    constructor (props) {
      super(props)

      this.state = {
        authUser: null
      }
    }

    componentDidMount () {
      this.auth = firebase.auth.onAuthStateChanged(authUser => {
        if (authUser) {
          authUser.getIdToken().then((token) => {
            authUser.token = token
            authUser.authHeader = utils.generateAuthHeader(authUser.token)
            this.setState({ authUser })
          }).catch((err) => Log.error(err))
        } else {
          this.setState({ authUser: null })
        }
      }).bind(this)
    }

    componentWillUnmount () {
      // Unsubscribe.
      if (this.auth) {
        this.auth()
      }
    }

    render () {
      const { authUser } = this.state
      const {...restProps} = this.props
      return (
        <AuthUserContext.Provider value={authUser}>
          <Component authUser={authUser} {...restProps} />
        </AuthUserContext.Provider>
      )
    }
  }

export default withAuthentication
