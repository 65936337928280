import React from 'react'
import Log from 'utils/logging.js'
import { Col, Row, Card } from 'reactstrap'
import Page from '../components/Page'
import { withAdmin } from '../components/Session'
import EditMeetingForm from '../components/Admin/EditMeetingForm'
import bn from 'utils/bemnames'
const bem = bn.create('organization-meeting')

class EditMeetingPage extends React.Component {
  render () {
    Log.info(this.props)
    const { admin, authUser } = this.props
    Log.info(admin)
    return (
      <Page className='DashboardPage'>
        <Row>
          <Col>
            <Row className={bem.b()}>
              <Col md={{size: 10, offset: 1}}>
                <Card>
                  <EditMeetingForm authUser={authUser} admin={admin} />
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </Page>
    )
  }
}

export default withAdmin(EditMeetingPage)
