// AttendanceTableDay.js

import React from 'react'
import PropTypes from 'utils/propTypes'
import { Popover, PopoverBody, Button } from 'reactstrap'
import { getAttendanceIcon } from '../../utils/attendance'
import { withRouter } from 'react-router'
import moment from 'moment'

class AttendanceTableDay extends React.Component {
  state = {
    isPopoverOpen: false
  }

  toggle = () => {
    this.setState({
      isPopoverOpen: !this.state.isPopoverOpen
    })
  }

  onButtonClick = () => {
    this.props.history.push(`/client/attendance/${this.props.clientId}`)
  }

  render () {
    const { attendance } = this.props
    return (
      <React.Fragment>
        <i className='attendance-row__icon' id={`AttendanceDay-${attendance.id}`}>
          <img alt={`${attendance.validation.status}`} src={getAttendanceIcon(attendance.validation.status)} />
        </i>
        { !!attendance.id &&
        (<Popover trigger='hover' isOpen={this.state.isPopoverOpen} toggle={this.toggle} placement='bottom' target={`AttendanceDay-${attendance.id}`}>
          <PopoverBody>
            <div>
              <div className='attendance-day-popover p-1 pb-2'>
                <div style={{flex: 1}}>
                  <i className='attendance-row__icon' >
                    <img alt={`${attendance.validation.status}`} src={getAttendanceIcon(attendance.validation.status)} />
                  </i>
                </div>
                <div style={{flex: 4}}>
                  <div className='pt-2 font-weight-bold'>{attendance.meeting.name}</div>
                  <div className='pt-1'>{attendance.isVirtual ? 'Virtual Meeting' : `${attendance.meeting.address.city}, ${attendance.meeting.address.state}`}</div>
                  <div className='pt-1 pb-2'>{`${moment(attendance.checkIn).format('ddd, MMMM Do, h:mm a')}`}</div>
                </div>
              </div>
              <div>
                <Button
                  outline
                  block
                  color='primary'
                  onClick={this.onButtonClick}
                >View
                </Button>
              </div>
            </div>
          </PopoverBody>
        </Popover>)}
      </React.Fragment>
    )
  }
}

AttendanceTableDay.propTypes = {
  attendance: PropTypes.object,
  clientId: PropTypes.string
}

export default withRouter(AttendanceTableDay)
