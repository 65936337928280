// Landing - manager Landing component

import React from 'react'
import { compose, withProps, pure } from 'recompose'
import PropTypes from 'prop-types'
import { queries, utils, mutations } from '../../graphql'
import { withRouter } from 'react-router'

import { Table, Col, Row, Card } from 'reactstrap'

import bn from 'utils/bemnames'
import { graphql } from 'react-apollo'
import { withLoading } from '../../hocs/withLoading'
import moment from 'moment'
import Hidable from '../Hidable'
import TBVButton from '../TBVButton'

const bem = bn.create('dash-landing')

class PendingInvitesComponent extends React.Component {
  state = {
    modalOpen: false
  }

  toggle = () => {
    this.setState({
      modalOpen: !this.state.modalOpen
    })
  }

  resend = (token) => async () => {
    const variables = {
      input: { token }
    }

    await this.props.resendInvite({
      variables,
      context: utils.generateAuthHeader(this.props.authUser.token),
      refetchQueries: [{
        query: queries.clientInvitesQuery,
        context: utils.generateAuthHeader(this.props.authUser.token)
      }]
    })
  }

  render () {
    return (
      <Row className={bem.b()}>
        <Col>
          <Row>
            <Col>
              <Card className='border-0 tbv-box-shadow pb-3'>
                <Hidable show={this.props.clientInvites.length}>
                  <div className='d-flex justify-content-between w-100 p-4'>
                    <Table responsive striped borderless>
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Email</th>
                          <th>Phone</th>
                          <th>Invited on</th>
                          <th>&nbsp;</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.props.clientInvites.map((c, i) => (
                          <tr key={i}>
                            <td>{`${c.firstName} ${c.lastName}`}</td>
                            <td>{c.email}</td>
                            <td>{c.phone}</td>
                            <td>{moment(c.updatedAt).format('MMM Do YYYY')}</td>
                            <td><TBVButton title='Resend' onClick={this.resend(c.token)} /></td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                </Hidable>
                <Hidable show={!this.props.clientInvites.length}>
                  <div className='w-100 p-4 text-center'>
                    <p className='font-weight-bold empty-card-text'>No pending invites</p>
                  </div>
                </Hidable>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    )
  }
}

PendingInvitesComponent.propTypes = {
  manager: PropTypes.object.isRequired
}

export const PendingInvites = compose(
  withRouter,
  graphql(mutations.RESEND_CLIENT_INVITE, { name: 'resendInvite' }),
  graphql(queries.clientInvitesQuery, {
    options: props => {
      return {
        context: utils.generateAuthHeader(props.authUser.token)
      }
    }
  }),
  withLoading,
  withProps(props => {
    return {
      clientInvites: props.data.meManager.clientInvites
    }
  }),
  pure
)(PendingInvitesComponent)
