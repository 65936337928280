// StateDropDown.js - duration unit dropdown
import React from 'react'
import { Field } from 'formik'
import * as Yup from 'yup'
import { FormGroup, Label, Input } from 'reactstrap'
import { TBVError } from 'components/TBVForm'

const select = ({field, form: {touched, errors, values, setFieldValue, setFieldTouched}, showError, ...props}) => {
  const errorMsg = touched[field.name] && errors[field.name]
  const handleBlur = () => {
    setFieldTouched(field.name, true)
  }
  return (
    <React.Fragment>
      <Input type='select' name={field.name} onBlur={handleBlur} {...field} {...props}>
        {props.children}
      </Input>
      { errorMsg && (
        <TBVError message={errorMsg} className='text-danger' />
      )}
    </React.Fragment>
  )
}

const ROLES = {
  '': '',
  'orgManager': 'Manager',
  'orgSupervisor': 'Supervisor',
  'orgRegionalSupervisor': 'Regional Supervisor'
}

const ROLES_SUPERVISOR = {
  '': '',
  'orgManager': 'Manager',
  'orgSupervisor': 'Supervisor'
}

export const ManagerRoleSchema = Yup.object().shape({
  roleName: Yup.string().required(`Role is required.`)
})

export const ManagerRoleMap = {
  roleName: ''
}

export const ManagerRoleDropdown = (props) => {
  const roles = props.roleName === 'admin' || props.roleName === 'orgRegionalSupervisor' ? ROLES : ROLES_SUPERVISOR
  return (
    <FormGroup>
      <Label>Role</Label>
      <Field component={select} label='roleName' name='roleName'>
        {Object.keys(roles).map((key, idx) => <option key={idx} value={key}>{roles[key]}</option>)}
      </Field>
    </FormGroup>
  )
}
