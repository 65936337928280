import React from 'react'
import { Col, Row } from 'reactstrap'
import Page from '../components/Page'
import { withManager } from '../components/Session'
import {PendingInvites} from '../components/Dashboard/PendingInvites'

class PendingInvitesPage extends React.Component {
  render () {
    const { manager, authUser } = this.props
    return (
      <Page className='DashboardPage'>
        <Row>
          <Col>
            <PendingInvites manager={manager} authUser={authUser} />
          </Col>
        </Row>
      </Page>
    )
  }
}

export default withManager(PendingInvitesPage)
