import * as React from 'react'
import { withGoogleMap, GoogleMap, Marker } from 'react-google-maps'
import { compose, pure, withProps } from 'recompose'

const MapComponent = (props) => {
  const {form: {setFieldValue}} = props
  const dragEnd = (val) => {
    const latitude = val.latLng.lat()
    const longitude = val.latLng.lng()
    setFieldValue('latitude', latitude)
    setFieldValue('longitude', longitude)
  }

  return (
    <GoogleMap
      defaultZoom={20}
      defaultCenter={{ lat: props.latitude, lng: props.longitude }}
    >
      {props.latitude && props.longitude && <Marker onDragEnd={dragEnd} draggable={props.draggable} position={{ lat: props.latitude, lng: props.longitude }} />}
    </GoogleMap>
  )
}

export const Map = compose(
  withProps({
    googleMapURL: 'https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places',
    loadingElement: <div style={{ height: '100%' }} />,
    containerElement: <div style={{ height: '300px' }} />,
    mapElement: <div style={{ height: '100%' }} />
  }),
  withGoogleMap,
  pure
)(MapComponent)
