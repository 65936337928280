// ClientDetailPage.js - client detail page for manager

import React from 'react'

import Page from 'components/Page'
import ClientDetail from 'components/Dashboard/ClientDetail'

import { withManager } from '../components/Session'

class ClientDetailPage extends React.Component {
  render () {
    const { authUser, roleState, match } = this.props

    return (
      <Page className='ClientDetailPage'>
        <ClientDetail
          authUser={authUser}
          userId={match.params.id}
          roleState={roleState}
        />
      </Page>
    )
  }
}

export default withManager(ClientDetailPage)
