import React from 'react'
import PropTypes from 'prop-types'
import { Table, NavLink as BSNavLink, Input } from 'reactstrap'
import { NavLink } from 'react-router-dom'
import TBVButton from '../TBVButton'
import Hidable from '../Hidable'
import { ORG_ROLES } from '../../utils/roles'
import { getOrgRoleTitle } from 'utils/roles'

class ManagersListItem extends React.Component {
  state = {
    isClientRowsOpen: false
  }
  toggleClientRow = () => {
    this.setState({
      isClientRowsOpen: !this.state.isClientRowsOpen
    })
  }
  onChange = (clientId) => (e) => {
    this.props.assignManager(e.target.value, clientId)
  }
  render () {
    const { manager, options } = this.props
    const clients = manager.clients
    const btnTitle = this.state.isClientRowsOpen ? 'hide clients' : `show clients (${clients.length})`
    return (
      <React.Fragment>
        <tr key={manager.id}>
          <td>{`${manager.firstName} ${manager.lastName}`}</td>
          <td>{manager.email}</td>
          <td>{getOrgRoleTitle(manager.role)}</td>
          <Hidable show={manager.role === ORG_ROLES.ORG_MANAGER && clients && clients.length}>
            <td>
              <TBVButton onClick={this.toggleClientRow} title={btnTitle} className='float-right' />
            </td>
          </Hidable>
          <Hidable show={manager.role !== ORG_ROLES.ORG_MANAGER || (manager.role === ORG_ROLES.ORG_MANAGER && (!clients || !clients.length))}>
            <td>&nbsp;</td>
          </Hidable>
        </tr>
        <Hidable show={this.state.isClientRowsOpen}>
          <tr>
            <td colspan={4}>
              <Table responsive striped borderless>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Assign To</th>
                    <th>&nbsp;</th>
                  </tr>
                </thead>
                <tbody>
                  {clients.map((c, i) => {
                    return (

                      <tr key={manager.id + i}>
                        <td>{`${c.firstName} ${c.lastName}`}</td>
                        <td>{c.email}</td>
                        <td>
                          <Input type='select' onChange={this.onChange(c.id)} defaultValue={c.managerId}>
                            {options.map(o => {
                              return (
                                <option value={o.value}>{o.text}</option>
                              )
                            })}
                          </Input>
                        </td>
                        <td><BSNavLink
                          tag={NavLink}
                          to={`/client/reports/${c.id}`}
                          className='float-right'
                          activeClassName='active'
                          exact>
                          <TBVButton title={`View`} className='float-right' />
                        </BSNavLink>
                        </td>
                      </tr>
                    )
                  })}
                </tbody>
              </Table>
            </td>
          </tr>
        </Hidable>
      </React.Fragment>
    )
  }
}

ManagersListItem.propTypes = {
  manager: PropTypes.object.isRequired
}

export default ManagersListItem
