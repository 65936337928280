// withAuthorization.js - handles page authorization

import React from 'react'
import Log from 'utils/logging.js'
import { withRouter } from 'react-router-dom'

import AuthUserContext from './AuthUserContext'

import { firebase } from '../../firebase'

import { HOME } from 'constants/routes'

import { utils } from '../../graphql'

const withAuthorization = (authCondition) => (Component) => {
  class WithAuthorization extends React.Component {
    componentDidMount () {
      this.auth = firebase.auth.onAuthStateChanged(authUser => {
        if (!authCondition(authUser)) {
          Log.info('pushing unauthenticated user to home')
          this.props.history.push(HOME)
        } else {
          authUser.getIdToken().then((token) => {
            authUser.token = token
            authUser.authHeader = utils.generateAuthHeader(authUser.token)
          }).catch((err) => Log.error(err))
        }
      }).bind(this)
    }

    componentWillUnmount () {
      // Unsubscribe.
      if (this.auth) {
        this.auth()
      }
    }

    render () {
      const {...restProps} = this.props
      Log.info('withAuthorization')
      Log.info(this.props)
      Log.info(restProps)
      return (
        <AuthUserContext.Consumer>
          {authUser => authUser ? <Component authUser={authUser} {...restProps} /> : null}
        </AuthUserContext.Consumer>
      )
    }
  }

  return withRouter(WithAuthorization)
}

export default withAuthorization
