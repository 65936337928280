// FormField.js - abstract out generic form field
import React from 'react'

import { Field } from 'formik'

import { FormGroup, Label } from 'reactstrap'

import TBVFormInput from './FormInput.js'

const FormField = ({ id, label, className, children, ...fieldProps }) => (
  <FormGroup className={className}>
    <Label for={label}>{label}</Label>
    <Field
      component={TBVFormInput}
      name={id}
      {...fieldProps}
    />
    {children}
  </FormGroup>
)

export default FormField
