// Profile.js - Client Profile

import React from 'react'
import Log from 'utils/logging.js'
import PropTypes from 'prop-types'
import Roles from 'utils/roles'
import { Col, Row, Card } from 'reactstrap'
import { Elements, StripeProvider } from 'react-stripe-elements'
import UpdatePasswordForm from '../UpdatePasswordForm'
import { PaymentForm } from '../SignUp'
import SubscriptionInfo from './SubscriptionInfo.js'
const paymentOn = !!process.env.REACT_APP_PAYMENT_ACTIVE

export const Profile = (props) => {
  const { authUser, client, roleState, stripeKey } = props

  return (
    <Row>
      <Col>
        {(paymentOn &&
        <Row>
          <Col sm={6}>
            <h3>Subscription Info</h3>
            <Card>
              <SubscriptionInfo client={client} authUser={authUser} roleState={roleState} />
            </Card>
          </Col>
          <Col sm={6}>
            <h3>Update Payment</h3>
            <Card>
              <StripeProvider apiKey={stripeKey}>
                <Elements>
                  <PaymentForm client={client} onFormSubmit={() => { Log.info('update card success') }} authUser={authUser} roleState={roleState} />
                </Elements>
              </StripeProvider>
            </Card>
          </Col>
        </Row>
        )}
        <Row>
          <Col sm={6}>
            <h3 style={{marginLeft: 85}}>Update Password</h3>
            <Card>
              <UpdatePasswordForm />
            </Card>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

Profile.propTypes = {
  authUser: PropTypes.object.isRequired,
  stripeKey: PropTypes.string.isRequired,
  client: PropTypes.object.isRequired,
  roleState: PropTypes.oneOf([Roles.CLIENT]).isRequired
}

export default Profile
