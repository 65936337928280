// Organization page
import React from 'react'
import { compose, withProps, pure } from 'recompose'
import { withRouter } from 'react-router'
import PropTypes from 'prop-types'
import { mutations, queries, utils } from '../../graphql'
import { Table, Button, Col, Row, Card, NavLink as BSNavLink } from 'reactstrap'
import Avatar from '../Avatar'
import Hidable from '../Hidable'
import bn from 'utils/bemnames'
import { graphql } from 'react-apollo'
import { withLoading } from '../../hocs/withLoading'
import { AddManagerToOrganization } from './AddManagerToOrganization'
import InviteManager from './InviteManager'
import { OrgLogo } from './OrgLogo'
import { storage } from '../../firebase/firebase'
import TBVButton from '../TBVButton'
import { NavLink } from 'react-router-dom'

const bem = bn.create('organization')

class OrganizationComponent extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      modalOpen: false,
      managerModalOpen: false,
      logoUploadOpen: false
    }
  }

  toggle = () => {
    this.setState({
      modalOpen: !this.state.modalOpen
    })
  }

  toggleInvite = () => {
    this.setState({
      managerModalOpen: !this.state.managerModalOpen
    })
  }

  toggleLogoUpload = () => {
    this.setState({
      logoUploadOpen: !this.state.logoUploadOpen
    })
  }

  uploadLogo = async (file) => {
    const orgId = this.props.match.params.id
    let ref = storage.ref().child(`org/${orgId}/logo.jpg`)
    const result = await ref.put(file)
    const imgUrl = await result.ref.getDownloadURL()

    this.props.addOrgLogo({
      context: utils.generateAuthHeader(this.props.authUser.token),
      variables: {
        input: {
          id: orgId,
          url: imgUrl
        }
      },
      update: (store, {data: { addOrgLogo }}) => {
        const data = {organization: addOrgLogo}
        store.writeQuery({query: queries.getOrganizationQuery, variables: {id: orgId}, data: data})
      }
    }).then(() => {
      this.toggleLogoUpload()
    })
  }

  assignManager = (managerId) => {
    const orgId = this.props.match.params.id
    this.props.assignManager({
      context: utils.generateAuthHeader(this.props.authUser.token),
      variables: {
        input: {
          organizationId: orgId,
          managerId
        }
      },
      update: (store, {data: { assignManagerToOrganization }}) => {
        const data = {organization: assignManagerToOrganization}
        store.writeQuery({query: queries.getOrganizationQuery, variables: {id: orgId}, data: data})
      }
    }).then(() => {
      this.toggle()
    })
  }

  render () {
    return (
      <Row className={bem.b()}>
        <Col>
          <Row>
            <Col>
              <Card className='border-0 tbv-box-shadow pb-3'>
                <div>
                  <div className='pt-3 pr-3 float-right' style={{display: 'inline-block'}}>
                    <Button outline color='primary' onClick={this.toggleLogoUpload}>Add logo</Button>
                  </div>
                  <div className='pt-3 pr-3 float-right' style={{display: 'inline-block'}}>
                    <Button outline color='primary' onClick={this.toggle}>+ Assign manager</Button>
                  </div>
                  <div className='pt-3 pr-3 float-right' onClick={this.toggleInvite} style={{display: 'inline-block'}}>
                    <Button color='primary'>Invite manager</Button>
                  </div>
                  <div className='pt-3 pr-3 float-right' style={{display: 'inline-block'}}>
                    <BSNavLink
                      style={{padding: 0}}
                      tag={NavLink}
                      to={`/meetings/new/${this.props.match.params.id}`}
                      exact>
                      <TBVButton buttonSize='md' title='Add meeting' />
                    </BSNavLink>
                  </div>
                </div>
                <Hidable show={!!this.props.organization.managers.length}>
                  <div className='d-flex justify-content-between w-100 p-4'>
                    <Table responsive borderless>
                      <thead>
                        <tr>
                          <th style={{width: 250}}>Organization Managers</th>
                          <th>&nbsp;</th>
                          <th>&nbsp;</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.props.organization.managers.map((m, i) => (
                          <tr key={i}>
                            <td><Avatar size={30} src={m.photoUrl} /></td>
                            <td>{`${m.firstName} ${m.lastName}`}</td>
                            <td>{m.email}</td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                </Hidable>
                <Hidable show={!this.props.organization.managers.length}>
                  <div className='d-flex justify-content-between w-100 p-4'>
                    <div>No Managers</div>
                  </div>
                </Hidable>
              </Card>
            </Col>
          </Row>
        </Col>
        <AddManagerToOrganization assignManager={this.assignManager} open={this.state.modalOpen} toggle={this.toggle} />
        <InviteManager open={this.state.managerModalOpen} toggle={this.toggleInvite} />
        <OrgLogo currentLogo={this.props.organization.logoUrl} toggle={this.toggleLogoUpload} open={this.state.logoUploadOpen} submit={this.uploadLogo} />
      </Row>
    )
  }
}

OrganizationComponent.propTypes = {
  authUser: PropTypes.object.isRequired,
  admin: PropTypes.object.isRequired
}

export const Organization = compose(
  withRouter,
  graphql(mutations.ASSIGN_MANAGER_TO_ORG, { name: 'assignManager' }),
  graphql(mutations.ADD_LOGO, { name: 'addOrgLogo' }),
  graphql(queries.getOrganizationQuery, {
    options: props => {
      return {
        context: utils.generateAuthHeader(props.authUser.token),
        variables: {
          input: {
            id: props.match.params.id
          }
        }
      }
    }
  }),
  withLoading,
  withProps(props => {
    return {
      organization: props.data.organization
    }
  }),
  pure
)(OrganizationComponent)
