import React from 'react'
import { Col, Row } from 'reactstrap'
import Page from '../components/Page'
import { ReviewAttendance } from '../components/Admin'
import { withAdmin } from '../components/Session'

class AdminReviewAttendancePage extends React.Component {
  render () {
    const { admin, authUser } = this.props
    return (
      <Page className='DashboardPage'>
        <Row>
          <Col>
            <ReviewAttendance authUser={authUser} admin={admin} />
          </Col>
        </Row>
      </Page>
    )
  }
}

export default withAdmin(AdminReviewAttendancePage)
