// Profile.js - Manager Profile

import React from 'react'
import Log from 'utils/logging.js'

import PropTypes from 'prop-types'

import Roles from 'utils/roles'

import { Col, Row, Card, NavLink as BSNavLink } from 'reactstrap'

import { UpdateManagerForm } from 'components/SignUp'
import UpdatePasswordForm from '../UpdatePasswordForm'

import ContactCard from '../Card/ContactCard'
import { NavLink } from 'react-router-dom'
import TBVButton from '../TBVButton'
import { DASHBOARD } from 'constants/routes'

const _genInitialData = (manager) => {
  const initialData = {
    email: manager.email || '',
    phoneNumber: manager.phone || '',
    firstName: manager.firstName || '',
    lastName: manager.lastName || ''
  }
  Log.info(initialData)
  return initialData
}

const ManagerContactCardItems = () => {
  return (
    <BSNavLink
      tag={NavLink}
      to={DASHBOARD}
      activeClassName='active'
      exact>
      <TBVButton title='Back to Dashbaord' />
    </BSNavLink>
  )
}

export const Profile = (props) => {
  const { authUser, manager, roleState } = props
  return (
    <Row>
      <Col md={5} xl={3}>
        <ContactCard
          user={manager} >
          <div className='d-flex justify-content-center'>
            <ManagerContactCardItems />
          </div>
        </ContactCard>
      </Col>
      <Col md={7} xl={8}>
        <Row>
          <Col>
            <Card className='border-0 tbv-box-shadow p-5 mb-3'>
              <UpdateManagerForm
                className='float-right border-0'
                roleState={roleState}
                initialData={_genInitialData(manager)}
                authUser={authUser}
                onLogInClick={(e) => { e.preventDefault() }}
                onFormSubmit={(e) => { e.preventDefault() }}
              />
            </Card>
          </Col>
        </Row>
        <Row>
          <Col>
            <Card className='border-0 tbv-box-shadow p-5 mb-3'>
              <UpdatePasswordForm
                className='float-right border-0'
              />
            </Card>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

Profile.propTypes = {
  authUser: PropTypes.object.isRequired,
  roleState: PropTypes.oneOf([Roles.CASE_MANAGER, Roles.PROBATION_OFFICER]).isRequired
}

export default Profile
