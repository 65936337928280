// utils/user.js - helpers for user

export const genFullName = (firstName, lastName) => {
  return firstName + ' ' + lastName
}

export const sortClientsByAZ = (a, b) => {
  if (a.lastName.toLowerCase() < b.lastName.toLowerCase()) return -1
  if (a.lastName.toLowerCase() > b.lastName.toLowerCase()) return 1
  else return 0
}

export const sortClientsByZA = (a, b) => {
  if (a.lastName.toLowerCase() > b.lastName.toLowerCase()) return -1
  if (a.lastName.toLowerCase() < b.lastName.toLowerCase()) return 1
  else return 0
}
