// client.js - setup graphQL client

import ApolloClient from 'apollo-boost'
import defaults from './defaults.js'
import resolvers from './resolvers.js'

const config = {
  endpoint: process.env.REACT_APP_GRAPHQL_ENDPOINT
}

// setup clientState for local queries, mutations
const clientState = {
  defaults: defaults,
  resolvers: resolvers
}

// Pass your GraphQL endpoint to uri
const client = new ApolloClient({ uri: config.endpoint, clientState: clientState })

export default client
