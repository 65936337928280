// ClientLayout.js - the dashoard layout

import React from 'react'

import { Content, Footer, ClientHeader } from 'components/Layout'

import {
  MdImportantDevices,
  MdLoyalty
} from 'react-icons/lib/md'

import NotificationSystem from 'react-notification-system'
import { NOTIFICATION_SYSTEM_STYLE } from 'constants/notifications'

import * as textConstants from 'constants/text'

import { withClient } from 'components/Session'

import moment from 'moment'

class ClientLayout extends React.Component {
  componentDidMount () {
    setTimeout(() => {
      if (!this.notificationSystem) {
        return
      }

      this.notificationSystem.addNotification({
        title: <MdImportantDevices />,
        message: textConstants.DASH_WELCOME_MESSAGE,
        level: 'info'
      })
    }, 1500)

    setTimeout(() => {
      if (!this.notificationSystem) {
        return
      }

      this.notificationSystem.addNotification({
        title: <MdLoyalty />,
        message: textConstants.DASH_CLIENT_INTRO_MESSAGE,
        level: 'info'
      })
    }, 2500)
  }

  render () {
    const { client, authUser, children } = this.props
    const isNew = (moment(client.createdAt).isAfter(moment().subtract(30, 'minutes')))

    return (
      <main className='tbv-app bg-light'>
        <Content fluid onClick={this.handleContentClick}>
          <ClientHeader client={client} authUser={authUser} />
          {children}
          <Footer />
        </Content>

        {isNew && (<NotificationSystem
          dismissible={false}
          ref={notificationSystem =>
            (this.notificationSystem = notificationSystem)
          }
          style={NOTIFICATION_SYSTEM_STYLE}
        />)}
      </main>
    )
  }
}

export default withClient(ClientLayout)
