// ForgotPasswordForm.js - form for sending forgot password link

import React from 'react'
import Log from 'utils/logging.js'

import PropTypes from 'prop-types'
import { HOME } from 'constants/routes'
import { auth } from '../firebase'

import * as textConstants from 'constants/text.js'

import TBVButton from './TBVButton.js'
import { Form, FormGroup, Input, Label } from 'reactstrap'
import { Link } from 'react-router-dom'

const INITIAL_STATE = {
  email: '',
  error: null,
  success: null
}

const byPropKey = (propertyName, value) => () => ({
  [propertyName]: value
})

class ForgotPasswordForm extends React.Component {
  constructor (props) {
    super(props)
    this.state = { ...INITIAL_STATE }
  }

  handleSubmit = (event) => {
    Log.info('handle forgot password submit')
    const { email } = this.state

    auth.doPasswordReset(email)
      .then(() => {
        this.setState(() => ({ ...INITIAL_STATE }))
        this.setState(byPropKey('success', 'Password reset sent'))
      })
      .catch(error => {
        this.setState(byPropKey('error', error))
      })

    event.preventDefault()
  }

  render () {
    const {
      emailLabel,
      emailInputProps,
      children
    } = this.props

    const {
      email,
      error,
      success
    } = this.state

    const isInvalid =
      email === ''

    return (
      <Form className='tbv-forgot-password-form' onSubmit={this.handleSubmit}>
        <FormGroup>
          <Label for={emailLabel}>{emailLabel}</Label>
          <Input
            onChange={event => this.setState(byPropKey('email', event.target.value))}
            {...emailInputProps} />
        </FormGroup>
        <div className='text-center pt-2'>
          <TBVButton block title={textConstants.FORGOT_PASSWORD} disabled={isInvalid} />
          { error && <p className='text-danger pt-2'>{error.message}</p> }
          { success && <p className='text-success pt-2'>{success}</p> }
        </div>
        <div className='text-center pt-3'>
          <h6>
            <Link className='text-bluishGrey' to={HOME}>Back to Log In</Link>
          </h6>
        </div>

        {children}
      </Form>
    )
  }
}

ForgotPasswordForm.propTypes = {
  emailLabel: PropTypes.string,
  emailInputProps: PropTypes.object
}

ForgotPasswordForm.defaultProps = {
  emailLabel: 'Email address',
  emailInputProps: {
    type: 'email',
    placeholder: ''
  }
}

export default ForgotPasswordForm
