// ClientAttendancePage.js - client attendance / discharge report page

import React from 'react'

import { Col, Row } from 'reactstrap'

import Page from 'components/Page'
import { AttendanceTable } from 'components/Attendance'

import { withClient } from 'components/Session'

class ClientAttendancePage extends React.Component {
  render () {
    const { client, authUser, roleState } = this.props
    return (
      <Page
        className='ClientAttendancePage bg-light'>
        <Row className='text-center'>
          <Col>
            <AttendanceTable client={client} roleState={roleState} authUser={authUser} className='table-striped' />
          </Col>
        </Row>
      </Page>
    )
  }
}

export default withClient(ClientAttendancePage)
