// resolver.js - local resolvers for cache based data
import Log from 'utils/logging.js'
import { auth } from '../firebase'
import { getFireBaseUserQuery } from './queries'

const createFireBaseUser = async (_, { input }, {cache}) => {
  // Read from cache after
  const query = getFireBaseUserQuery

  try {
    // create firebase user
    const authUser = await auth.doCreateUserWithEmailAndPassword(input.email, input.password)
    Log.info('FireBase user created')
    Log.info(authUser)

    const data = {
      firUser: {
        __typename: 'FireBaseUser',
        uid: authUser.user.uid,
        email: authUser.user.email
      }}

    Log.info('writing to firebase user to cache')
    Log.info(data)

    cache.writeQuery({query: query, data: data})
  } catch (error) {
    Log.info('error in resolver')
    Log.error(error)
  }
  // read data from cache
  // @TODO: see if we can just return data
  // or something from the write
  return cache.readQuery({ query })
}

export default {
  Mutation: {
    createFireBaseUser
  }
}
