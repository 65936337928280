// AttendanceReportPage.js - attendance report page
import React from 'react'

import { Col, Row } from 'reactstrap'

import Page from 'components/Page'
import { AttendanceTable } from 'components/Attendance'

import { DASHBOARD, ADD_CLIENT } from 'constants/routes'

import withManager from 'components/Session/withManager'

import { getClient } from 'utils/manager.js'

class AttendanceReportPage extends React.Component {
  handleDashClick = () => {
    this.props.history.push(DASHBOARD)
  }

  handleAddClientClick = () => {
    this.props.history.push(ADD_CLIENT)
  }

  render () {
    const { manager, match, authUser, roleState } = this.props
    const clientId = match.params.id
    const client = getClient(manager, clientId)

    return (
      <Page
        className='AttendanceReportPage bg-light'>
        <Row className='text-center'>
          <Col>
            <AttendanceTable client={client} authUser={authUser} roleState={roleState} className='table-striped' />
          </Col>
        </Row>
      </Page>
    )
  }
}

export default withManager(AttendanceReportPage)
