// ProfilePage.js - client home page
import React from 'react'

import { Col, Row } from 'reactstrap'

import Page from 'components/Page'

import { ClientProfile } from 'components/Profile'

import { withClient } from '../components/Session'

const STRIPE_KEY = process.env.REACT_APP_STRIPE_KEY

const ClientProfilePage = ({ client, roleState, authUser }) => (
  <Page className='ProfilePage'>
    <Row className='tbv-profile'>
      <Col>
        <ClientProfile stripeKey={STRIPE_KEY} client={client} roleState={roleState} authUser={authUser} />
      </Col>
    </Row>
  </Page>
)

export default withClient(ClientProfilePage)
