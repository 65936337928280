import React from 'react'
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Input, Label } from 'reactstrap'

const INITIAL_STATE = { confirmed: false }
export class ConfirmClientMessage extends React.Component {
  state = INITIAL_STATE
  submit = () => {
    if (!this.state.confirmed) return false
    this.props.submit()
    this.props.toggle()
  }
  close = () => {
    this.setState(INITIAL_STATE)
    this.props.toggle()
  }
  render () {
    return (
      <Modal
        isOpen={this.props.open}
        toggle={this.close}
        className='confirm-message-modal'
      >
        <ModalHeader toggle={this.close}>Confirm Message</ModalHeader>
        <div className='p-3'>
          <ModalBody>
            <label className='confirm-message-modal__label'>Message</label>
            <div className='confirm-message-modal__msg'>{this.props.msg}</div>
            <div className='confirm-message-modal__confirm'>
              <Label check>
                <Input
                  onChange={() => this.setState({ confirmed: !this.state.confirmed })}
                  checked={this.state.confirmed}
                  type='checkbox'
                />
                I understand that this cannot be undone, I want to send this message.
              </Label>
            </div>
          </ModalBody>
          <ModalFooter>
            <div className='pt-1 pb-3'>
              <button className='btn btn-primary btn-lg' disabled={!this.state.confirmed} onClick={this.submit}>Send message</button>
              <Button
                size='lg'
                color='primary'
                outline
                className='ml-3'
                onClick={() => {
                  this.close()
                }}>
                    Cancel
              </Button>
            </div>
          </ModalFooter>
        </div>
      </Modal>
    )
  }
}
