// DashboardHeader.js - the dashboard header

import React from 'react'

import bn from 'utils/bemnames'

import {
  Navbar,
  Nav,
  NavItem,
  NavLink,
  Popover,
  PopoverBody,
  Card,
  Col,
  Row
} from 'reactstrap'

import { NavLink as ReactNavLink } from 'react-router-dom'

import {
  MdNotificationsActive,
  MdNotificationsNone
} from 'react-icons/lib/md'

import classNames from 'classnames'

import Avatar from 'components/Avatar'
import Notifications from 'components/Dashboard/Notifications'
import SearchInput from 'components/SearchInput'
import TBVButton from 'components/TBVButton'
import tbvWhiteSvg from 'assets/img/logo/tbv-logo-white-no-tagline.svg'
import tbvSvg from 'assets/img/logo/tbv-logo-full-color-no-tagline.svg'

import withBadge from 'hocs/withBadge'

import { EDIT_PROFILE, LOG_OUT, ADD_CLIENT_DASH } from 'constants/text'
import { ADD_CLIENT, MANAGER_PROFILE, MANAGER_HOME } from 'constants/routes'
import { ORG_ROLES } from 'utils/roles'
import { auth } from '../../firebase'
import Hidable from '../Hidable'
import { managerCan } from '../Session/permission'
import { MANAGER_ACL } from '../../constants/acl'
import { SUPERVISOR_HOME } from '../../constants/routes'

const bem = bn.create('dash-header')

const MdNotificationsActiveWithBadge = withBadge({
  size: 'xs',
  color: 'green',
  style: {
    top: -8,
    right: -8,
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: 'none',
    background: 'aquamarine'
  }
})(MdNotificationsActive)

class DashboardHeader extends React.Component {
  state = {
    isOpenNotificationPopover: false,
    isNotificationConfirmed: false,
    isOpenUserCardPopover: false
  };

  toggleNotificationPopover = () => {
    this.setState({
      isOpenNotificationPopover: !this.state.isOpenNotificationPopover
    })

    if (!this.state.isNotificationConfirmed) {
      this.setState({ isNotificationConfirmed: true })
    }
  };

  toggleUserCardPopover = () => {
    this.setState({
      isOpenUserCardPopover: !this.state.isOpenUserCardPopover
    })
  };

  render () {
    const { isNotificationConfirmed } = this.state
    const { manager, white } = this.props
    const fill = white ? 'white' : '#283845'
    const HOME = managerCan(MANAGER_ACL.orgSupervisor, manager.role) ? SUPERVISOR_HOME : MANAGER_HOME

    return (
      <Navbar className='tbv-dash-navbar' expand>
        <Row className='w-100'>
          <Col xs={12} sm={3} >
            <ReactNavLink to={HOME} className='navbar-brand d-flex pl-3 flex-column justify-content-center h-100'>
              <span className='d-inline-flex'>
                <img
                  src={white ? tbvWhiteSvg : tbvSvg}
                  height='25'
                  alt=''
                />
              </span>
            </ReactNavLink>
          </Col>

          <Nav className='d-none' navbar>
            <SearchInput fill={fill} />
          </Nav>

          <Nav navbar className={classNames(bem.e('nav-right'), 'ml-2 ml-sm-auto')}>
            <Hidable show={manager.role === ORG_ROLES.MANAGER || manager.role === ORG_ROLES.ORG_MANAGER || manager.role === ORG_ROLES.ORG_SUPERVISOR}>
              <NavItem className='d-inline-flex'>
                <NavLink tag={ReactNavLink} className='position-relative' to={ADD_CLIENT}>
                  <TBVButton className='tbv-add-client-btn text-uppercase' block title={ADD_CLIENT_DASH} outline buttonType={'button'} />
                </NavLink>
              </NavItem>
            </Hidable>
            <NavItem className='d-inline-flex'>
              <NavLink id='Popover1' className='position-relative'>
                {isNotificationConfirmed ? (
                  <MdNotificationsNone
                    fill={fill}
                    size={25}
                    className='text-secondary can-click'
                    onClick={this.toggleNotificationPopover}
                  />
                ) : (
                  <MdNotificationsActiveWithBadge
                    fill={fill}
                    size={25}
                    className='text-secondary can-click animated swing infinite'
                    onClick={this.toggleNotificationPopover} />
                )}
              </NavLink>
              <Popover
                hideArrow
                className='tbv-notifications-popover nav-offset'
                placement='bottom'
                isOpen={this.state.isOpenNotificationPopover}
                toggle={this.toggleNotificationPopover}
                target='Popover1'>
                <Notifications onClick={this.toggleNotificationPopover} clients={manager.clients} />
              </Popover>
            </NavItem>
            <NavItem className='d-none d-md-block'>
              <NavLink className='position-relative pr-0 d-flex flex-column justify-content-center h-100'>
                <span>{manager.firstName}</span>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink id='Popover2'>
                <Avatar
                  onClick={this.toggleUserCardPopover}
                  className='can-click'
                  src={manager.photoUrl}
                />
              </NavLink>
              <Popover
                placement='bottom-end'
                isOpen={this.state.isOpenUserCardPopover}
                toggle={this.toggleUserCardPopover}
                target='Popover2'
                className='p-0 border-0 tbv-popover-menu'
                style={{ minWidth: 250 }}>
                <PopoverBody className='p-0 border-light'>
                  <Card className='border-0 tbv-box-shadow'>
                    <div className='d-flex justify-content-center flex-column'>
                      <ReactNavLink to={MANAGER_PROFILE}><p className='font-weight-bold border-bottom m-0 p-3'>{EDIT_PROFILE}</p></ReactNavLink>
                    </div>
                    <div className='d-flex justify-content-center flex-column'>
                      <NavLink className='tbv-logout font-weight-bold m-0 p-3'
                        onClick={() => { auth.doSignOut() }} tabIndex='-1'>
                        {LOG_OUT}
                      </NavLink>
                    </div>
                  </Card>
                </PopoverBody>
              </Popover>
            </NavItem>
          </Nav>

          <Col xs={12} className='d-none'>
            <Nav className='d-md-none' navbar>
              <SearchInput fill={fill} />
            </Nav>
          </Col>
        </Row>
      </Navbar>
    )
  }
}

export default DashboardHeader
