// UpdateStatusForm.js - update attendance status for client

import React from 'react'
import Log from 'utils/logging.js'

import { graphql, compose } from 'react-apollo'

import PropTypes from 'prop-types'

import { withAuthentication } from '../Session'

import { Form, FormGroup, Label } from 'reactstrap'

import { mutations } from '../../graphql'

import { UPDATE_ATTENDANCE_STATUS_SUCCESS, DEFAULT_ERROR_MSG } from 'constants/text.js'

import AttendanceStatusDropDown from './AttendanceStatusDropDown.js'

import TBVButton from '../TBVButton.js'

import { ValidationFields } from '../TBVForm'

import { withFormik } from 'formik'
import * as Yup from 'yup'

const formikEnhancer = withFormik({
  validationSchema: Yup.object().shape({
    status: ValidationFields.ATTENDANCE_VALIDATION_STATUS
  }),
  mapPropsToValues: props => ({
    status: ''
  }),
  handleSubmit: async (values, {setSubmitting, setErrors, setStatus, resetForm, props}) => {
    const payload = {
      ...values
    }

    const { clientId, attendanceId, authUser } = props

    // graphql createManageNote call
    const variables = {
      clientId: clientId,
      attendanceId: attendanceId,
      validationStatus: payload.status
    }

    Log.info('updateClientAttendance')
    Log.info(variables)

    try {
      await props.UpdateClientAttendance(
        { variables: variables,
          context: authUser.authHeader
        }
      )
      Log.info('successful client attendance update')
      setStatus({success: true})
      setSubmitting(false)
    } catch (error) {
      Log.info('failed graphQL update client attendance')
      Log.error(error)
      setErrors({submit: DEFAULT_ERROR_MSG})
      setSubmitting(false)
    }
  },
  displayName: 'UpdateStatusForm'
})

const UpdateStatusForm = (props) => {
  const {
    errors,
    status,
    isSubmitting,
    handleSubmit,
    initialValues,
    statusId,
    statusIsOpen,
    validationStatus,
    children
  } = props
  Object.assign(initialValues, {status: validationStatus})
  return (
    <Form className='tbv-add-note p-1' onSubmit={handleSubmit}>
      <FormGroup>
        <Label for={statusId}>&nbsp;</Label>
        <AttendanceStatusDropDown name={statusId} isOpenId={statusIsOpen} showError />
      </FormGroup>
      <div className='text-center m-2'>
        <TBVButton block title={'Save'} disabled={isSubmitting} />
        {errors.submit && <p className='text-danger pt-2'>{errors.submit}</p>}
        {status && status.success && <p className='text-success pt-2'>{UPDATE_ATTENDANCE_STATUS_SUCCESS}</p>}
      </div>
      {children}
    </Form>
  )
}

UpdateStatusForm.propTypes = {
  clientId: PropTypes.string.isRequired,
  attendanceId: PropTypes.string.isRequired,
  validationStatus: PropTypes.string.isRequired,
  statusId: PropTypes.string,
  statusIsOpen: PropTypes.string,
  statusLabel: PropTypes.string,
  statusInputProps: PropTypes.object
}

UpdateStatusForm.defaultProps = {
  statusId: 'status',
  statusIsOpen: 'statusIsOpen',
  statusLabel: ''
}

const UpdateStatusFormGraphQL = compose(
  graphql(mutations.UPDATE_CLIENT_ATTENDANCE, {name: 'UpdateClientAttendance'})
)(formikEnhancer(UpdateStatusForm))

export default withAuthentication(UpdateStatusFormGraphQL)
