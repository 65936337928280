import React from 'react'

const PrivacyPolicy = () => (
  <React.Fragment>
    <p><strong>TBV ME Application Privacy Policy</strong></p>
    <p>TBV ME, Co., (&ldquo;<strong>TBV</strong>&rdquo;, &ldquo;<strong>We</strong>&rdquo;, &ldquo;<strong>Us</strong>&rdquo;) has created this privacy policy (&ldquo;<strong>Privacy Policy</strong>&rdquo;) in order to set out how we collect, use, and disclose personal information through the TBV platform and our related personal contact management services and applications (collectively, together with the TBV platform, the &ldquo;<strong>Services</strong>&rdquo;). Any capitalized terms in this Privacy Policy shall have the same meaning as set out in our Terms of Service.</p>
    <p>The privacy of our users is of great importance to us. By using the Services in any manner, you acknowledge that you accept the practices and policies outlined in this Privacy Policy and you hereby consent to the collection, use and disclosure of your personal information in accordance with this Privacy Policy.</p>
    <ol>
      <li><strong>WHAT DOES THIS PRIVACY POLICY COVER?</strong></li>
    </ol>
    <p>This Privacy Policy covers our collection, use and disclosure of information about identifiable individuals (&ldquo;<strong>Personal Information</strong>&rdquo;), who use the Services (&ldquo;<strong>Users</strong>&rdquo;).</p>
    <ol>
      <li><strong>COLLECTION AND USE OF PERSONAL INFORMATION </strong></li>
    </ol>
    <ol>
      <ol>
        <li><strong>User Information </strong></li>
      </ol>
    </ol>
    <p>In order to use certain Services, Users must have a valid TBV App account to log into the Services (&ldquo;<strong>Account</strong>&rdquo;).&nbsp;</p>
    <p>When you register for an Account using email registration, TBV collects your name, cell phone number, email address, and location,. This Personal Information is used for the following purposes:</p>
    <ul>
      <li>Verifying the User&rsquo;s identity;</li>
      <li>Allowing Users to create a profile; and</li>
      <li>Contacting Users about relevant information related to the Services.</li>
    </ul>
    <ol>
      <ol>
        <li><strong>Transaction History</strong></li>
      </ol>
    </ol>
    <p>When Users use the Services, TBV collects information about the User&rsquo;s activity and transaction history. In particular, TBV keeps track of the device used to log in, the time of day, the time spent on the app, location, actions completed and IP address, and you may also be asked to provide other personal data such as age, location, gender, personal history, etc. (&ldquo;Personal Information&rdquo;).This Personal Information is used for the following purposes:</p>
    <ul>
      <li>To allow Users to keep track of their history;</li>
      <li>To store and report activities tracked by the Services;</li>
      <li>To support research and efficacy of the Services;</li>
      <li>For TBV&rsquo;s accounting records;</li>
      <li>To enhance and customize User experience, including showing Users relevant contents; and</li>
      <li>To monitor compliance with our Terms of Service.</li>
    </ul>
    <ol>
      <ol>
        <li><strong>Marketing Communications</strong></li>
      </ol>
    </ol>
    <p>TBV will use the contact information provided to notify Users of important information that is relevant to the Services and for marketing communications if the User chooses to opt in. Despite your indicated e-mail preferences, we may send you relevant notices where permitted by law.</p>
    <ol>
      <ol>
        <li><strong>Usage Data</strong></li>
      </ol>
    </ol>
    <p>TBV may also collect certain information from Users of the Website and Services, such as Internet addresses, time spent logged into the Services and other usage data (&ldquo;<strong>Usage Data</strong>&rdquo;). This Usage Data is logged to help diagnose technical problems, and to administer our Website and Services in order to constantly improve the quality of the Website and Services.</p>
    <ol>
      <ol>
        <li><strong>Cookies</strong></li>
      </ol>
    </ol>
    <p>TBV uses tracking technologies, such as cookies, beacons, scripts and tags, are used by us and our third-party partners. These technologies are used in analyzing trends, administering the Services, tracking Users&rsquo; movements around the Services, gathering demographic information about our User base as a whole and collecting information about your browsing activities over time and across different websites following your use of the Services. We may receive reports based on the use of these technologies by these companies on an individual and aggregated basis. Various browsers may offer their own management tools for removing these types of tracking technologies.</p>
    <p>Standing alone, cookies do not identify you personally. They merely recognize your browser. Cookies come in two flavors: session and persistent-based. Session cookies exist only during an online session. They disappear from your computer when you close your browser software or turn off your computer. Persistent cookies remain on your computer after you've closed your browser or turned off your computer.</p>
    <p>TBV uses session cookies containing encrypted information to allow the system to uniquely identify you while you are logged in. This information allows TBV to process online transactions and requests. Session cookies help us make sure you are who you say you are after you've logged in and are required in order to use the Service.</p>
    <p>TBV uses persistent cookies that only TBV can read and use, to identify the fact that you are a TBV User. We are especially careful about the security and confidentiality of the information stored in persistent cookies. Users who disable their web browser&rsquo;s ability to accept cookies will be able to browse our Website but may not be able to use all features of our Services.</p>
    <ol>
      <li><strong>SECURITY AND STORAGE</strong></li>
    </ol>
    <p><strong>3.1 Storage Location</strong></p>
    <p>TBV stores its data, including Personal Information, on Amazon Web Services servers located in the United States. By submitting any Personal Information or using the Services, you agree to this transfer, storing or processing of your Personal Information in the United States. You acknowledge and agree that your Personal Information may be accessible to law enforcement and governmental agencies in those countries under lawful access regimes or court order.</p>
    <p><strong>3.2 Security</strong></p>
    <p>The security of your Personal Information is important to us. We use commercially reasonable efforts to store and maintain your Personal Information in a secure environment. We take technical, contractual, administrative, and physical security steps designed to protect Personal Information that you provide to us. We have implemented procedures designed to limit the dissemination of your Personal Information to only such designated staff as are reasonably necessary to carry out the stated purposes we have communicated to you.However, no Internet or email transmission is ever fully secure or error free. In particular, email sent to or from the Services may not be secure. Therefore, you should take special care in deciding what information you send to us via email. Please keep this in mind when disclosing any Personal Information to us via the Internet.</p>
    <p>You are also responsible for helping to protect the security of your Personal Information. For instance, never give out your email account information or password for the Services to third parties.</p>
    <ol>
      <li><strong>DISCLOSURE OF PERSONAL INFORMATION WITH THIRD PARTIES</strong></li>
    </ol>
    <ol>
      <ol>
        <li><strong>Disclosure to our Service Providers</strong></li>
      </ol>
    </ol>
    <p>We may from time to time employ or engage other companies and people to perform tasks on our behalf and need to share Personal Information with them to provide the Services. Unless we tell you differently, we only provide such third parties with the Personal Information that they need to perform their specific function. This includes third party companies and individuals employed or engaged by us to facilitate our products and services, including the provision of maintenance services, database management, customer relationship management, web analytics and general improvement of the Services, and businesses who engage our Services (to the extent provided for above).In particular, TBV uses the following service providers: Google Cloud Platform, Amazon AWS, Stripe and Google Analytics.</p>
    <ol>
      <ol>
        <li><strong>Business Transfers</strong></li>
      </ol>
    </ol>
    <p>If we (or any of our assets) are acquired, or if we go out of business, enter bankruptcy, or go through some other change of control, Personal Information may be made available or otherwise transferred to the new controlling entity, where permitted under applicable law.</p>
    <ol>
      <ol>
        <li><strong>With Your Consent</strong></li>
      </ol>
    </ol>
    <p>If we need to use or disclose any Personal Information in a way not identified in this Privacy Policy, we will notify you and/or obtain your express consent as required under applicable privacy laws.</p>
    <ol>
      <ol>
        <li><strong>Affiliates</strong></li>
      </ol>
    </ol>
    <p>We may also share your Personal Information with our subsidiaries or other affiliated companies for purposes consistent with this Privacy Policy.</p>
    <ol>
      <ol>
        <li><strong>As required by law</strong></li>
      </ol>
    </ol>
    <p>We may disclose your Personal Information to third parties without your consent if we have reason to believe that disclosing this information is necessary to identify, contact or bring legal action against someone who may be causing injury to or interference with (either intentionally or unintentionally) our rights or property, other Users, or anyone else (including the rights or property of anyone else) that could be harmed by such activities. Further, we may disclose Personal Information when we believe in good faith that such disclosure is required by and in accordance with the law. We also reserve the right to access, read, preserve, and disclose any information as we reasonably believe is necessary to:</p>
    <ul>
      <li>satisfy any applicable law, regulation, legal process or governmental request;</li>
      <li>enforce our contracts or user agreement (Terms of Service), including investigation of potential violations hereof; and</li>
      <li>detect, prevent, or otherwise address fraud, security or technical issues.</li>
    </ul>
    <ol>
      <li><strong>RETENTION</strong></li>
    </ol>
    <p>We will keep your Personal Information for as long as it remains necessary for the identified purpose or as required by law, which may extend beyond the termination of our relationship with you. We may retain certain data as necessary to prevent fraud or future abuse, or for legitimate business purposes, such as analysis of aggregated, non-personally-identifiable data, account recovery, or if required by law. All retained Personal Information will remain subject to the terms of this Privacy Policy.</p>
    <ol>
      <li><strong>ACCESS, CORRECTION AND ACCURACY </strong></li>
    </ol>
    <p>You have the right to access the Personal Information we hold about you in order to verify the Personal Information we have collected in respect to you and to have a general account of our uses of that information. Upon receipt of your written request, we will provide you with a copy of your Personal Information, although in certain limited circumstances, and as permitted under law, we may not be able to make all relevant information available to you, such as where that information also pertains to another User. In such circumstances we will provide reasons for the denial to you upon request. We will endeavor to deal with all requests for access and modifications in a timely manner.</p>
    <p>We will make every reasonable effort to keep your Personal Information accurate and up to date, and we will provide you with mechanisms to update, correct, delete or add to your Personal Information as appropriate. As appropriate, this amended Personal Information will be transmitted to those parties to which we are permitted to disclose your information. Having accurate Personal Information about you enables us to give you the best possible service.</p>
    <ol>
      <li><strong>CHANGES TO THIS&nbsp;PRIVACY POLICY</strong></li>
    </ol>
    <p>We may amend this Privacy Policy from time to time. Use of Personal Information we collect is subject to the Privacy Policy in effect at the time such information is collected, used or disclosed. If we make material changes or changes in the way we use Personal Information, we will notify you by posting an announcement on our Website or sending you an email prior to the change becoming effective. You are bound by any changes to the Privacy Policy when you use the Website or Services after such changes have been first posted.</p>
    <ol>
      <li><strong>CHILDREN</strong></li>
    </ol>
    <p>TBV does not knowingly collect Personal Information from children under the age of 13. If you are under the age of 13, please do not submit any Personal Information through or use the Services. We encourage parents and legal guardians to monitor their children&rsquo;s Internet usage and to help enforce our Privacy Policy by instructing their children never to provide Personal Information through Services without their permission. If you have reason to believe that a child under the age of 13 has provided Personal Information to TBV through the Services, please contact us, and we will endeavor to delete that information from our databases.</p>
    <ol>
      <li><strong>Links to Other WebSites AND SERVICES </strong></li>
    </ol>
    <p>This Privacy Policy applies only to the Services. The Services may contain links to other web sites or services not operated or controlled by TBV (the &ldquo;Third Party Services&rdquo;). The policies and procedures we described here do not apply to the Third Party Services. The links from the Services do not imply that we endorse or have reviewed the Third Party Services. We suggest contacting those third parties directly for information on their privacy policies.</p>
    <ol>
      <li><strong>EXCLUSIONS</strong></li>
    </ol>
    <p>This Privacy Policy does not apply to any Personal Information collected by TBV other than Personal Information collected through the Services. This Privacy Policy shall not apply to any unsolicited information you provide to TBV through the Services or through any other means. This includes, but is not limited to, information posted to any public areas of the Services, such as forums, any ideas for new products or modifications to existing products, and other unsolicited submissions (collectively, &ldquo;Unsolicited Information&rdquo;). All Unsolicited Information shall be deemed to be non-confidential and TBV shall be free to reproduce, use, disclose, and distribute such Unsolicited Information to others without limitation or attribution.</p>
    <ol>
      <li><strong>ADDITIONAL INFORMATION</strong></li>
    </ol>
    <p>You can help by keeping us informed of any changes such as a change of address or telephone number. If you would like to access your Personal Information, if you have any questions, comments or suggestions about our Privacy Policy or practice, or if you find any errors in our Personal Information about you, please contact us at: <a href='mailto:support@tbvme.com'>support@tbvme.com</a>&nbsp;</p>
    <p>Last Updated: June 4, 2019</p>
  </React.Fragment>
)

export default PrivacyPolicy
