import React from 'react'

import { Row, Col } from 'reactstrap'

import WelcomeCard from './WelcomeCard'

import bn from 'utils/bemnames'
import * as textConstants from 'constants/text'

import dashCardImg from 'assets/img/welcome/dashboard.png'
import addClientCardImg from 'assets/img/welcome/profile.png'
import uploadClientsCardImg from 'assets/img/welcome/upload-clients.png'

const bem = bn.create('welcome-landing')

export const ManagerWelcome = ({
  onDashClick,
  onAddClientClick,
  ...restProps
}) => {
  return (
    <Row className={bem.b()} {...restProps}>
      <Col>
        <Row className='justify-content-center mb-5'>
          <Col>
            <h2 className='font-weight-bold mt-5'>{textConstants.WELCOME_HEADER}</h2>
            <p>{textConstants.WELCOME_SIGNUP_SUCCESS + ' ' + textConstants.WELCOME_PROMPT}</p>
          </Col>
        </Row>
        <Row className='justify-content-center'>
          <Col sm={4}>
            <WelcomeCard
              className='border-0 h-100 tbv-box-shadow pb-3 mb-3'
              imgClassName='mb-4'
              title={textConstants.WELCOME_CARD_DASH_TITLE}
              subtitle=''
              text={textConstants.WELCOME_CARD_DASH_TEXT}
              imgSrc={dashCardImg}
              imgSize={48}
              buttonTitle={textConstants.WELCOME_CARD_DASH_BTN_TITLE}
              onButtonClick={onDashClick}
            />
          </Col>
          <Col sm={4}>
            <WelcomeCard
              className='border-0 h-100 tbv-box-shadow pb-3 mb-3'
              imgClassName='mb-4'
              title={textConstants.WELCOME_CARD_ADDCLIENT_TITLE}
              subtitle=''
              text={textConstants.WELCOME_CARD_ADDCLIENT_TEXT}
              imgSrc={addClientCardImg}
              imgSize={48}
              buttonTitle={textConstants.WELCOME_CARD_ADDCLIENT_BTN_TITLE}
              onButtonClick={onAddClientClick}
            />
          </Col>
          <Col className='d-none' sm={4}>
            <WelcomeCard
              className='border-0 h-100 tbv-box-shadow pb-3 mb-3'
              imgClassName='mb-4'
              title={textConstants.WELCOME_CARD_UPLOADCLIENTS_TITLE}
              subtitle=''
              text={textConstants.WELCOME_CARD_UPLOADCLIENTS_TEXT}
              imgSrc={uploadClientsCardImg}
              imgSize={48}
              buttonTitle={textConstants.WELCOME_CARD_UPLOADCLIENTS_BTN_TITLE}
              onButtonClick={onAddClientClick}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default ManagerWelcome
