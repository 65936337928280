// MeetingsUnitDropDown.js - required meetings unit dropdown
import React from 'react'

import { TBVDropDown } from '../../TBVForm'

import { Field } from 'formik'

import { MEETING_FREQ_ITEMS } from 'constants/forms.js'

const MeetingsUnitDropDown = props => (
  <Field
    component={TBVDropDown}
    {...props}
  />
)

MeetingsUnitDropDown.defaultProps = {
  title: 'e.g. Monthly',
  items: MEETING_FREQ_ITEMS
}

export default MeetingsUnitDropDown
