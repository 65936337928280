// Organization page
import React from 'react'
import { compose, pure, withProps } from 'recompose'
import { withRouter } from 'react-router'
import PropTypes from 'prop-types'
import { queries, utils } from '../../graphql'
import { Table, Col, Row, Card, NavLink as BSNavLink } from 'reactstrap'
import TBVButton from '../TBVButton'
import { localDisplayTime, displayDurationMins } from 'utils/dates.js'
import Hidable from '../Hidable'
import bn from 'utils/bemnames'
import { graphql } from 'react-apollo'
import { withLoading } from '../../hocs/withLoading'
import { NavLink } from 'react-router-dom'

const bem = bn.create('attendance-review')

class ReviewAttendanceComponent extends React.Component {
  render () {
    return (
      <Row className={bem.b()}>
        <Col>
          <Row>
            <Col>
              <Card className='border-0 tbv-box-shadow pb-3'>
                <Hidable show={!!this.props.attendances.length}>
                  <Table responsive borderless>
                    <thead>
                      <tr>
                        <th>Reported by</th>
                        <th>Meeting name</th>
                        <th>Meeting location</th>
                        <th>Meeting Duration</th>
                        <th>Check in</th>
                        <th>Check out</th>
                        <th>&nbsp;</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.props.attendances.map((att, i) => {
                        const { client, meeting, ...attendance } = att
                        return (
                          <tr key={i}>
                            <td>{client.firstName} {client.lastName}</td>
                            <td>{meeting.name}</td>
                            <td>{meeting.address.city}, {meeting.address.state}</td>
                            <td>{displayDurationMins(attendance.meetingDurationMins)}</td>
                            <td>{localDisplayTime(attendance.checkIn, 'h:mmA')}</td>
                            <td>{localDisplayTime(attendance.checkOut, 'h:mmA')}</td>
                            <td><BSNavLink
                              tag={NavLink}
                              to={`/reviewattendance/${att.id}`}
                              className='float-right'
                              activeClassName='active'
                              exact>
                              <TBVButton title='Modify' />
                            </BSNavLink></td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </Table>
                </Hidable>
                <Hidable show={!this.props.attendances.length}>
                  <div className='d-flex justify-content-between w-100 p-4'>
                    <div>No Attendance Needing Location Review</div>
                  </div>
                </Hidable>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    )
  }
}

ReviewAttendanceComponent.propTypes = {
  authUser: PropTypes.object.isRequired,
  admin: PropTypes.object.isRequired
}

export const ReviewAttendance = compose(
  withRouter,
  graphql(queries.attendanceNeedsReview, {
    options: props => {
      return {
        context: utils.generateAuthHeader(props.authUser.token)
      }
    }
  }),
  withLoading,
  withProps(props => {
    return {
      attendances: props.data.attendanceNeedsReview
    }
  }),
  pure
)(ReviewAttendanceComponent)
