// routes.js - routes in app
export const SIGN_UP_CLIENT = '/signup/client'
export const SIGN_UP_OFFICER = '/signup/supervisor'
export const SIGN_UP_MANAGER = '/signup/supervisor'
export const SIGN_UP_INVITE = '/invite/'
export const SIGN_UP_MANAGER_INVITE = '/manager_invite/'
export const LOG_IN_CLIENT = '/login/client'
export const LOG_IN_OFFICER = '/login/supervisor'
export const LOG_IN_MANAGER = '/login/supervisor'
export const HOME = ''
export const DASHBOARD = '/dashboard'
export const MANAGER_ORG = '/myorganization'
export const MANAGER_ORG_MANAGERS = '/myorganization/managers'
export const MANAGER_CREATE_ORG_MEETING = '/myorganization/addmeeting'
export const MANAGER_EDIT_ORG_MEETING = '/myorganization/meeting/:id/edit'
export const CLIENT_PROFILE = '/profile/client'
export const FORGOT_PASSWORD = '/forgotpassword'
export const MANAGER_WELCOME = '/welcome/supervisor'
export const ADD_CLIENT = '/add/client'
export const CLIENTS = '/clients'
export const CLIENT_CONVERSATION = '/client/:id/conversation'
export const CLIENT_DETAIL = '/client/detail/'
export const CLIENT_REPORTS = '/client/reports/'
export const PAYMENT_CLIENT = '/payment/client'
export const CLIENT_WELCOME = '/welcome/client'
export const MANAGER_PROFILE = '/profile/supervisor'
export const MANAGER_CLIENT_ATTENDANCE = '/client/attendance/'
export const MANAGER_ATTENDANCE_REPORT = '/client/attendance/report/'
export const CLIENT_ATTENDANCE = '/attendance'
export const PENDING_INVITES = '/pending-invites'

export const CLIENT_HOME = CLIENT_PROFILE
export const MANAGER_HOME = DASHBOARD
export const SUPERVISOR_HOME = MANAGER_ORG_MANAGERS
export const ADMIN_HOME = '/organizations'
export const ADMIN_ATTENDANCE = '/reviewattendance'
export const ADMIN_ATTENDANCE_REVIEW = '/reviewattendance/:id'
export const ADMIN_MEETINGS = '/meetings'
export const ADMIN_CREATE_MEETING = '/meetings/new/:orgId?'
export const ADMIN_MEETINGS_EDIT = '/meetings/:id/edit'
export const ADMIN_REVIEW_MEETING = '/meetings/:id/review'
export const ORGANIZATION_PAGE = '/organizations/:id'

export const PRIVACY = '/privacy'
export const TOS = '/terms'
