// AttendanceVerify.js - client detail

import React from 'react'

import PropTypes from 'prop-types'

import Roles from 'utils/roles'

import { Col, Row, Card, NavLink as BSNavLink } from 'reactstrap'

import { AttendanceTable } from 'components/Attendance'

import ContactCard from '../Card/ContactCard'
import { NavLink } from 'react-router-dom'
import TBVButton from '../TBVButton'
import { CLIENT_REPORTS } from 'constants/routes'

const ClientContactCardItems = ({clientId}) => {
  return (
    <BSNavLink
      tag={NavLink}
      to={CLIENT_REPORTS + clientId}
      activeClassName='active'
      exact>
      <TBVButton title='Back to details' />
    </BSNavLink>
  )
}

const AttendanceVerify = (props) => {
  const { authUser, client, roleState } = props
  return (
    <Row>
      <Col>
        <Row>
          <Col md={{size: 6, offset: 3}} xl={{size: 4, offset: 4}}>
            <ContactCard
              user={client} >
              <div className='d-flex justify-content-center'>
                <ClientContactCardItems clientId={client.id} />
              </div>
            </ContactCard>
          </Col>
        </Row>
        <Row className={'pt-3 tbv-attendance'}>
          <Col md={{size: 10, offset: 1}}>
            <Card className='border-0 tbv-box-shadow p-5 mb-3'>
              <AttendanceTable authUser={authUser} client={client} roleState={roleState} allowEditStatus />
            </Card>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

AttendanceVerify.propTypes = {
  authUser: PropTypes.object.isRequired,
  client: PropTypes.object.isRequired,
  roleState: PropTypes.oneOf([Roles.CASE_MANAGER, Roles.PROBATION_OFFICER]).isRequired
}

export default AttendanceVerify
