import React from 'react'
import bn from 'utils/bemnames'
import { Col, Row, Card, Input } from 'reactstrap'
import { MESSAGE_GOOD, MESSAGE_BEHIND } from 'constants/text'
import TBVButton from '../TBVButton.js'
import { ConfirmClientMessage } from './ConfirmClientMessage'

const bem = bn.create('message-form')
const TEMPLATES = [
  { id: 'good', message: MESSAGE_GOOD },
  { id: 'behind', message: MESSAGE_BEHIND }
]
const INITIAL_STATE = {
  template: '',
  custom: '',
  isConfirmOpen: false
}

class MessageForm extends React.Component {
  state = INITIAL_STATE
  onTemplateSelect = (evt) => {
    const id = evt.target.id
    if (this.state.template === id) {
      return this.setState(INITIAL_STATE)
    }
    this.setState({
      template: id,
      custom: ''
    })
  }
  onCustomChange = (evt) => {
    this.setState({
      custom: evt.target.value
    })
  }
  canSubmit = () => {
    return !!this.state.template || !!this.state.custom
  }
  toggle = () => {
    this.setState({
      isConfirmOpen: !this.state.isConfirmOpen
    })
  }
  onSubmit = async () => {
    if (!this.canSubmit()) { return false }
    const ids = this.props.clients.map(c => c.id)
    const message = this.getMessage()
    const args = {input: { ids, message }}
    await this.props.submit(args)
    this.setState(INITIAL_STATE)
  }
  getMessage = () => {
    const template = TEMPLATES.find(t => t.id === this.state.template)
    return (template && template.message) || this.state.custom
  }
  render () {
    const classes = this.props.className || ''
    return (
      <Card className={[bem.e('form'), ...classes.split(' ')].join(' ')}>
        <Row>
          <Col>
            <h4 className='font-weight-bold'>{this.props.title}</h4>
          </Col>
        </Row>
        <Row>
          <Col md={6}>
            <label>Templated message</label>
            {TEMPLATES.map(t => (
              <div key={t.id} className={bem.e('template-message')}>
                <div className='msg-text'>
                  <p>{t.message}</p>
                </div>
                <div className='select-template'>
                  <Input onChange={this.onTemplateSelect} id={t.id} checked={this.state.template === t.id} type='checkbox' />
                </div>
              </div>
            ))}
          </Col>
          <Col md={6}>
            <label>Custom message</label>
            <div>
              <Input
                className='custom-message-box'
                disabled={!!this.state.template}
                type='textarea'
                onChange={this.onCustomChange}
                value={this.state.custom}
                placeholder='Send a custom message'
              />
              <br />
              <TBVButton
                className='confirm-btn'
                disabled={!this.canSubmit()}
                onClick={this.toggle}
                block
                title='Send message' />
            </div>
          </Col>
        </Row>
        <ConfirmClientMessage
          submit={this.onSubmit}
          open={this.state.isConfirmOpen}
          toggle={this.toggle}
          msg={this.getMessage()}
        />
      </Card>
    )
  }
}

export default MessageForm
