// TermsLink.js - app download link

import React from 'react'
import { PropTypes } from 'prop-types'

import { TOS } from 'constants/routes.js'

const TermsLink = ({children, ...restProps}) => {
  /* eslint-disable jsx-a11y/anchor-has-content */
  return (
    <a href={TOS} target='_blank' rel='noopener noreferrer' {...restProps}>{children}</a>
  )
}

TermsLink.propTypes = {
  title: PropTypes.string.isRequired
}

TermsLink.defaultProps = {
  title: 'TBV Terms Of Service'
}

export default TermsLink
