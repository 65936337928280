// SignUpPage.js - sign up form

import React from 'react'

import { Card, Col, Row } from 'reactstrap'

import { getRoleTitle, getLogInPath } from 'utils/roles'
import { MANAGER_WELCOME } from 'constants/routes'

import Page from 'components/Page'
import InviteManagerSignUp from '../components/SignUp/Form/InviteManagerSignUp'
import Instructions from '../components/SignUp/Instructions'
import * as textConstants from '../constants/text'

class InviteManagerSignUpPage extends React.Component {
  renderRoleTitle () {
    return getRoleTitle(this.props.roleState)
  }

  handleLogInClick = () => {
    this.props.history.push(getLogInPath(this.props.roleState))
  }

  handleSignedUp = () => {
    this.props.history.push(MANAGER_WELCOME)
  }

  render () {
    const managerInstructions = (
      <Col sm={4}>
        <Instructions
          title={textConstants.INSTRUCTIONS_TITLE_MANAGER}
          header={textConstants.INSTRUCTIONS_HEADER_MANAGER}
          subHeader={textConstants.INSTRUCTIONS_SUBHEADER_MANAGER}
        >
          <li>{textConstants.INSTRUCTIONS_FILL_OUT_FORM}</li>
          <li>{textConstants.INSTRUCTIONS_ADD_CLIENTS}</li>
        </Instructions>
      </Col>
    )

    return (
      <Page className='SignUpPage pt-5'>
        <Row>
          {managerInstructions}
          <Col>
            <Row className='tbv-signup'>
              <Col>
                <Card>
                  <InviteManagerSignUp
                    onLogInClick={this.handleLogInClick}
                    onFormSubmit={this.handleSignedUp}
                    token={this.props.match.params.token}
                  />
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </Page>
    )
  }
}

export default InviteManagerSignUpPage
