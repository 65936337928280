// Menu.js - dashboard menu
import React from 'react'
import {
  MdDashboard,
  MdPeopleOutline,
  MdDomain,
  MdGroupAdd
} from 'react-icons/lib/md'
import { NavLink } from 'react-router-dom'
import {
  Nav,
  NavItem,
  NavLink as BSNavLink
} from 'reactstrap'
import bn from 'utils/bemnames'
import classNames from 'classnames'
import TBVCard from '../Card/TBVCard'
import { CLIENTS, DASHBOARD, MANAGER_ORG, MANAGER_ORG_MANAGERS, PENDING_INVITES } from 'constants/routes.js'
import { CLIENTS_MENU, DASHBOARD_MENU, ORG_MANAGERS, PENDING_INVITES_MENU } from 'constants/text.js'
import { ORG_ROLES } from '../../utils/roles'

const navItems = (addCount, count) => [
  { to: CLIENTS, name: CLIENTS_MENU, exact: true, Icon: MdPeopleOutline },
  { to: DASHBOARD, name: DASHBOARD_MENU, exact: true, Icon: MdDashboard },
  { to: PENDING_INVITES, name: PENDING_INVITES_MENU + (addCount ? ` (${count})` : ''), exact: true, Icon: MdGroupAdd }
]
const navItemsSupervisor = [
  { to: MANAGER_ORG_MANAGERS, name: ORG_MANAGERS, exact: true, Icon: MdPeopleOutline }
]

const bem = bn.create('menu')

class Menu extends React.Component {
  state = {
    isOpenReports: false
  };

  handleClick = name => () => {
    this.setState(prevState => {
      const isOpen = prevState[`isOpen${name}`]

      return {
        [`isOpen${name}`]: !isOpen
      }
    })
  };

  render () {
    const isManager = this.props.manager.role === ORG_ROLES.MANAGER || this.props.manager.role === ORG_ROLES.ORG_MANAGER
    const invites = this.props.manager.clientInvites
    let items = navItems(isManager && invites.length, invites.length)
    if (this.props.manager.role === ORG_ROLES.ORG_SUPERVISOR) {
      items = items.concat(navItemsSupervisor)
    }
    return (
      <TBVCard className='border-white tbv-menu text-left align-items-left mb-3 mr-15px'>
        <div className={classNames(bem.e('content'), 'text-left align-items-left w-100')}>
          <Nav>
            {items.map(({ to, name, exact, Icon }, index) => (
              <NavItem key={index} className={bem.e('nav-item')}>
                <BSNavLink
                  id={`navItem-${name}-${index}`}
                  className='text-capitalize'
                  tag={NavLink}
                  to={to}
                  activeClassName='active'
                  exact={exact}>
                  <Icon className={bem.e('nav-item-icon')} size='24px' />
                  <span style={{fontSize: 16}} className='pl-3'>{name}</span>
                </BSNavLink>
              </NavItem>
            ))}

            { this.props.org &&
              <NavItem className={bem.e('nav-item')}>
                <BSNavLink
                  id={`navItem-org`}
                  className='text-capitalize'
                  tag={NavLink}
                  to={MANAGER_ORG}
                  activeClassName='active'
                  exact>
                  <MdDomain className={bem.e('nav-item-icon')} size='24px' />
                  <span style={{fontSize: 16}} className='pl-3'>Meetings</span>
                </BSNavLink>
              </NavItem>
            }
          </Nav>
        </div>
      </TBVCard>
    )
  }
}

export default Menu
