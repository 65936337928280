import { Content, Footer, Header } from 'components/Layout'
import React from 'react'

class MainLayout extends React.Component {
  render () {
    const { children } = this.props
    return (
      <main className='tbv-app bg-lightBlueGrey'>
        <Content fluid>
          <Header />
          {children}
          <Footer />
        </Content>
      </main>
    )
  }
}

export default MainLayout
