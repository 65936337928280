// FormFields.js - reusable form fields for validation
import * as Yup from 'yup'

import { DURATION_ITEMS, MEETING_FREQ_ITEMS } from 'constants/forms.js'
import States from 'constants/states.js'
import { CLIENT_STATUS } from 'constants/status'

const _reqFieldString = (name) => {
  return Yup.string().required(`${name} is required.`)
}

const _conditionalFieldString = (dependant, name, val = false) => {
  return Yup.string().when(dependant, {
    is: val,
    then: Yup.string().required(`${name} is required.`)
  })
}

const _conditionalFieldUrl = (dependant, name, val = false) => {
  return Yup.string().when(dependant, {
    is: val,
    then: Yup.string()
      .matches(
        /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
        'Must be a valid URL'
      )
      .required(`${name} is required.`)
  })
}

const _reqFieldNumber = (name) => {
  return Yup.number().required(`${name} is required.`)
}

export const EMAIL = _reqFieldString('Email').email('Invalid email address')

export const PASSWORD = _reqFieldString('Password').min(
  6,
  'Password must be at least 6 characters.'
)
export const LOGIN_PASSWORD = _reqFieldString('Password')

export const FIRST_NAME = _reqFieldString('First Name')

export const LAST_NAME = _reqFieldString('Last Name')

export const PHONE = _reqFieldString('Phone Number').min(
  10,
  'Phone Number must be at least 10 digits.'
)

export const STREET_ADDRESS = _reqFieldString('Address')
export const CITY = _reqFieldString('City/Town')
export const STATE = _reqFieldString('State').oneOf(
  Object.keys(States),
  'Invalid selection'
)
export const ZIP_CODE = _reqFieldString('Zip Code').min(
  5,
  'Zip Code must be at least 5 numbers.'
)

export const CC_NUMBER = _reqFieldString(
  'Credit Card Number'
).matches(/^\d{15,16}$/, { message: 'Card Number must be at least 15 digits' })

export const CC_CVC = _reqFieldString('Credit Card CVC').matches(/^\d{3}$/, {
  message: 'CVC must be 3 digits'
})

export const CC_EXPIRE = _reqFieldString(
  'Credit Card CVC'
).matches(/^\d{2}\/\d{4}$/, { message: 'CVC must be MM/YYYY format' })

export const PROBATION_ID = Yup.string()

export const DURATION_SUPERVISION = _reqFieldNumber('Supervision Duration')
  .typeError('Supervision duration must be a valid number of days or months.')
  .integer('Supervision duration must be a valid number of days or months.')
  .positive('Supervision duration must be a positive number.')

export const REQUIRED_MEETINGS = _reqFieldNumber('Required Meetings')
  .typeError('Required Meetngs must be a valid number.')
  .integer('Required Meetings must be a valid number.')
  .positive('Required Meetings must be a positive number.')

export const TERMS_CONSENT = Yup.bool()
  .test(
    'terms',
    'You must accept our Terms and Conditions.',
    (value) => value === true
  )
  .required('You must accept our Terms and Conditions.')

export const MANAGER_NOTE = _reqFieldString('Note')

export const ORGANIZATION = _reqFieldString('name')

export const ATTENDANCE_STATUS = Yup.string()
export const ATTENDANCE_MONTH = Yup.string()

export const DURATION_UNIT = _reqFieldString('Duration Unit').oneOf(
  Object.keys(DURATION_ITEMS),
  'Invalid selection'
)
export const REQUIRED_MEETINGS_FREQ = _reqFieldString(
  'Meeting Frequency'
).oneOf(Object.keys(MEETING_FREQ_ITEMS), 'Invalid selection')

export const MANAGER = Yup.string()
export const ATTENDANCE_REMINDER = Yup.bool()
export const ATTENDANCE_REMINDER_DAYS = Yup.number().when(
  'attendanceReminder',
  {
    is: true,
    then: Yup.number()
      .required('Required for reminder')
      .min(1, 'Must be at least 1')
      .integer('Must be integer')
      .typeError('Must be a number')
  }
)
export const CANCEL_NOTE = _reqFieldString('Note')

export const ATTENDANCE_VALIDATION_STATUS = _reqFieldString(
  'Attendance Status'
)
export const STATUS = _reqFieldString('status').oneOf(
  Object.keys(CLIENT_STATUS),
  'Invalid selection'
)
export const MEETING_FORM = {
  name: _reqFieldString('Meeting name'),
  day: _reqFieldString('Day'),
  type: _reqFieldString('Meeting type'),
  startTime: Yup.date().required(`Start time is required.`),
  endTime: Yup.date('Valid time required').min(Yup.ref('startTime'), 'End must be after start').required(`End time is required.`),
  allowsVirtual: Yup.bool(),
  temporarilySuspended: Yup.bool(),
  virtualUrl: _conditionalFieldUrl('allowsVirtual', 'virtualUrl', true),
  streetAddress: _conditionalFieldString('allowsVirtual', 'address'),
  city: _conditionalFieldString('allowsVirtual', 'address'),
  state: _conditionalFieldString('allowsVirtual', 'address'),
  postalCode: _conditionalFieldString('allowsVirtual', 'address'),
  latitude: _conditionalFieldString('allowsVirtual', 'address'),
  longitude: _conditionalFieldString('allowsVirtual', 'address')
}
