import React from 'react'
import { FormGroup, Label } from 'reactstrap'
import { Field } from 'formik'
import { ValidationFields, TBVFormInput, TBVFormField } from 'components/TBVForm'
import * as Yup from 'yup'
import Hidable from '../../Hidable'

export const ReminderSchema = Yup.object().shape({
  attendanceReminder: ValidationFields.ATTENDANCE_REMINDER,
  attendanceReminderDays: ValidationFields.ATTENDANCE_REMINDER_DAYS
})

export const ReminderPropsMap = {
  attendanceReminder: false,
  attendanceReminderDays: 0
}

export const ReminderSection = (props) => {
  const {
    values
  } = props
  return (
    <React.Fragment>
      <div className='form-row'>
        <FormGroup>
          <Label for='attendanceReminderCheck' check>
            <Field
              component={TBVFormInput}
              type='checkbox'
              name='attendanceReminder'
              id='attendanceReminderCheck'
              checked={values.attendanceReminder}
            />
            Missed Meetings Reminder
          </Label>
        </FormGroup>
      </div>
      <div className='form-row'>
        <Hidable show={values.attendanceReminder}>
          <TBVFormField className='col-md-6' label='Remind after X days' name='attendanceReminderDays' />
        </Hidable>
      </div>
    </React.Fragment>
  )
}
