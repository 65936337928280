import React from 'react'
import PropTypes from 'utils/propTypes'
import classNames from 'classnames'
import { CardSubtitle } from 'reactstrap'
import { Link } from 'react-router-dom'
import Avatar from '../Avatar'
import { CLIENT_REPORTS } from 'constants/routes'
import TBVCard from './TBVCard'
import { AttendanceStatuses } from 'constants/attendance.js'
import { getAttendanceIcon } from '../../utils/attendance'

const ClientCard = ({
  avatar,
  avatarSize,
  title,
  subtitle,
  text,
  subText,
  userId,
  children,
  className,
  bottomBodyClassName,
  statusCounts,
  ...restProps
}) => {
  const classes = classNames('light', className, 'text-secondary')
  return (
    <TBVCard tag={Link} to={CLIENT_REPORTS + userId} className={classes} {...restProps}>
      <Avatar className='mt-3' src={avatar} size={avatarSize} />
      <h3 className='font-weight-bold pt-2'>{title}</h3>
      <CardSubtitle>{subtitle}</CardSubtitle>
      <p className='mb-2'>{text}</p>
      <div className='client-card-statuses'>
        <div>
          <i>
            <img alt={AttendanceStatuses.UNVERIFIED} src={getAttendanceIcon(AttendanceStatuses.UNVERIFIED)} />
          </i>
          <p>{statusCounts[AttendanceStatuses.UNVERIFIED]}</p>
        </div>
        <div>
          <i>
            <img alt={AttendanceStatuses.PENDING} src={getAttendanceIcon(AttendanceStatuses.PENDING)} />
          </i>
          <p>{statusCounts[AttendanceStatuses.PENDING]}</p>
        </div>
        <div>
          <i>
            <img alt={AttendanceStatuses.VERIFIED} src={getAttendanceIcon(AttendanceStatuses.VERIFIED)} />
          </i>
          <p>{statusCounts[AttendanceStatuses.VERIFIED]}</p>
        </div>
      </div>
    </TBVCard>
  )
}

ClientCard.propTypes = {
  userId: PropTypes.string.isRequired,
  avatar: PropTypes.string,
  avatarSize: PropTypes.number,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  text: PropTypes.string,
  className: PropTypes.string,
  bottomBodyClassName: PropTypes.string,
  statusCounts: PropTypes.object
}

ClientCard.defaultProps = {
  avatarSize: 80,
  text: '',
  subText: '',
  subtitle: '',
  bottomBodyClassName: ''
}

export default ClientCard
