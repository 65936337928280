// PaymentForm.js - payment checkout form
import React from 'react'
import Log from 'utils/logging.js'

import { graphql, compose } from 'react-apollo'

import { injectStripe } from 'react-stripe-elements'

import PropTypes from 'prop-types'

import { mutations, utils } from '../../../graphql'

import { ADD_PAYMENT, BILLING_ADDRESS_LABEL, DEFAULT_ERROR_MSG, PAYMENT_SUCCESS } from 'constants/text'

import { AddressSchema, AddressPropsMap, AddressSection } from './AddressSection.js'
import StripeCCSection from './StripeCCSection.js'

import AuthButton from './AuthButton'

import { withFormik } from 'formik'

import { Form } from 'reactstrap'

const US_COUNTRY = 'US'

const _buildValidationSchema = () => {
  return AddressSchema
}

const formikEnhancer = withFormik({
  validationSchema: _buildValidationSchema(),
  mapPropsToValues: props => ({
    ...props.initialValues,
    ...AddressPropsMap
  }),
  handleSubmit: async (values, {setSubmitting, setErrors, setStatus, props}) => {
    const payload = {
      ...values
    }
    const {
      client,
      authUser,
      stripe,
      CreatePaymentProfile,
      UpdatePaymentProfile
    } = props

    Log.info('submit to stripe')
    Log.info(payload)
    try {
      // create payment token

      const {error, token} = await stripe.createToken({type: 'card',
        name: `${client.firstName} ${client.lastName}`,
        address_line1: payload.address,
        address_state: payload.state,
        address_city: payload.city,
        address_zip: payload.postalCode,
        address_country: US_COUNTRY
      })

      if (error) {
        Log.error(error)
        throw error
      } else {
        Log.info('payment token created')
        Log.info(token)
        Log.info(token.id)

        const variables = { token: token.id }
        const input = {variables: variables, context: utils.generateAuthHeader(authUser.token)}

        // create graphQL user
        Log.info('user graphQl update/create payment profile')
        Log.info(input)
        try {
          // update or create based on paymentProfile
          const hasPayment = (!!client.paymentProfile && !!client.paymentProfile.providerId)
          const payment = hasPayment ? await UpdatePaymentProfile(input) : await CreatePaymentProfile(input)
          Log.info('TBV payment profile updated/created')
          Log.info(payment)
          setStatus({success: true})
          props.onFormSubmit()
        } catch (err) {
          Log.info('failed graphQL payment profile update/create')
          Log.error(err)
          setErrors({submit: DEFAULT_ERROR_MSG})
          setSubmitting(false)
        }
      }
    } catch (err) {
      Log.info('failed stripe payment create')
      Log.error(err)
      setErrors({submit: err.message})
      setSubmitting(false)
    }
  },
  displayName: 'PaymentForm'
})

const _setAddressDefaults = (client, values) => {
  // set values from logged in client
  if (client && client.address) {
    values.address = client.address.street || ''
    values.state = client.address.state || ''
    values.city = client.address.city || ''
    values.zipCode = client.address.postalCode || ''
  }
}

const PaymentForm = (props) => {
  const {
    touched,
    errors,
    status,
    handleSubmit,
    isSubmitting,
    values,
    client,
    initialValues,
    children
  } = props

  _setAddressDefaults(client, initialValues)

  return (
    <Form className='tbv-login-form' onSubmit={handleSubmit}>
      <AddressSection addressLabel={BILLING_ADDRESS_LABEL} values={values} />
      <StripeCCSection />
      <AuthButton
        title={ADD_PAYMENT}
        disabled={isSubmitting}
        errors={errors}
        touched={touched}
        status={status}
        successMsg={PAYMENT_SUCCESS}
      />
      {children}
    </Form>
  )
}

PaymentForm.propTypes = {
  onFormSubmit: PropTypes.func.isRequired,
  authUser: PropTypes.object.isRequired
}

const PaymentFormGraphQL = compose(
  graphql(mutations.CREATE_PAYMENT_PROFILE, {name: 'CreatePaymentProfile'}),
  graphql(mutations.UPDATE_PAYMENT_PROFILE, {name: 'UpdatePaymentProfile'}),
  injectStripe
)(formikEnhancer(PaymentForm))

export default PaymentFormGraphQL
