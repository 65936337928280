// Probation Id Section - for client supervision duration

import React from 'react'

import PropTypes from 'prop-types'

import { Label } from 'reactstrap'

import { ValidationFields, TBVFormInput } from '../../TBVForm'
import TermsLink from '../../TermsLink.js'
import PrivacyLink from '../../PrivacyLink.js'

import { Field } from 'formik'

import * as Yup from 'yup'

import { TERMS_LABEL, PRIVACY_LABEL } from 'constants/text.js'

export const TermsSchema = Yup.object().shape({
  terms: ValidationFields.TERMS_CONSENT
})

export const TermsPropsMap = {
  terms: false
}

export const TermsSection = (props) => {
  const {
    termsId,
    termsLabel,
    termsInputProps,
    privacyLabel
  } = props

  return (
    <React.Fragment>
      <Label for={termsLabel} check>
        <Field
          component={TBVFormInput}
          name={termsId}
          showError={false}
          {...termsInputProps}
        />
        <span>Accepts the <TermsLink>{termsLabel}</TermsLink> and <PrivacyLink>{privacyLabel}</PrivacyLink></span>
      </Label>
    </React.Fragment>
  )
}

TermsSection.propTypes = {
  termsId: PropTypes.string,
  termsLabel: PropTypes.string,
  termsInputProps: PropTypes.object
}

TermsSection.defaultProps = {
  termsId: 'terms',
  termsLabel: TERMS_LABEL,
  termsInputProps: {
    type: 'checkbox'
  },
  privacyLabel: PRIVACY_LABEL
}
