// INstructions.js - signup instrunctions

import React from 'react'

import PropTypes from 'prop-types'

import { Row, Col } from 'reactstrap'

import bn from 'utils/bemnames'

const bem = bn.create('signup-instructions')

const Instructions = ({header, subHeader, title, children}) => {
  return (
    <Row className={bem.b()}>
      <Col>
        <Row className='justify-content-center'>
          <Col>
            <h3 className='font-weight-bold'>{header}</h3>
            <p>{subHeader}</p>
          </Col>
        </Row>
        <Row className='justify-content-center'>
          <Col>
            <h5 className='font-weight-bold'>{title}</h5>
            <ol>
              {children}
            </ol>
            <hr />
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

// import * as textConstants from 'constants/text'

Instructions.propTypes = {
  title: PropTypes.string.isRequired,
  header: PropTypes.string.isRequired,
  subHeader: PropTypes.string.isRequired
}

Instructions.defaultProps = {
  title: 'How it Works'
}

export default Instructions
