// WeeklyAttednanceGraph.js
import React from 'react'
import Log from 'utils/logging.js'

import PropTypes from 'prop-types'

import { getColor } from 'utils/colors'

import { Bar } from 'react-chartjs-2'

import { weeklyBarOptions } from 'configs/charts'

import { getWeekEnd, getWeekDate, getMonthWeekStartDates } from 'utils/graph.js'

import * as meetingUtils from 'utils/meetings.js'

import { Query } from 'react-apollo'

import { queries } from '../../graphql'

import LoadingPlaceHolder from '../LoadingPlaceHolder.js'
import Legend from '../Charts/Legend.js'
import { monthItems, MonthsDropDown } from './MonthsDropDown.js'

import moment from 'moment'

const generateLabels = (weeklyAttendance) => {
  // 6/8
  return weeklyAttendance.map(item => (`${getWeekDate(item.meetingWeek, item.meetingYear).format('M/D')} - ${getWeekEnd(item.meetingWeek, item.meetingYear).format('M/D')}`))
}

const generateDataPoints = (weeklyAttendance) => {
  return weeklyAttendance.map(item => (item.meetingCount))
}

const generateBarColors = (weeklyAttendance, reqMeetings) => {
  Log.info('reqMeetings')
  Log.info(reqMeetings)
  return weeklyAttendance.map(item => {
    let weekEndDate = moment().day('Sunday').year(item.meetingYear).week(item.meetingWeek)
    let pace = meetingUtils.getMeetingPace(item.meetingCount, reqMeetings, weekEndDate)
    switch (pace) {
      case meetingUtils.REQ_MEETINGS_BEHIND:
        return getColor('behind')
      case meetingUtils.REQ_MEETINGS_EXCEEDING:
        return getColor('exceeding')
      default:
        return getColor('on-target')
    }
  })
}

const genLineData = (weeklyAttendance, reqMeetings) => {
  const barColors = generateBarColors(weeklyAttendance, reqMeetings)

  return {
    labels: generateLabels(weeklyAttendance),
    datasets: [
      {
        label: 'Meeting Count',
        backgroundColor: barColors,
        borderColor: barColors,
        borderWidth: 1,
        data: generateDataPoints(weeklyAttendance)
      }
    ]
  }
}

const _genGraphItem = (wkDate) => ({ 'meetingYear': wkDate.year(),
  'meetingMonth': parseInt(wkDate.format('M'), 10),
  'meetingWeek': wkDate.week(),
  'meetingCount': 0,
  '__typename': 'WeeklyAttendanceCount'})

const fillWeeklyGaps = (start, weeklyAttendance) => {
  const wkDates = getMonthWeekStartDates(start)
  let cloneData = weeklyAttendance.slice()
  const currWeekNums = weeklyAttendance.map(item => item.meetingWeek)

  // fill the gaps
  wkDates.forEach(wkDate => {
    if (!currWeekNums.includes(wkDate.week())) {
      cloneData.push(_genGraphItem(wkDate))
    }
  })

  // sort data by year and wk
  cloneData.sort((d1, d2) => {
    const d1Date = getWeekDate(d1.meetingWeek, d1.meetingYear)
    const d2Date = getWeekDate(d2.meetingWeek, d2.meetingYear)
    if (moment(d1Date).isBefore(d2Date)) {
      return -1
    } else if (moment(d1Date).isAfter(d2Date)) {
      return 1
    } else {
      return 0
    }
  })

  return cloneData
}

export const genBarOptions = (weeklyAttendance) => {
  let cloneOptions = {...weeklyBarOptions}
  const counts = weeklyAttendance.map(item => item.meetingCount)
  const maxCount = Math.max(...counts)

  if (maxCount && maxCount > 10) {
    cloneOptions.scales.yAxes[0].ticks.stepSize = parseInt(maxCount / 5, 10)
    return cloneOptions
  } else {
    return cloneOptions
  }
}

class WeeklyAttendanceGraph extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      dropdownOpen: false,
      month: monthItems[0]
    }
  }

  toggle = () => this.setState(prevState => ({dropdownOpen: !prevState.dropdownOpen}))

  render () {
    const { authUser, reqMeetings, clientId } = this.props
    const { dropdownOpen, month } = this.state
    const weeklyGraphVars = {clientId: clientId, startDate: month.start, endDate: month.end}

    return (
      <Query query={queries.clientWeeklyCountQuery} variables={weeklyGraphVars} context={authUser.authHeader}>
        {({ loading: weeklyLoading, error: weeklyError, data: weeklyData }) => {
          if (weeklyError) return <div> Error:{weeklyError.message} :(</div>
          if (weeklyLoading) return <LoadingPlaceHolder />

          // fill the gaps in client weekly date
          const weeklyResults = fillWeeklyGaps(month.startObj, weeklyData.clientWeeklyAttendanceCount)

          return (
            <div>
              <div className='d-flex justify-content-between w-100 p-4 tbv-graph'>
                <h4 className='font-weight-bold'>{`Attendance for ${month.name}`}</h4>
                <MonthsDropDown title={month.title} dropdownOpen={dropdownOpen} toggle={this.toggle} onItemClick={item => this.setState({month: item})} />
              </div>
              <div className='ml-4 mr-4'>
                <Bar data={genLineData(weeklyResults, reqMeetings)} options={genBarOptions(weeklyResults)} />
              </div>
              <div className='d-flex justify-content-end pt-3 pr-4'>
                <Legend className='pl-3' />
              </div>
            </div>
          )
        }}
      </Query>
    )
  }
}

WeeklyAttendanceGraph.propTypes = {
  reqMeetings: PropTypes.number.isRequired,
  clientId: PropTypes.string.isRequired
}

export default WeeklyAttendanceGraph
