// Address section - for home and billing adddress

import React from 'react'

import PropTypes from 'prop-types'

import { Field } from 'formik'

import { Form, FormGroup, Label } from 'reactstrap'

import { TBVFormInput } from 'components/TBVForm'

const BaseForm = ({fields, children, ...props}) => {
  return (
    <Form {...props}>
      {fields && fields.map(({ id, label, className, children, ...fieldProps }, idx) => (
        <FormGroup className={className} key={idx}>
          <Label for={label}>{label}</Label>
          <Field component={TBVFormInput} name={id} label={label} key={id} {...fieldProps} />
          {children}
        </FormGroup>
      ))}
      {children}
    </Form>
  )
}

BaseForm.propTypes = {
  fields: PropTypes.array,
  props: PropTypes.object
}

BaseForm.defaultProps = {
  fields: [],
  props: {}
}

export default BaseForm
