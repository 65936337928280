// utils/payments.js

import { PaymentStatus } from 'constants/payments.js'

export const isCanceled = (paymentStatus) => {
  switch (paymentStatus) {
    case PaymentStatus.CANCEL_END_OF_PERIOD:
      return true
    case PaymentStatus.CANCELED:
      return true
    case PaymentStatus.ACTIVE:
      return false
    case PaymentStatus.PAST_DUE:
      return false
    default:
      return false
  }
}

export const isPastDue = (paymentStatus) => {
  switch (paymentStatus) {
    case PaymentStatus.PAST_DUE:
      return true
    default:
      return false
  }
}
