import React from 'react'

import { Row, Col } from 'reactstrap'

import WelcomeCard from './WelcomeCard'

import bn from 'utils/bemnames'
import * as textConstants from 'constants/text'

import notificationsImg from 'assets/img/welcome/notifications.png'

const bem = bn.create('welcome-landing')

export const ClientWelcome = ({
  onProfileClick,
  ...restProps
}) => {
  return (
    <Row className={bem.b()} {...restProps}>
      <Col>
        <Row className='text-center justify-content-center mb-4'>
          <Col sm={4}>
            <h2 className='font-weight-bold mt-5'>{textConstants.CLENT_WELCOME_MESSAGE}</h2>
            <p>{textConstants.CLIENT_WELCOME_INTRO}</p>
          </Col>
        </Row>
        <Row className='justify-content-center'>
          <Col sm={4}>
            <WelcomeCard
              className='border-0 h-100 tbv-box-shadow pb-3 mb-3'
              imgClassName='mb-4'
              title={textConstants.CLIENT_WELCOME_DOWNLOAD}
              subtitle=''
              text={textConstants.CLIENT_WELCOME_DOWNLOAD_INSTRUCTIONS}
              imgSrc={notificationsImg}
              imgSize={48}
              buttonTitle={textConstants.CLIENT_WELCOME_SEND_BUTTON}
              onButtonClick={onProfileClick}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default ClientWelcome
