import React from 'react'

import PropTypes from 'utils/propTypes'

import classNames from 'classnames'

import userImage from 'assets/img/users/user1.jpg'

const Avatar = ({
  rounded,
  circle,
  src,
  size,
  tag: Tag,
  className,
  style,
  ...restProps
}) => {
  const classes = classNames({ 'rounded-circle': circle, rounded }, className)
  return (
    <Tag
      src={src || userImage}
      style={{ width: size, height: size, ...style }}
      className={classes}
      {...restProps}
    />
  )
}

Avatar.propTypes = {
  tag: PropTypes.component,
  rounded: PropTypes.bool,
  circle: PropTypes.bool,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  src: PropTypes.string,
  style: PropTypes.object
}

Avatar.defaultProps = {
  tag: 'img',
  rounded: false,
  circle: true,
  size: 40,
  src: userImage,
  style: {}
}

export default Avatar
