// LandingPage.js - client home page

import React from 'react'
import Log from 'utils/logging.js'

import { Col, Row } from 'reactstrap'

import Page from '../components/Page'
import Landing from '../components/Dashboard/Landing'

import { withManager } from '../components/Session'

class DashboardLandingPage extends React.Component {
  render () {
    Log.info('dash landing')
    Log.info(this.props)
    const { manager, authUser } = this.props
    Log.info(manager)
    return (
      <Page className='DashboardPage'>
        <Row>
          <Col>
            <Landing authUser={authUser} manager={manager} />
          </Col>
        </Row>
      </Page>
    )
  }
}

export default withManager(DashboardLandingPage)
