// Managerssection - for client to select a manager

import React from 'react'

import PropTypes from 'prop-types'

import { FormGroup, Label } from 'reactstrap'

import { ValidationFields } from 'components/TBVForm'

import ManagersDropDown from './ManagersDropDown.js'

import * as Yup from 'yup'

import { MANAGERS_LABEL } from 'constants/text.js'

export const ManagersSchema = Yup.object().shape({
  managerId: ValidationFields.MANAGER
})

export const ManagersPropsMap = {
  managerId: ''
}

export const ManagersSection = (props) => {
  const {
    managerId,
    managerIdIsOpen,
    managerLabel
  } = props

  return (
    <React.Fragment>

      <div className='form-row'>
        <FormGroup className='col-md-6'>
          <Label for='managerIdLabel'>{managerLabel}</Label>
          <ManagersDropDown name={managerId} isOpenId={managerIdIsOpen} />
        </FormGroup>
      </div>

    </React.Fragment>
  )
}

ManagersSection.propTypes = {
  managerId: PropTypes.string,
  managerIdIsOpen: PropTypes.string,
  managerLabel: PropTypes.string
}

ManagersSection.defaultProps = {
  managerLabel: MANAGERS_LABEL,
  managerIdIsOpen: 'managerUnitIsOpen',
  managerId: 'managerId'
}
