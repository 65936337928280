// FeedItem.js - item to be used in notes and activity feed

import React from 'react'

import Avatar from '../Avatar'

export const FeedItem = ({activity, name, photoUrl, time, text}) => {
  const displayAvatar = photoUrl ? (
    <span className='pr-1 ml-2 d-flex flex-column justify-content-center mb-2 mt-2'>
      <Avatar className='mt-2 mb-3' src={photoUrl} size={24} />
    </span>
  ) : <div className={'mt-3 mb-0 mr-3 pr-2'} />

  return (
    <div className='tbv-feedItem d-flex border-top w-100 position-relative align-middle pt-3 pb-3'>
      {displayAvatar}
      <div className='d-flex flex-column justify-content-center position-relative'>
        <p className='mb-0 mr-3 pr-2 font-size-md'>{text}</p>
        <p className='m-0'>
          <small className='text-bluishGrey'>{time}</small>
        </p>
      </div>
    </div>
  )
}

export default FeedItem
