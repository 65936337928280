// AddOrganizationForm.js - form for manager to add note

import React from 'react'
import Log from 'utils/logging.js'
import { graphql, compose } from 'react-apollo'
import PropTypes from 'prop-types'
import { withAdmin } from 'components/Session'
import { Modal, ModalHeader, ModalBody, Form, FormGroup, ModalFooter, Button } from 'reactstrap'
import { queries, mutations, utils } from '../../graphql'
import { DEFAULT_ERROR_MSG, ADD_ORG_BTN, ADD_ORGANIZATION } from 'constants/text.js'
import TBVButton from '../TBVButton.js'
import { ValidationFields, TBVFormField } from 'components/TBVForm'
import { withFormik } from 'formik'
import * as Yup from 'yup'

const formikEnhancer = withFormik({
  validationSchema: Yup.object().shape({
    name: ValidationFields.ORGANIZATION
  }),
  mapPropsToValues: props => ({
    name: ''
  }),
  handleSubmit: async (values, {setSubmitting, setErrors, setStatus, resetForm, props}) => {
    const payload = {
      ...values
    }
    const variables = {
      input: {
        name: payload.name
      }
    }

    Log.info('createOrganization')
    Log.info(variables)

    try {
      const organization = await props.createOrganization(
        { variables: variables,
          context: utils.generateAuthHeader(props.authUser.token),
          update: (store, {data: {createOrganization}}) => {
            const data = store.readQuery({query: queries.getOrganizationsQuery})
            data.organizations.push(createOrganization)
            store.writeQuery({query: queries.getOrganizationsQuery, data: data})
          }
        }
      )
      resetForm({name: ''})
      Log.info('TBV organization created')
      Log.info(organization)
      setStatus({success: true})
      setSubmitting(false)
      props.toggle()
    } catch (error) {
      Log.info('failed graphQL create organization')
      Log.error(error)
      setErrors({submit: DEFAULT_ERROR_MSG})
      setSubmitting(false)
    }
  },
  displayName: 'AddOrganizationForm'
})

const AddOrganizationFormComponent = (props) => {
  const {
    errors,
    isSubmitting,
    handleSubmit,
    resetForm,
    nameId,
    nameLabel,
    nameInputProps
  } = props
  return (
    <Modal
      isOpen={props.open}
      toggle={props.toggle}
    >
      <ModalHeader toggle={props.toggle}>Add Organization</ModalHeader>
      <Form className='p-3' onSubmit={handleSubmit}>
        <ModalBody>
          <FormGroup>
            <TBVFormField label={nameLabel} id={nameId} {...nameInputProps} />
          </FormGroup>
          {errors.submit && <p className='text-danger pt-2'>{errors.submit}</p>}
        </ModalBody>
        <ModalFooter>
          <div className='text-center pt-1 pb-3'>
            <TBVButton title={ADD_ORG_BTN} disabled={isSubmitting} />
            <Button
              color='secondary'
              className='ml-1'
              onClick={() => {
                resetForm({name: ''})
                props.toggle()
              }}>
                        Cancel
            </Button>
          </div>
        </ModalFooter>
      </Form>
    </Modal>
  )
}

AddOrganizationFormComponent.propTypes = {
  nameId: PropTypes.string,
  nameLabel: PropTypes.string,
  nameInputProps: PropTypes.object
}

AddOrganizationFormComponent.defaultProps = {
  nameId: 'name',
  nameLabel: 'Name',
  nameInputProps: {
    type: 'text',
    placeholder: ADD_ORGANIZATION
  }
}

const AddOrganizationForm = compose(
  graphql(mutations.ADD_ORGANIZATION, {name: 'createOrganization'})
)(formikEnhancer(AddOrganizationFormComponent))

export default withAdmin(AddOrganizationForm)
