import * as React from 'react'
import {withRouter} from 'react-router'
import { Button } from 'reactstrap'

const BackButtonComponent = (props) => {
  return (
    <Button
      className='tbv-back-button text-uppercase'
      outline
      color='white'
      size='lg'
      onClick={props.history.goBack}
    >
      <span style={{fontSize: 20, marginLeft: -15}}>〈&nbsp;&nbsp;</span><span style={{fontSize: 18}}>Back</span>
    </Button>
  )
}

export const BackButton = withRouter(BackButtonComponent)
