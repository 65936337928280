// mutations.js - graphQL mutations

import { gql } from 'apollo-boost'

import { UserDetailFragment, ManagerDetailFragment, AttendanceFragment } from './fragments.js'

export const MeetingFrequency = gql`
  enum MeetingFrequency {
      MONTHLY
      WEEKLY
  }
`
export const AddressInput = gql`
  # Address Input
  input AddressInput {
    street: String!
    city: String!
    state: String!
    postalCode: String!
  }
`

// Sign Up Client
export const CREATE_CLIENT = gql`mutation CreateUser(
              $authProviderId: String!,
              $email: String!,
              $phone: String!,
              $firstName: String!,
              $lastName: String!,
              $reqMeetings: Int,
              $reqMeetingsFreq: MeetingFrequency,
              $durationDays: Int,
              $probationId: String,
              $address: AddressInput,
              $inviteToken: String
              ) {
              
              createUser(input:{authProviderId: $authProviderId
                             email: $email 
                             firstName: $firstName
                             lastName: $lastName
                             phone: $phone
                             reqMeetings: $reqMeetings
                             reqMeetingsFreq: $reqMeetingsFreq
                             probationId: $probationId
                             durationDays: $durationDays
                             address: $address
                             inviteToken: $inviteToken
                           }) 
              {id email firstName lastName email role}
        }`

// Sign Up Probation Officer or Client Manager
export const CREATE_MANAGER = gql`mutation CreateUser(
              $authProviderId: String!,
              $email: String!,
              $inviteToken: String,
              $phone: String!,
              $firstName: String!,
              $lastName: String!) {
              
              createManager(input:{authProviderId: $authProviderId
                             email: $email 
                             firstName: $firstName
                             lastName: $lastName
                             inviteToken: $inviteToken
                             phone: $phone}) 
              {id email firstName lastName email role}
        }`

export const CREATE_FIREBASE_USER = gql`
  mutation createFireBaseUser($email: String!, $password: String!) {
    createFireBaseUser(input:{email: $email password: $password}) @client {
        firUser @client {
            uid email
        }
    }
  }
`

// Create Manager Note on Client
export const CREATE_MANAGER_NOTE = gql`mutation CreateManagerNote(
              $clientId: String!,
              $note: String!) {
              
              createManagerNote(input:{clientId: $clientId 
                             note: $note}) 
              {note createdAt}
        }`

// Create Payment Profile for Client
export const CREATE_PAYMENT_PROFILE = gql`mutation CreatePaymentProfile(
              $token: String!) {
              
              createPaymentProfile(input:{token: $token}) 
              {
                ...UserDetail
              }
      }
      ${UserDetailFragment}`

// Update Payment Profile for Client
export const UPDATE_PAYMENT_PROFILE = gql`mutation UpdatePaymentProfile(
              $token: String!) {
              
              updatePaymentProfile(input:{token: $token}) 
              {providerId active}
        }`

// Add Client Invite
export const CREATE_CLIENT_INVITE = gql`mutation CreateClientInvite(
              $email: String!,
              $phone: String!,
              $firstName: String!,
              $lastName: String!,
              $reqMeetings: Int!,
              $reqMeetingsFreq: MeetingFrequency!,
              $durationDays: Int!,
              $probationId: String
              ) {
              
              createInvite(input:{
                             email: $email 
                             firstName: $firstName
                             lastName: $lastName
                             phone: $phone
                             reqMeetings: $reqMeetings
                             reqMeetingsFreq: $reqMeetingsFreq
                             probationId: $probationId
                             durationDays: $durationDays
                           }) 
              {id createdAt}
        }`

// Resend Client Invite
export const RESEND_CLIENT_INVITE = gql`
    mutation resendInvite($input: InviteResendInput) {
      resendInvite(input: $input) {
        id
        firstName
        lastName
        phone
        email
        token
        createdAt
        updatedAt
      }
    }
  `

// Add Manager Invite
export const CREATE_MANAGER_INVITE = gql`mutation createManagerInvite(
  $email: String!,
  $phone: String!,
  $firstName: String!,
  $lastName: String!,
  $organizationId: Int!,
  $roleName: String!
  ) {
  
  createManagerInvite(input:{
                 email: $email 
                 firstName: $firstName
                 lastName: $lastName
                 phone: $phone
                 organizationId: $organizationId,
                 roleName: $roleName
               }) 
  {id createdAt}
}`

// Create Discharge Email for Client
export const CREATE_DISCHARGE_EMAIL = gql`mutation EmailDischarge($clientId: String!) {
              emailDischarge(input:{clientId: $clientId})
        }`

// Update Client
export const UPDATE_CLIENT = gql`mutation UpdateClient(
              $clientId: String!,
              $reqMeetings: Int!,
              $reqMeetingsFreq: MeetingFrequency!,
              $durationDays: Int!,
              $probationId: String,
              $attendanceReminder: Boolean!,
              $attendanceReminderDays: Int!,
              $status: CLIENT_STATUS
              ) {
              
              updateClient(input:{clientId: $clientId
                             reqMeetings: $reqMeetings
                             reqMeetingsFreq: $reqMeetingsFreq
                             probationId: $probationId
                             durationDays: $durationDays
                             attendanceReminder: $attendanceReminder
                             attendanceReminderDays: $attendanceReminderDays
                             status: $status
                           }) 
            {
              ...UserDetail
            }
      }
      ${UserDetailFragment}`

// Update Client
export const UPDATE_MANAGER = gql`mutation UpdateClient(
              $email: String!,
              $phone: String!,
              $firstName: String!,
              $lastName: String!,
              $photoUrl: String
              ) {
              
              updateManager(input:{email: $email 
                             firstName: $firstName
                             lastName: $lastName
                             phone: $phone
                             photoUrl: $photoUrl
                           }) 
            {
              ...ManagerDetail
            }
      }
      ${ManagerDetailFragment}`

// Update Client's Manager
export const UPDATE_CLIENT_MANAGER = gql`mutation UpdateClientManager(
              $managerId: String!
              $clientId: String!
              ) {
              
              updateClientManager(input:{managerId: $managerId, clientId: $clientId}) 
            {
              ...UserDetail
            }
      }
      ${UserDetailFragment}`

// Cancel Subscription
export const CANCEL_CLIENT_SUBSCRIPTION = gql`mutation CancelSubscription($note: String) {
              
              cancelSubscription(input:{note: $note}) 
            {
              ...UserDetail
            }
      }
      ${UserDetailFragment}`

// Update Client Attendance
export const UPDATE_CLIENT_ATTENDANCE = gql`mutation UpdateClientAttendance(
              $clientId: String!,
              $attendanceId: String!,
              $validationStatus: AttendanceValidationStatus!
              ) {
              
              updateClientAttendance(input:{
                clientId: $clientId
                attendanceId: $attendanceId
                validationStatus: $validationStatus
              }) 
            {
              ...AttendanceDetail
            }
      }
      ${AttendanceFragment}`

// Email Client Discharge Report
export const EMAIL_DISCHARGE_REPORT = gql`mutation EmailDischargeReport(
              $clientId: String!
              ) {
              
              emailDischarge(input:{
                clientId: $clientId
              }) 
      }`

export const SEND_CLIENT_SMS = gql`mutation send($input: SendClientMessagesInput!) {
  sendClientMessages(input: $input)
}`

export const ASSIGN_MANAGER_TO_ORG = gql`
mutation assign($input: AssignManagerToOrganizationInput!){
    assignManagerToOrganization(input: $input) {
      id
      managers {
        id
        firstName
        lastName
        photoUrl
        email
      }
    }
  }
`

export const ADD_ORGANIZATION = gql`
mutation createOrganization($input: OrganizationCreateInput!) {
  createOrganization(input: $input) {
      id
      name
    }
  }
`

export const CREATE_ORG_MEETING = gql`
mutation createOrgMeeting($input: OrgMeetingCreateInput!){
  createOrgMeeting(input: $input) {
    id
    name
    address {
      street
      city
      state
      postalCode
    }
    weekDay,
    location {
      lat
      lng
    }
    startTime
    endTime
    type
    allowsVirtual
    virtualUrl
  }
}
`

export const CREATE_MEETING = gql`
mutation createMeeting($input: MeetingCreateInput!){
  createMeeting(input: $input) {
    id
    name
    address {
      street
      city
      state
      postalCode
    }
    weekDay
    nextMeeting
    location {
      lat
      lng
    }
    startTime
    endTime
    type
    allowsVirtual
    virtualUrl
  }
}
`

export const EDIT_MEETING = gql`
mutation updateMeeting($input: MeetingUpdateInput!){
  updateMeeting(input: $input) {
    id
    name
    address {
      street
      city
      state
      postalCode
    }
    weekDay,
    location {
      lat
      lng
    }
    startTime
    endTime
    type
    allowsVirtual
    virtualUrl
  }
}
`
export const ADD_LOGO = gql`
  mutation addLogo($input: AddOrgLogoInput!){
    addOrgLogo(input: $input){
      id
      name
      logoUrl
    }
  }
`

export const ADMIN_UPDATE_MEETING_LOCATION = gql`
mutation adminUpdateMeetingLocation($input: UpdateMeetingLocationInput!){
  adminUpdateMeetingLocation(input: $input)
}
`

export const APPROVE_MEETING = gql`
mutation approve($input: ApproveNewMeetingInput!) {
  approveNewMeeting(input: $input)
}
`

export const DENY_MEETING = gql`
mutation deny($input: DenyNewMeetingInput!) {
  denyNewMeeting(input: $input)
}
`

export const CREATE_NEW_CONVERSATION = gql`
mutation create($input: ManagerChatCreateInput!) {
  createManagerChat(input: $input) {
    id
    clientId
    managerId
    conversationSid
    clientParticipantSid
    managerIdentity
    token
  }
}
`
