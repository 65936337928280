// Link.js - app download link

import React from 'react'
import { PropTypes } from 'prop-types'

import { PRIVACY } from 'constants/routes.js'

const PrivacyLink = ({children, ...restProps}) => {
  /* eslint-disable jsx-a11y/anchor-has-content */
  return (
    <a href={PRIVACY} target='_blank' rel='noopener noreferrer' {...restProps}>{children}</a>
  )
}

PrivacyLink.propTypes = {
  title: PropTypes.string.isRequired
}

PrivacyLink.defaultProps = {
  title: 'TBV Privacy Of Service'
}

export default PrivacyLink
