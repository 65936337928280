// utils/activities.js
import * as status from 'constants/status.js'
import moment from 'moment'

export const createNotificationItem = (attendance, name, photoUrl) => {
  // generate a notification item from attendance object
  let eventOccurred
  let statement
  if (attendance.status === status.CHECKED_OUT) {
    statement = 'successfully checked out of a meeting'
    eventOccurred = moment(attendance.checkOut).calendar()
  } else if (attendance.status === status.CHECKED_IN) {
    statement = 'successfully checked into a meeting'
    eventOccurred = moment(attendance.checkIn).calendar()
  }

  return {id: attendance.id, message: statement, date: eventOccurred, avatar: photoUrl, name, checkIn: attendance.checkIn, checkOut: attendance.checkOut}
}

export const createClientsNotificationList = (clients) => {
  const clientNotifications = clients.map(client => {
    return client.latestAttendanceActivity.map(attendance => {
      return createNotificationItem(attendance, client.firstName, client.photoUrl)
    })
  })
  // flatten all client notifications
  return [].concat.apply([], clientNotifications)
}
