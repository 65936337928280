// Card Action Items

import React from 'react'

import PropTypes from 'prop-types'

import { MdEmail, MdMessage, MdPrint, MdPermIdentity, MdChat } from 'react-icons/lib/md'

import { NavLink } from 'react-router-dom'

import * as textConstants from 'constants/text'
import { DARK_BLUE_GREY } from 'constants/colors'

import * as routes from 'constants/routes'

import withPrint from 'hocs/withPrint'
import AttendanceTable from '../Attendance/AttendanceTable.js'

export const EmailDischargeReport = ({id}) => {
  return (
    <div>
      <MdEmail
        size='30'
        fill={DARK_BLUE_GREY}
      />
      <small className='font-weight-bold pl-1 text-darkBlueGrey'><NavLink to={routes.MANAGER_ATTENDANCE_REPORT + id}>{textConstants.EMAIL_DISCHARGE}</NavLink></small>
    </div>
  )
}

export const MessageClient = () => {
  return (
    <div>
      <MdMessage
        size='30'
        fill={DARK_BLUE_GREY}
      />
      <small className='font-weight-bold pl-1 text-darkBlueGrey'>{textConstants.MESSAGE_CLIENT}</small>
    </div>
  )
}

export const EmailNonComplianceReport = () => {
  return (
    <div>
      <MdEmail
        size='30'
        fill={DARK_BLUE_GREY}
      />
      <small className='font-weight-bold pl-1 text-darkBlueGrey'>{textConstants.EMAIL_NONCOMPLIANCE}</small>
    </div>
  )
}

export const EmailCompletionReport = () => {
  return (
    <div>
      <MdEmail
        size='30'
        fill={DARK_BLUE_GREY}
      />
      <small className='font-weight-bold pl-1 text-darkBlueGrey'>{textConstants.EMAIL_COMPLETION}</small>
    </div>
  )
}

export const DischargeClient = () => {
  return (
    <div>
      <MdMessage
        size='30'
        fill={DARK_BLUE_GREY}
      />
      <small className='font-weight-bold pl-1 text-darkBlueGrey'>{textConstants.DISCHARGE_CLIENT}</small>
    </div>
  )
}

export const PrintAttendanceTable = withPrint(AttendanceTable)

export const PrintReport = ({ authUser, client, roleState, start, end }) => {
  return (
    <div>

      <PrintAttendanceTable roleState={roleState} client={client} authUser={authUser} start={start} end={end}>
        <MdPrint
          size='30'
          fill={DARK_BLUE_GREY}
        />
        <small className='pl-1'>
          {textConstants.PRINT_REPORT}
        </small>
      </PrintAttendanceTable>
    </div>
  )
}

export const EditClientInfo = ({id}) => {
  return (
    <div>
      <NavLink to={routes.CLIENT_DETAIL + id}>
        <MdPermIdentity
          size='30'
          fill={DARK_BLUE_GREY}
        />
        <small className='pl-1'>{textConstants.EDIT_CLIENT_INFO}</small>
      </NavLink>
    </div>
  )
}

export const ChatWithClient = ({id}) => {
  return (
    <div>
      <NavLink to={`/client/${id}/conversation`}>
        <MdChat
          size='30'
          fill={DARK_BLUE_GREY}
        />
        <small className='pl-1'>{textConstants.CHAT_CLIENT}</small>
      </NavLink>
    </div>
  )
}

EditClientInfo.propTypes = {
  id: PropTypes.string.isRequired
}

export const EmailClient = ({email, subject}) => {
  const escapedSubject = escape(subject)
  const escapedEmail = escape(email)
  const mailToHref = `mailto:${escapedEmail}?Subject=${escapedSubject}`
  return (
    <div>
      <a href={mailToHref} target='_top'>
        <MdEmail
          size='30'
          fill={DARK_BLUE_GREY}
        />
        <small className='pl-1'>
          {textConstants.EMAIL_CLIENT}
        </small></a>
    </div>
  )
}

EmailClient.propTypes = {
  email: PropTypes.string.isRequired,
  subject: PropTypes.string.isRequired
}

EmailClient.defaultProps = {
  subject: textConstants.EMAIL_CLIENT_SUBJECT
}
