// Landing - manager Landing component

import React from 'react'
import { compose, withProps, pure } from 'recompose'
import PropTypes from 'prop-types'
import { queries, utils } from '../../graphql'
import { withRouter } from 'react-router'

import { Table, Col, Row, Card, NavLink as BSNavLink, Button } from 'reactstrap'

import bn from 'utils/bemnames'
import { graphql } from 'react-apollo'
import { withLoading } from '../../hocs/withLoading'
import TBVButton from '../TBVButton'
import { NavLink } from 'react-router-dom'
import AddOrganizationForm from './AddOrganizationForm'
const bem = bn.create('dash-landing')

class LandingComponent extends React.Component {
  state = {
    modalOpen: false
  }

  toggle = () => {
    this.setState({
      modalOpen: !this.state.modalOpen
    })
  }

  render () {
    return (
      <Row className={bem.b()}>
        <Col>
          <Row>
            <Col>
              <Card className='border-0 tbv-box-shadow pb-3'>
                <div className='pt-3 pr-3'>
                  <Button outline className='float-right' color='primary' onClick={this.toggle}>+ Add organization</Button>
                </div>
                <div className='d-flex justify-content-between w-100 p-4'>
                  <Table responsive striped borderless>
                    <thead>
                      <tr>
                        <th>Org #</th>
                        <th>Organization Name</th>
                        <th>&nbsp;</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.props.organizations.map((o, i) => (
                        <tr key={i}>
                          <td>{o.id}</td>
                          <td>{o.name}</td>
                          <td><BSNavLink
                            tag={NavLink}
                            to={`/organizations/${o.id}`}
                            className='float-right'
                            activeClassName='active'
                            exact>
                            <TBVButton title='Manage' />
                          </BSNavLink></td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              </Card>
            </Col>
          </Row>
        </Col>
        <AddOrganizationForm open={this.state.modalOpen} toggle={this.toggle} />
      </Row>
    )
  }
}

LandingComponent.propTypes = {
  authUser: PropTypes.object.isRequired,
  admin: PropTypes.object.isRequired
}

export const Landing = compose(
  withRouter,
  graphql(queries.getOrganizationsQuery, {
    options: props => {
      return {
        context: utils.generateAuthHeader(props.authUser.token)
      }
    }
  }),
  withLoading,
  withProps(props => {
    return {
      organizations: props.data.organizations
    }
  }),
  pure
)(LandingComponent)
