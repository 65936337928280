// RequiredMeetings.js - icons
import React from 'react'
import Icon from 'react-icon-base'

import PropTypes from 'prop-types'

const RequiredMeetings = (props) => {
  const { fillObj, ...restProps } = props

  return (
    <Icon viewBox='0 0 45 45' {...restProps}>
      <title>{props.title || 'Required Meetings'}</title>
      <g>
        <g fillRule='evenodd'>
          <path fill={fillObj} d='M37.625 42.406c3.659 0 6.625-8.142 6.625-11.438 0-3.296-2.966-5.968-6.625-5.968S31 27.672 31 30.968s2.966 11.438 6.625 11.438z' />
          <path fillRule='nonzero' d='M29.063 26.688a.938.938 0 0 1-.938-.938v-1.875h-11.25v11.25h11.25a.938.938 0 0 1 0 1.875H15.937a.938.938 0 0 1-.937-.938V22.938c0-.518.42-.938.938-.938h13.124c.518 0 .938.42.938.938v2.812c0 .517-.42.938-.938.938zm3.75 16.437a.938.938 0 0 1 0 1.875H6.562a.938.938 0 0 1-.938-.938V22.5H.937a.937.937 0 0 1-.664-1.601L20.236.937c1.197-1.201 3.327-1.198 4.523 0l5.239 5.237V.937c0-.517.42-.937.937-.937h7.5c.518 0 .938.42.938.938v14.61l5.351 5.35a.937.937 0 1 1-.662 1.601l-4.687.001a.938.938 0 0 1-1.875 0v-.938c0-.517.42-.937.937-.937H41.8l-4.024-4.024a.936.936 0 0 1-.274-.663V1.874h-5.625v6.563a.937.937 0 0 1-1.6.663l-6.839-6.838c-.497-.497-1.376-.493-1.871 0L3.2 20.625h3.362c.518 0 .938.42.938.938v21.562h25.312z' />
          <path fillRule='nonzero' d='M37.5 24c4.136 0 7.5 3.364 7.5 7.5 0 3.83-6.064 11.856-6.758 12.758a.932.932 0 0 1-1.484 0C36.063 43.355 30 35.33 30 31.5c0-4.136 3.364-7.5 7.5-7.5zm0 18.12c2.063-2.837 5.625-8.233 5.625-10.618a5.632 5.632 0 0 0-5.625-5.625 5.632 5.632 0 0 0-5.625 5.625c0 2.387 3.563 7.783 5.625 10.618zM37.75 28a3.754 3.754 0 0 1 3.75 3.75 3.754 3.754 0 0 1-3.75 3.75A3.754 3.754 0 0 1 34 31.75 3.754 3.754 0 0 1 37.75 28zm0 5.625a1.877 1.877 0 0 0 1.875-1.875c0-1.033-.84-1.875-1.875-1.875a1.877 1.877 0 0 0-1.875 1.875c0 1.033.84 1.875 1.875 1.875z' />
        </g>
      </g>
    </Icon>
  )
}

RequiredMeetings.propTypes = {
  fillObj: PropTypes.string.isRequired
}

RequiredMeetings.defaultProps = {
  fillObj: ''
}

export default RequiredMeetings
