// AttendanceVerify.js - client attendance verify page for manager

import React from 'react'

import Page from 'components/Page'
import { AttendanceVerify } from 'components/Dashboard'

import { withManager } from '../components/Session'

import { getClient } from 'utils/manager.js'

class AttendanceVerifyPage extends React.Component {
  render () {
    const { authUser, manager, roleState, match } = this.props
    const clientId = match.params.id
    const client = getClient(manager, clientId)

    return (
      <Page className='AttendanceVerifyPage'>
        <AttendanceVerify
          authUser={authUser}
          client={client}
          roleState={roleState}
        />
      </Page>
    )
  }
}

export default withManager(AttendanceVerifyPage)
