import React from 'react'

import classNames from 'classnames'
import { Nav, Navbar, Row } from 'reactstrap'

import logoSVGImage from 'assets/img/logo/tbv-app-icon.svg'

import DownloadLink from 'components/DownloadLink'

import * as textConstants from 'constants/text'

import bn from 'utils/bemnames'

import LoggedInLink from 'components/LoggedInLink'

const bem = bn.create('header')

const Header = (props) => {
  return (
    <Navbar light expand className={classNames(bem.b('bg-white'), 'd-flex')}>
      <DownloadLink className='navbar-brand d-flex'>
        <Row className='d-flex justify-content-center align-items-center pl-2 pl-sm-5 ml-sm-2 ml-md-4 ml-lg-5'>
          <img
            src={logoSVGImage}
            className='rounded'
            style={{ width: 60, height: 60, cursor: 'pointer' }}
            alt='logo'
          />
          <span className={classNames(bem.e('brand-text'), 'd-none d-sm-block pl-2 text-darkBlueGrey')}>{textConstants.HEADER_DOWNLOAD}</span>
        </Row>
      </DownloadLink>
      <Nav navbar className={classNames(bem.e('nav-right'), 'pr-2 pr-sm-5 mr-sm-2 mr-md-4 mr-lg-5')}>
        <LoggedInLink />
      </Nav>
    </Navbar>
  )
}

export default Header
