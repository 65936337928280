// ManagerWelcomePage.js - manager home page

import React from 'react'

import { Col, Row } from 'reactstrap'

import Page from 'components/Page'
import { ManagerWelcome } from 'components/Welcome'

import { DASHBOARD, ADD_CLIENT } from 'constants/routes'

import withAuthorization from 'components/Session/withAuthorization'

class WelcomePage extends React.Component {
  handleDashClick = () => {
    this.props.history.push(DASHBOARD)
  }

  handleAddClientClick = () => {
    this.props.history.push(ADD_CLIENT)
  }

  render () {
    return (
      <Page
        className='WelcomePage'>
        <Row className='text-center'>
          <Col>
            <ManagerWelcome
              onDashClick={this.handleDashClick}
              onAddClientClick={this.handleAddClientClick}
            />
          </Col>
        </Row>
      </Page>
    )
  }
}

const authCondition = (authUser) => !!authUser

export default withAuthorization(authCondition)(WelcomePage)
