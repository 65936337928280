import React from 'react'
import { compose, withProps, pure } from 'recompose'
import { graphql } from 'react-apollo'
import Log from 'utils/logging.js'
import { Col, Row } from 'reactstrap'
import Page from '../components/Page'
import { withManager } from '../components/Session'
import Managers from '../components/Dashboard/Managers'
import { MANAGER_ACL } from 'constants/acl'
import { managerCan } from '../components/Session/permission'
import { withLoading } from '../hocs/withLoading'
import { queries, utils } from '../graphql'

class ManagersPage extends React.Component {
  componentWillMount () {
    if (!this.props.manager.organization || !managerCan(MANAGER_ACL.orgSupervisor, this.props.manager.role)) {
      this.props.history.push('/')
    }
  }

  render () {
    Log.info('org managers page')
    Log.info(this.props)
    const { manager, authUser } = this.props
    Log.info(manager)
    return (
      <Page className='DashboardPage'>
        <Row>
          <Col>
            <Managers authUser={authUser} managers={this.props.managers} />
          </Col>
        </Row>
      </Page>
    )
  }
}

export default compose(
  withManager,
  graphql(queries.getOrganizationQuery, {
    options: props => {
      return {
        context: utils.generateAuthHeader(props.authUser.token),
        variables: {
          input: {
            id: props.manager.organization.id
          }
        }
      }
    }
  }),
  withLoading,
  withProps(props => {
    return {
      managers: props.data.organization.managers.filter(m => m.id !== props.manager.id)
    }
  }),
  pure
)(ManagersPage)
