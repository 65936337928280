import MessageBubble from './MessageBubble'
import React, {PureComponent} from 'react'
import PropTypes from 'prop-types'

class ChatMessages extends PureComponent {
  render = () => {
    if (!this.props.messages.length) {
      return (
        <div className='empty-history'>
          <p>Empty conversation, type below to get started</p>
        </div>
      )
    }
    return (
      <ul>
        {
          this.props.messages.map(m => {
            return (
              <MessageBubble
                key={m.index}
                displayNames={this.props.displayNames}
                direction={m.author === this.props.identity ? 'outgoing' : 'incoming'} message={m}
              />
            )
          })
        }
      </ul>
    )
  }
}

ChatMessages.propTypes = {
  displayNames: PropTypes.object.isRequired,
  identity: PropTypes.string.isRequired,
  messages: PropTypes.arrayOf(PropTypes.object).isRequired
}

export default ChatMessages
